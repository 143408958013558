import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { minutesToTimeString } from "../../libs/time";
import dayjs from "dayjs";

class EmployeeAttendancePost extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const {
        _id,
        Employee,
        EmployeeAttendanceRecord,
        _createdAt
      } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "EmployeeAttendanceRecord",
        Employee,
        EmployeeAttendanceRecord,
        _createdAt
      });
    }
  };

  renderCheckIn = record => {
    if (
      record.checkInStatus === "Masuk" ||
      record.checkInStatus === "Terlambat"
    ) {
      if (record.checkInMode === "Mesin") {
        return `${record.checkInStatus} Pukul ${minutesToTimeString(
          record.checkInMinutes
        )}`;
      } else {
        return `${record.checkInStatus}`;
      }
    } else if (record.checkInStatus === "Belum Presensi") {
      return "Belum Presensi";
    } else {
      return record.checkInStatus;
    }
  };

  renderCheckOut = record => {
    if (
      record.checkOutStatus === "Pulang" ||
      record.checkOutStatus === "Pulang Dulu"
    ) {
      if (record.checkOutMode === "Mesin") {
        return `${record.checkOutStatus} Pukul ${minutesToTimeString(
          record.checkOutMinutes
        )}`;
      } else {
        return `${record.checkOutStatus}`;
      }
    } else if (record.checkOutStatus === "Belum Presensi") {
      return "Belum Presensi";
    } else {
      return "";
    }
  };

  render() {
    const { Employee, EmployeeAttendanceRecord, _createdAt } = this.props;
    return (
      <View
        style={{
          paddingVertical: 7
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              paddingBottom: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#4a69bd",
                  marginTop: 2
                }}
              >
                <i className="fa fa-exclamation-circle" /> Update Presensi
                Pegawai
              </Text>
              <Text style={{ fontSize: 12, color: "#4a69bd", marginTop: 2 }}>
                {dayjs(_createdAt).format("dddd, DD MMMM YYYY   HH:mm")}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                  borderColor: "#eee",
                  borderWidth: 2,
                  borderRadius: 4
                }}
              >
                <View
                  style={{
                    margin: 4,
                    padding: 0,
                    backgroundColor:
                      Employee && Employee.profileImageUrl
                        ? "white"
                        : "#4a69bd",
                    borderRadius: 3,
                    justifyContent: "center"
                  }}
                >
                  {Employee && Employee.profileImageUrl ? (
                    <Image
                      source={{
                        uri: Employee.profileImageUrl
                      }}
                      style={{
                        minWidth: 80,
                        minHeight: 135
                      }}
                      resizeMode="cover"
                    />
                  ) : (
                    <View
                      style={{
                        minWidth: 80,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 20
                      }}
                    >
                      <i
                        className="fa fa-user-tie fa-2x"
                        style={{
                          color: "white"
                        }}
                      />
                    </View>
                  )}
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 4,
                    marginBottom: 8,
                    marginLeft: 6
                  }}
                >
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 17,
                      fontWeight: "bold"
                    }}
                  >
                    Jadwal {EmployeeAttendanceRecord.scheduleName}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "bold"
                    }}
                  >
                    {dayjs(
                      new Date(
                        EmployeeAttendanceRecord.year,
                        EmployeeAttendanceRecord.month - 1,
                        EmployeeAttendanceRecord.date,
                        1,
                        1,
                        1,
                        1
                      )
                    ).format("dddd, DD MMMM YYYY")}
                  </Text>
                  <Text
                    style={{ marginBottom: 4, color: "#4a69bd", fontSize: 15 }}
                    numberOfLines={1}
                  >
                    <i className="fa fa-user-clock" /> {Employee.name}
                  </Text>
                  <Text>
                    <i className="fa fa-calendar-day" /> Status Masuk:{" "}
                    {this.renderCheckIn(EmployeeAttendanceRecord)}
                  </Text>
                  <Text style={{ marginTop: 2 }}>
                    <i className="fa fa-calendar-day" /> Status Pulang:{" "}
                    {this.renderCheckOut(EmployeeAttendanceRecord)}
                  </Text>
                  <Text style={{ marginTop: 4, color: "#4a69bd" }}>
                    Klik untuk melihat riwayat presensi
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default EmployeeAttendancePost;
