import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native-web";
// import Ionicon from "react-native-vector-icons/Ionicons";
import { formatCurrency } from "../../libs/numbers";
// import { format } from "date-fns";
import locale from "date-fns/locale/id";
import dayjs from "dayjs";

const statusColor = {
  waiting: "#e67e22",
  paid: "#27ae60",
};
const statusText = {
  waiting: "MENUNGGU PEMBAYARAN",
  paid: "SUKSES TERBAYAR",
};
const statusImageUri = {
  // waiting: "/static/images/process-money-payment.png",
  // paid: "/static/images/money-exchange.png",
  waiting: "../../assets/process-money-payment.png",
  paid: "../../assets/money-exchange.png",
};
const statusIcon = {
  waiting: "fa fa-exclamation-circle",
  paid: "fa fa-check-circle",
};
const tagihanTextByStatus = {
  waiting: "Tagihan yang Belum Dibayar",
  paid: "Tagihan yang Terbayar",
};

class SchoolPayBills extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, SchoolPayBills, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "SchoolPayBills",
        SchoolPayBills,
        _createdAt,
      });
    }
  };

  render() {
    let { SchoolPayBills, Student, _createdAt } = this.props;
    if (!SchoolPayBills) {
      return <div></div>;
    }
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 6,
              paddingBottom: 4,
              borderRadius: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  defaultSource={{
                    uri: "" + statusImageUri[SchoolPayBills.status],
                  }}
                  source={{
                    uri: statusImageUri[SchoolPayBills.status],
                  }}
                />
              </View>

              <View
                style={{
                  flex: 1,
                  paddingVertical: 8,
                  paddingHorizontal: 12,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: 2,
                  }}
                >
                  <i
                    className={`${statusIcon[SchoolPayBills.status]}`}
                    style={{ color: statusColor[SchoolPayBills.status] }}
                  />{" "}
                  PEMBAYARAN TAGIHAN
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#00a8ff",
                    marginTop: 2,
                  }}
                >
                  {/* {format(_createdAt, "dddd, DD MMMM YYYY   HH:mm", { locale })} */}
                  {dayjs(_createdAt)
                    .locale("id")
                    .format("dddd, DD MMMM YYYY HH:mm")}
                </Text>
                <Text style={{ marginVertical: 6, fontSize: 20 }}>
                  {SchoolPayBills.transactionNumber}
                </Text>
                <Text
                  style={{
                    marginBottom: 4,
                    color: "#2980b9",
                    fontWeight: "bold",
                  }}
                >
                  <i className="fa fa-contact" />
                  {Student ? Student.name : ""}
                </Text>

                <View
                  style={{
                    marginTop: 6,
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginRight: 10,
                    }}
                  >
                    Total Rp.{" "}
                    <Text style={{ color: statusColor[SchoolPayBills.status] }}>
                      {formatCurrency(SchoolPayBills.total)}
                    </Text>{" "}
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 12,
                    backgroundColor: statusColor[SchoolPayBills.status],
                    padding: "5px",
                    borderRadius: "0.2rem",
                    alignSelf: "flex-start",
                  }}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    <i className={`${statusIcon[SchoolPayBills.status]}`} />{" "}
                    {statusText[SchoolPayBills.status]}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                borderTopColor: "#e0e0e0",
                borderTopWidth: 1,
                marginTop: 4,
                paddingVertical: 8,
                paddingHorizontal: 24,
              }}
            >
              <View style={{ flexDirection: "row" }}>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    marginTop: 2,
                  }}
                >
                  {tagihanTextByStatus[SchoolPayBills.status]}:
                </Text>
              </View>
              {SchoolPayBills.details.map((detail) => (
                <View
                  key={detail._id}
                  style={{
                    flex: 1,
                    marginTop: 5,
                    // marginLeft: 6,
                    marginBottom: 2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      lineHeight: "1.6",
                    }}
                  >
                    <i
                      className={`${statusIcon[SchoolPayBills.status]}`}
                      style={{ color: statusColor[SchoolPayBills.status] }}
                    />{" "}
                    Tagihan {detail.studentBillName}
                    {detail.academicYear && detail.month
                      ? ` (${detail.month} TA. ${detail.academicYear})`
                      : ""}
                    {"\n"}Pembayaran Sebesar{" "}
                    <b>Rp. {formatCurrency(detail.amount)}</b>
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default SchoolPayBills;
