import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  TouchableNativeFeedback,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import uuidV4 from "uuid/v4";
import { withI18n } from "../libs/withI18n";
import dayjs from "dayjs";
import { handleError } from "../libs/errors";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class SubjectVideoMeeting extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    classroomLists: [],
    listVideos: [],

    warningModal: {
      studentName: "",
      warningModalVisible: false,
      joinUrl: "",
    },
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUERIES,
      variables: {
        _id: accountSession._id,
        classroomId: urlValues.classroomId,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    let listVideos =
      result.data && result.data.allVideoMeetings
        ? result.data.allVideoMeetings
        : [];

    if (urlValues.studentId) {
      const url = queryString.stringify(urlValues);
      this.props.history.replace({
        pathname: "/subject_video_meeting",
        search: `?${url}`,
      });
    }

    this.setState({
      allStudents: [...students],
      listVideos,
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/classroom_list",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });

    this.initiate();
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openWarning = (student, video) => (e) => {
    this.setState({
      warningModal: {
        warningModalVisible: true,
        joinUrl: video.joinUrl,
        studentName: student.name,
      },
    });
  };

  openZoom = (video) => async (e) => {
    if (video.status !== "END") {
      // window.open(video.joinUrl, "__blank");

      try {
        if (video !== "END") {
          const joinDate = dayjs().format("YYYY-MM-DD");
          let joinTime = dayjs().format("HH:mm");
          if (joinDate !== video.startDate) {
            throw {
              message: "Bukan Tanggalnya",
            };
          }

          let status = "";
          if (joinDate === video.startDate) {
            let mTime = this.timeStringToMinutes(video.startTime);
            joinTime = this.timeStringToMinutes(joinTime);

            if (joinTime <= mTime) {
              status = "Masuk";
            } else  {
              status = "Terlambat";
            }
            // if (joinTime < mTime) {
            //   throw {
            //     message: "Belum Jamnya",
            //   };
            // }
          }

          console.log(video);
          const result = await mwvClient.mutate({
            mutation: UPDATE_STUDENT_ATTENDANCE_ZOOM,
            variables: {
              meetingId: video._id,
              studentId: this.props.router.query.studentId,
              status,
            },
            fetchPolicy: "no-cache",
          });

          window.open(video.joinUrl, "__blank");
        }
      } catch (err) {
        // console.log(err)
        handleError(err);
      }
    }
  };

  render() {
    const { allStudents, listVideos, warningModal } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"School Space"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            flex: 1,
            marginTop: 190,

            // flexDirection: "column",
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-video" /> {"Video Meeting"}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 30,
            marginBottom: "100vh",
            padding: 10,
          }}
        >
          {listVideos.length === 0 ? (
            <View
              style={{
                marginBottom: "50px",
              }}
            >
              <section className="card">
                <div className="card-block">
                  <center>
                    <Image
                      style={{ width: 250, height: 250 }}
                      source={require("../assets/video-meeting.png")}
                    />
                    <h5>Video Meeting Tidak Ditemukan</h5>
                  </center>
                </div>
              </section>
            </View>
          ) : (
            listVideos.map((video) => (
              <div
                className="card"
                style={{
                  marginBottom: 10,
                  borderRadius: "10px",
                }}
                onClick={this.openZoom(video)}
              >
                <div className="card-body">
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <View>
                      <Image
                        style={{
                          width: 100,
                          height: 100,
                          overflow: "hidden",
                        }}
                        source={require("../assets/video-meeting.png")}
                      />
                    </View>
                    <View
                      style={{
                        paddingLeft: 10,
                        flex: 1,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          marginBottom: 5,
                        }}
                      >
                        Topik: <b>{video.topic}</b>
                      </Text>
                      <Text>
                        Agenda: <b>{video.agenda}</b>
                      </Text>
                      <Text>
                        Waktu:{" "}
                        <b>
                          {video.startDate} {video.startTime}
                        </b>
                      </Text>
                    </View>
                  </View>
                </div>

                <div
                  className="card-footer"
                  style={{
                    backgroundColor:
                      video.status === "READY"
                        ? "#388e3c"
                        : video.status === "ON GOING"
                        ? "#039be5"
                        : "#fb8c00",
                    color: "#fff",
                    fontSize: 16,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <center>{video.status}</center>
                </div>
              </div>
            ))
          )}
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQuery($_id: String, $classroomId: String!) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
    allVideoMeetings(classroomId: $classroomId) {
      _id
      status
      topic
      agenda
      startDate
      startTime
      lateTolerance
      passwordMeeting
      agenda
      status
      Classroom {
        _id
        name
      }
      joinUrl
    }
  }
`;

const UPDATE_STUDENT_ATTENDANCE_ZOOM = gql`
  mutation updateStudentAttendanceByZoom(
    $meetingId: String!
    $studentId: String!
    $status: String!
  ) {
    updateStudentAttendanceByZoom(
      meetingId: $meetingId
      studentId: $studentId
      status: $status
    )
  }
`;

export default withRouter(SubjectVideoMeeting);
