import React, { Component } from "react";
import {
  Image,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import dayjs from "dayjs";
import lodash from "lodash";
import { defaultAcademicYear } from "../libs/time";
import App, {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";
import { handleError } from "../libs/errors";
import { FormModal } from "../components/Modal";

let urlValues;
class ListSubjectPresence extends Component {
  state = {
    // Adhe
    // username: __DEV__ ? "083854430431" : "",
    // password: __DEV__ ? "123123" : "",

    // Rama
    // username: __DEV__ ? "089624809625" : "",
    // password: __DEV__ ? "123123" : "",

    username: "083831114477",
    password: "3335557",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {},
    description: "",

    jamKe: 0,
    listJam: [],
    description: "",

    jurnalMengajarModal: false,
    daftarJurnalMengajar: [],
    selectedJurnalMengajar: [],
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.componentWillReceiveProps(this.props);
  };
  componentWillReceiveProps = (nextProps) => {
    let listJam = [];
    for (let i = 1; i <= 12; i++) {
      listJam.push(i);
    }
    this.setState({
      attendances: nextProps.studentAttendancePerSubject,
      description: nextProps.studentAttendancePerSubjectDescription,
      listJam,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (this.divElement) {
      const height = this.divElement.clientHeight;
      this.setState({
        height,
      });
    }

    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  }

  refresh = () => {
    window.location.reload();
  };

  handleStatus = (attendance) => async (e) => {
    if (e) e.preventDefault();

    let results = [];
    for (const att of this.state.attendances) {
      if (att._id === attendance._id) {
        results.push({
          ...att,
          status: e.target.value,
          lateTime:
            e.target.value === "Terlambat" ? dayjs().format("HH:mm") : "",
        });
      } else {
        results.push({ ...att });
      }
    }

    this.setState({
      attendances: [...results],
    });
  };

  handleSetAll = (e) => {
    if (e) e.preventDefault();
    let results = [];
    for (const att of this.state.attendances) {
      results.push({
        ...att,
        status: "Masuk",
      });
    }

    this.setState({
      attendances: results,
    });
  };

  handleResetAll = (e) => {
    if (e) e.preventDefault();
    let results = [];
    for (const att of this.state.attendances) {
      results.push({
        ...att,
        status: "",
      });
    }

    this.setState({
      attendances: results,
    });
  };

  handleInput = (attendance) => (e) => {
    const selectedStudent = {
      ...attendance,
      description: e.target.value,
    };

    let results = this.state.attendances.filter(
      (att) => att._id !== attendance._id
    );

    this.setState({
      attendances: [...results, selectedStudent],
    });
  };

  handleLateTime = (attendance) => (e) => {
    const selectedStudent = {
      ...attendance,
      lateTime: e.target.value,
    };

    let results = this.state.attendances.filter(
      (att) => att._id !== attendance._id
    );

    this.setState({
      attendances: [...results, selectedStudent],
    });
  };

  openJurnalMengajar = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      let result = await mwvClient.query({
        query: GET_TEACHER_ATTENDANCE_PER_SUBJECT,
        variables: {
          classroomId: urlValues.classroomId,
          employeeId: urlValues.employeeId,
          date: dayjs().format("YYYY-MM-DD"),
        },
        fetchPolicy: "no-cache",
      });

      result = result.data.getTeacherTeachingAttendancePerSubject;

      let daftarJurnalMengajar = [];
      for (const jam of this.state.listJam) {
        const found = result.find((r) => r.hourTo === jam);
        if (!found) {
          daftarJurnalMengajar.push({
            hourTo: jam,
            disabled: false,
            description: "",
            selected: false,
          });
        } else {
          daftarJurnalMengajar.push({
            hourTo: jam,
            disabled: true,
            description: found.description,
            selected: true,
          });
        }
      }

      this.setState({
        jurnalMengajarModal: true,
        daftarJurnalMengajar,
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleInputJurnalMengajar = (list, key) => (e) => {
    // console.log({ list, key });
    if (key === "description") {
      this.setState({
        daftarJurnalMengajar: this.state.daftarJurnalMengajar.map((selected) =>
          selected.hourTo !== list.hourTo
            ? selected
            : {
                ...list,
                description: e.target.value,
              }
        ),
      });
    } else if (key === "hourTo") {
      const found = this.state.selectedJurnalMengajar.find(
        (selected) => selected.hourTo === list.hourTo
      );

      if (!found) {
        this.setState({
          selectedJurnalMengajar: [...this.state.selectedJurnalMengajar, list],
        });
      } else {
        this.setState({
          selectedJurnalMengajar: this.state.selectedJurnalMengajar.filter(
            (s) => s.hourTo !== list.hourTo
          ),
        });
      }
    }
  };

  render() {
    const { allEmployees, classroom } = this.props;
    const {
      attendances,
      height,
      description,
      jurnalMengajarModal,
      daftarJurnalMengajar,
      selectedJurnalMengajar,
    } = this.state;
    const getEmployee = allEmployees
      ? allEmployees.filter((s) => s._id === urlValues.employeeId)
      : [];

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-info-circle" /> Konfirmasi Presensi
            </span>
          }
          visible={this.state.confirmationVisible ? true : false}
          onClose={(e) =>
            this.setState({
              confirmationVisible: false,
            })
          }
          onSubmit={this.submitSave}
        >
          Apakah Anda yakin untuk menyimpan presensi mata pelajaran{" "}
          <b>{urlValues.subjectName}</b> di kelas{" "}
          <b>{classroom ? classroom.name : "-"}</b> ?
          <br />
          <br />
          <font color="red">
            <b>*Presensi yang telah disimpan tidak dapat dibatalkan kembali!</b>
          </font>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-info-circle" /> Jurnal Mengajar Guru
            </span>
          }
          visible={jurnalMengajarModal ? true : false}
          onClose={(e) =>
            this.setState({
              jurnalMengajarModal: false,
              daftarJurnalMengajar: [],
              selectedJurnalMengajar: [],
            })
          }
          onSubmit={this.saveTeacherJournal}
        >
          PERHATIAN!!
          <br />
          <b>
            <p>
              Setelah menyimpan jurnal mengajar guru, maka tidak akan bisa
              dirubah untuk jam yang sama!
            </p>
          </b>
          <hr />
          {daftarJurnalMengajar.map((list) => {
            let isChecked = false;
            const found = selectedJurnalMengajar.findIndex(
              (j) => j.hourTo === list.hourTo
            );
            if (found >= 0) {
              isChecked = true;
            }
            if (list.selected === true) {
              isChecked = true;
            }
            return (
              <div>
                <div className="row">
                  <div className="col-6">Jam Ke: {list.hourTo}</div>
                  <div className="col-6">
                    <input
                      className="form-control"
                      type="checkbox"
                      style={{
                        height: "20px",
                      }}
                      onChange={this.handleInputJurnalMengajar(list, "hourTo")}
                      checked={isChecked}
                      disabled={list.disabled}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Keterangan</label>
                  <textarea
                    placeholder="Keterangan Mengajar"
                    className="form-control"
                    value={list.description}
                    onChange={
                      isChecked
                        ? this.handleInputJurnalMengajar(list, "description")
                        : null
                    }
                    disabled={list.disabled}
                  />
                </div>
              </div>
            );
          })}
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div
          className="fixed-top"
          ref={(divElement) => {
            this.divElement = divElement;
          }}
        >
          <HeaderBar
            title={<Text>Presensi Mapel</Text>}
            right={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/explore",
                  })
                }
              >
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 18,
                    paddingVertical: 2,
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        <div style={{ marginTop: height }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      {/* height: 100px;
                      object-fit: cover; */}
                      {getEmployee && getEmployee.length !== 0 ? (
                        getEmployee[0].photoUrl ? (
                          <Image
                            style={{ width: 60, height: 80 }}
                            source={getEmployee[0].photoUrl}
                          />
                        ) : (
                          // <img src={getEmployee[0].photoUrl} width="80" alt={getEmployee[0].photoUrl} />
                          <Image
                            style={{ width: 60, height: 60 }}
                            source={require("../assets/user-dummy.jpg")}
                          />
                        )
                      ) : null}
                    </div>
                    <div className="col-10">
                      <div className="px-2 text-left">
                        {getEmployee && getEmployee.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              width: "250px",
                              textOverflow: "ellipsis",
                              marginBottom: 10,
                            }}
                          >
                            {getEmployee[0].name}
                            <br />
                            <b>
                              {getEmployee[0].SchoolInformation &&
                              getEmployee[0].SchoolInformation.name
                                ? getEmployee[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2" style={{ marginTop: 10 }}>
                <div className="col-6">
                  Tanggal: <b>{dayjs().format("DD-MM-YYYY")}</b>
                  <br />
                  Tahun Ajaran: <b>{defaultAcademicYear()}</b>
                </div>
                <div className="col-6 text-right">
                  Semester:{" "}
                  <b>{new Date().getMonth() < 6 ? "GENAP" : "GANJIL"}</b>
                  <br />
                  Kelas: <b>{classroom ? classroom.name : "-"}</b>
                </div>
              </div>
            </div>
          </section>
        </div>
        <View style={{ marginTop: 10, marginBottom: 10 }}>
          <Text
            style={{ fontSize: 16, marginTop: 10, marginBottom: 4 }}
            className="text-center"
          >
            Presensi Mapel
          </Text>
          <Text
            style={{ fontSize: 18, marginBottom: 10 }}
            className="text-center"
          >
            {urlValues.subjectName}
          </Text>

          <View
            style={{
              marginRight: 10,
              marginBottom: 10,
            }}
          >
            <div className="text-right">
              <button
                className="btn btn-success btn-md btn-rounded"
                onClick={this.handleSetAll}
              >
                <i className="fa fa-check" /> Masuk Semua
              </button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <button
                className="btn btn-danger btn-md btn-rounded"
                onClick={this.handleResetAll}
              >
                <i className="fa fa-history" /> Reset
              </button>
            </div>
          </View>
          <View style={{ paddingBottom: 100 }}>
            <table
              className="table table-bordered table-striped"
              style={{ backgroundColor: "#fff" }}
            >
              <thead>
                <tr>
                  <th
                    style={{ minWidth: 5, color: "#000", fontWeight: "bold" }}
                  >
                    NIS
                  </th>
                  <th
                    style={{
                      minWidth: 80,
                      color: "#000",
                      fontWeight: "bold",
                    }}
                  >
                    Nama
                  </th>
                  <th
                    style={{ minWidth: 5, color: "#000", fontWeight: "bold" }}
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {lodash
                  .orderBy(attendances, ["Student.name"], ["asc"])
                  .map((att) => {
                    return (
                      <tr key={att._id}>
                        <td>{att.Student.regNumber}</td>
                        <td>
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "150px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {att.Student.name}
                          </div>
                        </td>
                        <td>
                          <select
                            className="form-control form-control-sm"
                            value={att.status}
                            onChange={this.handleStatus(att)}
                            disabled={
                              att.savedAt && att.status !== "" ? true : false
                            }
                          >
                            <option value="">Belum Presensi</option>
                            <option value="Masuk">Masuk</option>
                            <option value="Sakit">Sakit</option>
                            <option value="Izin">Izin</option>
                            <option value="Terlambat">Terlambat</option>
                            <option value="Tanpa Keterangan">
                              Tanpa Keterangan
                            </option>
                          </select>
                          {att.status !== "Masuk" ? (
                            <div style={{ marginTop: 5 }}>
                              <input
                                className="form-control form-control-sm"
                                value={att.description}
                                onChange={this.handleInput(att)}
                                disabled={att.savedAt ? true : false}
                              />
                              {att.status === "Terlambat" ? (
                                <input
                                  style={{
                                    marginTop: 5,
                                  }}
                                  className="form-control form-control-sm"
                                  value={att.lateTime || ""}
                                  onChange={this.handleLateTime(att)}
                                  type="time"
                                />
                              ) : null}
                            </div>
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <div className="form-group">
              <label>Deskripsi</label>
              <textarea
                className="form-control"
                value={description}
                onChange={(e) => {
                  if (e) e.preventDefault();
                  this.setState({
                    description: e.target.value,
                  });
                }}
              />
            </div>

            <div className="row">
              <div
                className="col-6"
                style={{
                  padding: 0,
                }}
              >
                <button
                  type="button"
                  style={{
                    zIndex: 9999,
                    backgroundColor: "#2ecc71",
                    height: 60,
                    borderWidth: 0,
                    borderRadius: 0,
                    boxShadow: "0px -2px 5px 2px #ddd",
                  }}
                  className="btn btn-lg btn-success btn-block"
                  onClick={(e) =>
                    this.setState({
                      confirmationVisible: true,
                    })
                  }
                >
                  <b>
                    <i className="fa fa-save" /> Simpan
                  </b>
                </button>
              </div>
              <div
                className="col-6"
                style={{
                  padding: 0,
                }}
              >
                <button
                  type="button"
                  style={{
                    zIndex: 9999,
                    backgroundColor: "#8e44ad",
                    height: 60,
                    borderWidth: 0,
                    borderRadius: 0,
                    boxShadow: "0px -2px 5px 2px #ddd",
                  }}
                  className="btn btn-lg btn-success btn-block"
                  onClick={(e) => this.openJurnalMengajar()}
                >
                  <b>
                    <i className="fa fa-save" /> Jurnal Mengajar
                  </b>
                </button>
              </div>
            </div>
          </View>
        </View>
        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }

  submitSave = async (e) => {
    if (e) e.preventDefault();
    try {
      const { description } = this.state;
      urlValues = queryString.parse(this.props.location.search);
      showLoadingSpinner();
      for (const att of this.state.attendances) {
        if (att.status) {
          await mwvClient.mutate({
            mutation: UPDATE_ATTENDANCE,
            variables: {
              _id: att._id,
              status: att.status,
              description: att.status === "Masuk" ? null : att.description,
              lateTime: att.status === "Terlambat" ? att.lateTime : null,
            },
            fetchPolicy: "no-cache",
          });
        }
      }

      await mwvClient.mutate({
        mutation: UPDATE_ATTENDANCE_DESCRIPTION,
        variables: {
          classroomId: urlValues.classroomId,
          employeeId: urlValues.employeeId,
          date: dayjs().format("YYYY-MM-DD"),
          description,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        level: "success",
        message: "Presensi berhasil diperbarui",
      });
      this.setState({
        confirmationVisible: false,
      });
      this.props.refetch();

      // setTimeout(
      //   () =>
      //     (window.location.href = `/daftar_mapel_presensi?sessionId=${this.props.router.query.sessionId}&employeeId=${this.props.router.query.employeeId}&classroomId=${this.props.router.query.classroomId}`),
      //   2000
      // );
      hideLoadingSpinner();
    } catch (err) {
      handleError(err);
    }
  };

  saveTeacherJournal = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      const { daftarJurnalMengajar, selectedJurnalMengajar } = this.state;

      urlValues = queryString.parse(this.props.location.search);
      let tmpJurnal = daftarJurnalMengajar.map((d) => {
        const foundChecked = selectedJurnalMengajar.find(
          (s) => s.hourTo === d.hourTo
        );
        return {
          ...d,
          selected: foundChecked ? true : false,
        };
      });

      tmpJurnal = tmpJurnal.filter((t) => t.selected === true);

      //Check jika terdapat deskripsi kosong
      for (const tmp of tmpJurnal) {
        if (!tmp.description) {
          throw {
            message: `Keterangan tidak boleh kosong pada jam ${tmp.hourTo}`,
          };
        }
      }

      for (const tmp of tmpJurnal) {
        await mwvClient.mutate({
          mutation: UPDATE_TEACHER_TEACHING_ATTENDANCE_PER_SUBJECT,
          variables: {
            classroomId: urlValues.classroomId,
            employeeId: urlValues.employeeId,
            date: dayjs().format("YYYY-MM-DD"),
            description: tmp.description,
            hourTo: tmp.hourTo,
            subjectId: urlValues.subjectId,
          },
        });
      }
      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });

      this.setState({
        jurnalMengajarModal: false,
        daftarJurnalMengajar: [],
        selectedJurnalMengajar: [],
      });
    } catch (err) {
      handleError(err);
    }

    hideLoadingSpinner();
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQuery(
    $sessionId: String
    $classroomId: String
    $employeeId: String
    $subjectId: String
    $academicYear: String
    $semester: String
    $date: String
  ) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }

    classroomById(classroomId: $classroomId) {
      _id
      name
    }

    initStudentAttendancePerSubject(
      classroomId: $classroomId
      employeeId: $employeeId
      subjectId: $subjectId
      academicYear: $academicYear
      semester: $semester
      date: $date
    ) {
      _id
      academicYear
      semester
      tanggal
      bulan
      tahun
      status
      Student {
        _id
        name
        regNumber
      }
      description
      savedAt
      lateTime
    }

    studentAttendancePerSubjectDescription(
      classroomId: $classroomId
      academicYear: $academicYear
      semester: $semester
      date: $date
    )
  }
`;

const UPDATE_ATTENDANCE = gql`
  mutation updateStudentAttendancePerSubject(
    $_id: ID!
    $status: String
    $description: String
    $lateTime: String
  ) {
    updateStudentAttendancePerSubject(
      _id: $_id
      status: $status
      description: $description
      lateTime: $lateTime
    )
  }
`;

const UPDATE_ATTENDANCE_DESCRIPTION = gql`
  mutation updateStudentAttendancePerSubjectDescription(
    $classroomId: String
    $employeeId: String
    $date: String
    $description: String
  ) {
    updateStudentAttendancePerSubjectDescription(
      employeeId: $employeeId
      classroomId: $classroomId
      date: $date
      description: $description
    )
  }
`;

const GET_TEACHER_ATTENDANCE_PER_SUBJECT = gql`
  query getTeacherTeachingAttendancePerSubject(
    $classroomId: String
    $employeeId: String
    $date: String
  ) {
    getTeacherTeachingAttendancePerSubject(
      classroomId: $classroomId
      employeeId: $employeeId
      date: $date
    ) {
      _id
      classroomId
      subjectId
      hourTo
      date
      description
    }
  }
`;

const UPDATE_TEACHER_TEACHING_ATTENDANCE_PER_SUBJECT = gql`
  mutation updateTeacherTeachingAttendancePerSubject(
    $classroomId: String
    $employeeId: String
    $date: String
    $description: String
    $hourTo: Int
    $subjectId: String
  ) {
    updateTeacherTeachingAttendancePerSubject(
      classroomId: $classroomId
      employeeId: $employeeId
      date: $date
      description: $description
      hourTo: $hourTo
      subjectId: $subjectId
    )
  }
`;

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation mutation={UPDATE_ATTENDANCE}>
          {(updateStudentAttendancePerSubject) => (
            <Query
              query={QUERIES}
              client={mwvClient}
              variables={{
                ...urlValues,
                sessionId:
                  urlValues && urlValues.sessionId ? urlValues.sessionId : "",
                employeeId:
                  urlValues && urlValues.employeeId ? urlValues.employeeId : "",
                semester: new Date().getMonth() < 6 ? "GENAP" : "GANJIL",
                academicYear: defaultAcademicYear(),
                classroomId:
                  urlValues && urlValues.classroomId
                    ? urlValues.classroomId
                    : "",
                date: dayjs().format("YYYY-MM-DD"),
              }}
            >
              {({ error, loading, data, refetch }) => (
                <div>
                  <ListSubjectPresence
                    {...props}
                    client={client}
                    error={error}
                    loading={loading}
                    refetch={refetch}
                    allEmployees={
                      data && data.allEmployeesBySessionId
                        ? data.allEmployeesBySessionId
                        : []
                    }
                    classroom={
                      data && data.classroomById ? data.classroomById : null
                    }
                    studentAttendancePerSubject={
                      data && data.initStudentAttendancePerSubject
                        ? data.initStudentAttendancePerSubject
                        : []
                    }
                    updateStudentAttendancePerSubject={
                      updateStudentAttendancePerSubject
                    }
                    studentAttendancePerSubjectDescription={
                      (data && data.studentAttendancePerSubjectDescription) ||
                      ""
                    }
                  />
                </div>
              )}
            </Query>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
});
