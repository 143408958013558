import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import dayjs from "dayjs";
import ReactHtmlParser from "react-html-parser";

let urlValues;

class CBTExplanation extends Component {
  state = {
    semester: "",
    academicYear: "",
    selectedNumber: 1,
    questionListVisible: false,
    explanationVisible: false,
    viewExplanation: null,

    daftarSoal: null,
    daftarJawaban: [],
    showAnswer: false,
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: CBT_PACKAGE_QUERIES,
      variables: {
        _id: accountSession._id,
        studentId: urlValues.studentId,
        studentAnswerId: urlValues.studentAnswerId,
        packageId: urlValues.packageId,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    const daftarJawaban =
      result.data && result.data.studentAnswerByPackageId
        ? JSON.parse(result.data.studentAnswerByPackageId.questions)
        : [];

    const daftarSoal =
      result.data && result.data.packageById
        ? result.data.packageById.CBTQuestion
        : null;

    const showAnswer =
      result.data && result.data.packageById
        ? result.data.packageById.showAnswer
        : false;

    this.setState({
      allStudents: [...students],
      academicYear: urlValues.academicYear,
      semester: urlValues.semester,
      daftarSoal,
      daftarJawaban,
      showAnswer,
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.initiate();
  };

  questionNavigation = (nav) => (e) => {
    if (nav === "Next") {
      window.scrollTo(0, 0);
      this.setState({
        selectedNumber: this.state.selectedNumber + 1,
      });
    } else {
      window.scrollTo(0, 0);
      this.setState({
        selectedNumber: this.state.selectedNumber - 1,
      });
    }
  };

  openQuestionList = () => {
    this.setState({
      questionListVisible: true,
    });
  };

  getExplanation = (daftarJawaban, daftarSoal) => {
    if (daftarSoal.length > 0) {
      let results = [];
      const indexedSoal = daftarSoal.reduce((all, soal) => {
        all[soal._id] = soal;
        return all;
      }, {});

      // console.log({ indexedSoal });
      for (const j of daftarJawaban) {
        if (indexedSoal[j._id]) {
          // console.log(indexedSoal[j._id]);
          results.push({
            _id: j._id,
            listAnswers: j.listAnswers,
            question: j.question,
            studentAnswer: j.answer,
            correctAnswerObject: {
              qId: indexedSoal[j._id].listAnswers.filter(
                (a) => a.alphabet === j.answer
              )[0]
                ? indexedSoal[j._id].listAnswers.filter(
                    (a) => a.alphabet === j.answer
                  )[0]._id
                : "",
              answer: indexedSoal[j._id].correctAnswer,
            },
            explanation: indexedSoal[j._id].explanation,
          });
        }
      }
      return results;
    }
    return [];
  };

  generateListNumbers = (questions) => {
    if (questions) {
      let no = 0;
      let result = [];
      for (const q of questions) {
        no = no + 1;
        result.push({
          no,
          ...q,
        });
      }

      // console.log(result);
      return result;
    }
  };

  indexedSoal = (soal) => {
    if (soal.length > 0) {
      const results = soal.reduce((all, s) => {
        all[s._id] = s;
        return all;
      }, {});

      return results;
    }
  };

  handleSelectNumber = (num) => (e) => {
    this.setState({
      selectedNumber: num.no,
      questionListVisible: false,
    });
  };

  openExplanation = (soal) => (e) => {
    console.log(soal);
    this.setState({
      explanationVisible: true,
      viewExplanation: {
        explanation: soal.explanation,
        correctAnswer: soal.correctAnswerObject.answer,
        studentAnswer: soal.studentAnswer,
      },
    });
  };

  toCBTPage = () => {
    urlValues = {
      sessionId: urlValues.sessionId,
      studentId: urlValues.studentId,
      academicYear: urlValues.academicYear,
      semester: urlValues.semester,
      PREFIX: urlValues.semester,
    };
    const res = queryString.stringify(urlValues);
    this.props.history.replace({
      pathname: "/cbt/cbt_page",
      search: `?${res}`,
    });
  };

  renderPreview = (question) => {
    let q = question;
    q = q.split("[latex]").join("<math-field style='display: inline-block'>");
    q = q.split("[/latex]").join("</math-field>");

    q = q.split("&").join("\\hspace{2}");
    q = q.split("\\hline").join("\n");
    q = q.split("|mathrm").join("\\mathrm");

    return ReactHtmlParser(q);
  };

  renderExplanation = (explanation) => {
    let exp = explanation;
    exp = exp
      .split("[latex]")
      .join("<math-field style='display: inline-block' disabled>");
    exp = exp.split("[/latex]").join("</math-field>");

    exp = exp.split("&").join("\\hspace{2}");
    exp = exp.split("\\hline").join("\n");
    exp = exp.split("|mathrm").join("\\mathrm");
    exp = exp.split("export=view\\hspace{2}").join("&");

    // console.log({ exp });

    return (
      <div
        style={{
          overflow: "scroll",
        }}
      >
        {ReactHtmlParser(exp)}
      </div>
    );
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const {
      allStudents,
      selectedNumber,
      questionListVisible,
      explanationVisible,
      viewExplanation,
      daftarSoal,
      daftarJawaban,
      showAnswer,
    } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    const questionList = daftarSoal ? daftarSoal : [];

    const answerList = daftarJawaban;
    const explanations = this.getExplanation(answerList, questionList);
    const indexedSoal = this.indexedSoal(questionList);

    const listNumbers = this.generateListNumbers(explanations);

    let soal =
      selectedNumber === 0 ? explanations[0] : explanations[selectedNumber - 1];

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <FormModal
          title={"Daftar Nomor Soal"}
          visible={questionListVisible}
          onClose={(e) =>
            this.setState({
              questionListVisible: false,
            })
          }
        >
          {showAnswer ? (
            <div className="row">
              {listNumbers.map((num) => (
                <div className="col-3" style={{ marginBottom: 5 }} key={num}>
                  <button
                    className={`btn btn-sm btn-block`}
                    onClick={this.handleSelectNumber(num)}
                    style={{
                      backgroundColor:
                        num.studentAnswer == indexedSoal[num._id].correctAnswer
                          ? "#43a047"
                          : "#f4511e",
                      color: "#fff",
                    }}
                  >
                    <b>{num.no}</b>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="row">
              {listNumbers.map((num) => (
                <div className="col-3" style={{ marginBottom: 5 }} key={num}>
                  <button
                    className={`btn btn-sm btn-block`}
                    onClick={this.handleSelectNumber(num)}
                    style={{
                      backgroundColor: "#546E7A",
                      color: "#fff",
                    }}
                  >
                    <b>{num.no}</b>
                  </button>
                </div>
              ))}
            </div>
          )}
        </FormModal>

        <FormModal
          title={"Penjelasan"}
          visible={explanationVisible}
          onClose={(e) =>
            this.setState({
              explanationVisible: false,
            })
          }
        >
          {viewExplanation ? (
            <View>
              <View
                style={{
                  marginBottom: 25,
                }}
              >
                {this.renderExplanation(viewExplanation.explanation)}
                {/* {ReactHtmlParser(viewExplanation.explanation)} */}
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    width: 120,
                  }}
                >
                  <Text>Jawaban Benar</Text>
                </View>
                <View
                  style={{
                    width: 20,
                  }}
                >
                  <Text
                    style={{
                      color: "green",
                    }}
                  >
                    {" "}
                    <b>{viewExplanation.correctAnswer}</b>
                  </Text>
                </View>
              </View>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    width: 120,
                  }}
                >
                  <Text>Jawaban Anda</Text>
                </View>
                <View
                  style={{
                    width: 20,
                  }}
                >
                  <Text
                    style={{
                      color:
                        viewExplanation.studentAnswer !==
                        viewExplanation.correctAnswer
                          ? "red"
                          : "green",
                    }}
                  >
                    {" "}
                    <b>{viewExplanation.studentAnswer}</b>
                  </Text>
                </View>
              </View>
            </View>
          ) : null}
        </FormModal>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-desktop"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"CBT"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-2">
                  <Image
                    style={{ width: 65, height: 65 }}
                    source={require("../../assets/owl-laptop-study.png")}
                  />
                </div>
                <div className="col-10" style={{ paddingLeft: 30 }}>
                  <div>
                    {getStudent.length !== 0 ? (
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "200px",
                          whiteSpace: "nowrap",
                          fontSize: 18,
                        }}
                      >
                        {getStudent[0].name}
                        <br />
                        <b>
                          {getStudent[0].SchoolInformation
                            ? getStudent[0].SchoolInformation.name
                            : ""}
                        </b>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>

              <View style={{ flex: 1, marginTop: 5, flexDirection: "row" }}>
                <View
                  style={{
                    width: 50,
                  }}
                >
                  {selectedNumber === 1 ? null : (
                    <Text
                      onClick={this.questionNavigation("Prev")}
                      style={{
                        color: "#29b6f6",
                      }}
                    >
                      <b>
                        <i className="fa fa-backward" /> Prev
                      </b>
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    width: 230,
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Soal No. {selectedNumber}
                  </Text>
                </View>
                <View
                  style={{
                    width: 50,
                  }}
                >
                  {selectedNumber === questionList.length ? null : (
                    <Text
                      style={{
                        color: "#29b6f6",
                      }}
                      onClick={this.questionNavigation("Next")}
                    >
                      <i className="fa fa-forward" /> Next
                    </Text>
                  )}
                </View>
              </View>

              <View style={{ flex: 1, marginTop: 10, flexDirection: "row" }}>
                <View
                  style={{
                    width: 50,
                  }}
                >
                  <Text>{""}</Text>
                </View>
                <View
                  style={{
                    width: 230,
                    alignItems: "center",
                  }}
                >
                  <button
                    className="btn btn-sm btn-primary btn-block"
                    onClick={this.openQuestionList}
                  >
                    <i className="fa fa-question-circle" /> Daftar Soal
                  </button>
                </View>
              </View>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            marginTop: 250,
            paddingLeft: 8,
            paddingRight: 8,
          }}
        >
          <section
            className="card card-blue"
            style={{ padding: 20, marginBottom: "100vh" }}
          >
            {soal ? (
              <div onClick={showAnswer ? this.openExplanation(soal) : null}>
                {this.renderPreview(soal.question)}
                <br />

                {soal.listAnswers.map((answer) => {
                  let jawaban = answer.answers;
                  if (jawaban.startsWith(answer.alphabet + ".")) {
                    jawaban = jawaban.slice(
                      answer.alphabet.length + 1,
                      jawaban.length
                    );
                    jawaban = jawaban.trim();
                  } else if (jawaban.startsWith(answer.alphabet)) {
                    jawaban = jawaban.slice(
                      answer.alphabet.length,
                      jawaban.length
                    );
                    jawaban = jawaban.trim();
                  }
                  // console.log({ soal, answer, jawaban });

                  jawaban = jawaban
                    .split("[latex]")
                    .join("<math-field style='display: inline-block'>");
                  jawaban = jawaban.split("[/latex]").join("</math-field>");

                  jawaban = jawaban.split("&").join("\\hspace{2}");
                  jawaban = jawaban.split("|mathrm").join("\\mathrm");
                  jawaban = jawaban.split("\\hline").join("----");
                  if (
                    soal.correctAnswerObject.qId === answer._id &&
                    soal.correctAnswerObject.answer === soal.studentAnswer
                  ) {
                    return (
                      <div
                        key={answer._id}
                        className={`card card-block px-2 py-2 mb-2`}
                        style={{
                          borderWidth: "2px",
                          borderColor: showAnswer ? "green" : "red",
                        }}
                      >
                        <a>
                          <div className="row px-2">
                            <div className="col-1 align-items-center pl-1">
                              {answer.alphabet}
                            </div>
                            <div className="pl-1 align-items-center">
                              {ReactHtmlParser(jawaban)}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  } else if (
                    soal.correctAnswerObject.qId === answer._id &&
                    soal.correctAnswerObject.answer !== soal.studentAnswer
                  ) {
                    return (
                      <div
                        key={answer._id}
                        className={`card card-block px-2 py-2 mb-2`}
                        style={{
                          borderWidth: "2px",
                          borderColor: "red",
                        }}
                      >
                        <a>
                          <div className="row px-2">
                            <div className="col-1 align-items-center pl-1">
                              {answer.alphabet}
                            </div>
                            <div className="pl-1 align-items-center">
                              {ReactHtmlParser(jawaban)}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={answer._id}
                        className={`card card-block px-2 py-2 mb-2`}
                        style={{
                          borderWidth: "2px",
                          borderColor:
                            soal.correctAnswerObject.answer ===
                              answer.alphabet && showAnswer
                              ? "green"
                              : "",
                        }}
                      >
                        <a>
                          <div className="row px-2">
                            <div className="col-1 align-items-center pl-1">
                              {answer.alphabet}
                            </div>
                            <div className="pl-1 align-items-center">
                              {ReactHtmlParser(jawaban)}
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  }
                })}
                {showAnswer ? (
                  <center>
                    <h5 style={{ color: "blue" }}>
                      ({"Ketuk untuk melihat Penjelasan"})
                    </h5>
                  </center>
                ) : null}
              </div>
            ) : null}
          </section>
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.toCBTPage}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-arrow-left" /> {"Siswa Lain"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const CBT_PACKAGE_QUERIES = gql`
  query listQueries(
    $_id: String
    $studentId: String!
    $studentAnswerId: String!
    $packageId: String!
  ) {
    studentById(studentId: $studentId) {
      _id
      name
      Classroom {
        _id
        name
        academicYear
      }
    }
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
        PREFIX
      }
      PREFIX
      photoUrl
    }
    packageById(packageId: $packageId) {
      _id
      name
      showAnswer
      CBTQuestion {
        _id
        correctAnswer
        explanation
        listAnswers {
          _id
          alphabet
          answers
          imageUrl
        }
      }
    }
    studentAnswerByPackageId(
      studentAnswerId: $studentAnswerId
      studentId: $studentId
    ) {
      _id
      Package {
        _id
        name
        time
      }
      Subject {
        _id
        name
      }
      questions
      status
    }
  }
`;

export default withRouter(CBTExplanation);
