import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import queryString from "query-string";
import { ApolloConsumer, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";

import localforage from "localforage";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";

import Fingerprint2 from "fingerprintjs2";
import { handleError } from "../libs/errors";

let urlValues;

class InputOtp extends Component {
  constructor() {
    super();
    this.inputOtp1Ref = React.createRef();
    this.inputOtp2Ref = React.createRef();
    this.inputOtp3Ref = React.createRef();
    this.inputOtp4Ref = React.createRef();
    this.buttonSubmit = React.createRef();
    this.state = {
      inputWidth: 50,
      inputHeight: 50,
      phoneNumber: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otpCode: "",
      Student: null,
      session: {},
      loading: false,
      accountSession: null,
      height: "80px",
    };
  }

  updateDimensions() {
    if (this.inputOtp1Ref) {
      if (this.inputOtp1Ref.current !== null) {
        this.setState({
          inputWidth: this.inputOtp1Ref.current.offsetWidth,
          inputHeight: this.inputOtp1Ref.current.offsetWidth,
        });
      }
    }
  }

  componentDidMount() {
    this.updateDimensions();

    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  refresh = () => {
    window.location.reload();
  };

  handleInputOtp = (e) => {
    this.setState({
      otpCode: e.target.value,
    });
  };

  handleSubmitOtpCode = async (e) => {
    urlValues = queryString.parse(this.props.location.search);
    const { phoneNumber } = urlValues;
    const { otp1, otp2, otp3, otp4 } = this.state;
    const otpCode = `${otp1}${otp2}${otp3}${otp4}`;
    if(!otpCode){
      return handleError({
        message: "Kode OTP tidak valid!"
      });
    }
    try {
      this.setState({
        loading: true,
      });
      const response = await this.props.getAccountByOtp({
        variables: {
          phoneNumber,
          otpCode,
        },
        fetchPolicy: "no-cache",
      });
      const Account =
        response.data && response.data.getAccountByOtp
          ? response.data.getAccountByOtp
          : null;

      if (!Account) {
        this.props.history.push({
          pathname: "/register",
          search: `?phoneNumber=${phoneNumber}&registeredBy=WHATSAPP`,
        });
      } else {
        return this.logInWithOtp(Account);
      }
      // setTimeout(() => {
      //   this.setState({
      //     loading: false,
      //   });
      // }, 2000);
    } catch (e) {
      handleError({
        message: e.message.replace("GraphQL error: ", ""),
      });
      this.setState({
        loading: false,
      });
    }
  };

  logInWithOtp = async (AccountSession) => {
    let browserInfo = {};
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        Fingerprint2.get((components) => {
          var values = components.map((c) => {
            // console.log(c)
            return c.value;
          });
          var hashId = Fingerprint2.x64hash128(values.join(""), 31);
          browserInfo = {
            uniqueId: hashId,
          };
          resolve();
        });
      }, 800);
    });

    var objappVersion = navigator.appVersion;
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = "" + parseFloat(navigator.appVersion);
    let objBrMajorVersion = parseInt(navigator.appVersion, 10);
    let objOffsetName, objOffsetVersion, ix;

    let browserVersion;
    // console.log(objAgent)
    if ((objOffsetVersion = objAgent.indexOf("Chrome")) !== -1) {
      objbrowserName = "Chrome";
      const version = objAgent.slice(objOffsetVersion + 7);
      browserVersion = version.slice(0, 12);
    } else if ((objOffsetVersion = objAgent.indexOf("Safari")) !== -1) {
      objbrowserName = "Safari";
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf("Version")) !== -1) {
        objfullVersion = objAgent.substring(objOffsetVersion + 8);

        browserVersion = objfullVersion.slice(26, 32);
      }
    } else {
      objbrowserName = "Mozilla";
      browserVersion = objfullVersion;
    }

    browserInfo = {
      ...browserInfo,
      objbrowserName,
      browserVersion,
    };

    if (!firebase.apps.length) {
      // console.log("Initialize firebase...");
      firebase.initializeApp(firebaseConfig);
    }
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
    }

    const session = AccountSession;
    // console.log("Session", session);
    localforage.setItem("accountSession", JSON.stringify(session));

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const fcmToken = await messaging.getToken();
      localforage.setItem("fcm-token", fcmToken);
      await this.props.resubscribeTopicForPushNotification({
        variables: {
          fcmToken,
        },
      });
    }
    addNotification({
      title: "SUCCESS",
      message: `Berhasil masuk!`,
      level: "success",
    });
    this.setState({
      loading: false,
    });

    setTimeout(() => {
      window.location = "/explore";
    }, 1000);
  };

  handleKeydownEnter = async (e) => {
    if (e.key === "Enter") {
      urlValues = queryString.parse(this.props.location.search);
      const { phoneNumber } = urlValues;
      const { otpCode } = this.state;
      try {
        this.setState({
          loading: true,
        });
        const response = await this.props.getAccountByOtp({
          variables: {
            phoneNumber,
            otpCode,
          },
          fetchPolicy: "no-cache",
        });
        const Account =
          response.data && response.data.getAccountByOtp
            ? response.data.getAccountByOtp
            : null;

        if (!Account) {
          this.props.history.push({
            pathname: "/register",
            search: `?phoneNumber=${phoneNumber}&registeredBy=WHATSAPP`,
          });
        } else {
          return this.logInWithOtp(Account);
        }
        // setTimeout(() => {
        //   this.setState({
        //     loading: false,
        //   });
        // }, 2000);
      } catch (e) {
        handleError({
          message: e.message.replace("GraphQL error: ", ""),
        });
        this.setState({
          loading: false,
        });
      }
    }
  };

  handleInput = (key) => (e) => {
    if (e.target.value.length > 1) {
      this.setState({
        [key]: e.target.value[e.target.value.length - 1],
      });
    } else {
      this.setState({
        [key]: e.target.value,
      });
    }
  };

  handleKeyUp = (key) => (e) => {
    let indexOtp = key;
    indexOtp = indexOtp.replace(/[^0-9]/g, "");
    const charStr = String.fromCharCode(e.keyCode);

    if (e.keyCode !== 8) {
      if (/[0-9]/i.test(charStr)) {
        if (parseInt(indexOtp) !== 4) {
          this[`inputOtp${parseInt(indexOtp) + 1}Ref`].current.focus();
        } else {
          this.buttonSubmit.current.focus();
        }
      }
    } else {
      if (parseInt(indexOtp) - 1 === 0) {
        this[`inputOtp${parseInt(indexOtp)}Ref`].current.focus();
      } else {
        this[`inputOtp${parseInt(indexOtp) - 1}Ref`].current.focus();
      }
    }
  };

  render() {
    const { loading, otp1, otp2, otp3, otp4 } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/login_whatsapp",
                  })
                }
              >
                <Text>
                  <i
                    className="fa fa-arrow-left"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {translate("Kembali")}
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        <style jsx>
          {`
            .form-row {
              display: flex;
              flex-wrap: wrap;
              margin-right: -5px;
              margin-left: -5px;
            }
            .form-control {
              font-size: 2.5rem;
              text-align: center;
              border: solid 2px rgb(133 133 133 / 70%);
            }
          `}
        </style>
        <div
          className="card"
          style={{ borderRadius: "20px", margin: "auto 15px" }}
        >
          {/* <div className="card-header">
            <Image
              style={{
                width: 140,
                height: 140,
                margin: "auto",
              }}
              source={require("../assets/iphone-hand-touch.png")}
            />
          </div> */}
          <div className="card-body">
            <p className="text-center">
              Masukkan kode OTP yang telah anda terima.
            </p>
            {/* <div className="form-group">
              <input
                style={{ textAlign: "center" }}
                type="text"
                className="form-control"
                placeholder="Masukkan kode OTP..."
                maxLength={15}
                value={this.state.otpCode}
                onChange={this.handleInputOtp}
                onKeyDown={this.handleKeydownEnter}
              />
            </div> */}

            <div className="form-group form-row container-otp mt-4">
              <div className="col">
                <input
                  type="number"
                  maxLength="1"
                  className="form-control"
                  style={{
                    height: this.state.inputHeight + "px",
                  }}
                  value={otp1}
                  ref={this.inputOtp1Ref}
                  onChange={this.handleInput("otp1")}
                  onKeyUp={this.handleKeyUp("inputOtp1Ref")}
                />
              </div>
              <div className="col">
                <input
                  type="number"
                  maxLength="1"
                  name="otp2"
                  className="form-control"
                  style={{
                    height: this.state.inputHeight + "px",
                  }}
                  value={otp2}
                  ref={this.inputOtp2Ref}
                  onChange={this.handleInput("otp2")}
                  onKeyUp={this.handleKeyUp("inputOtp2Ref")}
                />
              </div>
              <div className="col">
                <input
                  type="number"
                  maxLength="1"
                  name="otp3"
                  className="form-control"
                  style={{
                    height: this.state.inputHeight + "px",
                  }}
                  value={otp3}
                  ref={this.inputOtp3Ref}
                  onChange={this.handleInput("otp3")}
                  onKeyUp={this.handleKeyUp("inputOtp3Ref")}
                />
              </div>
              <div className="col">
                <input
                  type="number"
                  maxLength="1"
                  name="otp4"
                  className="form-control"
                  style={{
                    height: this.state.inputHeight + "px",
                  }}
                  value={otp4}
                  ref={this.inputOtp4Ref}
                  onChange={this.handleInput("otp4")}
                  onKeyUp={this.handleKeyUp("inputOtp4Ref")}
                />
              </div>
            </div>

            <button
              className="btn btn-block"
              style={{
                backgroundColor: "#00a8ff",
                color: "#fff",
              }}
              ref={this.buttonSubmit}
              onClick={this.handleSubmitOtpCode}
            >
              <i className="fa fa-arrow-right" /> Lanjutkan
            </button>
          </div>
        </div>
      </View>
    );
  }
}

const GET_ACCOUNT_BY_OTP = gql`
  mutation getAccountByOtp($phoneNumber: String!, $otpCode: String!) {
    getAccountByOtp(phoneNumber: $phoneNumber, otpCode: $otpCode) {
      _id
      token
      expiresIn
      Account {
        _id
        name
        email
        phone
        status
        roles
        subscribedTopics
      }
      _createdAt
    }
  }
`;

const RESUBSCRIBE = gql`
  mutation resubscribeTopicForPushNotification($fcmToken: String!) {
    resubscribeTopicForPushNotification(fcmToken: $fcmToken)
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation mutation={RESUBSCRIBE}>
            {(resubscribeTopicForPushNotification) => (
              <Mutation mutation={GET_ACCOUNT_BY_OTP}>
                {(getAccountByOtp) => (
                  <InputOtp
                    {...props}
                    resubscribeTopicForPushNotification={
                      resubscribeTopicForPushNotification
                    }
                    getAccountByOtp={getAccountByOtp}
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  })
);
