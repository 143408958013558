import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class CBTPackageHistory extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    semester: "",
    academicYear: "",

    allAcademicYears: [],
    histories: [],
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: CBT_PACKAGE_QUERIES,
      variables: {
        _id: accountSession._id,
        PREFIX: urlValues.PREFIX,
        semester: urlValues.semester,
        academicYear: urlValues.academicYear,
        studentId: urlValues.studentId,
        subjectId: urlValues.subjectId,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    const allAcademicYears =
      result.data && result.data.allAcademicYears
        ? result.data.allAcademicYears
        : [];

    const histories =
      result.data && result.data.historyStudentAnswer
        ? result.data.historyStudentAnswer
        : [];

    this.setState({
      allStudents: [...students],
      academicYear: urlValues.academicYear,
      semester: urlValues.semester,
      allAcademicYears,
      histories,
    });
  };

  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
      type: "history",
    };

    const res = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/cbt/cbt_subject_list",
      search: `?${res}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleKriteria = (key) => (e) => {
    urlValues = {
      ...urlValues,
      [key]: e.target.value,
    };

    this.setState({
      [key]: e.target.value,
    });

    const res = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/cbt/cbt_packages_history",
      search: `?${res}`,
    });

    this.initiate();
  };

  viewHistory = (hist) => (e) => {
    try {
      urlValues = queryString.parse(this.props.location.search);

      if (!hist.Package) {
        throw {
          message: "Invalid Package",
        };
      }
      urlValues = {
        ...urlValues,
        studentAnswerId: hist._id,
        packageId: hist.Package._id,
      };

      const res = queryString.stringify(urlValues);

      this.props.history.replace({
        pathname: "/cbt/cbt_explanation",
        search: `?${res}`,
      });
    } catch (err) {
      handleError(err);
    }
  };

  render() {
    const { allStudents, allAcademicYears, academicYear, semester, histories } =
      this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-desktop"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"CBT"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-2">
                  <Image
                    style={{ width: 65, height: 65 }}
                    source={require("../../assets/owl-laptop-study.png")}
                  />
                </div>
                <div className="col-10" style={{ paddingLeft: 30 }}>
                  <div>
                    {getStudent.length !== 0 ? (
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "200px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {getStudent[0].name}
                        <br />
                        <b>
                          {getStudent[0].SchoolInformation
                            ? getStudent[0].SchoolInformation.name
                            : ""}
                        </b>
                      </div>
                    ) : null}
                  </div>
                  <View
                    style={{
                      flex: 1,
                      marginTop: 10,
                      flexDirection: "row",
                    }}
                  >
                    <View
                      style={{
                        width: 150,
                      }}
                    >
                      <select
                        className="form-control"
                        value={academicYear}
                        onChange={this.handleKriteria("academicYear")}
                      >
                        {allAcademicYears.map((year) => (
                          <option key={year._id} value={year.label}>
                            {year.label} - {year.inProgress ? "(On Going)" : ""}
                          </option>
                        ))}
                      </select>
                    </View>
                    <View
                      style={{
                        width: 100,
                        marginLeft: 5,
                      }}
                    >
                      <select
                        className="form-control"
                        value={semester}
                        onChange={this.handleKriteria("semester")}
                      >
                        <option value="GANJIL">GANJIL</option>
                        <option value="GENAP">GENAP</option>
                      </select>
                    </View>
                  </View>
                </div>
              </div>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            marginTop: 210,
          }}
        >
          <View
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: "100vh",
            }}
          >
            <View
              style={{
                alignItems: "Center",
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontSize: 18,
                }}
              >
                Daftar Riwayat Ujian
              </Text>
            </View>
            {histories.length === 0 ? (
              <section
                className="card"
                style={{
                  paddingBottom: 15,
                }}
              >
                <div className="card-block">
                  <View
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{ width: 240, height: 240 }}
                      source={require("../../assets/student-reading-male-caucasian.png")}
                    />
                    <Text
                      style={{
                        fontSize: 16,
                      }}
                    >
                      Riwayat Tidak Ditemukan!
                    </Text>
                  </View>
                </div>
              </section>
            ) : (
              histories.map((hist) => (
                <section
                  className="card"
                  style={{
                    padding: 20,
                    marginBottom: 10,
                  }}
                  onClick={this.viewHistory(hist)}
                >
                  <div className="row px-2 mb-1">
                    <div className="col-2 px-0">Paket</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>{hist.Package ? hist.Package.name : "-"}</b>
                    </div>
                  </div>
                  <div className="row px-2 mb-1">
                    <div className="col-2 px-0">Soal</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>{hist.countQuestions}</b>
                    </div>
                  </div>
                  <div className="row px-2 mb-1">
                    <div className="col-2 px-0">Benar</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>{hist.countCorrect}</b>
                    </div>
                  </div>
                  {/* <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <View
                      style={{
                        width: 100,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Paket
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 150,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: "#1565c0",
                        }}
                      >
                        : <b>{hist.Package ? hist.Package.name : "-"}</b>
                      </Text>
                    </View>
                  </View> */}

                  {/* <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <View
                      style={{
                        width: 100,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Soal
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 150,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#1565c0",
                        }}
                      >
                        : <b>{hist.countQuestions}</b>
                      </Text>
                    </View>
                  </View> */}

                  {/* <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <View
                      style={{
                        width: 100,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                        }}
                      >
                        Benar
                      </Text>
                    </View>
                    <View
                      style={{
                        width: 150,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#1565c0",
                        }}
                      >
                        : <b>{hist.countCorrect}</b>
                      </Text>
                    </View>
                  </View> */}

                  <View
                    style={{
                      flex: 1,
                      marginTop: 15,
                      alignItems: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        fontWeight: "bold",
                        color: "#1565c0",
                      }}
                    >
                      <u>( Ketuk untuk melihat riwayat ini )</u>
                    </Text>
                  </View>
                </section>
              ))
            )}
          </View>
        </View>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa/Mapel Lain"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const CBT_PACKAGE_QUERIES = gql`
  query listQueries(
    $_id: String
    $PREFIX: String
    $semester: String!
    $academicYear: String!
    $studentId: String!
    $subjectId: String!
  ) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      inProgress
    }
    studentById(studentId: $studentId) {
      _id
      name
      Classroom {
        _id
        name
        academicYear
      }
    }
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
        PREFIX
      }
      PREFIX
      photoUrl
    }
    historyStudentAnswer(
      studentId: $studentId
      academicYear: $academicYear
      subjectId: $subjectId
      semester: $semester
    ) {
      _id
      Package {
        _id
        name
      }
      countCorrect
      countQuestions
      status
    }
  }
`;

export default withRouter(CBTPackageHistory);
