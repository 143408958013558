import { View, Text } from "react-native-web";
import React, { Component } from "react";
class ATM extends Component {
  render() {
    const { bankCode } = this.props;
    if (bankCode === "MANDIRI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Masukan PIN ATM</Text>
          <Text>2. Pilih "Bayar/Beli"</Text>
          <Text>3. Pilih "MULTIPAYMENT"</Text>
          <Text>4. Masukkan Kode Perusahaan "88908 (Xendit 88908)"</Text>
          <Text>5. Masukkan Kode VA Anda - Tekan Benar</Text>
          <Text>6. Isi NOMINAL - Tekan Benar</Text>
          <Text>
            7. Pada konfirmasi data customer. Pilih Nomor 1 sesuai tagihan yang
            dibayar. Tekan "YA"
          </Text>
          <Text>8. Pada konfirmasi pembayaran. Tekan "YA"</Text>
          <Text>9. Anda Akan mendapatkan struk bukti pembayaran </Text>
          <Text>10. Transaksi Selesai</Text>
        </View>
      );
    } else if (bankCode === "BNI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Masukan PIN ATM</Text>
          <Text>2. Pilih "Menu Lainnya"</Text>
          <Text>3. Pilih "Transafer"</Text>
          <Text>4. Pilih jenis rekening (Contoh: "Tabungan")</Text>
          <Text>5. Pilih "Virtual Account Billing"</Text>
          <Text>6. Masukkan Kode VA Anda (contoh: 88089999XXXXXX)</Text>
          <Text>7. Konfirmasi apabila sudah sesusai</Text>
          <Text>8. Transaksi Selesai</Text>
        </View>
      );
    } else if (bankCode === "BRI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Pilih menu "Transaksi Lain"</Text>
          <Text>2. Pilih menu "Lainnya"</Text>
          <Text>3. Pilih menu "Pembayaran"</Text>
          <Text>4. Pilih menu "Lainnya"</Text>
          <Text>5. Pilih menu "BRIVA"</Text>
          <Text>6. Masukan Kode VA Anda - Tekan Benar</Text>
          <Text>7. Konfirmasi pembayaran. Tekan "YA" bila sudah sesuai</Text>
        </View>
      );
    } else {
      return (
        <View>
          <Text>{""}</Text>
        </View>
      );
    }
  }
}

export default ATM;
