import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
  TextInput,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { handleError } from "../libs/errors";
import dayjs from "dayjs";
import lodash from "lodash";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const SubjectModal = ({ subjects, handleSubject }) => (
  <div>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginBottom: 10,
      }}
    >
      <View style={{ width: 250 }} />
      <View style={{ width: 80 }}>
        <Text>Jml. Tugas</Text>
      </View>
    </View>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {subjects.map((sub) => (
        <div key={sub._id}>
          <TouchableOpacity onPress={handleSubject(sub)}>
            <section
              className="card"
              style={{
                padding: 10,
                backgroundColor: sub.countAssignment === 0 ? "#fff" : "#ef6c00",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: sub.countAssignment === 0 ? "#000" : "#fff",
                      }}
                    >
                      <i className="fa fa-book-open" /> {sub.name}
                    </Text>
                  </View>
                  <View style={{ width: 50, alignItems: "center" }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: sub.countAssignment === 0 ? "#000" : "#fff",
                      }}
                    >
                      {sub.countAssignment > 99
                        ? "99+"
                        : sub.countAssignment === 0
                        ? "-"
                        : sub.countAssignment}
                    </Text>
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </div>
      ))}
    </div>
  </div>
);

class Classwork extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    newClasswork: {
      academicAssessmentId: "",
      attachmentFileUrl: "",
      content: "",
      teacherId: "",
    },
    editClasswork: {
      academicAssessmentId: "",
      attachmentFileUrl: "",
      content: "",
      teacherId: "",
    },
    subjectList: [],

    assessment: null,
    classwork: null,

    loadingSubmitVisible: false,
    status: "",
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });
    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/classwork",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&classroomId=${urlValues.classroomId}&academicYear=${urlValues.academicYear}&assessmentId=${urlValues.assessmentId}&teacherId=${urlValues.teacherId}`,
      });

      const res = await mwvClient.query({
        query: CLASSWORK_QUERIES,
        variables: {
          classroomId: urlValues.classroomId,
          assessmentId: urlValues.assessmentId,
          studentId: urlValues.studentId,
        },
      });

      this.setState({
        subjectList: res.data.classroomSubject,
        assessment: res.data.assessmentById,
        classwork: res.data.classworkByAssessmentId,
      });
    }
    // else {
    //   this.props.history.replace({
    //     pathname: "/classroom_list",
    //     search: `?sessionId=${accountSession._id}&studentId=${
    //       students[0] ? students[0]._id : ""
    //     }&PREFIX=${students[0] ? students[0].PREFIX : ""}`,
    //   });
    // }

    this.setState({
      allStudents: [...students],
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/classroom_list",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleSubject = (subject) => (e) => {
    if (e) e.preventDefault();
    urlValues = queryString.parse(this.props.location.search);

    this.props.history.replace({
      pathname: "/daftar_tugas",
      search: `?sessionId=${urlValues.sessionId}&studentId=${
        urlValues.studentId
      }&PREFIX=${urlValues.PREFIX}&classroomId=${
        urlValues.classroomId
      }&academicYear=${urlValues.academicYear}&subjectId=${
        subject._id
      }&subjectName=${subject.name}&semester=${defaultSemester()}`,
    });
  };

  openSubject = () => {
    this.setState({
      subjectModalVisible: true,
    });
  };

  handleNewClasswork = (key) => (e) => {
    if (key === "attachmentFileUrl") {
      const file = e.target.files[0];
      if (file && file.size > 20480 * 10 * 1024) {
        return handleError({
          message: "Maximum allowed image size is 200MB!",
        });
      }

      let reader = new FileReader();
      reader.onloadend = async () => {
        this.setState({
          newClasswork: {
            ...this.state.newClasswork,
            attachmentFileUrl: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
    } else {
      this.setState({
        newClasswork: {
          ...this.state.newClasswork,
          [key]: e.target.value,
        },
      });
    }
  };

  handleInputEditClasswork = (key) => (e) => {
    if (key === "attachmentFileUrl") {
      const file = e.target.files[0];
      if (file && file.size > 20480 * 10 * 1024) {
        return handleError({
          message: "Maximum allowed image size is 200MB!",
        });
      }

      let reader = new FileReader();
      reader.onloadend = async () => {
        this.setState({
          editClasswork: {
            ...this.state.editClasswork,
            attachmentFileUrl: reader.result,
          },
        });
      };

      // console.log(reader);
      reader.readAsDataURL(file);
    } else {
      this.setState({
        editClasswork: {
          ...this.state.editClasswork,
          [key]: e.target.value,
        },
      });
    }
  };

  enableEdit = (classwork) => (e) => {
    // const { query } = this.props.router;
    urlValues = queryString.parse(this.props.location.search);

    this.setState({
      status: "edit",
      editClasswork: {
        _id: classwork._id,
        academicAssessmentId: urlValues.assessmentId,
        attachmentFileUrl: classwork.attachmentFileUrl,
        content: classwork.content,
        teacherId: urlValues.teacherId,
      },
    });
  };

  render() {
    const {
      allStudents,

      subjectList,
      subjectModalVisible,
      assessment,
      classwork,

      newClasswork,
      editClasswork,
      status,
      loadingSubmitVisible,
    } = this.state;

    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-book-open" /> {"Mata Pelajaran"}
            </span>
          }
          visible={subjectModalVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
          onClose={(e) => {
            this.setState({
              subjectModalVisible: false,
            });
          }}
        >
          <SubjectModal
            subjects={subjectList}
            handleSubject={this.handleSubject}
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-hourglass" /> {"Loading"}
            </span>
          }
          visible={loadingSubmitVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
        >
          <View
            style={{
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: 300,
                height: 300,

                borderRadius: 50,
              }}
              source={require("../assets/upload-to-cloud.png")}
            />

            <Text
              style={{
                marginTop: 20,
                fontSize: 30,
              }}
            >
              Loading...
            </Text>
          </View>
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"School Space"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TouchableOpacity onPress={this.openSubject}>
                <button
                  className="btn btn-sm btn-block"
                  style={{
                    backgroundColor: "#9c27b0",
                    color: "#fff",
                    fontWeight: "bold",
                  }}
                >
                  <i className="fa fa-book-open" /> Mata Pelajaran
                </button>
              </TouchableOpacity>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            marginTop: 210,
          }}
        >
          {assessment &&
          assessment.BoardMessages &&
          assessment.BoardMessages.Teacher ? (
            <View
              style={{
                flex: 1,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 25,
                  marginBottom: 10,
                }}
              >
                <i className="fa fa-paper-plane" /> Kirim Tugas
              </Text>
            </View>
          ) : (
            <h3 className="text-center mt-5">Tidak Ada Tugas</h3>
          )}
        </View>
        {assessment &&
        assessment.BoardMessages &&
        assessment.BoardMessages.Teacher ? (
          <View
            style={{
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <section
              className="card"
              style={{
                padding: 20,
                borderRadius: 10,
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <View>
                  <Image
                    style={{
                      width: 65,
                      height: 65,
                      overflow: "hidden",
                      borderRadius: 50,
                    }}
                    source={
                      assessment.BoardMessages.Teacher &&
                      assessment.BoardMessages.Teacher.photoUrl
                        ? assessment.BoardMessages.Teacher.photoUrl
                        : require("../assets/owl-laptop-study.png")
                    }
                  />
                </View>
                <View
                  style={{
                    paddingLeft: 10,
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      marginBottom: 5,
                    }}
                  >
                    Guru:{" "}
                    <b>
                      {" "}
                      {assessment.BoardMessages.Teacher
                        ? assessment.BoardMessages.Teacher.name
                        : ""}
                    </b>
                  </Text>
                  <Text>
                    Mapel:{" "}
                    <b>{assessment.Subject ? assessment.Subject.name : ""}</b>
                  </Text>
                  <Text>
                    Tugas: <b>{assessment.title}</b>
                  </Text>
                </View>
              </View>
              {assessment.isScheduled ? (
                <View style={{ flex: 1, marginTop: 10, flexDirection: "row" }}>
                  <View
                    style={{
                      width: 220,
                      // height: 50,
                      // backgroundColor: "powderblue",
                    }}
                  />
                  <View>
                    <Text style={{ marginBottom: 5 }}>Tenggat Waktu</Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Tgl: {assessment.dueDate}{" "}
                    </Text>
                    <Text
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Jam: {assessment.dueTime}
                    </Text>
                  </View>
                </View>
              ) : null}

              <hr
                style={{
                  marginTop: 5,
                  marginBottom: 10,
                  height: "1px",
                  borderTop: "1px solid",
                }}
              />

              {/* <View
                style={{
                  flex: 1,
                  marginBottom: 10,
                }}
              >
                <Text style={{ fontWeight: "bold" }}>Deskripsi: </Text>
                <Text style={{ marginTop: 10 }}>{assessment.description} </Text>
              </View> */}

              <div>
                <View>
                  <Text>
                    <p
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      Deskripsi
                    </p>
                  </Text>

                  <Text>
                    <p
                      align="justify"
                      style={{
                        marginBottom: 20,
                      }}
                    >
                      {assessment.description}
                    </p>
                  </Text>
                </View>
              </div>
              <hr
                style={{
                  marginTop: 10,
                  marginBottom: 0,
                  height: "1px",
                  borderTop: "1px solid",
                }}
              />

              <View style={{ marginTop: 10 }}>
                <Text>
                  Web Link:{" "}
                  {assessment.link ? (
                    <a href={assessment.link}>
                      {assessment.link.slice(0, 30)}...
                    </a>
                  ) : (
                    "-"
                  )}
                </Text>
                <Text style={{ marginTop: 10 }}>
                  Tautan File:{" "}
                  {assessment.attachmentFileUrl ? (
                    // <a href={lodash.get(assessment, "fileBaseObject.s3Url", assessment.attachmentFileUrl)} download>
                    //   <i className="fa fa-file" /> Download File
                    // </a>
                    <a href={assessment.attachmentFileUrl} download>
                      <i className="fa fa-file" /> Download File
                    </a>
                  ) : (
                    "-"
                  )}{" "}
                </Text>
              </View>
            </section>

            {classwork ? (
              <section
                className="card"
                style={{
                  padding: 15,
                  borderRadius: 10,
                  width: "360px",
                  marginTop: 10,
                  marginBottom: "100vh",
                }}
              >
                <View>
                  <Text
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    Isi / Pesan / Link Tugas:{" "}
                  </Text>
                  <input
                    disabled={status === "edit" ? false : true}
                    className="form-control"
                    onChange={
                      status === "edit"
                        ? this.handleInputEditClasswork("content")
                        : null
                    }
                    value={
                      status === "edit"
                        ? editClasswork.content
                        : classwork.content
                    }
                    placeholder={"Isi / Pesan / Link Tugas Anda"}
                  />

                  <Text
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    Tautan ke File Tugas: (max: 200mb){" "}
                  </Text>
                  <input
                    style={{ margin: 0, padding: 0, fontSize: 12 }}
                    className=""
                    type="file"
                    name="attachedFile"
                    accept="image/*,audio/*,video/*,application/pdf,.docx,.pptx,.xlsx"
                    disabled={status === "edit" ? false : true}
                    onChange={
                      status === "edit"
                        ? this.handleInputEditClasswork("attachmentFileUrl")
                        : null
                    }
                  />

                  {status !== "edit" &&
                  classwork.attachmentFileUrl &&
                  classwork.attachmentFileUrl.slice(0, 4) === "http" ? (
                    <Text style={{ marginTop: 10 }}>
                      Link To File:{" "}
                      <a href={classwork.attachmentFileUrl}>
                        <i className="fa fa-download" /> Download File
                      </a>
                    </Text>
                  ) : null}
                </View>

                <hr
                  style={{
                    marginTop: 5,
                    marginBottom: 5,
                  }}
                />

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-end",
                  }}
                >
                  {status === "edit" ? (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row-reverse",
                        justifyContent: "space-between",
                      }}
                    >
                      <button
                        className="btn btn-sm btn-danger btn-rounded"
                        style={{
                          marginRight: 10,
                        }}
                        onClick={(e) => {
                          this.setState({
                            status: "",
                          });
                        }}
                      >
                        <i className="fa fa-times" /> Batalkan
                      </button>
                      <button
                        className="btn btn-sm btn-success btn-rounded"
                        style={{
                          marginRight: 20,
                          paddingInlineStart: 20,
                          paddingInlineEnd: 20,
                        }}
                        onClick={this.submitUpdateClasswork(classwork)}
                      >
                        <i className="fa fa-paper-plane" /> Send
                      </button>
                    </View>
                  ) : classwork.score === 0 ? (
                    <button
                      className="btn btn-sm btn-success btn-rounded"
                      style={{
                        marginRight: 10,
                        paddingInlineStart: 20,
                        paddingInlineEnd: 20,
                      }}
                      onClick={this.enableEdit(classwork)}
                      disabled={classwork.score !== 0}
                    >
                      <i className="fa fa-edit" /> Edit
                    </button>
                  ) : null}

                  {classwork.score ? (
                    <View
                      style={{
                        flex: 1,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                        }}
                      >
                        Nilai:
                      </Text>
                      <Text
                        style={{
                          fontSize: 25,
                          fontWeight: "bold",
                        }}
                      >
                        {classwork.score}
                      </Text>
                    </View>
                  ) : null}
                </View>
              </section>
            ) : (
              <section
                className="card"
                style={{
                  padding: 15,
                  borderRadius: 10,
                  width: "360px",
                  marginTop: 10,
                  marginBottom: "100vh",
                }}
              >
                <View>
                  <Text style={{ marginBottom: 5 }}>
                    Isi / Pesan / Link Tugas:{" "}
                  </Text>
                  <input
                    className="form-control"
                    onChange={this.handleNewClasswork("content")}
                    value={newClasswork.content}
                    placeholder={"Isi / Pesan / Link Tugas Anda"}
                  />

                  <Text
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    Tautan ke File Tugas: (max: 200mb){" "}
                  </Text>
                  <input
                    style={{ margin: 0, padding: 0, fontSize: 12 }}
                    className=""
                    type="file"
                    name="attachedFile"
                    accept=".pdf,.mp3,.png,video/mp4"
                    onChange={this.handleNewClasswork("attachmentFileUrl")}
                  />
                </View>
                <hr
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                />

                <View
                  style={{
                    flex: 1,
                    flexDirection: "row-reverse",
                  }}
                >
                  <button
                    className="btn btn-sm btn-success btn-rounded"
                    style={{
                      marginRight: 10,
                    }}
                    onClick={this.submitClasswork}
                  >
                    <i className="fa fa-paper-plane" /> Kirim
                  </button>
                </View>
              </section>
            )}
          </View>
        ) : null}

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa / Kelas Lain"}
            </Text>
          </button>
        </div>
      </View>
    );
  }

  submitClasswork = async (e) => {
    if (e) e.preventDefault();

    try {
      const { newClasswork, assessment } = this.state;

      if (assessment) {
        if (assessment.isScheduled) {
          const currentDate = dayjs().format("YYYY-MM-DD");
          const currentTime = dayjs().format("HH:mm");
          const expiry = assessment.dueDate + " " + assessment.dueTime;
          const current = currentDate + " " + currentTime;
          if (dayjs(current).valueOf() > dayjs(expiry).valueOf()) {
            throw {
              message: "Pengumpulan tugas melebihi batas waktu",
            };
          }
        }
      }

      if (!newClasswork.content) {
        throw {
          message: "Isi / Pesan / Link Tugas belum diisi",
        };
      }

      urlValues = queryString.parse(this.props.location.search);

      this.setState({
        loadingSubmitVisible: true,
      });

      let result = await mwvClient.mutate({
        mutation: SUBMIT_CLASSWORK,
        variables: {
          academicAssessmentId: urlValues.assessmentId,
          attachmentFileUrl: newClasswork.attachmentFileUrl,
          content: newClasswork.content,
          teacherId: urlValues.teacherId,
          studentId: urlValues.studentId,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data.submitClasswork === "success") {
        addNotification({
          title: "SUCCESS",
          message: `Tugas Berhasil Dikirim`,
          level: "success",
        });

        this.initiate();
        this.setState({
          status: "edit",
          loadingSubmitVisible: false,
        });

        setTimeout(() => this.refresh(), 1500);
      }
    } catch (err) {
      handleError(err);
    }
  };
  submitUpdateClasswork = (classwork) => async (e) => {
    if (e) e.preventDefault();

    try {
      const { editClasswork, assessment } = this.state;

      if (assessment) {
        if (assessment.isScheduled) {
          const currentDate = dayjs().format("YYYY-MM-DD");
          const currentTime = dayjs().format("HH:mm");
          const expiry = assessment.dueDate + " " + assessment.dueTime;
          const current = currentDate + " " + currentTime;
          if (dayjs(current).valueOf() > dayjs(expiry).valueOf()) {
            throw {
              message: "Pengumpulan tugas melebihi batas waktu",
            };
          }
        }
      }

      if (!editClasswork.content) {
        throw {
          message: "Isi / Pesan / Link Tugas belum diisi",
        };
      }

      urlValues = queryString.parse(this.props.location.search);

      this.setState({
        loadingSubmitVisible: true,
      });

      let result = await mwvClient.mutate({
        mutation: UPDATE_CLASSWORK,
        variables: {
          _id: editClasswork._id,
          academicAssessmentId: urlValues.assessmentId,
          attachmentFileUrl: editClasswork.attachmentFileUrl,
          content: editClasswork.content,
          teacherId: urlValues.teacherId,
          studentId: urlValues.studentId,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data.updateClasswork === "success") {
        addNotification({
          title: "SUCCESS",
          message: `Tugas Berhasil Dikirim`,
          level: "success",
        });

        this.initiate();
        this.setState({
          status: "edit",
          loadingSubmitVisible: false,
        });

        setTimeout(() => this.refresh(), 1500);
      }
    } catch (err) {
      handleError(err);
    }
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const CLASSWORK_QUERIES = gql`
  query listClassworkQuery(
    $assessmentId: String!
    $studentId: String!
    $classroomId: String!
  ) {
    assessmentById(assessmentId: $assessmentId) {
      _id
      title
      description
      Subject {
        _id
        name
      }
      BoardMessages {
        _id
        Teacher {
          _id
          name
          photoUrl
        }
      }
      attachmentFileUrl
      fileBaseObject {
        s3Url
      }
      link
      dueDate
      dueTime
      isScheduled
    }

    classworkByAssessmentId(
      assessmentId: $assessmentId
      studentId: $studentId
    ) {
      _id
      attachmentFileUrl
      content
      score
    }

    classroomSubject(classroomId: $classroomId, studentId: $studentId) {
      _id
      name
      countAssignment
    }
  }
`;

const SUBMIT_CLASSWORK = gql`
  mutation submitClasswork(
    $academicAssessmentId: String!
    $attachmentFileUrl: String
    $content: String
    $teacherId: String
    $studentId: String
  ) {
    submitClasswork(
      input: {
        academicAssessmentId: $academicAssessmentId
        attachmentFileUrl: $attachmentFileUrl
        content: $content
        teacherId: $teacherId
        studentId: $studentId
      }
    )
  }
`;

const UPDATE_CLASSWORK = gql`
  mutation updateClasswork(
    $_id: ID!
    $academicAssessmentId: String
    $attachmentFileUrl: String
    $content: String
    $teacherId: String
    $studentId: String
  ) {
    updateClasswork(
      _id: $_id
      input: {
        academicAssessmentId: $academicAssessmentId
        attachmentFileUrl: $attachmentFileUrl
        content: $content
        teacherId: $teacherId
        studentId: $studentId
      }
    )
  }
`;

export default withRouter(Classwork);

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
