import React, { Component } from "react";
import { View, StatusBar, Text, TouchableOpacity } from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

let urlValues;
class Help extends Component {
  state = {
    username: process.env.NODE_ENV !== "production" ? "083831114477" : "",
    password: process.env.NODE_ENV !== "production" ? "3335557" : "",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {}
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession);

    urlValues = queryString.parse(this.props.location.search);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name
      });
      // this.props.history.push({
      //   pathname: "/help",
      //   search: `?sessionId=${accountSession._id}`
      // });
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  };

  handleInput = e => {
    // console.log(key, text);
    this.setState({
      keyword: e.target.value
    });
  };

  openPage = category => e => {
    this.props.history.push({
      pathname: "/all_help_items",
      search: `?sessiondId=${urlValues.sessionId}&categoryId=${category._id}&categoryName=${category.name}`
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleSearch = e => {
    if (e) e.preventDefault();
    this.props.history.push({
      pathname: "/find_help",
      search: `?sessiondId=${urlValues.sessionId}&keyword=${this.state.keyword}`
    });
  };

  render() {
    const { allHelpItemCategories } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-question-circle"
                  style={{
                    fontSize: 20
                  }}
                />{" "}
                &nbsp; Help
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 80,
            marginTop: 65
          }}
        >
          <section
            className="card card-blue"
            style={{ padding: 20, margin: "3px 0" }}
          >
            <div className="card-block">
              <form onSubmit={this.handleSearch}>
                <div>
                  <h5>Help Center</h5>
                </div>
                <div className="form-group" style={{ marginBottom: 6 }}>
                  <label>
                    <i className="fa fa-search" /> Search
                  </label>
                  <input
                    className="form-control"
                    placeholder="Find something"
                    value={this.state.keyword}
                    onChange={this.handleInput}
                  />
                </div>
              </form>
            </div>
          </section>

          {allHelpItemCategories
            ? allHelpItemCategories.map(category => {
                return (
                  <TouchableOpacity
                    onPress={this.openPage(category)}
                    key={category._id}
                  >
                    <section
                      className="card"
                      style={{
                        padding: 20,
                        margin: "3px 0",
                        borderColor: category.iconColor,
                        color: category.iconColor
                      }}
                    >
                      <div className="card-block">
                        <div className="fa-pull-left">
                          <i
                            className={"fa fa-" + category.iconKey}
                            style={{
                              fontSize: 20
                            }}
                          />
                          &nbsp; {category.name}
                        </div>
                        <div className="fa-pull-right">
                          <i
                            className="fa fa-caret-right"
                            style={{
                              fontSize: 20
                            }}
                          />
                        </div>
                        <div className="clearfix" />
                      </div>
                    </section>
                  </TouchableOpacity>
                );
              })
            : null}
          <br />
          <section className="card" style={{ padding: 20 }}>
            <div className="card-block">
              <p>
                <b>Hubungi Kami</b>
                <br />
                Jika ada pertanyaan yang belum tercakup, Anda dapat menghubungi
                Kami menggunakan informasi dibawah ini
              </p>
              <p>
                www.softwaresekolah.co.id
                <br />
                Perumahan Pondok Maritim Indah Blok DD 18
                <br />
                Surabaya, Jawa Timur
                <br />
                Indonesia
                <br />
                info@softwaresekolah.co.id
                <br />
                087722663637
              </p>
            </div>
          </section>
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const supportClient = new ApolloClient({
  uri: "https://api.support.softwaresekolah.co.id/graphql"
});

const HELP_CATEGORIES = gql`
  query allHelpItemCategories {
    allHelpItemCategories {
      _id
      name
      keywords
      iconKey
      iconColor
    }
  }
`;

export default withRouter(props => (
  <Query query={HELP_CATEGORIES} client={supportClient}>
    {({ error, loading, data, refetch }) => (
      <div>
        <Help
          {...props}
          error={error}
          loading={loading}
          refetch={refetch}
          allHelpItemCategories={
            data && data.allHelpItemCategories ? data.allHelpItemCategories : []
          }
        />
      </div>
    )}
  </Query>
));
