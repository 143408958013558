import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import localforage from "localforage";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";
import queryString from "query-string";
import { ApolloConsumer, Mutation, Query } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import { handleError } from "../libs/errors";
import ApolloClient from "apollo-boost";

let urlValues;

class ClaimStudent extends Component {
  state = {
    uniqueCode: "",
    Student: null,
    session: {},
    loading: false,
    accountSession: null,
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    this.setState({
      accountSession,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleInputUniqueCode = (e) => {
    this.setState({
      uniqueCode: e.target.value,
    });
  };

  handleKeyDownUniqueCode = async (e) => {
    if (e.key === "Enter") {
      if (this.state.uniqueCode.length > 15) {
        return handleError({
          message: "Kode unik tidak valid!",
        });
      }
      this.setState({
        Student: null,
        Errors: null,
        loading: true,
      });

      try {
        // const response = await this.props.searchStudentByUniqueCode({
        //   variables: {
        //     uniqueCode: this.state.uniqueCode,
        //   },
        // });

        const response = await mwvClient.mutate({
          mutation: SEARCH_STUDENT_BY_UNIQUE_CODE,
          variables: {
            uniqueCode: this.state.uniqueCode,
          },
          fetchPolicy: "no-cache",
        });
        this.setState({
          Student: response.data.searchStudentByUniqueCode,
          loading: false,
        });
      } catch (e) {
        this.setState({
          Errors: e,
          loading: false,
        });
      }
    }
  };

  handleSearch = async (e) => {
    if (this.state.uniqueCode.length > 15) {
      return handleError({
        message: "Kode unik tidak valid!",
      });
    }
    this.setState({
      Student: null,
      Errors: null,
      loading: true,
    });

    try {
      // const response = await this.props.searchStudentByUniqueCode({
      //   variables: {
      //     uniqueCode: this.state.uniqueCode,
      //   },
      // });

      const response = await mwvClient.mutate({
        mutation: SEARCH_STUDENT_BY_UNIQUE_CODE,
        variables: {
          uniqueCode: this.state.uniqueCode,
        },
        fetchPolicy: "no-cache",
      });
      this.setState({
        Student: response.data.searchStudentByUniqueCode,
        loading: false,
      });
    } catch (e) {
      this.setState({
        Errors: e,
        loading: false,
      });
    }
  };

  handleClaim = async (e) => {
    if (e) e.preventDefault();
    this.setState({
      loading: true,
    });
    try {
      let fcmToken = "";
      if (!firebase.apps.length) {
        // console.log("Initialize firebase...");
        firebase.initializeApp(firebaseConfig);
      }
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        fcmToken = await messaging.getToken();
      }

      let userId = "";

      if (
        this.props.currentUser &&
        this.props.currentUser.data &&
        this.props.currentUser.data.currentUser &&
        this.props.currentUser.data.currentUser._id
      ) {
        userId = this.props.currentUser.data.currentUser._id;
      }

      if (!userId) {
        throw {
          message: "Invalid User ID",
        };
      }

      const response = await mwvClient.mutate({
        mutation: CLAIM_STUDENT,
        variables: {
          userId,
          uniqueCode: this.state.uniqueCode,
          fcmToken,
        },
      });
      // const response = await this.props.claimStudentForAccount({
      //   variables: {
      //     uniqueCode: this.state.uniqueCode,
      //     fcmToken,
      //   },
      // });
      const student =
        response.data.claimStudentForAccount &&
        response.data.claimStudentForAccount.name
          ? response.data.claimStudentForAccount.name
          : "";
      this.setState({
        Student: response.data.claimStudentForAccount,
        loading: false,
      });
      addNotification({
        title: "SUCCESS",
        message: `Berhasil klaim siswa ${student}`,
        level: "success",
      });
    } catch (e) {
      console.log(e);
      if (e.message.indexOf("Student not found!") > -1) {
        this.setState({
          Student: null,
          Errors: e.message,
          loading: false,
        });
      }
    }
  };

  render() {
    const { Student, Errors, accountSession, loading } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-user-plus"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Klaim Siswa")}
              </Text>
            }
            right={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/explore",
                  })
                }
              >
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  {/* <i
										className="fa fa-sync-alt"
										style={{
											fontSize: 20,
											marginBottom: 5
										}}
									/> */}
                  {translate("Kembali")}
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        <div
          className="card"
          style={{ borderRadius: "20px", margin: "auto 15px" }}
        >
          <div className="card-header">
            <Image
              style={{
                width: 140,
                height: 140,
                margin: "auto",
              }}
              source={require("../assets/call-center-female-caucasian.png")}
            />
          </div>
          <div className="card-body">
            <p className="text-center">
              {translate("Masukkan Kode Unik Siswa Di Bawah Ini")}.
            </p>
            <div className="form-group">
              <input
                style={{ textAlign: "center" }}
                type="text"
                className="form-control"
                placeholder={`${translate("Masukkan kode unik")}...`}
                maxLength={15}
                onChange={this.handleInputUniqueCode}
                onKeyDown={this.handleKeyDownUniqueCode}
              />
              <button
                type="button"
                className="btn btn-block btn-primary mt-3"
                onClick={this.handleSearch}
              >
                <i className="fa fa-search" /> Cari
              </button>
            </div>

            {Student ? (
              <div>
                <hr />
                <h6
                  className="text-bold text-center"
                  style={{ fontWeight: "bold" }}
                >
                  {translate("Siswa ditemukan")}!
                </h6>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th width="20%" />
                      <th width="5%" />
                      <th width="65%" />
                      <th width="10%" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{translate("Nama")}</td>
                      <td>:</td>
                      <td>{Student.name}</td>
                    </tr>
                    <tr>
                      <td>{translate("NIS")}</td>
                      <td>:</td>
                      <td>{Student.regNumber}</td>
                    </tr>
                  </tbody>
                </table>
                <hr />
                <button
                  onClick={this.handleClaim}
                  className="btn btn-primary btn-block"
                >
                  <i className="fa fa-user-check" /> {translate("Konfirmasi")}
                </button>
              </div>
            ) : Errors ? (
              <div className="text-center">
                <hr />
                <h6
                  className="text-bold text-center"
                  style={{ fontWeight: "bold" }}
                >
                  {translate("Siswa tidak ditemukan")}!
                </h6>
                <Image
                  style={{
                    width: 140,
                    height: 140,
                    margin: "auto",
                  }}
                  source={require("../assets/user-remove-denied-male-asian.png")}
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="fixed-bottom">
          <TouchableOpacity
            onPress={() =>
              this.props.history.push({
                pathname: "/list_students",
                search: `?sessionId=${accountSession._id}`,
              })
            }
          >
            <button
              type="button"
              // onClick={this.openFilterModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#2e86de",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                }}
              >
                <i className="fa fa-users" /> {translate("Daftar Siswa")}
              </Text>
            </button>
          </TouchableOpacity>
        </div>
      </View>
    );
  }
}

const SEARCH_STUDENT_BY_UNIQUE_CODE = gql`
  mutation searchStudentByUniqueCode($uniqueCode: String!) {
    searchStudentByUniqueCode(uniqueCode: $uniqueCode) {
      _id
      name
      regNumber
    }
  }
`;

const CLAIM_STUDENT = gql`
  mutation claimStudentForAccount(
    $uniqueCode: String!
    $fcmToken: String
    $userId: String
  ) {
    claimStudentForAccount(
      uniqueCode: $uniqueCode
      fcmToken: $fcmToken
      userId: $userId
    ) {
      _id
      name
      regNumber
    }
  }
`;

const QUERY = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
      studentIds
      parentIds
      employeeIds
      prefixes
      roles
    }
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter(
  withI18n("claim_student")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Query query={QUERY}>
            {(currentUser) => (
              <ClaimStudent {...props} currentUser={currentUser} />
            )}
          </Query>
        )}
      </ApolloConsumer>
    );
  })
);

// export default withRouter(
//   withI18n("claim_student")((props) => {
//     urlValues = queryString.parse(props.location.search);
//     return (
//       <ApolloConsumer>
//         {(client) => (
//           <Mutation mutation={SEARCH_STUDENT_BY_UNIQUE_CODE}>
//             {(searchStudentByUniqueCode) => (
//               <Mutation mutation={CLAIM_STUDENT}>
//                 {(claimStudentForAccount) => (
//                   <ClaimStudent
//                     {...props}
//                     searchStudentByUniqueCode={searchStudentByUniqueCode}
//                     claimStudentForAccount={claimStudentForAccount}
//                   />
//                 )}
//               </Mutation>
//             )}
//           </Mutation>
//         )}
//       </ApolloConsumer>
//     );
//   })
// );
