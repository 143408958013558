/**
 * REQUIRED:
 * @param sessionId => id sesi yang didapatkan setelah login
 *
 * OPTIONAL:
 * @param filter => digunakan untuk mengecek apakah menggunakan filter atau tidak
 * @param filterType => digunakan untuk memilih tipe  filter
 * @param fromDate => digunakan untuk memilih filter Start Date
 * @param toDate => digunakan untuk memilih filter End Date
 */

import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
import localforage from "localforage";
import { withRouter } from "react-router";
import ReactHtmlParser from "react-html-parser";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import HeaderBar from "../../components/HeaderBar";
import { LoadingOverlay } from "../../components/Overlay";
import { FormModal } from "../../components/Modal";
import { handleError } from "../../libs/errors";
import { withI18n, translate } from "../../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
// import momentId from 'moment/locale/id';
// moment.locale('id', momentId);

let urlValues;

const CardBook = ({
  book,
  studentId,
  employeeId,
  handleFilter,
  handleCancel,
  counter,
  router,
  t,
}) => {
  const renderButton = () => {
    let status = "";
    let cancelId = "";
    if (book.borrowedBy && book.borrowedBy.length > 0) {
      const keyFilter = {
        EMPLOYEE: "employeeId",
        STUDENT: "studentId",
      };
      const valueFilter = {
        EMPLOYEE: employeeId,
        STUDENT: studentId,
      };

      const waitStatus = book.borrowedBy.filter(
        (b) =>
          b.status === "Waiting Confirmation" &&
          b[keyFilter[router.for]] === valueFilter[router.for]
      );
      if (waitStatus.length > 0) {
        status = "Waiting Confirmation";
        cancelId = book.borrowedBy.filter(
          (b) =>
            b.status === "Waiting Confirmation" &&
            b[keyFilter[router.for]] === valueFilter[router.for]
        )[0]._id;
      }

      const borrowStatus = book.borrowedBy.filter(
        (b) =>
          b.status === "Borrowed" &&
          b[keyFilter[router.for]] === valueFilter[router.for]
      );

      if (borrowStatus.length > 0) {
        status = "Borrowed";
      }
    }

    if (book.totalBook <= book.borrowed) {
      return (
        <button className="btn btn-secondary rounded btn-block" disabled>
          Stok kosong
        </button>
      );
    } else if (
      status === "Waiting Confirmation"
      // book.borrowedBy &&
      // book.borrowedBy.length > 0 &&
      // book.borrowedBy.filter(
      //   (borrow) =>
      //     borrow.status === "Waiting Confirmation" &&
      //     borrow.studentId === studentId
      // )
    ) {
      return (
        <button
          className="btn btn-danger rounded btn-block"
          onClick={handleCancel(cancelId)}
        // onClick={handleCancel(
        //   book.borrowedBy.filter(
        //     (borrow) =>
        //       borrow.status === "Waiting Confirmation" &&
        //       borrow.studentId === studentId
        //   )[0]._id
        // )}
        >
          Batal Pinjam
        </button>
      );
    } else if (
      status === "Borrowed"
      // book.borrowedBy &&
      // book.borrowedBy.length > 0 &&
      // book.borrowedBy.filter(
      //   (borrow) =>
      //     borrow.status === "Borrowed" && borrow.studentId === studentId
      // )
    ) {
      return (
        <button className="btn btn-secondary rounded btn-block" disabled>
          Sedang Dipinjam
        </button>
      );
    } else {
      return (
        <button
          className="btn text-white rounded btn-block"
          style={{
            backgroundColor: "#00a8ff",
            borderWidth: 0,
            borderRadius: 0,
            boxShadow: "0px -2px 5px 2px #ddd",
          }}
          onClick={handleFilter}
        // disabled={book.totalBook <= book.borrowed}
        >
          <i className="fa fa-bookmark mr-1" />
          Pinjam Buku
        </button>
      );
    }
  };
  return (
    <div className="col-md-6" key={book._id}>
      <style jsx="true">
        {`
          .card {
            box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
              0px 1px 1px 0px rgba(0, 0, 0, 0.14),
              0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            border: none;
          }
        `}
      </style>
      <div
        className={"card card-material mb-3"}
        style={{ border: "none", borderRadius: "4px" }}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-12">
              <img
                alt={book.coverImageUrl}
                src={book.coverImageUrl}
                className="img-fluid"
              />
            </div>
            <div className="col-12 mt-2">
              <h4 style={{ marginBottom: 0 }} className="card-title">
                <b>{book.name}</b>
              </h4>
              <small className="text-small text-info">{book.author}</small>
              <p className="card-text text-secondary">{book.type}</p>
              <h5 className="mb-1">Tentang Buku Ini</h5>
              {ReactHtmlParser(book.description)}
              <h5 className="mb-1">Informasi Buku</h5>
              <div className="d-flex justify-content-between">
                <span className="mb-1">Tahun terbit</span>
                <span className="mb-1">{book.publicationYear}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="mb-1">Halaman</span>
                <span className="mb-1">{book.pages}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="mb-1">ISBN</span>
                <span className="mb-1">{book.isbn}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span className="mb-1">Stok</span>
                <span className="mb-1">
                  {book.totalBook === 0 ? 0 : book.totalBook}
                </span>
              </div>
            </div>
            <div className="col-lg mt-3">{renderButton()}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FilterForm = ({ formData, handleChangeDate, t }) => (
  <div className="row">
    <div className="col mt-2">
      <div className="form-group">
        <label>Tanggal Pengembalian</label>
        <input
          type="date"
          className="form-control"
          value={formData.endDateTime}
          onChange={handleChangeDate("endDateTime")}
        />
      </div>
    </div>
  </div>
);

class BookDetail extends Component {
  state = {
    session: {},
    employees: [],
    loading: false,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    startDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
    endDateTime: dayjs().add(7, "day").format("YYYY-MM-DD"),
    isCheckedMonth: true,
    isCheckedDate: false,

    filterFormVisible: false,
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  handleFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  handleChangeRadio = (key) => (e) => {
    if (key === "isCheckedDate") {
      this.setState({
        [key]: !this.state.isCheckedDate,
        isCheckedMonth: !this.state.isCheckedMonth,
      });
    }
    this.setState({
      [key]: !this.state.isCheckedMonth,
      isCheckedDate: !this.state.isCheckedDate,
    });
  };
  handleChangeMonth = (key) => (e) => {
    if (key === "month") {
      this.setState({
        month: parseInt(e.target.value),
      });
    } else {
      this.setState({
        [key]: e.target.value,
      });
    }
  };
  handleChangeDate = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  handleCloseFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  refresh = () => {
    window.location.reload();
  };
  handleCancel = (_id) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();

    try {
      await mwvClient.mutate({
        mutation: CANCEL_BORROW,
        variables: {
          _id,
          studentId: urlValues.studentId,
          employeeId: urlValues.employeeId
        },
      });
      await this.props.refetch();
      addNotification({
        title: "SUCCESS",
        message: "Berhasil membatalkan peminjaman buku!",
        level: "success",
      });
    } catch (e) {
      handleError(e);
    }
    hideLoadingSpinner();
  };
  handleBorrowBook = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();

    try {
      await mwvClient.mutate({
        mutation: CREATE_BORROW,
        variables: {
          academicYear: defaultAcademicYear(),
          studentId: urlValues.studentId || "",
          employeeId: urlValues.employeeId || "",
          bookId: urlValues.bookId,
          borrowDate: dayjs().format("YYYY-MM-DD"),
          returnDate: this.state.endDateTime,
          dueDate: dayjs(this.state.endDateTime)
            .add(3, "day")
            .format("YYYY-MM-DD"),
          renewalDate: "",
          totalFines: 0,
          for: urlValues.for,
        },
      });
      // await this.props.createBorrowingBook({
      //   variables: {
      //     input: {
      //       academicYear: defaultAcademicYear(),
      //       studentId: urlValues.studentId,
      //       bookId: urlValues.bookId,
      //       borrowDate: dayjs().format("YYYY-MM-DD"),
      //       returnDate: this.state.endDateTime,
      //       dueDate: dayjs(this.state.endDateTime).add(3, "day").format("YYYY-MM-DD"),
      //       renewalDate: "",
      //       totalFines: 0
      //     }
      //   }
      // });
      await this.props.refetch();
      addNotification({
        title: "SUCCESS",
        message: "Berhasil meminjam buku!",
        level: "success",
      });

      this.setState({
        filterFormVisible: !this.state.filterFormVisible,
      });
    } catch (e) {
      handleError(e);
    }
    hideLoadingSpinner();
  };
  render() {
    let counter = 0;
    const { loading } = this.state;
    const { bookById } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={<Text>Detail Buku</Text>}
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <FormModal
          title={
            <span>
              <i className="fa fa-filter" /> {translate("Konfirmasi")}
            </span>
          }
          visible={this.state.filterFormVisible}
          onClose={this.handleCloseFilter}
          onSubmit={this.handleBorrowBook}
          size="lg"
          hideCloseButton
        >
          <FilterForm
            formData={this.state}
            handleChangeRadio={this.handleChangeRadio}
            handleChangeDate={this.handleChangeDate}
            handleChangeMonth={this.handleChangeMonth}
            t={translate}
          />
        </FormModal>
        <div
          className="container-fluid mt-3"
          style={{ position: "relative", bottom: "30px" }}
        >
          <div className="row">
            <div className="col">
              <br />
              <div>
                <div className="row">
                  {bookById ? (
                    <CardBook
                      book={bookById}
                      studentId={urlValues.studentId}
                      handleFilter={this.handleFilter}
                      handleCancel={this.handleCancel}
                      counter={counter++}
                      router={urlValues}
                      t={translate}
                    />
                  ) : (
                    <div className="col text-center">
                      <Image
                        style={{ width: 135, height: 135, margin: "auto" }}
                        source={require("../../assets/03-calendar.png")}
                      />
                      <h5 className="text-center text-secondary">
                        {this.props.loading ? (
                          <span>
                            <i className="fa fa-circle-notch fa-spin" />{" "}
                            {translate("Sedang Mengambil Buku")}.
                          </span>
                        ) : (
                          translate("Buku tidak ditemukan")
                        )}
                      </h5>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ALL_QUERIES = gql`
  query allQueries($_id: ID!) {
    bookById(_id: $_id) {
      _id
      name
      coverImageUrl
      author
      type
      description
      publicationYear
      pages
      isbn
      borrowed
      totalBook
      borrowedBy {
        _id
        status
        studentId
      }
    }
  }
`;

const CREATE_BORROW = gql`
  mutation createBorrowingBook(
    $academicYear: String!
    $studentId: String!
    $employeeId: String!
    $bookId: String!
    $borrowDate: String!
    $dueDate: String!
    $renewalDate: String
    $returnDate: String
    $totalFines: Int!
    $for: String!
  ) {
    createBorrowingBook(
      input: {
        academicYear: $academicYear
        studentId: $studentId
        employeeId: $employeeId
        bookId: $bookId
        borrowDate: $borrowDate
        dueDate: $dueDate
        renewalDate: $renewalDate
        returnDate: $returnDate
        totalFines: $totalFines
        for: $for
      }
    )
  }
`;

const CANCEL_BORROW = gql`
  mutation cancelBorrowConfirmation($_id: ID!, $studentId: ID, $employeeId: ID) {
    cancelBorrowConfirmation(_id: $_id, studentId: $studentId, employeeId: $employeeId)
  }
`;

export default withRouter(
  withI18n("agenda")((props) => {
    urlValues = queryString.parse(props.location.search);
    const bookId = urlValues.bookId ? urlValues.bookId : "";
    return (
      <Mutation mutation={CANCEL_BORROW}>
        {(cancelBorrowConfirmation) => (
          <Mutation mutation={CREATE_BORROW}>
            {(createBorrowingBook) => (
              <Query
                query={ALL_QUERIES}
                client={mwvClient}
                variables={{
                  sessionId: urlValues.sessionId,
                  _id: bookId,
                }}
              >
                {({ error, loading, data, refetch }) => (
                  <div>
                    <BookDetail
                      {...props}
                      error={error}
                      loading={loading}
                      bookById={data && data.bookById ? data.bookById : {}}
                      createBorrowingBook={createBorrowingBook}
                      cancelBorrowConfirmation={cancelBorrowConfirmation}
                      refetch={refetch}
                    />
                  </div>
                )}
              </Query>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  })
);

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${new Date().getFullYear() - 1
      }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${new Date().getFullYear() + 1
      }`;
  }
  return academicYear;
};
