import React, { Component } from "react";
import { View, TouchableOpacity, StyleSheet, Text, Image } from "react-native";
import withPreventDoubleClick from "../libs/withPreventDoubleClick";
import localforage from "localforage";
import { withRouter } from "react-router";
import { withI18n, translate } from "../libs/withI18n";
import encryptedLS from "../libs/encryptedLS";

const DEVICE_WIDTH = window.innerWidth;

const SCHOOL_LIFE_ITEMS = [
  {
    label: "Performa Siswa",
    icon: "star",
    image: require("../assets/images/akademik.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );

      let academicYear;
      if (new Date().getMonth() < 6) {
        academicYear = `${
          new Date().getFullYear() - 1
        }/${new Date().getFullYear()}`;
      } else {
        academicYear = `${new Date().getFullYear()}/${
          new Date().getFullYear() + 1
        }`;
      }

      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/student_performance`,
        search: `?sessionId=${accountSession._id}&academicYear=${academicYear}`,
      });
      // window.location = "/student_saving?sessionId=" + accountSession._id;
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Tagihan dan Pembayaran",
    icon: "file-invoice",
    image: require("../assets/images/tagihan.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/student_bill_and_payment`,
        search: `?sessionId=${accountSession._id}`,
      });
      // window.location = "/student_saving?sessionId=" + accountSession._id;
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
    hidden: ({ hideMenuByRole }) => hideMenuByRole,
  },
  {
    label: "Presensi Siswa",
    icon: "clock",
    image: require("../assets/images/presensi_siswa.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );

      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/presensi_siswa`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Agenda Sekolah",
    icon: "calendar-day",
    image: require("../assets/images/agenda.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/agenda`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) => hasParent || hasEmployee,
  },
  {
    label: "Tabungan Siswa",
    icon: "coins",
    image: require("../assets/images/tabungan_siswa.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/student_saving`,
        search: `?sessionId=${accountSession._id}`,
      });
      // window.location = "/student_saving?sessionId=" + accountSession._id;
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
    hidden: ({ hideMenuByRole }) => hideMenuByRole,
  },
  {
    label: "Tabungan Pegawai",
    icon: "university",
    image: require("../assets/images/tabungan_pegawai.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/employee_saving`,
        search: `?sessionId=${accountSession._id}`,
      });
      // window.location = "/employee_saving?sessionId=" + accountSession._id;
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasEmployee,
    color: "#273c75",
  },
  {
    label: "Presensi Pegawai",
    icon: "clock",
    image: require("../assets/images/presensi_pegawai.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );

      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/presensi_pegawai`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) => {
      return (!hasParent && !hasEmployee) || hasEmployee;
    },
    color: "#273c75",
  },
  {
    label: "Klaim Siswa",
    icon: "student",
    image: require("../assets/images/student.png"),
    onPress: (props) => async (e) => {
      props.history.push({
        pathname: `/claim_student`,
      });
    },
    visible: ({ hasParent, hasEmployee }) => true,
    // (!hasParent && !hasEmployee) || hasParent,
    hidden: ({ hideMenuByRole }) => false,
  },
  {
    label: "Klaim Pegawai",
    icon: "employee",
    image: require("../assets/images/employee.png"),
    onPress: (props) => async (e) => {
      props.history.push({
        pathname: `/claim_employee`,
      });
    },
    visible: ({ hasParent, hasEmployee }) => true,
    // (!hasParent && !hasEmployee) || hasParent,
    hidden: ({ hideMenuByRole }) => false,
    // visible: ({ hasParent, hasEmployee }) =>
    //   (!hasParent && !hasEmployee) || hasParent,
    // hidden: ({ hideMenuByRole }) => hideMenuByRole,
  },
  {
    label: "School Space",
    icon: "classroom",
    image: require("../assets/images/classroom.png"),
    onPress: (props) => async (e) => {
      props.history.push({
        pathname: `/classroom_list`,
      });
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Siswa",
    icon: "student",
    image: require("../assets/Akademik.png"),
    onPress: (props) => async (e) => {
      props.history.push({
        pathname: `/student_list`,
      });
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Perpustakaan",
    icon: "student",
    image: require("../assets/books.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/library/borrow_as`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Buku Penghubung",
    icon: "student",
    image: require("../assets/buku_penghubung.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );
      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/comm_book`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) =>
      (!hasParent && !hasEmployee) || hasParent,
  },
  {
    label: "Assessmen Pegawai",
    icon: "employee",
    image: require("../assets/icon-pegawai.png"),
    onPress: (props) => async (e) => {
      let accountSession = await localforage.getItem(
        "accountSession",
        (err, val) => {
          if (err !== null) {
            return null;
          }
          return val;
        }
      );

      accountSession = JSON.parse(accountSession);
      props.history.push({
        pathname: `/list_menu_kepegawaian`,
        search: `?sessionId=${accountSession._id}`,
      });
    },
    visible: ({ hasParent, hasEmployee }) => {
      return (!hasParent && !hasEmployee) || hasEmployee;
    },
    // visible: ({ hasParent, hasEmployee }) =>
    //   (!hasParent && !hasEmployee) || hasParent,
    // hidden: ({ hideMenuByRole }) => hideMenuByRole,
  },
];

class SchoolLifeMenu extends Component {
  renderItems({ hasEmployee, hasParent, hideMenuByRole }) {
    console.log({ hasEmployee, hasParent });
    return (
      <View>
        {SCHOOL_LIFE_ITEMS.reduce(
          (rows, item) => {
            if (item.hidden && item.hidden({ hideMenuByRole })) {
              return rows;
            }
            if (item.visible({ hasParent, hasEmployee })) {
              if (rows[rows.length - 1].items.length < 3) {
                rows[rows.length - 1].items.push(item);
              } else {
                rows.push({
                  items: [],
                });
                rows[rows.length - 1].items.push(item);
              }
            }
            return rows;
          },
          [
            {
              items: [],
            },
          ]
        ).map((rows, index) => (
          <View style={styles.row} key={index}>
            {rows.items.map((item) => (
              <SchoolLifeItem
                key={item.label}
                label={translate(item.label)}
                icon={item.icon}
                onPress={item.onPress(this.props)}
                color={item.color}
                image={item.image}
              />
            ))}
          </View>
        ))}
      </View>
    );
  }

  render() {
    return (
      <View
        style={{
          // marginTop: 6,

          marginVertical: 6,
          shadowColor: "#000",
          shadowOffset: { width: 0, height: 2 },
          shadowOpacity: 0.2,
          shadowRadius: 4,
          elevation: 2,

          marginHorizontal: 0,
          backgroundColor: "white",
          paddingBottom: 10,
        }}
      >
        <View
          style={{
            flex: 1,
            padding: 10,
          }}
        >
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 15,
            }}
          >
            <i
              className="fa fa-star"
              style={{ fontSize: 18, color: "#058efe" }}
            />{" "}
            School Life
          </Text>
        </View>

        {this.renderItems(this.props)}
      </View>
    );
  }
}

export default withRouter(withI18n("school_life_menu")(SchoolLifeMenu));

const styles = StyleSheet.create({
  row: {
    flex: 1,
    paddingVertical: 4,
    flexDirection: "row",
    justifyContent: "flex-start",
  },
});

const EnhancedTouchableOpacity = withPreventDoubleClick(TouchableOpacity);

const SchoolLifeItem = ({ label, icon, onPress, color, invisible, image }) => (
  <EnhancedTouchableOpacity onPress={onPress}>
    {invisible ? (
      <View
        style={{
          width: (DEVICE_WIDTH * 1.0) / 3.0,
          justifyContent: "center",
          alignItems: "center",
          padding: 7,
        }}
      />
    ) : (
      <View
        style={{
          width: (DEVICE_WIDTH * 1.0) / 3.0,
          paddingHorizontal: 5,
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            borderColor: "#ddd",
            borderWidth: 1,
            borderRadius: 2,
            paddingVertical: 10,
          }}
        >
          {image ? (
            <Image
              style={{
                height: 40,
                width: 40,
                marginBottom: 10,
              }}
              onError={() => {
                this.setState({
                  profileImageUrl: null,
                });
              }}
              source={image}
            />
          ) : (
            <Text>
              <i
                className={"fa fa-" + icon}
                style={{
                  fontSize: 40,
                  color: color ? color : "#058efe",
                  marginBottom: 10,
                }}
              />
            </Text>
          )}
          <Text
            style={{
              textAlign: "center",
              fontWeight: "bold",
              fontStyle: "italic",
              fontSize: 12,
              color: color ? color : "#058efe",
            }}
          >
            {translate(label)}
          </Text>
        </View>
      </View>
    )}
  </EnhancedTouchableOpacity>
);
