import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import localforage from "localforage";
import queryString from "query-string";
import { ApolloConsumer, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import { handleError } from "../libs/errors";

let urlValues;

class LoginWhatsappOfficial extends Component {
  state = {
    phoneNumber: "",
    Student: null,
    session: {},
    loading: false,
    accountSession: null,
  };

  componentDidMount = async () => {
    this.inputWhatsapp.focus();
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    this.setState({
      accountSession,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleInputWhatsapp = (e) => {
    this.setState({
      phoneNumber: e.target.value.replace(/\D/g, ""),
    });
  };

  handleSendOTP = async (e) => {
    if (e.key === "Enter") {
      if (!this.state.phoneNumber) {
        return handleError({
          message: "Nomor Whatsapp tidak boleh kosong!",
        });
      }
      this.setState({
        loading: true,
      });
      try {
        // const response = await this.props.generateOtpCode({
        //   variables: {
        //     phoneNumber: this.state.phoneNumber,
        //   },
        //   fetchPolicy: "no-cache",
        // });
        // addNotification({
        //   title: "SUCCESS",
        //   message: `Kode OTP berhasil dikirim!`,
        //   level: "success",
        // });
        setTimeout(() => {
          this.setState({
            loading: false,
          });
          this.props.history.replace({
            pathname: "/input_otp",
            search: `?phoneNumber=${this.state.phoneNumber}&textWA=Halo%20SCHOOL%20TALK,%20berikan%20saya%20kode%20OTP%20untuk%20nomor%20${this.state.phoneNumber}`,
          });
          // this.props.history.replace({
          //   pathname: "/input_otp",
          //   search: `?phoneNumber=${this.state.phoneNumber}`,
          // });
        }, 2000);
      } catch (e) {
        console.log(e);
        this.setState({
          loading: false,
        });
      }
    }
  };

  handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (!this.state.phoneNumber) {
      return handleError({
        message: "Nomor Whatsapp tidak boleh kosong!",
      });
    }
    this.setState({
      loading: true,
    });
    try {
      //   const response = await this.props.generateOtpCode({
      //     variables: {
      //       phoneNumber: this.state.phoneNumber,
      //     },
      //     fetchPolicy: "no-cache",
      //   });
      //   addNotification({
      //     title: "SUCCESS",
      //     message: `Kode OTP berhasil dikirim!`,
      //     level: "success",
      //   });
      //   setTimeout(() => {
      //     this.setState({
      //       loading: false,
      //     });
      this.props.history.replace({
        pathname: "/input_otp",
        search: `?phoneNumber=${this.state.phoneNumber}&textWA=Halo%20SCHOOL%20TALK,%20berikan%20saya%20kode%20OTP%20untuk%20nomor%20${this.state.phoneNumber}`,
      });
      //   }, 2000);
    } catch (e) {
      console.log(e);
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { Student, Errors, accountSession, loading } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/login",
                  })
                }
              >
                <Text>
                  {/* <i
                    className="fa fa-arrow-left"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "} */}
                  {translate("Kembali")}
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        <div
          className="card"
          style={{ borderRadius: "20px", margin: "auto 15px" }}
        >
          <div className="card-header">
            <Image
              style={{
                width: 140,
                height: 140,
                margin: "auto",
              }}
              source={require("../assets/iphone-hand-touch.png")}
            />
          </div>
          <div className="card-body">
            <p className="text-center">
              Masukkan nomor Whatsapp lalu tekan enter.
            </p>

            <div className="form-group">
              <input
                required
                ref={(input) => {
                  this.inputWhatsapp = input;
                }}
                style={{ textAlign: "center" }}
                type="text"
                className="form-control"
                placeholder="Masukkan nomor Whatsapp..."
                maxLength={15}
                value={this.state.phoneNumber}
                onChange={this.handleInputWhatsapp}
                onKeyDown={this.handleSendOTP}
              />
            </div>

            <button
              className="btn btn-block"
              style={{
                backgroundColor: "#128C7E",
                color: "#fff",
              }}
              onClick={this.handleSubmit}
            >
              <i className="fa fa-check-double" /> Submit
            </button>

            {/* <button
                className="btn btn-block"
                style={{
                  backgroundColor: "#128C7E",
                  color: "#fff",
                }}
                type="submit"
              >
                <i className="fab fa-whatsapp" /> Kirim OTP
              </button> */}
          </div>
        </div>
      </View>
    );
  }
}

const GENERATE_OTP_CODE = gql`
  mutation generateOtpCode($phoneNumber: String!) {
    generateOtpCode(phoneNumber: $phoneNumber)
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation mutation={GENERATE_OTP_CODE}>
            {(generateOtpCode) => (
              <LoginWhatsappOfficial
                {...props}
                generateOtpCode={generateOtpCode}
              />
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  })
);
