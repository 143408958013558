import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import dayjs from "dayjs";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class CBTPage extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    this.setState({
      allStudents: [...students],
    });
  };

  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    urlValues = queryString.stringify(urlValues);
    this.props.history.replace({
      pathname: "/cbt/cbt_page",
      // search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
      search: `${urlValues}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openPage = (page) => (e) => {
    // const url =
    //   this.props.location.search +
    //   `&type=${page}&academicYear=${
    //     urlValues.academicYear ? urlValues.academicYear : defaultAcademicYear()
    //   }`;

    urlValues = {
      ...urlValues,
      type: page,
      academicYear: urlValues.academicYear
        ? urlValues.academicYear
        : defaultAcademicYear(),
      semester: defaultSemester(),
    };

    const res = queryString.stringify(urlValues);
    this.props.history.replace({
      pathname: "/cbt/cbt_subject_list",
      search: `?${res}`,
    });
  };

  render() {
    const { allStudents } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-desktop"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"CBT"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                            <b>
                              {getStudent[0].status ? getStudent[0].status : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            flex: 1,
            marginTop: 190,
          }}
        >
          <View
            style={{
              flex: 1,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <section
              className="card"
              onClick={this.openPage("start")}
              style={{
                borderRadius: 10,
                marginBottom: 10,
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    padding: 10,
                  }}
                >
                  <View>
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../../assets/macbook-hand.png")}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingLeft: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Mulai CBT
                    </Text>
                  </View>
                </View>
              </div>
            </section>

            <section
              className="card"
              onClick={this.openPage("history")}
              style={{
                borderRadius: 10,
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    padding: 10,
                  }}
                >
                  <View>
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../../assets/student-reading-male-caucasian.png")}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingLeft: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Riwayat CBT
                    </Text>
                  </View>
                </View>
              </div>
            </section>
          </View>
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
      status
    }
  }
`;

export default withRouter(CBTPage);

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
