import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { withI18n, translate } from "../libs/withI18n";
import dayjs from "dayjs";
import { LoadingOverlay } from "../components/Overlay";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const KriteriaModal = ({ kriteria, handleInputKriteria, applyFilter }) => (
  <View>
    <Text
      style={{
        marginBottom: 10,
      }}
    >
      Tahun Ajaran: <b>{kriteria.academicYear}</b>
    </Text>
    <Text style={{ marginBottom: 10 }}>
      Kelas: <b>{kriteria.classroomName}</b>
    </Text>
    <View
      style={{
        marginBottom: 10,
      }}
    >
      <Text>Semester</Text>
      <View>
        <select
          className="form-control form-control-sm"
          value={kriteria.semester}
          onChange={handleInputKriteria("semester")}
        >
          <option value="Semua Semester">Semua Semester</option>
          <option value="GANJIL">GANJIL</option>
          <option value="GENAP">GENAP</option>
        </select>
      </View>
    </View>
    <View>
      <Text>Jenis Rapor</Text>
      <View>
        <select
          className="form-control form-control-sm"
          value={kriteria.type}
          onChange={handleInputKriteria("type")}
        >
          <option value="ACADEMIC">Akademik</option>
          <option value="EXTRA">Ekstrakurikuler</option>
        </select>
      </View>
    </View>
    <View>
      <button
        onClick={applyFilter}
        style={{
          backgroundColor: "#689f38",
          marginTop: 10,
          borderWidth: 0,
          borderRadius: 20,
          boxShadow: "0px -2px 5px 2px #ddd",
        }}
        className="btn btn-sm btn-success btn-block"
      >
        <Text
          style={{
            color: "white",
          }}
        >
          <i className="fa fa-filter" /> {"Terapkan Filter"}
        </Text>
      </button>
    </View>
  </View>
);

class StudentReports extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    isLoading: true,

    kriteria: {
      academicYear: "",
      semester: "",
      type: "",
      classroomName: "",
    },
    filterKriteriaModalVisible: false,
    reports: [],
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });

    if (urlValues.studentId) {
      urlValues = queryString.parse(this.props.location.search);
      const url = queryString.stringify(urlValues);
      this.props.history.replace({
        pathname: "/student_reports",
        search: `?${url}`,
      });

      const results = await mwvClient.query({
        query: REPORT_QUERIES,
        variables: {
          studentId: urlValues.studentId,
          academicYear: urlValues.academicYear,
          semester: urlValues.semester ? urlValues.semester : "Semua Semester",
        },
        // fetchPolicy: "no-cache",
      });
      this.setState({
        reports: results.data.allAcademicAndExtracurricularReports,
        // reports:
        //   urlValues.type === "ACADEMIC"
        //     ? results.data.studentAcademicReports
        //     : results.data.studentExtracurricularReports,
      });
    }

    let semester;
    if (new Date().getMonth() < 6) {
      semester = "GENAP";
    } else {
      semester = "GANJIL";
    }
    let terms = [];
    terms = await mwvClient.query({
      query: ACADEMIC_YEARS,
      variables: {
        PREFIX: students[0] ? students[0].PREFIX : "",
      },
    });

    const foundTerm = terms.data.allAcademicYears.find((y) => y.isMonthly);
    if (foundTerm) {
      semester = "";
    }

    this.setState({
      allAcademicYears: terms.data.allAcademicYears,
      academicYear: urlValues.academicYear,
      semester,
      allStudents: [...students],
      isLoading: false,
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };
    this.closeFilterModalVisible();
    this.props.history.replace({
      pathname: "/student_reports",
      search: `?${queryString.stringify(urlValues)}`,
    });
    this.initiate();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  selectClassroom = () => {
    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
    };

    const url = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/report_classroom_list",
      search: `?${url}`,
    });
  };

  handleInputKriteria = (key) => (e) => {
    this.setState({
      kriteria: {
        ...this.state.kriteria,
        [key]: e.target.value,
      },
    });
  };

  openFilterKriteria = () => {
    urlValues = queryString.parse(this.props.location.search);
    this.setState({
      filterKriteriaModalVisible: true,
      kriteria: {
        classroomName: urlValues.classroomName,
        academicYear: urlValues.academicYear,
        semester: urlValues.semester ? urlValues.semester : "Semua Semester",
        type: urlValues.type ? urlValues.type : "ACADEMIC",
      },
    });
  };
  closeFilterKriteria = () => {
    this.setState({
      filterKriteriaModalVisible: false,
    });
  };
  applyFilter = (e) => {
    const { kriteria } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
      ...kriteria,
    };
    const url = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/student_reports",
      search: `?${url}`,
    });

    this.setState({
      filterKriteriaModalVisible: false,
      isLoading: true,
    });
    this.initiate();
  };

  handleCriteria = (key) => (e) => {
    if (key === "academicYear") {
      this.setState({
        academicYear: e.target.value,
      });
      urlValues = {
        ...urlValues,
        academicYear: e.target.value,
      };
    } else {
      this.setState({
        semester: e.target.value,
      });

      urlValues = {
        ...urlValues,
        semester: e.target.value,
      };
    }

    this.props.history.replace({
      pathname: "/student_reports",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&classroomId=${urlValues.classroomId}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
    });
    this.initiate();
  };

  render() {
    const {
      allStudents,
      allAcademicYears,
      isLoading,
      filterKriteriaModalVisible,
      kriteria,
      reports,
    } = this.state;
    // console.log(reports);
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];
    const foundTerm = "";

    return (
      <View style={{ flex: 1 }}>
        <LoadingOverlay visible={isLoading} />
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-filter" /> Filter
            </span>
          }
          visible={filterKriteriaModalVisible}
          size="md"
          onClose={this.closeFilterKriteria}
        >
          <KriteriaModal
            kriteria={kriteria}
            handleInputKriteria={this.handleInputKriteria}
            applyFilter={this.applyFilter}
          />
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-pdf"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Rapor"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-2">
                  <Image
                    style={{ width: 65, height: 65 }}
                    source={require("../assets/owl-laptop-study.png")}
                  />
                </div>
                <div className="col-10" style={{ paddingLeft: 30 }}>
                  <div className="float-left text-left">
                    {getStudent.length !== 0 ? (
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "200px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {getStudent[0].name}
                        <br />
                        <b>
                          {getStudent[0].SchoolInformation
                            ? getStudent[0].SchoolInformation.name
                            : ""}
                        </b>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={foundTerm ? "col-12" : "col-8"}>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("academicYear")}
                      value={this.state.academicYear}
                    >
                      <option value="" disabled>
                        {foundTerm
                          ? translate("Pilih Term")
                          : translate("Pilih Tahun Ajaran")}
                      </option>
                      {allAcademicYears &&
                        allAcademicYears.map((ta) => (
                          <option
                            value={ta.label}
                            key={ta._id}
                            selected={ta.label === urlValues.academicYear}
                          >
                            {foundTerm ? "Term" : "Tahun Ajaran"} {ta.label}{" "}
                            {ta.inProgress ? "(On Going)" : ""}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {!foundTerm ? (
                  <div className="col-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        onChange={this.handleCriteria("semester")}
                        value={urlValues.semester}
                      >
                        <option
                          value="GANJIL"
                          selected={new Date().getMonth() + 1 > 6}
                        >
                          GANJIL
                        </option>
                        <option value="GENAP">GENAP</option>
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>

              <button
                onClick={this.openFilterModal}
                style={{
                  // zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  // height: 50,
                  borderWidth: 0,
                  borderRadius: 20,
                  boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-sm btn-primary btn-block"
              >
                <Text
                  style={{
                    color: "white",
                    // fontSize: 20,
                  }}
                >
                  <i className="fa fa-search" /> {translate("Pilih Siswa")}
                </Text>
              </button>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            flex: 1,
            marginTop: "35vh",
            // flexDirection: "column",
          }}
        >
          <View
            style={{
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-star" /> {"Daftar Rapor"}
            </Text>
          </View>

          {isLoading ? (
            <View
              style={{
                // flex: 1,
                // justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Image
                style={{
                  width: 240,
                  height: 240,
                  resizeMode: "contain",
                  opacity: 0.8,
                }}
                source={require("../assets/05- education-reward.png")}
              />
              <Text
                style={{
                  fontSize: 17,
                  color: "#737373",
                  textAlign: "center",
                  paddingHorizontal: 30,
                  marginBottom: 30,
                }}
              >
                <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
              </Text>
            </View>
          ) : (
            <View
              style={{
                marginTop: 30,
              }}
            >
              {reports.length === 0 ? (
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      marginBottom: 10,
                    }}
                  >
                    Rapor Tidak Ditemukan
                  </Text>
                </View>
              ) : (
                <View>
                  {reports.map((rep) => (
                    <section
                      className="card"
                      style={{
                        padding: 10,
                        marginBottom: 10,
                      }}
                      key={rep._id}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "column",
                        }}
                      >
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{ width: 65, height: 65 }}
                            source={require("../assets/avatar-sign-orange.png")}
                          />

                          <View
                            style={{
                              flex: 1,
                              // flexDirection: "column",
                              marginLeft: 10,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                // marginBottom: 10,
                              }}
                              numberOfLines={1}
                              ellipsizeMode="tail"
                            >
                              {rep.BroadcastMessage.authorName}
                            </Text>
                            <Text
                              style={{
                                marginBottom: 10,
                              }}
                            >
                              Mempublish rapor baru
                            </Text>
                            <Text
                              style={{
                                marginBottom: 20,
                              }}
                            >
                              {dayjs(rep._updatedAt).format(
                                "dddd, DD MMMM YYYY HH:mm"
                              )}
                              {/* {dayjs(rep.BroadcastMessage._createdAt).format(
                                "dddd, DD MMMM YYYY HH:mm"
                              )} */}
                            </Text>
                          </View>
                        </View>

                        <View
                          style={{
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 50,
                              color: "#00a8ff",
                            }}
                          >
                            <i className="fa fa-bullhorn" />
                          </Text>

                          <View
                            style={{
                              flex: 1,
                              flexDirection: "column",
                              marginLeft: 20,
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 18,
                                fontWeight: "bold",
                                marginBottom: 10,
                              }}
                              numberOfLines={1}
                              ellipsizeMode="tail"
                            >
                              {rep.BroadcastMessage.title}
                            </Text>
                            <Text
                              style={{
                                marginBottom: 10,
                              }}
                            >
                              {rep.Student.name}
                            </Text>
                            <a href={rep.pdfUrl}>
                              <Text
                                style={{
                                  marginBottom: 20,
                                  color: "red",
                                }}
                              >
                                <i className="fa fa-file-pdf" /> Klik disini
                                untuk melihat dokumen
                              </Text>
                            </a>
                          </View>
                        </View>
                      </View>
                    </section>
                  ))}
                </View>
              )}
            </View>
          )}
        </View>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const ACADEMIC_YEARS = gql`
  query listAcademicYear($PREFIX: String) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      isMonthly
      inProgress
    }
  }
`;

const REPORT_QUERIES = gql`
  query listQueries(
    $studentId: String!
    $academicYear: String!
    $semester: String!
  ) {
    allAcademicAndExtracurricularReports(
      studentId: $studentId
      academicYear: $academicYear
      semester: $semester
    ) {
      _id
      reportType
      pdfUrl
      academicYear
      semester

      Student {
        _id
        name
      }
      _createdAt
      _updatedAt

      BroadcastMessage {
        _id
        title
        content
        attachedAudios
        attachedDocuments
        attachedImages
        targetTopics
        authorId
        authorName
        _createdAt
        _updatedAt
      }
    }

    studentExtracurricularReports(
      studentId: $studentId
      academicYear: $academicYear
      semester: $semester
    ) {
      _id
      reportType
      pdfUrl
      academicYear
      semester
      _createdAt
      _updatedAt

      Student {
        _id
        name
      }

      BroadcastMessage {
        _id
        title
        content
        attachedAudios
        attachedDocuments
        attachedImages
        targetTopics
        authorId
        authorName
        _createdAt
        _updatedAt
      }
    }
  }
`;

export default withRouter(withI18n("student_reports")(StudentReports));
