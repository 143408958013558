/**
 * REQUIRED:
 * @param sessionId => id sesi yang didapatkan setelah login
 *
 * OPTIONAL:
 * @param filter => digunakan untuk mengecek apakah menggunakan filter atau tidak
 * @param filterType => digunakan untuk memilih tipe  filter
 * @param fromDate => digunakan untuk memilih filter Start Date
 * @param toDate => digunakan untuk memilih filter End Date
 */

import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
import localforage from "localforage";
import { withRouter } from "react-router";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import lodash from "lodash";
import HeaderBar from "../../components/HeaderBar";
import { LoadingOverlay } from "../../components/Overlay";
import { handleError } from "../../libs/errors";
import { withI18n, translate } from "../../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
// import momentId from 'moment/locale/id';
// moment.locale('id', momentId);

let urlValues;

class BorrowAs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      session: {},
      employees: [],
      loading: false,
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
      startDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
      endDateTime: dayjs().add(7, "day").format("YYYY-MM-DD"),
      isCheckedMonth: true,
      isCheckedDate: false,

      filterFormVisible: false,
    };
  }

  componentDidMount = async () => {
    const getEmployees =
      this.props && this.props.allEmployees ? this.props.allEmployees : [];
    let studentIds = this.props.allStudents
      ? this.props.allStudents.map((s) => {
        return {
          _id: s._id,
          PREFIX: s.PREFIX,
        };
      })
      : [];

    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  resize() {
    if (this.divElement) {
      const height = this.divElement.clientHeight;
      this.setState({
        height,
      });
    }
  }

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { loading } = this.state;
    const { currentUser, allEmployees, allStudents, } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
             .card {
               box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
               border: true;
             }
           `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={<Text>Pinjam Sebagai</Text>}
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />

        <div
          className="container-fluid"
          style={{ position: "relative", marginTop: 65, bottom: "30px" }}
        >
          <div className="row">
            {allStudents.length > 0 && (
              <div className="col mb-4">
                <div onClick={() => {
                  this.props.history.push({
                    pathname: `/library/list_book`,
                    search: `?sessionId=${urlValues.sessionId}&studentId=${allStudents[0]._id}&for=STUDENT`,
                  });
                }} style={{
                  backgroundColor: "#A7E5A3",
                  borderRadius: 32,
                  display: "flex",
                  alignItems: "center",
                  padding: "0.75rem 2rem"
                }}>
                  <Image
                    style={{ width: 100, height: 100 }}
                    source={require("../../assets/highschool-student-male-caucasian.png")}
                  />
                  <h1 className="text-center text-white mb-0" style={{ flex: 1 }}>
                    Siswa
                  </h1>
                </div>
              </div>
            )}

            {allEmployees.length > 0 && (<div className="col">
              <div onClick={() => {
                this.props.history.push({
                  pathname: `/library/list_book_employee`,
                  search: `?sessionId=${urlValues.sessionId}&employeeId=${allEmployees[0]._id}&for=EMPLOYEE`,
                });
              }} style={{
                backgroundColor: "#87C3FF",
                borderRadius: 32,
                display: "flex",
                alignItems: "center",
                padding: "0.75rem 2rem"
              }}>
                <Image
                  style={{ width: 100, height: 100 }}
                  source={require("../../assets/employee-office.png")}
                />
                <h1 className="text-center text-white mb-0" style={{ flex: 1 }}>
                  Pegawai
                </h1>
              </div>
            </div>)}
          </div>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ALL_QUERIES = gql`
   query allQueries($sessionId: String!) {
     currentUserBySessionId(sessionId: $sessionId) {
      _id
      name
      email
      phone
      studentIds
      parentIds
      employeeIds
    }
    allStudentBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
        PREFIX
      }
      PREFIX
      photoUrl
    }
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
   }
 `;

export default withRouter(
  withI18n("agenda")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <Query
        query={ALL_QUERIES}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <BorrowAs
              {...props}
              error={error}
              loading={loading}
              allEmployees={
                data && data.allEmployeesBySessionId
                  ? data.allEmployeesBySessionId
                  : []
              }
              allStudents={lodash.get(data, 'allStudentBySessionId', [])}
              currentUser={data && data.currentUserBySessionId ? data.currentUserBySessionId : {}}
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    )
  })
);