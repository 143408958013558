import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  StatusBar,
  TouchableOpacity,
  Picker,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
// import NavigationWithFlex from "../components/NavigationWithFlex";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ProfileImage from "../components/ProfileImage";
import ListSection from "../components/ListSection";
// import TouchableSectionItem from "../components/TouchableSectionItem";
import TextInput from "../components/TextInput";
import BlockButton from "../components/BlockButton";
import { LoadingOverlay } from "../components/Overlay";
// import {
//     faUser,
//     faLock,
//     faLifeRing,
//     faInfoCircle,
//     faSignOutAlt
// } from "@fortawesome/free-solid-svg-icons";
import { ApolloConsumer, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
import { addNotification } from "../App";

import encryptedLS from "../libs/encryptedLS";

class MyProfile extends Component {
  state = {
    userId: "",
    name: "",
    email: "",
    phone: "",
    loading: false,
    session: {},
    deviceInfo: {},

    lang: "",
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await this.props.client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          userId: Account._id,
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    const lang = encryptedLS.get("______lang") || "en";

    console.log({ lang });
    this.setState({
      lang,
    });

    this.refetchData();
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
      });
      this.props.history.replace({
        pathname: "/my_profile",
        search: `?sessionId=${accountSession._id}`,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };
  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  handleSubmit = async () => {
    this.setState({
      loading: true,
    });
    try {
      let { name, email, phone, userId, lang } = this.state;
      if (phone === "-1") {
        phone = "";
      }
      await this.props.updateProfile({
        variables: {
          name,
          email,
          phone,
        },
      });

      await this.props.updatePrefferedLanguageByUserId({
        variables: {
          userId,
          language: !lang || lang === "" ? "id" : lang,
        },
      });

      encryptedLS.set("______lang", lang);
      setTimeout(() => {
        window.location.reload();
      }, 500);

      await this.refetchData();
      addNotification({
        title: "SUCCESS",
        message: "Profil berhasil diperbarui",
        type: "success",
      });
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  selectLanguage = (lang) => async (e) => {
    // console.log({ lang });
    this.setState({
      lang,
    });
  };

  render() {
    const { name, email, phone, loading } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-user" style={{ fontSize: 20 }} /> &nbsp; My
              Profile
            </Text>
          }
          right={
            <TouchableOpacity
              onPress={() =>
                this.props.history.push({
                  pathname: "/account",
                })
              }
            >
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                Close
              </Text>
            </TouchableOpacity>
          }
        />
        <ScrollView keyboardShouldPersistTaps="handled">
          <ListSection>
            <TextInput
              editable={true}
              label="Name"
              value={name}
              placeholder="Full Name"
              onChangeText={this.handleInput("name")}
              autoFocus={true}
              autoCorrect={false}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
            />
            <TextInput
              editable={true}
              label="Email"
              value={email}
              placeholder="Email"
              onChangeText={this.handleInput("email")}
              autoFocus={true}
              autoCorrect={false}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
            />
            <TextInput
              editable={true}
              label="Phone Number"
              value={phone}
              placeholder="Mobile Phone Number"
              onChangeText={this.handleInput("phone")}
              autoFocus={true}
              keyboardType="phone-pad"
              autoCorrect={false}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
            />

            <View style={{ marginLeft: 10, marginBottom: 20 }}>
              <Text style={{ fontSize: 14 }}>Language</Text>

              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text></Text>
                <Text
                  onPress={this.selectLanguage("id")}
                  style={{
                    fontSize: 18,
                    fontWeight: this.state.lang === "id" ? "bold" : "",
                    color: this.state.lang === "id" ? "#0288d1" : "",
                  }}
                >
                  &middot; Bahasa
                </Text>
                <Text
                  onPress={this.selectLanguage("en")}
                  style={{
                    fontSize: 18,
                    fontWeight: this.state.lang === "en" ? "bold" : "",
                    color: this.state.lang === "en" ? "#0288d1" : "",
                  }}
                >
                  &middot; English
                </Text>
                <Text></Text>
              </View>
            </View>
          </ListSection>

          <BlockButton
            icon="md-checkmark-circle"
            label="Save Profile"
            onPress={this.handleSubmit}
          />
        </ScrollView>
      </View>
    );
  }
}

const UPDATE_PROFILE = gql`
  mutation updateProfile($name: String!, $email: String!, $phone: String!) {
    updateProfile(name: $name, email: $email, phone: $phone)
  }
`;
const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;

const UPDATE_LANGUAGE = gql`
  mutation updatePrefferedLanguageByUserId(
    $userId: String!
    $language: String!
  ) {
    updatePrefferedLanguageByUserId(userId: $userId, language: $language)
  }
`;
export default withRouter((props) => (
  <ApolloConsumer>
    {(client) => (
      <Mutation mutation={UPDATE_LANGUAGE}>
        {(updatePrefferedLanguageByUserId) => (
          <Mutation mutation={UPDATE_PROFILE}>
            {(updateProfile) => (
              <MyProfile
                {...props}
                client={client}
                updateProfile={updateProfile}
                updatePrefferedLanguageByUserId={
                  updatePrefferedLanguageByUserId
                }
              />
            )}
          </Mutation>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
));
