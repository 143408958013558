import React, { Component } from "react";
import { View, StatusBar, Text, TouchableOpacity } from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

let urlValues;
class AllHelpItems extends Component {
  state = {
    // Adhe
    // username: __DEV__ ? "083854430431" : "",
    // password: __DEV__ ? "123123" : "",

    // Rama
    // username: __DEV__ ? "089624809625" : "",
    // password: __DEV__ ? "123123" : "",

    username: "083831114477",
    password: "3335557",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {}
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession)

    urlValues = queryString.parse(this.props.location.search);

    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name
      });
      this.props.history.push({
        pathname: "/all_help_items",
        search: `?sessionId=${accountSession._id}&categoryId=${urlValues.categoryId}&categoryName=${urlValues.categoryName}`
      });
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  };

  openPage = item => e => {
    this.props.history.push({
      pathname: "/help_item_descriptions",
      search: `?sessionId=${this.state.session._id}&categoryId=${urlValues.categoryId}&categoryName=${urlValues.categoryName}&itemId=${item._id}`
    });
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { allHelpItemByCategoryId } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-question-circle"
                  style={{
                    fontSize: 20
                  }}
                />{" "}
                Help
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 80,
            marginTop: 70
          }}
        >
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-4">
              <div className="fa-pull-left">
                <TouchableOpacity
                  onPress={() =>
                    this.props.history.push({
                      pathname: "/help",
                      search: `?sessionId=${
                        urlValues && urlValues.sessionId
                          ? urlValues.sessionId
                          : ""
                      }`
                    })
                  }
                >
                  <Text style={{ color: "#00a8ff" }}>
                    <i
                      className="fa fa-arrow-left"
                      style={{
                        fontSize: 20
                      }}
                    />{" "}
                    Kembali
                  </Text>
                </TouchableOpacity>
              </div>
            </div>
            <div className="col-8">
              <div className="fa-pull-right">
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  {urlValues && urlValues.categoryName
                    ? urlValues.categoryName
                    : ""}
                </Text>
              </div>
            </div>
          </div>
          {allHelpItemByCategoryId
            ? allHelpItemByCategoryId.map(item => {
                return (
                  <TouchableOpacity onPress={this.openPage(item)}>
                    <section
                      className="card"
                      style={{
                        padding: 20,
                        margin: "3px 0",
                        borderColor: item.Category.iconColor,
                        color: item.Category.iconColor
                      }}
                    >
                      <div className="card-block">
                        <div className="fa-pull-left">
                          <i
                            className={"fa fa-" + item.iconKey}
                            style={{
                              fontSize: 20
                            }}
                          />{" "}
                          {item.topic}
                        </div>
                        <div className="fa-pull-right">
                          <i className="fa fa-caret-right" />
                        </div>
                        <div className="clearfix" />
                      </div>
                    </section>
                  </TouchableOpacity>
                );
              })
            : null}
          <br />
          <section className="card" style={{ padding: 20 }}>
            <div className="card-block">
              <p>
                <b>Hubungi Kami</b>
                <br />
                Jika ada pertanyaan yang belum tercakup, Anda dapat menghubungi
                Kami menggunakan informasi dibawah ini
              </p>
              <p>
                www.softwaresekolah.co.id
                <br />
                Perumahan Pondok Maritim Indah Blok DD 18
                <br />
                Surabaya, Jawa Timur
                <br />
                Indonesia
                <br />
                info@softwaresekolah.co.id
                <br />
                087722663637
              </p>
            </div>
          </section>
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const supportClient = new ApolloClient({
  uri: "https://api.support.softwaresekolah.co.id/graphql"
});

const ALL_HELPITEMS_QUERY = gql`
  query allHelpItemByCategoryId($categoryId: ID!) {
    allHelpItemByCategoryId(categoryId: $categoryId) {
      _id
      keywords
      Category {
        _id
        name
        iconKey
        iconColor
      }
      topic
      content
      author
    }
    helpItemCategoryById(_id: $categoryId) {
      _id
      name
      keywords
      iconKey
      iconColor
    }
  }
`;

export default withRouter(props => (
  <Query
    query={ALL_HELPITEMS_QUERY}
    client={supportClient}
    variables={{
      categoryId: urlValues && urlValues.categoryId ? urlValues.categoryId : ""
    }}
  >
    {({ error, loading, data, refetch }) => (
      <div>
        <AllHelpItems
          {...props}
          error={error}
          loading={loading}
          refetch={refetch}
          allHelpItemByCategoryId={
            data && data.allHelpItemByCategoryId
              ? data.allHelpItemByCategoryId
              : []
          }
        />
      </div>
    )}
  </Query>
));
