import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import dayjs from "dayjs";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const SubjectModal = ({ subjects, handleSubject, type }) => (
  <div>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginBottom: 10,
      }}
    >
      <View style={{ width: 250 }} />
      <View style={{ width: 80 }}>
        {type === "assessment" ? <Text>Jml. Tugas</Text> : null}
      </View>
    </View>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {subjects.map((sub) => (
        <div key={sub._id}>
          <TouchableOpacity onPress={handleSubject(sub, type)}>
            <section
              className="card"
              style={{
                padding: 10,
                backgroundColor:
                  sub.countAssignment === 0 || type === "feed"
                    ? "#fff"
                    : "#ef6c00",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color:
                          sub.countAssignment === 0 || type === "feed"
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      <i className="fa fa-book-open" /> {sub.name}
                    </Text>
                  </View>
                  <View style={{ width: 50, alignItems: "center" }}>
                    {type === "assessment" ? (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: sub.countAssignment === 0 ? "#000" : "#fff",
                        }}
                      >
                        {sub.countAssignment > 99
                          ? "99+"
                          : sub.countAssignment === 0
                          ? "-"
                          : sub.countAssignment}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </div>
      ))}
    </div>
  </div>
);

const ExtracurricularModal = ({ extracurriculars, handleExtra, type }) => (
  <div>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginBottom: 10,
      }}
    >
      <View style={{ width: 250 }} />
      <View style={{ width: 80 }}>
        {type === "assessment" ? <Text>Jml. Tugas</Text> : null}
      </View>
    </View>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {extracurriculars.map((extra) => (
        <div key={extra._id}>
          <TouchableOpacity onPress={handleExtra(extra, type)}>
            <section
              className="card"
              style={{
                padding: 10,
                backgroundColor:
                  extra.countClassworkExtra === 0 || type === "feed"
                    ? "#fff"
                    : "#ef6c00",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color:
                          extra.countClassworkExtra === 0 || type === "feed"
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      <i className="fa fa-book-open" /> {extra.name}
                    </Text>
                  </View>
                  <View style={{ width: 50, alignItems: "center" }}>
                    {type === "assessment" ? (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color:
                            extra.countClassworkExtra === 0 ? "#000" : "#fff",
                        }}
                      >
                        {extra.countClassworkExtra > 99
                          ? "99+"
                          : extra.countClassworkExtra === 0
                          ? "-"
                          : extra.countClassworkExtra}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </div>
      ))}
    </div>
  </div>
);

class ExtraBoardMessages extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    filterModalStudentVisible: false,
    boardMessagesList: [],

    subjectList: [],
    subjectModalVisible: false,

    extracurricularList: [],
    extracurricularModalVisible: false,
    isLoading: true,

    extraModalType: "",
    subjectModalType: "",
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });
    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/daftar_feed_ekstra",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&classroomId=${urlValues.classroomId}&academicYear=${urlValues.academicYear}&extracurricularId=${urlValues.extracurricularId}`,
      });

      const res = await mwvClient.query({
        query: BOARD_MESSAGES,
        variables: {
          classroomId: urlValues.classroomId,
          studentId: urlValues.studentId,
          academicYear: urlValues.academicYear,
          extracurricularId: urlValues.extracurricularId,
        },
      });

      this.setState({
        boardMessagesList: res.data.boardMessageByExtracurricularId,
        subjectList: res.data.classroomSubject,
        extracurricularList: res.data.countClassworkExtracurricular,
      });
    }
    // else {
    //   this.props.history.replace({
    //     pathname: "/classroom_list",
    //     search: `?sessionId=${accountSession._id}&studentId=${
    //       students[0] ? students[0]._id : ""
    //     }&PREFIX=${students[0] ? students[0].PREFIX : ""}`,
    //   });
    // }
    this.setState({
      allStudents: [...students],
      isLoading: false,
    });
  };

  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  openFilterStudentModal = () => {
    this.setState({
      filterModalStudentVisible: true,
      filterModalVisible: false,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/classroom_list",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
      filterModalStudentVisible: false,
    });
  };

  closeFilterStudentModalVisible = () => {
    this.setState({
      filterModalVisible: true,
      filterModalStudentVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleSubject = (subject, type) => (e) => {
    urlValues = queryString.parse(this.props.location.search);

    urlValues = {
      ...urlValues,
      extracurricularId: subject._id,
      subjectName: subject.name,
      semester: defaultSemester(),
    };

    if (type === "assessment") {
      this.props.history.replace({
        pathname: "/daftar_tugas",
        search: `?${queryString.stringify(urlValues)}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/daftar_feed",
        search: `?${queryString.stringify(urlValues)}`,
      });
    }
  };

  openSubject = (type) => (e) => {
    this.setState({
      subjectModalVisible: true,
      filterModalVisible: false,
      subjectModalType: type,
    });
  };

  openExtra = (type) => (e) => {
    this.setState({
      extracurricularModalVisible: true,
      filterModalVisible: false,
      extraModalType: type,
    });
  };

  handleExtra = (extracurricular, type) => (e) => {
    urlValues = {
      ...urlValues,
      extracurricularId: extracurricular._id,
      extracurricularName: extracurricular.name,
      semester: defaultSemester(),
    };
    if (type === "assessment") {
      this.props.history.replace({
        pathname: "/daftar_tugas_ekstra",
        search: `?${queryString.stringify(urlValues)}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/daftar_feed_ekstra",
        search: `?${queryString.stringify(urlValues)}`,
      });
      this.initiate();
      this.setState({
        subjectModalVisible: false,
        extracurricularModalVisible: false,
        filterModalVisible: false,
        isLoading: true,
      });
    }
  };

  downloadFile = (link) => (e) => {
    window.location.href = link;
  };

  render() {
    const {
      allStudents,
      boardMessagesList,
      subjectList,
      subjectModalVisible,
      extracurricularList,
      extracurricularModalVisible,
      isLoading,
      extraModalType,
      subjectModalType,
    } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-filter" /> Filter
            </span>
          }
          visible={this.state.filterModalVisible}
          onClose={this.closeFilterModalVisible}
          size="md"
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginBottom: 50,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#9c27b0",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "200px",
                }}
                onClick={this.openSubject("assessment")}
              >
                <i className="fa fa-book-open" /> Mata Pelajaran
              </button>
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#9c27b0",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "100px",
                }}
                onClick={this.openSubject("feed")}
              >
                <i className="fa fa-book-open" /> Materi
              </button>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#43a047",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "200px",
                }}
                onClick={this.openExtra("assessment")}
              >
                <i className="fa fa-basketball-ball" /> Ekstrakurikuler
              </button>
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#43a047",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "100px",
                }}
                onClick={this.openExtra("feed")}
              >
                <i className="fa fa-basketball-ball" /> Materi
              </button>
            </View>
          </View>
          <button
            className="btn btn-block"
            type="button"
            onClick={this.openFilterStudentModal}
            style={{
              backgroundColor: "#00a8ff",
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa Lain"}
            </Text>
          </button>
        </FormModal>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalStudentVisible}
          size="md"
          onClose={this.closeFilterStudentModalVisible}
          // hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-book-open" />{" "}
              {subjectModalType === "assessment"
                ? "Mata Pelajaran"
                : "Feed/Materi"}
            </span>
          }
          visible={subjectModalVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
          onClose={(e) => {
            this.setState({
              subjectModalVisible: false,
              filterModalVisible: true,
            });
          }}
        >
          <SubjectModal
            subjects={subjectList}
            handleSubject={this.handleSubject}
            type={subjectModalType}
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-basketball-ball" />{" "}
              {extraModalType === "assessment"
                ? "Ekstrakurikuler"
                : "Feed/Materi"}
            </span>
          }
          visible={extracurricularModalVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
          onClose={(e) => {
            this.setState({
              extracurricularModalVisible: false,
              filterModalVisible: true,
            });
          }}
        >
          <ExtracurricularModal
            extracurriculars={extracurricularList}
            handleExtra={this.handleExtra}
            type={extraModalType}
          />
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"School Space"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-sm btn-block"
                style={{
                  zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  // height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  boxShadow: "0px -2px 5px 2px #ddd",
                  // width: "200px",
                }}
                onClick={!isLoading ? this.openFilterModal : null}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                >
                  <i className="fa fa-filter" /> {"Filter"}
                </Text>
              </button>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            // flex: 1,
            marginTop: "35vh",
            // flexDirection: "column",
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 25,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-bookmark" /> Feed / Materi
            </Text>
          </View>
        </View>

        {isLoading ? (
          <View
            style={{
              flex: 1,
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: 240,
                height: 240,
                resizeMode: "contain",
                opacity: 0.8,
              }}
              source={require("../assets/teacher-chalkboard.png")}
            />
            <Text
              style={{
                fontSize: 17,
                color: "#737373",
                textAlign: "center",
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
            >
              <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
            </Text>
          </View>
        ) : (
          <View
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: "300px",
            }}
          >
            {boardMessagesList.length > 0 ? (
              boardMessagesList.map((message) => (
                <section
                  key={message._id}
                  className="card"
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      paddingTop: 15,
                      paddingBottom: 15,
                    }}
                  >
                    <div
                      className="d-flex flex-row"
                      style={{
                        marginBottom: 10,
                      }}
                    >
                      <Image
                        style={{
                          width: 65,
                          height: 65,
                          overflow: "hidden",
                          borderRadius: 50,
                        }}
                        source={
                          message.Teacher && message.Teacher.photoUrl
                            ? message.Teacher && message.Teacher.photoUrl
                            : require("../assets/owl-laptop-study.png")
                        }
                      />
                      <div
                        className="flex-column"
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <div
                          style={{
                            whiteSpace: "nowrap",
                            width: "225px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <p
                            style={{
                              fontSize: 18,
                              marginBottom: 0,
                            }}
                          >
                            Ekstra:{" "}
                            <b>
                              {message.Extracurricular
                                ? message.Extracurricular.name
                                : ""}
                            </b>
                          </p>
                          <p
                            style={{
                              marginBottom: 10,
                            }}
                          >
                            Pembina:{" "}
                            <b>{message.Teacher ? message.Teacher.name : ""}</b>
                          </p>
                          <p
                            style={{
                              marginBottom: 0,
                              color: "#039be5",
                            }}
                          >
                            Tgl:{" "}
                            <b>
                              {dayjs(message._creatdAt).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </b>
                          </p>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        textAlign: "justify",
                        marginBottom: 10,
                      }}
                    >
                      <b>Feed / Materi:</b> {message.title}
                    </div>
                    <div
                      style={{
                        textAlign: "justify",
                        marginBottom: 10,
                      }}
                    >
                      <b>Deskripsi:</b> {message.description}
                    </div>
                  </div>

                  <div
                    className="card-footer"
                    style={{
                      backgroundColor: message.attachmentFileUrl
                        ? "#43a047"
                        : "#bdbdbd",
                      color: "#fff",
                      fontSize: 16,
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      paddingTop: 10,
                      paddingBottom: 10,
                    }}
                    onClick={
                      message.attachmentFileUrl
                        ? this.downloadFile(message.attachmentFileUrl)
                        : null
                    }
                  >
                    {message.attachmentFileUrl ? (
                      <center>
                        <i className="fa fa-file-download" /> Download File
                      </center>
                    ) : (
                      <center>No File Attached</center>
                    )}
                  </div>
                </section>
              ))
            ) : (
              <center>
                <h3>Tidak Feed / Materi</h3>
              </center>
            )}
          </View>
        )}

        {/* <div className="fixed-bottom">
          <button
            type="button"
            onClick={!isLoading ? this.openFilterModal : null}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-filter" /> {"Filter"}
            </Text>
          </button>
        </div>
       */}
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const BOARD_MESSAGES = gql`
  query boardMessagesQuery(
    $classroomId: String!
    $studentId: String!
    $academicYear: String!
    $extracurricularId: String!
  ) {
    boardMessageByExtracurricularId(
      extracurricularId: $extracurricularId
      academicYear: $academicYear
    ) {
      _id
      Teacher {
        _id
        name
      }
      Extracurricular {
        _id
        name
      }
      title
      description
      attachmentFileUrl
      _createdAt
    }

    classroomSubject(classroomId: $classroomId, studentId: $studentId) {
      _id
      name
      countAssignment
    }

    countClassworkExtracurricular(
      studentId: $studentId
      academicYear: $academicYear
    ) {
      _id
      name
      countClassworkExtra
    }
  }
`;

export default withRouter(ExtraBoardMessages);

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
