import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LabelList
} from "recharts";

const RADIAN = Math.PI / 180;
const COLORS_PER_SIX_MONTHS = ["#38ada9", "#00c853"];
const COLORS = ["#e67e22", "#009432", "#686de0", "#1abc9c"];

let urlValues;
class RekapitulasiPresensiSiswa extends Component {
  state = {
    session: {},
    students: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    loading: false,
    filterModalVisible: false
  };

  //   refetchData = async () => {
  //     this.setState({
  //       loading: true
  //     });
  //     try {
  //       let result = await this.props.client.query({
  //         query: CURRENT_USER,
  //         fetchPolicy: "no-cache"
  //       });
  //       let Account = result.data.currentUser;
  //       if (Account) {
  //         this.setState({
  //           name: Account.name,
  //           email: Account.email,
  //           phone: Account.phone
  //         });
  //       }
  //     } catch (err) {
  //       handleError(err);
  //     }
  //     this.setState({
  //       loading: false
  //     });
  //   };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: ALL_RECORDS,
      variables: {
        sessionId: accountSession._id
      }
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/rekapitulasi_presensi_siswa",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}`
      });
    } else {
      this.props.history.replace({
        pathname: "/rekapitulasi_presensi_siswa",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }`
      });
    }
  };
  refresh = () => {
    this.props.history.replace({
      pathname: "/explore"
    });
  };

  renderCustomizedLabel = props => {
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      percent,
      index,
      name
    } = props;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    // console.log({ props });

    if (percent * 100 !== 0) {
      return (
        <text
          x={x}
          y={y}
          fill="white"
          style={{ fontWeight: "bold", fontSize: 18 }}
          textAnchor={"middle"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    }
  };

  attendaceRecapPerSixMonthPercentage = recap => {
    let results = [];
    if (recap && recap.countAttendances) {
      for (const rec of recap.countAttendances) {
        let totalRecord = rec.countPresence + rec.countLate;

        let percentage;
        if (totalRecord === 0) {
          percentage = 0;
        } else {
          percentage = parseInt(
            ((rec.countPresence / totalRecord) * 100).toFixed(0)
          );
          // console.log(percentage);
        }
        results.push({
          bulan: rec.bulan,
          percentage
        });
      }
      return results;
    } else {
      return results;
    }
  };

  render() {
    const { loading } = this.state;
    const allStudents = this.props.allStudents;
    const student =
      allStudents && allStudents !== 0
        ? allStudents.find(student => student._id === urlValues.studentId)
        : [];

    // console.log(this.props.allAttendanceRecap);
    const attendaceRecap =
      this.props.allAttendanceRecap && this.props.allAttendanceRecap.length > 0
        ? this.props.allAttendanceRecap.find(
            s => s.Student._id === urlValues.studentId
          )
        : {};

    // console.log(attendaceRecap);

    let data = [];
    if (attendaceRecap) {
      for (const att of Object.keys(attendaceRecap)) {
        if (att === "countLate") {
          data.push({
            name: "Terlambat",
            value: attendaceRecap["countLate"]
          });
        } else if (att === "countPresence") {
          data.push({
            name: "Masuk",
            value: attendaceRecap["countPresence"]
          });
        } else if (att === "countPermission") {
          data.push({
            name: "Izin",
            value: attendaceRecap["countPermission"]
          });
        } else if (att === "countSick") {
          data.push({
            name: "Sakit",
            value: attendaceRecap["countSick"]
          });
        }
      }
    }

    let currentMonth = parseInt(dayjs().format("M")) - 1;
    let currentYear = parseInt(dayjs().format("YYYY"));
    if (currentMonth <= 0) {
      currentMonth = 12;
      currentYear = parseInt(currentYear) - 1;
    }

    const attendanceRecapPerSixMonths =
      this.props.allAttendanceRecapPerSixMonths &&
      this.props.allAttendanceRecapPerSixMonths.length > 0
        ? this.props.allAttendanceRecapPerSixMonths.find(
            s => s.Student._id === urlValues.studentId
          )
        : {};

    // console.log({ attendanceRecapPerSixMonths });
    const perSixMonthRecap = this.attendaceRecapPerSixMonthPercentage(
      attendanceRecapPerSixMonths
    );

    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-clock" style={{ fontSize: 20 }} />{" "}
              Rekapitulasi Presensi
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2
                }}
              >
                <i className="fa fa-times" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {student && student.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap"
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{student.name}
                            <br />
                            <b>
                              {student.SchoolInformation
                                ? student.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>
        </div>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <Text style={{ fontSize: 18 }}>
            <i className="fa fa-calendar-check" /> Presensimu Pada{" "}
            {dayjs()
              .month(currentMonth - 1)
              .format("MMMM")}{" "}
            {dayjs()
              .year(currentYear)
              .format("YYYY")}
          </Text>
        </View>
        <View
          style={{
            marginTop: 10,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            padding: 10
          }}
        >
          <View
            style={{
              width: 100,
              height: 100,
              backgroundColor: "#009432",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 36, color: "#fff" }}>
              {attendaceRecap && attendaceRecap.countPresence
                ? attendaceRecap.countPresence
                : "-"}
            </Text>

            <Text style={{ fontWeight: "bold", fontSize: 14, color: "#fff" }}>
              <i className="fa fa-smile-beam" /> Masuk
            </Text>
          </View>
          <View
            style={{
              width: 100,
              height: 100,
              backgroundColor: "#e67e22",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 36, color: "#fff" }}>
              {attendaceRecap && attendaceRecap.countLate
                ? attendaceRecap.countLate
                : "-"}
            </Text>

            <Text style={{ fontWeight: "bold", fontSize: 14, color: "#fff" }}>
              <i className="fa fa-angry" /> Terlambat
            </Text>
          </View>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-around",
            padding: 10
          }}
        >
          <View
            style={{
              width: 100,
              height: 100,
              backgroundColor: "#686de0",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 36, color: "#fff" }}>
              {attendaceRecap && attendaceRecap.countSick
                ? attendaceRecap.countSick
                : "-"}
            </Text>

            <Text style={{ fontWeight: "bold", fontSize: 14, color: "#fff" }}>
              <i className="fa fa-dizzy" /> Sakit
            </Text>
          </View>
          <View
            style={{
              width: 100,
              height: 100,
              backgroundColor: "#1abc9c",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
            <Text style={{ fontWeight: "bold", fontSize: 36, color: "#fff" }}>
              {attendaceRecap && attendaceRecap.countPermission
                ? attendaceRecap.countPermission
                : "-"}
            </Text>

            <Text style={{ fontWeight: "bold", fontSize: 14, color: "#fff" }}>
              <i className="fa fa-info-circle" /> Izin
            </Text>
          </View>
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          <Text style={{ fontSize: 18 }}>
            <i className="fa fa-percentage" /> Presentasi Kehadiran Pada{" "}
            {dayjs()
              .month(currentMonth - 1)
              .format("MMMM")}{" "}
            {dayjs()
              .year(currentYear)
              .format("YYYY")}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          {data.length > 0 ? (
            <ResponsiveContainer height={300} width="100%">
              <PieChart
                // width={400} height={200}
                margin={{ top: 50 }}
              >
                <Legend verticalAlign="bottom" height={30} />
                <Pie
                  data={data}
                  // cx={180}
                  cy={80}
                  labelLine={false}
                  label={this.renderCustomizedLabel}
                  outerRadius={130}
                  fill="#8884d8"
                  dataKey="value"
                  isAnimationActive={false}
                >
                  {data.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <Text style={{ fontSize: 18 }}>
              <i className="fa fa-exclamation-triangle" /> Data Tidak Ditemukan
            </Text>
          )}
        </View>

        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          <Text style={{ fontSize: 18 }}>
            <i className="fa fa-percentage" /> Masuk Tepat Waktu 6 Bulan
            Terakhir
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 10
          }}
        >
          {perSixMonthRecap.length > 0 ? (
            <ResponsiveContainer height={300} width="100%">
              <BarChart
                width={500}
                height={300}
                data={perSixMonthRecap}
                margin={{ top: 15, right: 30, left: 0, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="bulan" />
                <YAxis />
                <Tooltip />
                <Bar
                  dataKey="percentage"
                  label={{ position: "top" }}
                  isAnimationActive={false}
                >
                  <LabelList dataKey="percentage" position="top" />
                  {perSixMonthRecap.map((entry, index) => {
                    return (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          COLORS_PER_SIX_MONTHS[
                            index % COLORS_PER_SIX_MONTHS.length
                          ]
                        }
                      />
                    );
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <Text style={{ fontSize: 18 }}>
              <i className="fa fa-exclamation-triangle" /> Data Tidak Ditemukan
            </Text>
          )}
        </View>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://dev-api.mwv.softwaresekolah.co.id/graphql"
});

const ALL_RECORDS = gql`
  query allStudentAttendanceRecords($sessionId: String!, $studentId: String) {
    studentAttendanceRecap(sessionId: $sessionId, studentId: $studentId) {
      Student {
        _id
        name
      }
      countLate
      countPresence
      countSick
      countPermission
      type
      PREFIX
    }
    studentAttendanceRecapPerSixMonths(
      sessionId: $sessionId
      studentId: $studentId
    ) {
      Student {
        _id
        name
      }
      countAttendances {
        bulan
        countLate
        countPresence
      }
      PREFIX
      type
    }
    allStudentBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;

export default withRouter(props => {
  urlValues = queryString.parse(props.location.search);

  let studentId = urlValues.studentId ? urlValues.studentId : "";
  return (
    <Query
      query={ALL_RECORDS}
      client={mwvClient}
      variables={{
        sessionId: urlValues.sessionId,
        studentId
      }}
    >
      {({ error, loading, data, refetch }) => (
        <div>
          <RekapitulasiPresensiSiswa
            {...props}
            error={error}
            loading={loading}
            allAttendanceRecap={
              data && data.studentAttendanceRecap
                ? data.studentAttendanceRecap
                : []
            }
            allAttendanceRecapPerSixMonths={
              data && data.studentAttendanceRecapPerSixMonths
                ? data.studentAttendanceRecapPerSixMonths
                : []
            }
            allStudents={
              data && data.allStudentBySessionId
                ? data.allStudentBySessionId
                : []
            }
            refetch={refetch}
          />
        </div>
      )}
    </Query>
  );
});
