import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import uuidV4 from "uuid/v4";
import { withI18n, translate } from "../libs/withI18n";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class StudentPerformance extends Component {
  state = {
    allStudents: [],
    allAcademicYears: [],
    filterModalVisible: false,
    semester: "",
    academicYear: "",
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let semester;
    if (new Date().getMonth() < 6) {
      semester = "GENAP";
    } else {
      semester = "GANJIL";
    }

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    let terms = [];
    terms = await mwvClient.query({
      query: ACADEMIC_YEARS,
      variables: {
        PREFIX: students[0] ? students[0].PREFIX : "",
      },
    });

    const foundTerm = terms.data.allAcademicYears.find((y) => y.isMonthly);
    if (foundTerm) {
      semester = "";
    }

    // console.log({ terms });
    let PREFIX = "";

    if (urlValues.PREFIX && urlValues.studentId) {
      PREFIX = urlValues.PREFIX;
    } else {
      PREFIX = students[0] ? students[0].PREFIX : urlValues.PREFIX;
    }

    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/student_performance",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${PREFIX}&academicYear=${urlValues.academicYear}&semester=${semester}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/student_performance",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&classroomId=${
          students[0].Classroom?._id
        }&PREFIX=${PREFIX}&academicYear=${
          urlValues.academicYear
        }&semester=${semester}`,
      });
    }

    this.setState({
      allStudents: [...students],
      allAcademicYears: terms.data.allAcademicYears,
      academicYear: urlValues.academicYear,
      semester,
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    let academicYear;
    if (new Date().getMonth() < 6) {
      academicYear = `${
        new Date().getFullYear() - 1
      }/${new Date().getFullYear()}`;
    } else {
      academicYear = `${new Date().getFullYear()}/${
        new Date().getFullYear() + 1
      }`;
    }

    let semester;
    if (new Date().getMonth() < 6) {
      semester = "GENAP";
    } else {
      semester = "GANJIL";
    }

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/student_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${academicYear}&semester=${semester}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleCriteria = (key) => (e) => {
    if (key === "academicYear") {
      this.setState({
        academicYear: e.target.value,
      });
      urlValues = {
        ...urlValues,
        academicYear: e.target.value,
      };
    } else {
      this.setState({
        semester: e.target.value,
      });

      urlValues = {
        ...urlValues,
        semester: e.target.value,
      };
    }

    this.props.history.replace({
      pathname: "/student_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
    });
  };

  openPage = (page) => (e) => {
    if (page === "academic") {
      this.props.history.replace({
        pathname: "/academic_performance",
        search: this.props.location.search,
      });
    } else if (page === "counseling") {
      this.props.history.replace({
        pathname: "/counseling",
        search: this.props.location.search,
      });
    } else if (page === "cbt") {
      this.props.history.replace({
        pathname: "/cbt/cbt_page",
        search: this.props.location.search,
      });
    } else if (page === "report") {
      this.props.history.replace({
        pathname: "/student_reports",
        search: this.props.location.search,
      });
    } else {
      this.props.history.replace({
        pathname: "/extracurricular_performance",
        search: this.props.location.search,
      });
    }
  };

  generateAcademicYears = () => {
    let listYears = [];
    let initYear = new Date().getFullYear() - 3;
    let counter = 1;
    for (let i = 0; i < 7; i++) {
      let ay = initYear + i + "/" + (initYear + (counter + i));
      listYears.push({
        _id: uuidV4(),
        label: ay,
      });
    }

    return listYears;
  };

  render() {
    const { allStudents, allAcademicYears } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];
    const foundTerm = allAcademicYears.find((y) => y.isMonthly);

    // const TA = this.generateAcademicYears();

    // let academicYearNow;
    // if (new Date().getMonth() < 6) {
    //   academicYearNow = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYearNow = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Performa Siswa")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-2">
                  <Image
                    style={{ width: 65, height: 65 }}
                    source={require("../assets/owl-laptop-study.png")}
                  />
                </div>
                <div className="col-10" style={{ paddingLeft: 30 }}>
                  <div className="float-left text-left">
                    {getStudent.length !== 0 ? (
                      <div
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          width: "200px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {getStudent[0].name}
                        <br />
                        <b>
                          {getStudent[0].SchoolInformation
                            ? getStudent[0].SchoolInformation.name
                            : ""}
                        </b>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className={foundTerm ? "col-12" : "col-8"}>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("academicYear")}
                      value={this.state.academicYear}
                    >
                      <option value="" disabled>
                        {foundTerm
                          ? translate("Pilih Term")
                          : translate("Pilih Tahun Ajaran")}
                      </option>
                      {allAcademicYears.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === urlValues.academicYear}
                        >
                          {foundTerm ? "Term" : "Tahun Ajaran"} {ta.label}{" "}
                          {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {!foundTerm ? (
                  <div className="col-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        onChange={this.handleCriteria("semester")}
                        value={this.state.semester}
                      >
                        <option
                          value="GANJIL"
                          selected={new Date().getMonth() + 1 > 6}
                        >
                          GANJIL
                        </option>
                        <option value="GENAP">GENAP</option>
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>

              <button
                onClick={this.openFilterModal}
                style={{
                  // zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  // height: 50,
                  borderWidth: 0,
                  borderRadius: 20,
                  boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-sm btn-primary btn-block"
              >
                <Text
                  style={{
                    color: "white",
                    // fontSize: 20,
                  }}
                >
                  <i className="fa fa-search" /> {translate("Pilih Siswa")}
                </Text>
              </button>
            </div>
          </section>
        </div>

        <View style={{ flex: 1, marginTop: "40vh" }}>
          <TouchableOpacity
            style={{
              marginBottom: 5,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onClick={this.openPage("academic")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <div className="row">
                  <div className="col-4">
                    <Image
                      style={{ width: 100, height: 100 }}
                      source={require("../assets/contract-book-pencil.png")}
                    />
                  </div>
                  <div className="col-8" style={{ paddingTop: 30 }}>
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      {translate("Nilai Akademik Siswa")}
                    </Text>
                  </div>
                </div>
              </div>
            </section>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginBottom: 20,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onClick={this.openPage("extracurricular")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <div className="row">
                  <div className="col-4">
                    <Image
                      style={{ width: 100, height: 100 }}
                      source={require("../assets/baseball.png")}
                    />
                  </div>
                  <div className="col-8" style={{ paddingTop: 15 }}>
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      {translate("Nilai Ekstrakurikuler Siswa")}
                    </Text>
                  </div>
                </div>
              </div>
            </section>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginBottom: 20,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onClick={this.openPage("cbt")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View>
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../assets/macbook-hand.png")}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingLeft: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Computer Based Test (CBT)
                    </Text>
                  </View>
                </View>
                {/* <div className="row">
                  <div className="col-4">
                    <Image
                      style={{ width: 100, height: 100 }}
                      source={require("../assets/macbook-hand.png")}
                    />
                  </div>
                  <div className="col-8" style={{ paddingTop: 15 }}>
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      {translate("CBT")}
                    </Text>
                  </div>
                </div> */}
              </div>
            </section>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginBottom: 20,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onClick={this.openPage("counseling")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View>
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../assets/03-_law-court-hammer-judge.png")}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingLeft: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      {translate("Bimbingan Konseling Siswa")}
                    </Text>
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginBottom: 20,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onClick={this.openPage("report")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View>
                    <Image
                      style={{
                        width: 100,
                        height: 100,
                      }}
                      source={require("../assets/05- education-reward.png")}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      paddingLeft: 15,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 20,
                      }}
                    >
                      {translate("Rapor")}
                    </Text>
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </View>
        {/* <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </Text>
          </button>
        
        </div> */}
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      Classroom {
        _id
      }
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const ACADEMIC_YEARS = gql`
  query listAcademicYear($PREFIX: String) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      isMonthly
      inProgress
    }
  }
`;

export default withRouter(withI18n("student_performance")(StudentPerformance));
