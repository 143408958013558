import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import dayjs from "dayjs";

class PrimaryExtracurricularScorePost extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const {
        _id,
        Student,
        PrimaryExtracurricularScore,
        _createdAt
      } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "PrimaryExtracurricularScore",
        Student,
        PrimaryExtracurricularScore,
        _createdAt
      });
    }
  };

  render() {
    const { Student, PrimaryExtracurricularScore, _createdAt } = this.props;
    return (
      <View
        style={{
          paddingVertical: 7
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              paddingBottom: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#1696ff",
                  marginTop: 2
                }}
              >
                <i className="fa fa-exclamation-circle" /> Update Nilai Siswa
              </Text>
              <Text style={{ fontSize: 12, color: "#1696ff", marginTop: 2 }}>
                {dayjs(_createdAt).format("dddd, DD MMMM YYYY   HH:mm")}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                  borderColor: "#ddd",
                  borderWidth: 2,
                  borderRadius: 4
                }}
              >
                <View
                  style={{
                    margin: 4,
                    padding: 0,
                    borderRadius: 3,
                    justifyContent: "center"
                  }}
                >
                  <Image
                    source={require("../../assets/teacher-male-asian.png")}
                    style={{
                      width: 100,
                      height: 120
                    }}
                    resizeMode="cover"
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 4,
                    marginBottom: 8,
                    marginLeft: 6
                  }}
                >
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 17,
                      fontWeight: "bold"
                    }}
                  >
                    Nilai {PrimaryExtracurricularScore.extracurricularName}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 6,
                      fontSize: 13
                    }}
                  >
                    Semester {PrimaryExtracurricularScore.semester}{" "}
                    {PrimaryExtracurricularScore.academicYear}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 4,
                      fontSize: 15,
                      fontWeight: "bold"
                    }}
                  >
                    <i className="fa fa-user-graduate" /> {Student.name}
                  </Text>
                  {PrimaryExtracurricularScore.type === "DESCRIPTIVE" ? (
                    <View
                      style={{
                        fontSize: 24,
                        marginLeft: 8,
                        marginVertical: 6,
                        paddingVertical: 3,
                        paddingHorizontal: 8,
                        borderWidth: 2,
                        borderColor: "#ff9f43",
                        borderRadius: 4,
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Text>
                        <i className="fa fa-info-circle" /> Nilai:{" "}
                        {PrimaryExtracurricularScore.descriptiveScore}
                      </Text>
                    </View>
                  ) : (
                    <View
                      style={{
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                    >
                      <Text>
                        <i className="fa fa-info-circle" /> Nilai:
                      </Text>
                      <Text
                        style={{
                          fontSize: 24,
                          marginLeft: 8,
                          marginVertical: 6,
                          paddingVertical: 3,
                          paddingHorizontal: 8,
                          borderWidth: 2,
                          borderColor: "#ff9f43",
                          borderRadius: 4
                        }}
                      >
                        {PrimaryExtracurricularScore.type === "PREDICATE"
                          ? PrimaryExtracurricularScore.predicateScore
                          : PrimaryExtracurricularScore.score}
                      </Text>
                    </View>
                  )}
                  <Text style={{ marginTop: 4, color: "#1696ff" }}>
                    Klik untuk melihat riwayat nilai
                  </Text>
                </View>
                <View
                  style={{
                    marginRight: 1,
                    marginTop: -31,
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <div
                    style={{
                      borderTop: "60px solid #ff9f43",
                      borderBottom: "60px solid transparent",
                      borderLeft: "60px solid transparent"
                    }}
                  >
                    <span
                      style={{
                        position: "absolute",
                        top: 42,
                        width: 60,
                        left: 12,
                        textAlign: "center",
                        transform: "rotate(45deg)",
                        display: "block",
                        color: "white"
                      }}
                    >
                      <b>Nilai</b>
                    </span>
                  </div>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default PrimaryExtracurricularScorePost;
