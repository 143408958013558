import React, { Component } from "react";
import { View, StatusBar, Text, TouchableOpacity } from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

let urlValues;
class HelpItemAnswer extends Component {
  state = {
    username: "083831114477",
    password: "3335557",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {}
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession)

    urlValues = queryString.parse(this.props.location.search);

    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name
      });
      this.props.history.push({
        pathname: "/help_item_descriptions",
        search: `?sessionId=${accountSession._id}&categoryId=${urlValues.categoryId}&categoryName=${urlValues.categoryName}&itemId=${urlValues.itemId}`
      });
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { helpItemById } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-question-circle"
                  style={{
                    fontSize: 20
                  }}
                />{" "}
                Help
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 80,
            marginTop: 70
          }}
        >
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-4">
              <div className="fa-pull-left">
                <TouchableOpacity
                  onPress={() =>
                    this.props.history.push({
                      pathname: "/all_help_items",
                      search: `?sessionId=${
                        urlValues && urlValues.sessionId
                          ? urlValues.sessionId
                          : ""
                      }&categoryId=${urlValues.categoryId}&categoryName=${
                        urlValues.categoryName
                      }`
                    })
                  }
                >
                  <Text style={{ color: "#00a8ff" }}>
                    <i
                      className="fa fa-arrow-left"
                      style={{
                        fontSize: 20
                      }}
                    />{" "}
                    Kembali
                  </Text>
                </TouchableOpacity>
              </div>
            </div>
            <div className="col-8">
              <div className="fa-pull-right">
                <Text style={{ fontSize: 14, fontWeight: "bold" }}>
                  {urlValues && urlValues.categoryName
                    ? urlValues.categoryName
                    : ""}
                </Text>
              </div>
            </div>
          </div>

          <section
            className="card"
            style={{
              padding: 20,
              borderColor: helpItemById.Category
                ? helpItemById.Category.iconColor
                : "black",
              margin: "3px 0",
              color: helpItemById.Category
                ? helpItemById.Category.iconColor
                : "black"
            }}
          >
            <div className="card-block">
              <div>{helpItemById.topic}</div>
            </div>
          </section>
          <section
            className="card"
            style={{
              padding: 20,
              margin: "3px 0",
              color: "black"
            }}
          >
            <div className="card-block">
              <div
                className="ql-editor"
                style={{ padding: "0 5px" }}
                dangerouslySetInnerHTML={{ __html: helpItemById.content }}
              />
            </div>
          </section>
          <br />
          <section className="card" style={{ padding: 20 }}>
            <div className="card-block">
              <p>
                <b>Hubungi Kami</b>
                <br />
                Jika ada pertanyaan yang belum tercakup, Anda dapat menghubungi
                Kami menggunakan informasi dibawah ini
              </p>
              <p>
                www.softwaresekolah.co.id
                <br />
                Perumahan Pondok Maritim Indah Blok DD 18
                <br />
                Surabaya, Jawa Timur
                <br />
                Indonesia
                <br />
                info@softwaresekolah.co.id
                <br />
                087722663637
              </p>
            </div>
          </section>
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const supportClient = new ApolloClient({
  uri: "https://api.support.softwaresekolah.co.id/graphql"
});

const HELPITEMS_ANSWER_QUERY = gql`
  query listQueries($helpItemId: ID!) {
    helpItemById(_id: $helpItemId) {
      _id
      Category {
        _id
        name
        iconColor
        iconKey
      }
      topic
      content
      keywords
    }
  }
`;

export default withRouter(props => (
  <Query
    query={HELPITEMS_ANSWER_QUERY}
    client={supportClient}
    variables={{
      helpItemId: urlValues && urlValues.itemId ? urlValues.itemId : ""
    }}
  >
    {({ error, loading, data, refetch }) => (
      <div>
        {/* {console.log(data)} */}
        <HelpItemAnswer
          {...props}
          error={error}
          loading={loading}
          refetch={refetch}
          helpItemById={data && data.helpItemById ? data.helpItemById : []}
        />
      </div>
    )}
  </Query>
));
