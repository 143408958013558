import React, { Component } from "react";

import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";

class CheckoutBills extends Component {
  render() {
    const { t } = this.props;
    const totalBills = this.props.bills
      .map((c) => c.unpaid)
      .reduce((acc, curr) => acc + curr, 0);
    return (
      <div>
        <div>
          <center>
            <Image
              style={{ width: 200, height: 200 }}
              source={require("../../assets/mobile-smartphone-credit-card-payment.jpg")}
            />
          </center>
          <div className="row">
            <div className="col-6 small">{t("Daftar Tagihan")}</div>
            <div className="col-6 text-right small">{t("Nominal Tagihan")}</div>
          </div>
          {/* <hr style={{ borderWidth: "2px", margin: 0 }} /> */}
          <br />
          {this.props.bills.map((bill) => (
            <div className="mb-3" key={bill._id}>
              <div className="row">
                <div className="col-6">
                  {bill.StudentBillType.name} {bill.academicYear} ({bill.month})
                </div>
                <div className="col-6 text-right">
                  <b>
                    {t("Rp")}:{" "}
                    {Number(bill.unpaid)
                      .toLocaleString("id-ID")
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                  </b>
                </div>
              </div>
              <hr style={{ borderWidth: "2px", margin: 0 }} />
            </div>
          ))}
          <div className="row text-success">
            <div className="col-6 text-right">
              <b>
                <i className="fa fa-info-circle" /> {t("Total Tagihan")}
              </b>
            </div>
            <div className="col-6 text-right">
              <b>
                {t("Rp")}:{" "}
                {Number(totalBills)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CheckoutBills;
