import navigation from "./navigation.json";
import school_life_menu from "./school_life_menu.json";
import student_performance from "./student_performance.json";
import account from "./account.json";
import student_bill_and_payment from "./student_bill_and_payment.json";
import attendance from "./attendance.json";
import agenda from "./agenda.json";
import saving from "./saving.json";
import activity from "./activity.json";
import claim_student from "./claim_student.json";
import list_students from "./list_students.json";

export default {
  navigation,
  school_life_menu,
  student_performance,
  account,
  student_bill_and_payment,
  attendance,
  agenda,
  saving,
  activity,
  claim_student,
  list_students
};
