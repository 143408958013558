import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;
const FilterModal = ({
  employees,
  handleSelectEmployee,
  selectedEmployeeId,
  employeeId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {employees.map((employee) => (
        <div key={employee._id}>
          <section
            className="card"
            onClick={handleSelectEmployee(employee)}
            style={{
              borderWidth: "3px",
              borderColor: employee._id === employeeId ? "#3498db" : "#bdc3c7",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {employee.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  ) : (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginTop: "0.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {employee.name}
                    </b>
                    <br />
                    {employee.SchoolInformation
                      ? employee.SchoolInformation.name
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class PresensiPegawai extends Component {
  state = {
    session: {},
    employees: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    loading: false,
    filterModalVisible: false,

    scheduleBreak: {
      lists: [],
      visible: false,
      date: "",
      selectedScheduleId: "",
    },
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await this.props.client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: ALL_RECORDS,
      variables: {
        sessionId: accountSession._id,
      },
    });

    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];
    if (urlValues.employeeId) {
      this.props.history.replace({
        pathname: "/presensi_pegawai",
        search: `?sessionId=${accountSession._id}&employeeId=${
          urlValues.employeeId
        }&month=${dayjs().month() + 1}&year=${dayjs().year()}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/presensi_pegawai",
        search: `?sessionId=${accountSession._id}&employeeId=${
          employees[0] ? employees[0]._id : ""
        }&month=${dayjs().month() + 1}&year=${dayjs().year()}`,
      });
    }
  };

  handleSelectEmployee = (employee) => async (e) => {
    this.props.history.replace({
      pathname: "/presensi_pegawai",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${employee._id}&month=${urlValues.month}&year=${urlValues.year}`,
    });
    this.closeFilterModalVisible();
  };
  handleChangeCalendar = (key) => (e) => {
    if (
      (e[0] !== dayjs().month() + 1 && e[1] !== dayjs().year()) ||
      e[2] !== undefined
    ) {
      const date = e[2];
      this.props.history.replace({
        pathname: "/presensi_pegawai",
        search: `?sessionId=${urlValues.sessionId}&employeeId=${urlValues.employeeId}&month=${e[0]}&year=${e[1]}&date=${e[2]}`,
      });
      // Router.replace({
      //   pathname: "/presensi_pegawai",
      //   query: {
      //     sessionId: this.props.router.query.sessionId,
      //     employeeId: this.props.router.query.employeeId,
      //     date: e[2],
      //     month: e[0],
      //     year: e[1],
      //   }
      // });
    } else {
      this.props.history.replace({
        pathname: "/presensi_pegawai",
        search: `?sessionId=${urlValues.sessionId}&employeeId=${
          urlValues.employeeId
        }&month=${
          dayjs().month() + 1
        }&year=${dayjs().year()}&date=${dayjs().date()}`,
      });
    }
  };
  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };
  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };
  refresh = () => {
    window.location.reload();
  };

  openScheduleBreak = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);

      let date = `${urlValues.year}-${urlValues.month}-${urlValues.date}`;

      date = dayjs(date).format("YYYY-MM-DD");
      const result = await mwvClient.query({
        query: GET_SCHEDULE_BREAK,
        variables: {
          employeeId: urlValues.employeeId,
          date,
        },
        fetchPolicy: "no-cache",
      });
      this.setState({
        scheduleBreak: {
          visible: true,
          lists: result.data.getEmployeeScheduleBreak,
          date,
          selectedScheduleId: "",
        },
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
  render() {
    const { loading, scheduleBreak } = this.state;
    const allEmployees = this.props.allEmployees;
    const employee =
      allEmployees && allEmployees !== 0
        ? allEmployees.find((employee) => employee._id === urlValues.employeeId)
        : [];
    const presence = this.props.allRecords;

    console.log({ employee });
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-clock" style={{ fontSize: 20 }} />{" "}
              {translate("Presensi Pegawai")}
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {employee && employee.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{employee.name}
                            <br />
                            <b>
                              {employee.SchoolInformation
                                ? employee.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Filter Pegawai")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            employees={allEmployees}
            handleSelectEmployee={this.handleSelectEmployee}
            employeeId={urlValues.employeeId}
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> List Jadwal Rehat
            </span>
          }
          visible={scheduleBreak.visible}
          size="md"
          onClose={(e) => {
            this.setState({
              scheduleBreak: {
                lists: [],
                visible: false,
                date: "",
                selectedScheduleId: "",
              },
            });
          }}
          onSubmit={async (e) => {
            if (e) e.preventDefault();
            showLoadingSpinner();
            try {
              await mwvClient.mutate({
                mutation: BOOK_SCHEDULE_BREAK,
                variables: {
                  date: scheduleBreak.date,
                  scheduleId: scheduleBreak.selectedScheduleId,
                  employeeId: employee._id,
                },
              });

              addNotification({
                title: "SUCCESS",
                message: `Data disimpan`,
                level: "success",
              });
            } catch (err) {
              handleError(err);
            }
            hideLoadingSpinner();
          }}
        >
          <div className="form-group">
            <label>Tanggal</label>
            <input
              className="form-control"
              type="date"
              value={scheduleBreak.date}
              disabled
            />
          </div>

          <div className="form-group">
            <label>Shift Tersedia</label>
            <select
              className="form-control"
              value={scheduleBreak.selectedScheduleId}
              required
              onChange={(e) => {
                if (e) e.preventDefault();
                this.setState({
                  scheduleBreak: {
                    ...scheduleBreak,
                    selectedScheduleId: e.target.value,
                  },
                });
              }}
            >
              <option value={""} disabled>
                Pilih Shift
              </option>
              {scheduleBreak.lists.map((list) => (
                <option value={list._id}>{list.name}</option>
              ))}
            </select>
          </div>
        </FormModal>

        <div className="container">
          <Calendar
            presensi={presence}
            month={urlValues.month}
            year={urlValues.year}
            onChangeCalendar={this.handleChangeCalendar("calendar")}
            t={translate}
          />
        </div>
        <div className="fixed-bottom">
          {employee ? (
            employee.PREFIX === "DEMO" || employee.PREFIX === "HIDAYATULLAH" ? (
              <button
                type="button"
                onClick={this.openScheduleBreak}
                style={{
                  zIndex: 9999,
                  backgroundColor: "#9b59b6",
                  height: 60,
                  borderWidth: 0,
                  borderRadius: 0,
                  boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-lg btn-primary btn-block"
              >
                <b>
                  <i className="fa fa-calendar" /> Geser Shift
                </b>
              </button>
            ) : null
          ) : null}

          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ALL_RECORDS = gql`
  query allEmployeeAttendanceRecords(
    $sessionId: String!
    $employeeId: ID
    $month: Int
    $year: Int
  ) {
    allEmployeeAttendanceRecords(
      sessionId: $sessionId
      employeeId: $employeeId
      month: $month
      year: $year
    ) {
      _id
      scheduleName
      day
      month
      year
      date

      checkInStatus
      checkInMode
      checkInMinutes

      checkOutStatus
      checkOutMode
      checkOutMinutes

      _createdAt
    }
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      PREFIX
    }
  }
`;
const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;
// export default withRouter(props => (
//   <ApolloConsumer>
//     {client => (
//       <PresensiPegawai
//         {...props}
//         client={client}
//       />
//     )}
//   </ApolloConsumer>
// ));

const GET_SCHEDULE_BREAK = gql`
  query getEmployeeScheduleBreak($employeeId: ID!, $date: String!) {
    getEmployeeScheduleBreak(employeeId: $employeeId, date: $date) {
      _id
      name
    }
  }
`;

const BOOK_SCHEDULE_BREAK = gql`
  mutation bookScheduleBreak(
    $employeeId: ID!
    $date: String!
    $scheduleId: String!
  ) {
    bookScheduleBreak(
      employeeId: $employeeId
      date: $date
      scheduleId: $scheduleId
    )
  }
`;

export default withRouter(
  withI18n("attendance")((props) => {
    urlValues = queryString.parse(props.location.search);
    let employeeId = urlValues.employeeId ? urlValues.employeeId : "";
    let month = urlValues.month ? parseInt(urlValues.month) : "";
    let year = urlValues.year ? parseInt(urlValues.year) : "";
    return (
      <Query
        query={ALL_RECORDS}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
          employeeId,
          month,
          year,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <PresensiPegawai
              {...props}
              error={error}
              loading={loading}
              allRecords={
                data && data.allEmployeeAttendanceRecords
                  ? data.allEmployeeAttendanceRecords
                  : []
              }
              allEmployees={
                data && data.allEmployeesBySessionId
                  ? data.allEmployeesBySessionId
                  : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
