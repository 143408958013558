import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import dayjs from "dayjs";
import { Query } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";

let urlValues;
const STATUS_COLOR = {
  SETORAN_TABUNGAN_PEGAWAI: "green",
  PENARIKAN_TABUNGAN_PEGAWAI: "red",
};

const depositColors = ["#1abc9c", "#2ecc71", "#3498db"];

const withdrawColros = ["#f1c40f", "#e67e22", "#e74c3c"];

// let hexColors = [
//   "#0961a5",
//   "#1abc9c",
//   "#0984e3",
//   "#e67e22",
//   "#e74c3c",
//   "#f39c12",
//   "#4834d4",
//   "#be2edd"
// ];

const FilterModal = ({
  employees,
  handleSelectEmployee,
  // selectedEmployeeId,
  employeeId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {employees.map((employee) => (
        <div key={employee._id}>
          <section
            className="card"
            onClick={handleSelectEmployee(employee)}
            style={{
              borderWidth: "3px",
              borderColor: employee._id === employeeId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {employee.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {employee.name}
                    </b>
                    <br />
                    {employee.SchoolInformation
                      ? employee.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);
class EmployeeSaving extends Component {
  state = {
    session: {},
    transactionLimit: 5,
    filterModalVisible: false,
    allEmployees: [],
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: SAVING_QUERIES,
      variables: {
        _id: accountSession._id,
        // employeeId: "ac46337e-748b-4cd4-9cdd-e77e3675f4a9",
        // limit: 5
      },
    });

    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];

    if (urlValues.employeeId) {
      this.props.history.replace({
        pathname: "/employee_saving",
        search: `?sessionId=${accountSession._id}&employeeId=${urlValues.employeeId}&limit=${urlValues.limit}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/employee_saving",
        search: `?sessionId=${accountSession._id}&employeeId=${
          employees[0] ? employees[0]._id : ""
        }&limit=${5}`,
      });
    }

    this.setState({
      allEmployees: [...employees],
    });
  };

  handleLimit = (e) => {
    this.setState({
      transactionLimit: e.target.value,
    });

    urlValues = {
      ...urlValues,
      limit: e.target.value,
    };

    this.props.history.replace({
      pathname: "/employee_saving",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${urlValues.employeeId}&limit=${e.target.value}`,
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectEmployee = (employee) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      employeeId: employee._id,
    };

    this.setState({
      transactionLimit: 5,
    });
    this.props.history.replace({
      pathname: "/employee_saving",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${
        urlValues.employeeId
      }&limit=${5}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { allEmployees } = this.state;
    const { employeeSaving } = this.props;

    urlValues = queryString.parse(this.props.location.search);

    const getEmployee = allEmployees
      ? allEmployees.filter((s) => s._id === urlValues.employeeId)
      : [];

    const dataTabungan =
      employeeSaving.length !== 0
        ? employeeSaving.filter((s) => s.Employee._id === urlValues.employeeId)
        : [];

    const latestSaving = dataTabungan.find(
      (s) => s.Employee._id === urlValues.employeeId
    );

    // const latestSaving = null;

    let counter = 0;
    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            employees={allEmployees}
            handleSelectEmployee={this.handleSelectEmployee}
            // selectedEmployeeId={this.state.selectedEmployeeId}
            employeeId={
              urlValues && urlValues.employeeId ? urlValues.employeeId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-archive"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Tabungan Pegawai")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getEmployee.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getEmployee[0] ? getEmployee[0].name : ""}
                            <br />
                            <b>
                              {getEmployee[0] &&
                              getEmployee[0].SchoolInformation
                                ? getEmployee[0].SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                            <Text
                              style={{
                                paddingRight: 10,
                                fontSize: 12,
                                paddingVertical: 2,
                              }}
                            >
                              <i
                                className="fa fa-info-circle"
                                style={{
                                  fontSize: 15,
                                }}
                              />
                            </Text>
                            {translate("Rp")}.{" "}
                            {Number(latestSaving ? latestSaving.balance : 0)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="form-group">
                <select
                  className="form-control"
                  onChange={this.handleLimit}
                  value={this.state.transactionLimit}
                >
                  <option value="5">5 {translate("Transaksi Terakhir")}</option>
                  <option value="10">
                    10 {translate("Transaksi Terakhir")}
                  </option>
                  <option value="20">
                    20 {translate("Transaksi Terakhir")}
                  </option>
                  <option value="100">
                    100 {translate("Transaksi Terakhir")}
                  </option>
                </select>
              </div>
            </div>
          </section>
        </div>

        {dataTabungan.length === 0 ? (
          <View
            style={{
              marginTop: "250px",
              marginBottom: "50px",
            }}
          >
            <section className="card">
              <div className="card-block py-4">
                <center>
                  <Image
                    style={{ width: 250, height: 250 }}
                    source={require("../assets/money-angel.png")}
                  />
                  <h5 style={{ fontSize: "100%" }}>
                    {translate("Tabungan Tidak Ditemukan, Ayo Menabung")}!!
                  </h5>
                </center>
              </div>
            </section>
          </View>
        ) : (
          <View
            style={{
              marginTop: "8rem",
              marginBottom: 50,
              paddingTop: 100,
            }}
          >
            {dataTabungan.map((tabungan) => (
              <section
                key={tabungan._id}
                className={"card card-" + STATUS_COLOR[tabungan.type]}
                style={{ padding: 20, marginBottom: 8 }}
              >
                <div className="card-block">
                  <div className="row">
                    <div className="col-md-9">
                      <span
                        className="dot"
                        style={{
                          height: "100px",
                          width: "100px",
                          backgroundColor:
                            // hexColors[counter % hexColors.length],
                            tabungan.type === "SETORAN_TABUNGAN_PEGAWAI"
                              ? depositColors[counter % depositColors.length]
                              : withdrawColros[counter % withdrawColros.length],
                          borderRadius: "50px",
                          padding: "10px",
                          color: "#fff",
                          fontWeight: 600,
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {tabungan.type.slice(0, 2)}
                      </span>
                      &nbsp;&nbsp;
                      <b>{tabungan.description}</b>
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp; {translate("Jumlah")}: {translate("Rp")}.{" "}
                      <font size="4">
                        {tabungan.type === "SETORAN_TABUNGAN_PEGAWAI"
                          ? Number(tabungan.debit)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                          : Number(tabungan.credit)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </font>
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp; {translate("No Transaksi")}:{" "}
                      {tabungan.transactionNumber}
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font color="#00a8ff">
                    {translate("Tanggal")}:&nbsp;
                    {dayjs(tabungan.date).format("DD-MM-YYYY")}, Jam{" "}
                    {dayjs(tabungan._createdAt).get("hour")}:
                    {dayjs(tabungan._createdAt).get("minute")}
                  </font>
                  <font color="#fff">{counter++}</font>
                </div>
              </section>
            ))}
          </View>
        )}

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}
const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const SAVING_QUERIES = gql`
  query listQuery($_id: String, $limit: Int, $employeeId: String) {
    allEmployeesBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
    employeeSaving(employeeId: $employeeId, limit: $limit) {
      _id
      date
      transactionNumber
      debit
      credit
      type
      balance
      Employee {
        _id
        name
      }
      description
      _createdAt
    }
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);

    let employeeId = urlValues.employeeId ? urlValues.employeeId : "";
    let limit = urlValues.limit ? parseInt(urlValues.limit) : 5;

    return (
      <Query
        query={SAVING_QUERIES}
        client={mwvClient}
        variables={{
          _id: urlValues.sessionId,
          limit,
          employeeId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <EmployeeSaving
              {...props}
              error={error}
              loading={loading}
              employeeSaving={
                data && data.employeeSaving ? data.employeeSaving : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
