import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import queryString from "query-string";
import { ApolloConsumer, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import localforage from "localforage";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";

import Fingerprint2 from "fingerprintjs2";
import { handleError } from "../libs/errors";

let urlValues;

class Register extends Component {
  state = {
    uniqueCode: "",
    Student: null,
    session: {},
    loading: false,
    accountSession: null,
    Account: {
      name: "",
      phone: "",
      email: "",
      defaultPassword: "",
      gender: "",
      birthDate: "",
      registeredBy: "",
    },
  };

  componentDidMount() {
    urlValues = queryString.parse(this.props.location.search);
    this.setState({
      Account: {
        ...this.state.Account,
        phone: urlValues.phoneNumber,
        email: urlValues.email,
        name: urlValues.displayName ? urlValues.displayName : "",
        registeredBy: urlValues.registeredBy,
      },
    });
  }

  refresh = () => {
    window.location.reload();
  };

  handleInput = (key) => (e) => {
    this.setState({
      Account: {
        ...this.state.Account,
        [key]: e.target.value,
      },
    });
  };

  handleRegister = async (e) => {
    if (e) e.preventDefault();
    const { Account } = this.state;
    if (!Account.registeredBy) {
      return handleError({
        message: "Your data is invalid!",
      });
    }
    this.setState({
      loading: true,
    });
    try {
      const response = await this.props.registerAccount({
        variables: {
          ...Account,
          phone: Account.phone ? Account.phone : "",
          email: Account.email ? Account.email : "",
          defaultPassword: generateOTP(),
        },
      });
      addNotification({
        title: "SUCCESS",
        message: `Berhasil mendaftar akun`,
        level: "success",
      });
      const AccountSession =
        response.data && response.data.registerAccountV2
          ? response.data.registerAccountV2
          : null;

      if (!AccountSession) {
        return handleError({
          message: "Server Error!",
        });
      } else {
        return this.logInWithGoogleOrWhatsapp(AccountSession);
      }
    } catch (e) {
      handleError({
        message: e.message.replace("GraphQL error: ", ""),
      });
      this.setState({
        loading: false,
      });
    }
  };

  logInWithGoogleOrWhatsapp = async (AccountSession) => {
    let browserInfo = {};
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        Fingerprint2.get((components) => {
          var values = components.map((c) => {
            // console.log(c)
            return c.value;
          });
          var hashId = Fingerprint2.x64hash128(values.join(""), 31);
          browserInfo = {
            uniqueId: hashId,
          };
          resolve();
        });
      }, 800);
    });

    var objappVersion = navigator.appVersion;
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = "" + parseFloat(navigator.appVersion);
    let objBrMajorVersion = parseInt(navigator.appVersion, 10);
    let objOffsetName, objOffsetVersion, ix;

    let browserVersion;
    // console.log(objAgent)
    if ((objOffsetVersion = objAgent.indexOf("Chrome")) !== -1) {
      objbrowserName = "Chrome";
      const version = objAgent.slice(objOffsetVersion + 7);
      browserVersion = version.slice(0, 12);
    } else if ((objOffsetVersion = objAgent.indexOf("Safari")) !== -1) {
      objbrowserName = "Safari";
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf("Version")) !== -1) {
        objfullVersion = objAgent.substring(objOffsetVersion + 8);

        browserVersion = objfullVersion.slice(26, 32);
      }
    } else {
      objbrowserName = "Mozilla";
      browserVersion = objfullVersion;
    }

    browserInfo = {
      ...browserInfo,
      objbrowserName,
      browserVersion,
    };

    if (!firebase.apps.length) {
      // console.log("Initialize firebase...");
      firebase.initializeApp(firebaseConfig);
    }
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
    }

    const session = AccountSession;
    localforage.setItem("accountSession", JSON.stringify(session));

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const fcmToken = await messaging.getToken();
      localforage.setItem("fcm-token", fcmToken);
      await this.props.resubscribeTopicForPushNotification({
        variables: {
          fcmToken,
        },
      });
    }

    this.setState({
      loading: false,
    });

    setTimeout(() => {
      window.location = "/explore";
    }, 1000);
  };

  render() {
    const { Account, loading } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/login",
                  })
                }
              >
                <Text>
                  <i
                    className="fa fa-arrow-left"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {translate("Kembali")}
                </Text>
              </TouchableOpacity>
            }
            // right={
            //   <TouchableOpacity
            //     onPress={() =>
            //       this.props.history.push({
            //         pathname: '/login'
            //       })}
            //   >
            //     <Text
            //       style={{
            //         paddingRight: 12,
            //         paddingLeft: 20,
            //         color: 'white',
            //         fontSize: 16,
            //         paddingVertical: 2
            //       }}
            //     >
            //       {translate('Kembali')}
            //     </Text>
            //   </TouchableOpacity>
            // }
          />
        </div>
        <div
          className="card"
          style={{ borderRadius: "20px", margin: "auto 15px" }}
        >
          <div
            className="card-header text-center"
            style={{
              backgroundColor: "#10ac84",
              borderRadius: "20px 20px 0 0",
              color: "#fff",
            }}
          >
            {/* <Image style={{
							width: 140,
							height: 140,
							margin: 'auto'
						}} source={require("../assets/call-center-female-caucasian.png")} /> */}
            <h4>Mohon isi data di bawah ini dengan benar</h4>
          </div>
          <div className="card-body">
            {Account && Account.registeredBy === "GOOGLE" ? (
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  className="form-control"
                  value={Account.email}
                  disabled
                />
              </div>
            ) : (
              <div className="form-group">
                <label>Nomor HP</label>
                <input
                  type="text"
                  className="form-control"
                  value={Account.phone}
                  disabled
                  onChange={this.handleInput("phone")}
                />
              </div>
            )}

            <div className="form-group">
              <label>Nama Lengkap</label>
              <input
                type="text"
                className="form-control"
                value={Account.name}
                placeholder="Masukkan nama lengkap anda"
                onChange={this.handleInput("name")}
              />
            </div>
            <div className="form-group">
              <label>Jenis Kelamin</label>
              <select
                className="form-control"
                onChange={this.handleInput("gender")}
              >
                <option value="">Pilih Jenis Kelamin</option>
                <option value="MALE">Laki-Laki</option>
                <option value="FEMALE">Perempuan</option>
              </select>
            </div>
            <div className="form-group">
              <label>Tanggal Lahir</label>
              <input
                type="date"
                className="form-control"
                onChange={this.handleInput("birthDate")}
              />
            </div>
            <button
              onClick={this.handleRegister}
              className="btn btn-primary btn-block"
            >
              Daftar
            </button>
          </div>
        </div>
      </View>
    );
  }
}

const REGISTER = gql`
  mutation registerAccount(
    $name: String!
    $phone: String!
    $email: String!
    $defaultPassword: String!
    $gender: String
    $birthDate: String
    $registeredBy: String!
  ) {
    registerAccountV2(
      name: $name
      phone: $phone
      email: $email
      defaultPassword: $defaultPassword
      gender: $gender
      birthDate: $birthDate
      registeredBy: $registeredBy
    ) {
      _id
      token
      expiresIn
      Account {
        _id
        name
        email
        phone
        status
        roles
        subscribedTopics
      }
      _createdAt
    }
  }
`;

const RESUBSCRIBE = gql`
  mutation resubscribeTopicForPushNotification($fcmToken: String!) {
    resubscribeTopicForPushNotification(fcmToken: $fcmToken)
  }
`;

const generateOTP = () => {
  const character =
    "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let OTP = "";

  // Find the length of string
  var len = character.length;
  for (let i = 0; i < 6; i++) {
    OTP += character[Math.floor(Math.random() * len)];
  }
  return OTP;
};

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation mutation={RESUBSCRIBE}>
            {(resubscribeTopicForPushNotification) => (
              <Mutation mutation={REGISTER}>
                {(registerAccount) => (
                  <Register
                    {...props}
                    registerAccount={registerAccount}
                    resubscribeTopicForPushNotification={
                      resubscribeTopicForPushNotification
                    }
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  })
);
