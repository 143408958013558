import React from "react";
import { View, TextInput, Text } from "react-native-web";

const CustomTextInput = ({ label, onChangeText, value, ...textInputProps }) => (
  <View
    style={{
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: "#eee",
      padding: 6
    }}
  >
    <Text style={{ paddingHorizontal: 4, paddingTop: 6 }}>{label}</Text>
    <TextInput
      {...textInputProps}
      onChangeText={onChangeText}
      value={value}
      underlineColorAndroid="#ccc"
      style={{
        height: 42,
        fontSize: 18,
        outlineStyle: "none",
      }}
    />
  </View>
);

export default CustomTextInput;
