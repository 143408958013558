import React, { Component } from "react";
import gql from "graphql-tag";

import App, {
  showLoadingSpinner,
  hideLoadingSpinner,
  addNotification,
} from "../../App";
import { handleError } from "../../libs/errors";

class EmployeeForm extends Component {
  state = {
    employee: {},
  };
  componentDidMount = () => {
    this.setState({
      employee: this.props.employee,
    });
  };

  handleInputUpdate = (key) => (e) => {
    this.setState({
      employee: {
        ...this.state.employee,
        [key]: e.target.value,
      },
    });
  };

  handleSubmitUpdate = async () => {
    showLoadingSpinner();
    try {
      const { Documents, __typename, ...emp } = this.state.employee;

      await this.props.mwvClient.mutate({
        mutation: UPDATE_EMPLOYEE,
        variables: {
          _id: this.state.employee._id,
          input: emp,
        },
      });
      addNotification({
        level: "success",
        message: "Data Berhasil Disimpan",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  render() {
    const { employee } = this.state;

    return (
      <section
        className="card"
        style={{
          padding: 10,
        }}
      >
        <div className="row">
          <div className="col-8">
            <div className="form-group">
              <label>{"Nama Lengkap"} *</label>
              <input
                className="form-control"
                required
                value={employee.name}
                onChange={this.handleInputUpdate("name")}
              />
            </div>
            <div className="form-group">
              <label>{"Nama Panggilan"} *</label>
              <input
                className="form-control"
                required
                value={employee.nickName}
                onChange={this.handleInputUpdate("nickName")}
              />
            </div>
            <div className="form-group">
              <label>{"Gelar Depan"}</label>
              <input
                className="form-control"
                value={employee.titleAhead}
                onChange={this.handleInputUpdate("titleAhead")}
              />
            </div>
            <div className="form-group">
              <label>{"Gelar Belakang"}</label>
              <input
                className="form-control"
                value={employee.titleBehind}
                onChange={this.handleInputUpdate("titleBehind")}
              />
            </div>
            <div className="form-group">
              <label>{"Jenis Kelamin"} *</label>
              <select
                className="form-control"
                required
                value={employee.gender}
                onChange={this.handleInputUpdate("gender")}
              >
                <option value="Laki-laki">{"Laki-laki"}</option>
                <option value="Perempuan">{"Perempuan"}</option>
              </select>
            </div>
            <div className="form-group">
              <label>{"NRP / NIP"} *</label>
              <input
                className="form-control"
                required
                value={employee.regNumber}
                onChange={this.handleInputUpdate("regNumber")}
              />
            </div>
            <div className="form-group">
              <label>{"NUPTK"}</label>
              <input
                className="form-control"
                value={employee.nuptk}
                onChange={this.handleInputUpdate("nuptk")}
              />
            </div>
            <div className="form-group">
              <label>{"Status"} *</label>
              <select
                className="form-control"
                required
                value={employee.status}
                onChange={this.handleInputUpdate("status")}
              >
                <option value="" disabled>
                  {"Plih status"}
                </option>
                <option value="Aktif">{"Aktif"}</option>
                <option value="Non Aktif">{"Non Aktif"}</option>
              </select>
            </div>
            <div className="form-group">
              <label>{"Nomor HP / Telepon"} *</label>
              <input
                required
                className="form-control"
                value={employee.phoneNumber}
                onChange={this.handleInputUpdate("phoneNumber")}
              />
            </div>
            <div className="form-group">
              <label>{"Nomor Whatsapp"}</label>
              <input
                required
                className="form-control"
                value={employee.noWA}
                onChange={this.handleInputUpdate("noWA")}
              />
            </div>
            <div className="form-group">
              <label>{"Alamat Email"}</label>
              <input
                type="email"
                className="form-control"
                value={employee.email}
                onChange={this.handleInputUpdate("email")}
              />
            </div>
            <div className="form-group">
              <label>{"Nomor Telepon Untuk Keadaan Darurat"}</label>
              <input
                className="form-control"
                value={employee.emergencyPhoneNumber}
                onChange={this.handleInputUpdate("emergencyPhoneNumber")}
              />
            </div>

            <hr />

            <div className="form-group">
              <label>
                {employee.PREFIX === "INKA"
                  ? "Tanggal Bulan Tahun Pegawai Tetap"
                  : "Tanggal Masuk"}
              </label>
              <input
                type="date"
                className="form-control"
                value={employee.workEntryDate}
                onChange={this.handleInputUpdate("workEntryDate")}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Tahun Pendidikan"}</label>
              <input
                className="form-control"
                type="number"
                style={{ maxWidth: 150 }}
                value={employee.educationYear}
                onChange={this.handleInputUpdate("educationYear")}
              />
            </div>
            <div className="form-group">
              <label>
                {employee.PREFIX === "INKA"
                  ? "Nomor SK Kepegawaian Tetap"
                  : "Nomor SK Kepegawaian"}
              </label>
              <input
                className="form-control"
                value={employee.decreeNumber}
                onChange={this.handleInputUpdate("decreeNumber")}
              />
            </div>

            <hr />

            <div className="form-group">
              <label>{"Kota Lahir"}</label>
              <input
                className="form-control"
                value={employee.hometown}
                onChange={this.handleInputUpdate("hometown")}
              />
            </div>
            <div className="form-group">
              <label>{"Tanggal Lahir"}</label>
              <input
                type="date"
                className="form-control"
                value={employee.birthDate}
                onChange={this.handleInputUpdate("birthDate")}
              />
            </div>
            <div className="form-group">
              <label>{"Alamat Tempat Tinggal"}</label>
              <textarea
                className="form-control"
                value={employee.address}
                onChange={this.handleInputUpdate("address")}
              />
            </div>
            <div className="form-group">
              <label>{"Kota Tempat Tinggal"}</label>
              <input
                className="form-control"
                value={employee.cityOfResidence}
                onChange={this.handleInputUpdate("cityOfResidence")}
              />
            </div>

            <hr />

            <div className="form-group">
              <label>{"Status Pernikahan"}</label>
              <select
                className="form-control"
                value={employee.marriedStatus}
                onChange={this.handleInputUpdate("marriedStatus")}
              >
                <option value="" disabled>
                  {"Plih status pernikahan"}
                </option>
                <option value="Lajang">{"Lajang"}</option>
                <option value="Menikah">{"Menikah"}</option>
                <option value="Duda / Janda">{"Duda / Janda"}</option>
              </select>
            </div>
            <div className="form-group">
              <label>{"Agama"}</label>
              <select
                className="form-control"
                value={employee.religion}
                onChange={this.handleInputUpdate("religion")}
              >
                <option value="" disabled>
                  {"Plih agama"}
                </option>
                <option value="Islam">{"Islam"}</option>
                <option value="Kristen">{"Kristen"}</option>
                <option value="Katolik">{"Katolik"}</option>
                <option value="Hindu">{"Hindu"}</option>
                <option value="Budha">{"Budha"}</option>
                <option value="Konghuchu">{"Konghuchu"}</option>
              </select>
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Bahasa Sehari-hari"}</label>
              <input
                className="form-control"
                value={employee.language}
                onChange={this.handleInputUpdate("religion")}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Berat Badan (kg)"}</label>
              <input
                className="form-control"
                disabled
                value={employee.weight}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Tinggi Badan (cm)"}</label>
              <input
                className="form-control"
                disabled
                value={employee.height}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Riwayat Penyakit"}</label>
              <textarea
                className="form-control"
                disabled
                value={employee.diseaseHistory}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Alergi Makanan"}</label>
              <textarea
                className="form-control"
                disabled
                value={employee.foodAllergies}
              />
            </div>
            <div
              className="form-group"
              style={{
                display: employee.PREFIX === "INKA" ? "none" : "",
              }}
            >
              <label>{"Alergi Minuman"}</label>
              <textarea
                className="form-control"
                disabled
                value={employee.beverageAllergies}
              />
            </div>
          </div>
          <div className="col-4">
            <button
              className="btn btn-primary btn-block"
              onClick={this.handleSubmitUpdate}
            >
              <i className="fa fa-save" /> Simpan
            </button>
          </div>
        </div>
      </section>
    );
  }
}

const UPDATE_EMPLOYEE = gql`
  mutation updateEmployee($_id: String!, $input: JSON) {
    updateEmployee(_id: $_id, input: $input)
  }
`;

export default EmployeeForm;
