import React, { Component } from "react";
import {
  View,
  Text,
  StatusBar,
  // Alert,
  // Linking,
  // AppState,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView,
} from "react-native-web";
import lodash from "lodash";
import BackgroundImage from "../components/BackgroundImage";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import gql from "graphql-tag";

import FeaturedArticles from "../components/FeaturedArticles";
import HighlightedArticles from "../components/HighlightedArticles";
import SchoolLifeMenu from "../components/SchoolLifeMenu";
import { formatCurrency } from "../libs/numbers";

import { withI18n, translate } from "../libs/withI18n";

// import { handleError } from "../libs/errors";

// const DEVICE_WIDTH = window.innerWidth;
const HEADER_MAX_HEIGHT = 150;
const HEADER_MIN_HEIGHT = 55;
// const HEADER_SCROLL_DISTANCE = HEADER_MAX_HEIGHT - HEADER_MIN_HEIGHT;

const styles = StyleSheet.create({
  scrollViewContent: {
    marginTop: HEADER_MAX_HEIGHT + HEADER_MIN_HEIGHT,
  },
  header: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    backgroundColor: "white",
    overflow: "hidden",
    height: HEADER_MAX_HEIGHT,
  },
  profileStatContainer: {
    position: "absolute",
    top: HEADER_MAX_HEIGHT - 10,
    left: 8,
    right: 8,
    backgroundColor: "white",
    height: 68,

    shadowColor: "#000",
    shadowOffset: { width: 3, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 10,
    elevation: 1,

    flex: 1,
    flexDirection: "row",
  },
});

class Explore extends Component {
  state = {
    username: "",
    loading: false,
    deviceInfo: {},
    session: {},
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name,
      });
      // this.props.history.push({
      //   pathname: "/explore",
      //   search: `?sessionId=${accountSession._id}`
      // });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  renderProfileStats = () => {
    const { currentUser } = this.props;

    return (
      <View style={styles.profileStatContainer}>
        <TouchableOpacity
          style={{ flex: 1 }}
          onPress={(e) => {
            this.props.history.push({
              pathname: "/account",
            });
          }}
        >
          <View style={{ flex: 1, paddingLeft: 15 }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text>
                <i
                  className="fa fa-user"
                  style={{
                    paddingTop: 20,
                    marginLeft: 4,
                    color: "blue",
                    fontSize: 26,
                  }}
                />
              </Text>
              <View
                style={{
                  flex: 1,
                  paddingLeft: 8,
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Text numberOfLines={2} style={{ fontSize: 14 }}>
                  {currentUser ? currentUser.name : "Loading..."}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flex: 1, width: 90 }}
          onPress={() => {
            this.props.history.push({
              pathname: "/qr_code",
              search: `?sessionId=${this.state.session._id}`,
            });
          }}
        >
          <View style={{ flex: 1, margin: "auto" }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Text>
                <i
                  className="fas fa-qrcode"
                  style={{
                    paddingTop: 20,
                    marginLeft: 4,
                    color: "blue",
                    fontSize: 26,
                  }}
                ></i>
              </Text>
              <View
                style={{
                  flex: 1,
                  paddingLeft: 8,
                  justifyContent: "center",
                  alignItems: "flex-start",
                }}
              >
                <Text numberOfLines={2} style={{ fontSize: 14 }}>
                  Scan
                </Text>
              </View>
            </View>
            {/* <Text
              style={{
                flex: 1,
                marginBottom: 12,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <i
                className="fa fa-star"
                style={{
                  color: "#ffd200",
                  fontSize: 20,
                }}
              />{" "}
              Points
            </Text> */}
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ flex: 1 }}
          // onPress={() => {
          //   this.props.navigation.navigate("SchoolPay");
          // }}
        >
          <View style={{ flex: 1 }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 14,
              }}
            >
              {currentUser ? (
                <Text
                  style={{
                    flex: 1,
                    textAlign: "center",
                  }}
                >
                  Rp. {formatCurrency(0)}
                </Text>
              ) : (
                <ActivityIndicator
                  size="small"
                  color="#777"
                  style={{
                    margin: 0,
                    flex: 1,
                    alignItems: "center",
                  }}
                />
              )}
            </View>
            <Text
              style={{
                flex: 1,
                marginBottom: 12,
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              <i
                className="fa fa-check-circle"
                style={{
                  color: "#ffd200",
                  fontSize: 20,
                }}
              />{" "}
              School Pay
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    );
  };

  getHideMenuByRole = () => {
    const currentUser = lodash.get(this.props, "currentUser", {});
    if (currentUser.roles && currentUser.roles.length > 0) {
      return (
        currentUser.roles
          .map((role) => role.indexOf("hide_menu") > -1)
          .filter((role) => !!role).length > 0
      );
    }
    return false;
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <StatusBar
          backgroundColor="#1696ff"
          barStyle="light-content"
          animated={true}
        />
        <BackgroundImage />
        <ScrollView style={{ flex: 1, marginBottom: 55 }}>
          <View style={styles.header}>
            <HighlightedArticles />
          </View>
          {this.renderProfileStats()}
          <View style={styles.scrollViewContent}>
            <FeaturedArticles />
            <SchoolLifeMenu
              hasEmployee={
                this.props.currentUser &&
                this.props.currentUser.employeeIds &&
                this.props.currentUser.employeeIds.length > 0
              }
              hasParent={
                this.props.currentUser &&
                ((this.props.currentUser.studentIds &&
                  this.props.currentUser.studentIds.length > 0) ||
                  (this.props.currentUser.parentIds &&
                    this.props.currentUser.parentIds.length > 0))
              }
              hideMenuByRole={this.getHideMenuByRole()}
            />
          </View>
        </ScrollView>
        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const QUERY = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
      studentIds
      parentIds
      employeeIds
      prefixes
      roles
    }
  }
`;

const RESUBSCRIBE = gql`
  mutation resubscribeTopicForPushNotification($fcmToken: String!) {
    resubscribeTopicForPushNotification(fcmToken: $fcmToken)
  }
`;

const INTERACT = gql`
  mutation interactWithActivityItem(
    $activityItemId: String!
    $type: String!
    $sessionId: String!
  ) {
    interactWithActivityItem(
      activityItemId: $activityItemId
      type: $type
      sessionId: $sessionId
    )
  }
`;

export default (props) => (
  <ApolloConsumer>
    {(client) => (
      <Mutation mutation={INTERACT}>
        {(interactWithActivityItem) => (
          <Mutation mutation={RESUBSCRIBE}>
            {(resubscribeTopicForPushNotification) => (
              <Query query={QUERY}>
                {({ error, loading, data }) => (
                  <Explore
                    {...props}
                    client={client}
                    currentUser={
                      data && data.currentUser
                        ? data.currentUser
                        : {
                            studentIds: [],
                            parentIds: [],
                            employeeIds: [],
                          }
                    }
                    resubscribeTopicForPushNotification={
                      resubscribeTopicForPushNotification
                    }
                    interactWithActivityItem={interactWithActivityItem}
                  />
                )}
              </Query>
            )}
          </Mutation>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
);
