/* eslint-disable */
import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  ScrollView,
  TouchableOpacity
} from "react-native-web";
import ListSection from "../components/ListSection";
import TextInput from "../components/TextInput";
import BlockButton from "../components/BlockButton";
import BackgroundImage from "../components/BackgroundImage";
import { LoadingOverlay } from "../components/Overlay";
import HeaderBar from "../components/HeaderBar";
import { ApolloConsumer, Mutation } from "react-apollo";
import gql from "graphql-tag";
import localforage from "localforage";
import { handleError } from "../libs/errors";
import { addNotification } from "../App";

class ChangePassword extends Component {
  handleInput = key => value => {
    this.setState({
      [key]: value
    });
  };

  render = () => {
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      loading
    } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <LoadingOverlay visible={loading} />
        <StatusBar
          backgroundColor="#1696ff"
          barStyle="light-content"
          animated={true}
        />
        <BackgroundImage />
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-lock" style={{ fontSize: 20 }} />&nbsp; Change
              Password
            </Text>
          }
          right={
            <TouchableOpacity
              onPress={() =>
                this.props.history.push({
                  pathname: "/account"
                })
              }
            >
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2
                }}
              >
                Close
              </Text>
            </TouchableOpacity>
          }
        />
        <ScrollView keyboardShouldPersistTaps="handled">
          <ListSection>
            <TextInput
              label="Old Password"
              placeholder="Type Old Password"
              value={currentPassword}
              onChangeText={this.handleInput("currentPassword")}
              secureTextEntry={true}
              autoFocus={true}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
              autoCorrect={false}
            />
            <TextInput
              label="New Password"
              placeholder="Type New Password"
              value={newPassword}
              onChangeText={this.handleInput("newPassword")}
              secureTextEntry={true}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
              autoCorrect={false}
            />
            <TextInput
              label="Confirm New Password"
              placeholder="Type New Password Again"
              value={confirmPassword}
              onChangeText={this.handleInput("confirmPassword")}
              secureTextEntry={true}
              enablesReturnKeyAutomatically={true}
              clearButtonMode="always"
              autoCorrect={false}
            />
            <BlockButton
              icon="md-checkmark-circle"
              label="Submit"
              onPress={this.handleSubmit}
            />
          </ListSection>
        </ScrollView>
      </View>
    );
  };

  state = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    loading: false
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    if (accountSession !== null) {
      this.setState({
        session: accountSession
      });
      this.props.history.replace({
        pathname: "/change_password",
        search: `?sessionId=${accountSession._id}`
      });
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  };

  handleSubmit = async () => {
    try {
      let { currentPassword, newPassword, confirmPassword } = this.state;
      if (!currentPassword || !newPassword || !confirmPassword) {
        throw {
          message: "Input password tidak valid!"
        };
      }
      if (newPassword !== confirmPassword) {
        throw {
          message: `Konfirmasi password tidak cocok!`
        };
      }

      await this.props.updatePassword({
        variables: {
          oldPassword: currentPassword,
          newPassword
        }
      });

      this.setState({
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
      });

      addNotification({
        title: "SUCCESS",
        message: "Password berhasil diperbarui",
        level: "success"
      });
    } catch (err) {
      handleError(err);
    }
  };
}

const UPDATE = gql`
  mutation updatePassword($oldPassword: String!, $newPassword: String!) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

export default props => (
  <ApolloConsumer>
    {client => (
      <Mutation mutation={UPDATE}>
        {updatePassword => (
          <ChangePassword
            {...props}
            client={client}
            updatePassword={updatePassword}
          />
        )}
      </Mutation>
    )}
  </ApolloConsumer>
);
