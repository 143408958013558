import React, { Component, Fragment } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import dayjs from "dayjs";
import ReactHtmlParser from "react-html-parser";
import { handleError } from "../../libs/errors";
import { addNotification } from "../../App";
import Countdown, { zeroPad } from "react-countdown";
import { MathfieldElement } from "mathlive";

// mfe.executeCommand(["showVirtualKeyboard", "apple"]);
import { Tex } from "react-tex";
import { slice } from "lodash-es";
let urlValues;

const config = {
  loader: { load: ["input/asciimath"] },
};

class CBTStart extends Component {
  constructor(props) {
    super(props);
    this.countDownRef = React.createRef();

    this.state = {
      today: null,

      questions: [],
      selectedNumber: 1,
      selectedAnswer: {},

      studentAnswer: null,

      session: {},
      isStarted: false,

      timer: 0,
      autoStart: false,

      questionListVisible: false,

      student: null,
      showWarningVisible: false,

      listNumbers: [],
      listYangSudahDijawab: [],
      showFinishButton: true,
    };
  }

  initiate = async () => {
    const { history } = this.props;
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUESTION_LISTS_QUERIES,
      variables: {
        studentId: urlValues.studentId,
        academicYear: urlValues.academicYear,
        subjectId: urlValues.subjectId,
        packageId: urlValues.packageId,
      },
      fetchPolicy: "no-cache",
    });

    const studentObj =
      result.data && result.data.studentById ? result.data.studentById : null;

    if (result.data && result.data.studentAnswer) {
      // Get Timer
      const studentAnswer = result.data.studentAnswer;

      window.scrollTo(0, 0);

      const startedAt = dayjs(studentAnswer.startedAt);
      const endAt = dayjs(studentAnswer.endAt);

      const siswaWaktu = endAt.diff(startedAt);
      const current = Date.now() + siswaWaktu; //dayjs(Date.now() + siswaWaktu).format();

      this.setState({
        today: current,
      });

      this.setState({
        listYangSudahDijawab: JSON.parse(studentAnswer.questions),
      });

      if (
        this.countDownRef &&
        this.countDownRef.current &&
        this.countDownRef.current.api
      ) {
        this.countDownRef.current.api.start();
      }

      const daftarSoal = studentAnswer
        ? JSON.parse(studentAnswer.questions)
        : [];

      this.setState({
        studentAnswer,
        student: studentObj,
        listNumbers: this.generateListNumbers(daftarSoal),
      });
    }
  };

  componentDidMount = async () => {
    this.initiate();

    const exitConfirmation = await localforage.getItem(
      `exitCBT_${urlValues.studentId}_${urlValues.subjectId}_${urlValues.packageId}`
    );

    if (exitConfirmation) {
      this.handleSubmitQuestion();
    }
  };

  generateListNumbers = (questions) => {
    const { listYangSudahDijawab } = this.state;
    let no = 0;
    let result = [];

    for (const q of questions) {
      const foundDijawab = listYangSudahDijawab.find(
        (list) => list._id === q._id
      );

      let isMarked = false;
      if (foundDijawab && foundDijawab.isMarked) {
        isMarked = foundDijawab.isMarked;
      }

      no = no + 1;
      result.push({
        no,
        ...q,
        answer: foundDijawab ? foundDijawab.answer : "",
        isMarked,
      });
    }

    // console.log(result);
    return result;
  };

  handleSelectNumber = (num) => async (e) => {
    this.setState({
      selectedNumber: num.no,
      questionListVisible: false,
    });

    await mwvClient.mutate({
      mutation: UPDATE_TIMER,
      variables: {
        studentId: urlValues.studentId,
        packageId: urlValues.packageId,
      },
      fetchPolicy: "no-cache",
    });
    this.initiate();
  };

  questionNavigation = (nav) => async (e) => {
    if (nav === "Next") {
      window.scrollTo(0, 0);
      this.setState({
        selectedNumber: this.state.selectedNumber + 1,
      });

      await mwvClient.mutate({
        mutation: UPDATE_TIMER,
        variables: {
          studentId: urlValues.studentId,
          packageId: urlValues.packageId,
        },
        fetchPolicy: "no-cache",
      });
      this.initiate();
    } else {
      window.scrollTo(0, 0);
      this.setState({
        selectedNumber: this.state.selectedNumber - 1,
      });
      await mwvClient.mutate({
        mutation: UPDATE_TIMER,
        variables: {
          studentId: urlValues.studentId,
          packageId: urlValues.packageId,
        },
        fetchPolicy: "no-cache",
      });
      this.initiate();
    }
  };

  openQuestionList = async () => {
    this.initiate();
    this.setState({
      questionListVisible: true,
    });
  };

  toHistory = () => {
    urlValues = {
      ...urlValues,
      type: "history",
    };
    const url = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/cbt/cbt_subject_list",
      search: `?${url}`,
    });
  };

  renderPreview = (question) => {
    let q = question;
    q = q
      .split("[latex]")
      .join(
        "<math-field style='display: inline-block color:black; font-weight:bold; pointer-events:none' disabled=true>"
      );
    q = q.split("[/latex]").join("</math-field>");

    q = q.split("&").join("\\hspace{2}");
    q = q.split("|mathrm").join("\\mathrm");
    q = q.split("\\hline").join("----");

    return ReactHtmlParser(q);
  };

  // renderAnswers = (soal, xanswer, index) => {
  //   const listYangSudahDijawab = this.state.listYangSudahDijawab;
  //   let jawaban = answer.answers;
  //   //######   Math View ############
  //   // jawaban = jawaban.split("[latex]").join("<math-field disabled='true'>");
  //   // jawaban = jawaban.split("[/latex]").join("</math-field>");

  //   // jawaban = jawaban.split("&").join("\\hspace{2}");
  //   // jawaban = jawaban.split("|mathrm").join("\\mathrm");
  //   // jawaban = jawaban.split("\\hline").join("----");

  //   // if (jawaban.includes("math-field")) {
  //   //   jawaban = jawaban.split(" ").join("\\hspace{2}");
  //   // }

  //   //######## React Tex #########/

  //   jawaban = jawaban.split("[latex]").join("");
  //   jawaban = jawaban.split("[/latex]").join("");
  //   jawaban = jawaban.split("&").join("\\:");
  //   jawaban = jawaban.split("|mathrm").join("\\mathrm");
  //   jawaban = jawaban.split("\\hline").join("----");

  //   // console.log({ jawaban });
  //   // jawaban =
  //   //   jawaban +
  //   //   `<img src="http://drive.google.com/uc?export=view&id=1MnfHu1KigjsuKJyD6-Ym3KpghXtWBJbe"></img>`;
  //   // Ambil gambarnya dulu
  //   // var expression =
  //   //   /[-a-zA-Z0-9@:%.+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%+.~#?&//=]*)?/gi;
  //   // var regexImg = new RegExp(expression);

  //   var expression = /src\s*=\s*"([^"]+)"/;
  //   let regexImg = new RegExp(expression);
  //   regexImg = jawaban.match(regexImg) ? jawaban.match(regexImg)[1] : "";

  //   if (regexImg.includes("></img")) {
  //     regexImg = regexImg.split("></img>").join("");
  //     regexImg = regexImg.split('"').join("");
  //     if (jawaban.includes("<img")) {
  //       const startTagImg = jawaban.indexOf("<img");
  //       const endTagImg = jawaban.indexOf("</img>");

  //       let sliced = jawaban.substring(startTagImg, endTagImg + 6);
  //       jawaban = jawaban.replace(sliced, "");
  //     }
  //   } else if (regexImg.includes("<img")) {
  //     // console.log("Else...");
  //     const startTagImg = jawaban.indexOf("<img");
  //     const endTagImg = jawaban.indexOf('">');

  //     let sliced = jawaban.substring(startTagImg, endTagImg + 2);
  //     jawaban = jawaban.replace(sliced, "");
  //   }

  //   jawaban = jawaban.split("<p>").join("");
  //   jawaban = jawaban.split("</p>").join("");
  //   jawaban = jawaban.split(" ").join("\\:");

  //   //     test = str.substring(idx1, idx2+6)
  //   // console.log(str.replace(test, ""))

  //   let borderColor = "red";
  //   if (listYangSudahDijawab.length > 0) {
  //     const found = listYangSudahDijawab.find(
  //       (list) => list.qId === soal._id && list.alphabet === answer.alphabet
  //     );
  //     if (found) {
  //       borderColor = "#009432";
  //     }
  //   }
  //   // let test = "A. terletak di dalam lingkaran sebab (5-2)^{2}+(1+3)^{2}<25";

  //   // let latexString = `A. terletak di dalam lingkaran sebab (5-2)^{2}+(1+3)^{2}<25 pada gambar <img src="http://drive.google.com/uc?export=view&id=1MnfHu1KigjsuKJyD6-Ym3KpghXtWBJbe"></img>`;
  //   //<img src="http://drive.google.com/uc?export=view&id=1MnfHu1KigjsuKJyD6-Ym3KpghXtWBJbe"></img>

  //   return (
  //     <div
  //       className={`card card-block mb-2 ${index === 0 ? "mt-2" : ""}`}
  //       style={{
  //         borderColor,
  //         borderWidth: "2px",
  //         marginBottom: 10,
  //         paddingLeft: 5,
  //       }}
  //       onClick={this.handleAnswer(soal, answer)}
  //     >
  //       <div className="row">
  //         <div className="col-1 d-flex align-items-center">
  //           <b>{answer.alphabet}</b>
  //         </div>
  //         <div className="col-11 py-2 ">
  //           {/* <div
  //             className="container text-justify break-all"
  //             dangerouslySetInnerHTML={{
  //               __html: jawaban,
  //             }}
  //           /> */}
  //           <div>
  //             <Tex texContent={jawaban} />
  //             {regexImg ? (
  //               <img
  //                 src={regexImg}
  //                 className="img img-fluid d-block"
  //                 // style={{
  //                 //   height: 100,
  //                 //   width: 100,
  //                 // }}
  //               />
  //             ) : null}
  //           </div>

  //           {/* <math-field disabled="true" style={{ color: "#000" }}>
  //             {test}
  //           </math-field> */}
  //           {/* {ReactHtmlParser(jawaban)} */}
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  renderAnswers = (soal, answer, index) => {
    const listYangSudahDijawab = this.state.listYangSudahDijawab;
    let jawaban = answer.answers;

    const expression = /src\s*=\s*"([^"]+)"/;
    const pattern = new RegExp(expression);
    const regexImg = jawaban.match(pattern) ? jawaban.match(pattern)[1] : "";
    let isContainsLatex = false;
    if (!jawaban.includes("[latex]")) {
      jawaban = jawaban.replace(/<img/g, "<img class='img-fluid'");
      jawaban = jawaban.replace(/<p/g, "<p class='mb-0'");
    } else {
      isContainsLatex = true;
      jawaban = jawaban.split("[latex]").join("");
      jawaban = jawaban.split("[/latex]").join("");
      jawaban = jawaban.split("&").join("\\:");
      jawaban = jawaban.split("|mathrm").join("\\mathrm");
      jawaban = jawaban.split("\\hline").join("----");
      jawaban = jawaban.split("<p>").join("");
      jawaban = jawaban.split("</p>").join("");
    }
    // if (jawaban.includes("A.")) {
    //   jawaban = jawaban.split("[latex]").join("");
    //   jawaban = jawaban.split("[/latex]").join("");
    //   jawaban = jawaban.split("&").join("\\:");
    //   jawaban = jawaban.split("|mathrm").join("\\mathrm");
    //   jawaban = jawaban.split("\\hline").join("----");
    //   return <Tex texContent={jawaban} />;
    // }
    let borderColor = "red";
    if (listYangSudahDijawab.length > 0) {
      const found = listYangSudahDijawab.find(
        (list) => list._id === soal._id && list.answer === answer.alphabet
      );
      if (found) {
        borderColor = "#009432";
      }
    }
    return (
      <div
        className={`card card-block mb-2 ${index === 0 ? "mt-2" : ""}`}
        style={{
          borderColor,
          borderWidth: "2px",
          marginBottom: 10,
          paddingLeft: 5,
        }}
        onClick={this.handleAnswer(soal, answer)}
      >
        <div className="row">
          <div className="col-1 col-md-1 col-sm-1 d-flex align-items-center">
            <b>{answer.alphabet}</b>
          </div>
          <div className="col-10 col-md-11 col-sm-10 py-2 ">
            <div>
              {isContainsLatex ? (
                <Fragment>
                  <math-field
                    disabled
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      pointerEvents: "none",
                    }}
                  >
                    {jawaban}
                  </math-field>
                  {regexImg ? (
                    <img
                      alt={"Image"}
                      src={regexImg}
                      className="img img-fluid d-block"
                    />
                  ) : null}
                </Fragment>
              ) : (
                ReactHtmlParser(jawaban)
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  getTick = async (e) => {
    urlValues = queryString.parse(this.props.location.search);

    try {
      if (urlValues.PREFIX === "SMKIQON") {
        if (e.minutes <= 15 && e.hours === 0) {
          this.setState({
            showFinishButton: true,
          });
        } else {
          this.setState({
            showFinishButton: false,
          });
        }
      } else {
        this.setState({
          showFinishButton: true,
        });
      }
    } catch (err) {
      handleError(err);
    }
  };

  render() {
    const {
      studentAnswer,
      selectedNumber,
      timer,
      isStarted,
      autoStart,
      questionListVisible,
      digitalTime,
      student,
      listNumbers,
      showWarningVisible,
      showFinishButton,
    } = this.state;
    let daftarSoal = studentAnswer ? JSON.parse(studentAnswer.questions) : [];

    let soal =
      selectedNumber === 0 ? daftarSoal[0] : daftarSoal[selectedNumber - 1];

    urlValues = queryString.parse(this.props.location.search);

    if (studentAnswer && studentAnswer.status) {
      return (
        <View
          style={{
            flex: 1,
          }}
        >
          <div className="fixed-top">
            <HeaderBar
              title={
                <Text>
                  <i
                    className="fa fa-desktop"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {"CBT"}
                </Text>
              }
              right={
                <TouchableOpacity onPress={this.refresh}>
                  <Text
                    style={{
                      paddingRight: 12,
                      paddingLeft: 20,
                      color: "white",
                      fontSize: 16,
                      paddingVertical: 2,
                    }}
                  >
                    <i
                      className="fa fa-sync-alt"
                      style={{
                        fontSize: 20,
                        marginBottom: 5,
                      }}
                    />
                  </Text>
                </TouchableOpacity>
              }
            />

            <section
              className="card"
              style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
            >
              <div className="card-block">
                <View
                  style={{
                    padding: 20,
                  }}
                >
                  <div className="row">
                    <div className="col-3">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-9" style={{ paddingLeft: 10 }}>
                      <View>
                        {student ? (
                          <div>
                            <Text
                              style={{
                                fontSize: 18,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "200px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {student.name}
                            </Text>
                            <br />
                            <Text
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "200px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b>
                                {student.SchoolInformation
                                  ? student.SchoolInformation.name
                                  : ""}
                              </b>
                            </Text>
                            <br />
                            <Text
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "200px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b>
                                {student.Classroom
                                  ? student.Classroom.name
                                  : ""}
                              </b>
                            </Text>
                            <br />
                            <Text
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                width: "200px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <b>{student.status ? student.status : ""}</b>
                            </Text>
                          </div>
                        ) : null}
                      </View>
                      <div className="float-left text-left"></div>
                    </div>
                  </div>
                </View>
              </div>
            </section>
          </div>

          <BackgroundImage />

          <View
            style={{
              flex: 1,
              marginTop: 190,
              marginBottom: "100vh",
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <section
              className="card"
              style={{
                padding: 20,
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: 240, height: 240 }}
                    source={require("../../assets/success-badge.png")}
                  />
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    Paket Ini Telah Kamu Kerjakan!
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 20,
                    alignItems: "center",
                  }}
                  onClick={this.toHistory}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      color: "#2196f3",
                    }}
                  >
                    <b>( Lihat Riwayatmu Disini ! )</b>
                  </Text>
                </View>
              </div>
            </section>
          </View>
        </View>
      );
    } else if (studentAnswer && studentAnswer.Package) {
      return (
        <Fragment>
          <View
            style={{
              flex: 1,
            }}
          >
            <FormModal
              title={
                <span>
                  <i className="fa fa-exclamation-triangle" /> Peringatan
                </span>
              }
              onClose={this.confirmationStartCBT}
              visible={showWarningVisible}
            >
              <p>
                <h5>
                  Keluar selama CBT berlangsung, maka CBT akan disubmit secara
                  otomtatis!
                </h5>
              </p>
            </FormModal>
            <FormModal
              title={"Daftar Nomor Soal"}
              visible={questionListVisible}
              onClose={(e) =>
                this.setState({
                  questionListVisible: false,
                })
              }
            >
              <div className="row">
                {listNumbers.map((num) => (
                  <div className="col-3" style={{ marginBottom: 5 }}>
                    <button
                      className={`btn btn-sm btn-block`}
                      onClick={this.handleSelectNumber(num)}
                      style={{
                        backgroundColor: num.isMarked
                          ? "#FDD835"
                          : num.answer
                          ? "#43A047"
                          : "#E64A19",
                        color: "#fff",
                        fontWeight: "bold",
                      }}
                    >
                      {num.no}
                    </button>
                  </div>
                ))}
              </div>
            </FormModal>

            <FormModal
              title={
                <span>
                  <i className="fa fa-info-circle" /> CBT Info
                </span>
              }
              visible={this.state.openInfoVisible}
              onClose={(e) =>
                this.setState({
                  openInfoVisible: false,
                })
              }
            >
              <div className="row">
                <div className="col-3">
                  <View>
                    <Image
                      style={{
                        width: 65,
                        height: 65,
                        overflow: "hidden",
                        borderRadius: 50,
                      }}
                      source={
                        student && student.photoUrl
                          ? student.photoUrl
                          : require("../../assets/owl-laptop-study.png")
                      }
                    />
                  </View>
                </div>
                <div className="col-9">
                  <View
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "180px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <b>{student ? student.name : "-"}</b>
                    </Text>
                  </View>
                  <View
                    style={{
                      marginBottom: 5,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "180px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <b>
                        {student && student.SchoolInformation
                          ? student.SchoolInformation.name
                          : "-"}
                      </b>
                    </Text>
                  </View>

                  <View
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        width: "180px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Kelas:{" "}
                      <b>
                        {student && student.Classroom
                          ? student.Classroom.name
                          : "-"}
                      </b>
                    </Text>
                  </View>

                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <View
                      style={{
                        width: 30,
                      }}
                    >
                      <Text>TA</Text>
                    </View>
                    <View
                      style={{
                        width: 100,
                      }}
                    >
                      <Text>
                        : <b>{urlValues.academicYear}</b>
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                    }}
                  >
                    <View
                      style={{
                        width: 30,
                      }}
                    >
                      <Text>Smt</Text>
                    </View>
                    <View
                      style={{
                        width: 100,
                      }}
                    >
                      <Text>
                        : <b>{urlValues.semester}</b>
                      </Text>
                    </View>
                  </View>
                </div>
              </div>
            </FormModal>
            <div
              style={{
                position: "sticky",
                top: 0,
                right: 0,
                left: 0,
                zIndex: 1030,
              }}
            >
              <HeaderBar
                title={
                  <Text>
                    <i
                      className="fa fa-desktop"
                      style={{
                        fontSize: 20,
                      }}
                    />{" "}
                    {"CBT"}
                  </Text>
                }
              />

              <section className="card pt-3" style={{ paddingBottom: 10 }}>
                <div className="container">
                  <div className="row px-4">
                    <div className="col-2 px-0">Mapel</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>
                        {studentAnswer && studentAnswer.Subject
                          ? studentAnswer.Subject.name
                          : "-"}
                      </b>
                    </div>
                  </div>
                  <div className="row px-4">
                    <div className="col-2 px-0">Paket</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>
                        {studentAnswer && studentAnswer.Package
                          ? studentAnswer.Package.name
                          : "-"}
                      </b>
                    </div>
                  </div>
                  <div className="row px-4">
                    <div className="col-2 px-0">Waktu</div>
                    <div className="col-1 px-0">:</div>
                    <div
                      className="col-9 px-0 d-flex align-items-center justify-content-end"
                      style={{
                        fontSize: 12,
                      }}
                    >
                      <b>
                        <Countdown
                          date={this.state.today}
                          ref={this.countDownRef}
                          onTick={this.getTick}
                          onComplete={this.handleSubmitQuestion("byCountDown")}
                        />
                      </b>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end px-2">
                    {showFinishButton ? (
                      <button
                        className="btn btn-sm btn-danger"
                        onClick={this.handleSubmitQuestion("direct")}
                      >
                        <i className="fa fa-flag-checkered" /> Finish
                      </button>
                    ) : null}
                  </div>
                  <div className="d-flex justify-content-center mb-2">
                    Soal No. {selectedNumber}
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-8">
                      <button
                        className="btn btn-sm btn-primary btn-block"
                        onClick={this.openQuestionList}
                      >
                        <i className="fa fa-question-circle" /> Daftar Soal
                      </button>
                    </div>
                    <div className="col-3 px-0">
                      <button
                        className="btn btn-sm btn-success"
                        onClick={(e) =>
                          this.setState({
                            openInfoVisible: true,
                          })
                        }
                      >
                        <i className="fa fa-info-circle" /> Info
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <BackgroundImage />

            <View
              style={{
                marginTop: 5,
                paddingLeft: 5,
                paddingRight: 5,
                marginBottom: "100vh",
              }}
            >
              <section
                className="card"
                style={{
                  padding: 10,
                  minHeight: "100vh",
                }}
              >
                {soal ? (
                  <div>
                    {this.renderPreview(soal.question)}
                    {soal.videoUrl && (
                      <video height="200" controls style={{ width: "100%" }}>
                        <source src={soal.videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    )}
                    {soal.audioUrl && (
                      <audio controls>
                        <source src={soal.audioUrl} type="audio/mpeg" />
                        Your browser does not support the audio tag.
                      </audio>
                    )}
                    {/* {ReactHtmlParser(soal.question)} */}
                    {soal.listAnswers.map(
                      (answer, index) => this.renderAnswers(soal, answer, index)
                      // {
                      //   return (
                      //     <div
                      //       className={`card card-block`}
                      //       style={{
                      //         borderColor:
                      //           soal.answer === answer.alphabet ? "#009432" : "red",
                      //         borderWidth: "2px",
                      //         marginBottom: 10,
                      //         paddingLeft: 5,
                      //       }}
                      //       onClick={this.handleAnswer(soal, answer)}
                      //     >
                      //       <a>
                      //         <div className="d-flex flex-row">
                      //           <div className="p-2 d-flex align-items-center justify-content-center ">
                      //             <b>{answer.alphabet}</b>
                      //           </div>
                      //           <div className="p-3 d-flex flex-column align-items-center">
                      //             <p align="justify" style={{ margin: 0 }}>
                      //               {answer.answers}
                      //             </p>
                      //             {answer.imageUrl ? (
                      //               <img
                      //                 src={answer.imageUrl}
                      //                 className="img-fluid"
                      //               />
                      //             ) : null}
                      //           </div>
                      //         </div>
                      //       </a>
                      //     </div>
                      //   );
                      // }
                    )}
                  </div>
                ) : null}
              </section>
            </View>
            <div>.</div>
          </View>
          <div style={{ position: "fixed", right: 0, left: 0, bottom: 0 }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
              }}
            >
              <button
                type="button"
                onClick={
                  selectedNumber === 1 ? null : this.questionNavigation("Prev")
                }
                style={{
                  zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  // boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-lg btn-primary btn-block"
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 16,
                  }}
                >
                  <i className="fa fa-backward" /> Prev
                </Text>
              </button>
              <button
                type="button"
                onClick={this.markedQuestion(soal)}
                style={{
                  zIndex: 9999,
                  height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  marginTop: 0,
                  // boxShadow: "0px -2px 5px 2px #ddd",
                  color: "#fff",
                  fontSize: "14px",
                }}
                className="btn btn-lg btn-warning btn-block"
                //   className={`btn btn-lg
                // ${
                //   this.state.listYangSudahDijawab.find(
                //     (q) => q.qId === soal._id && q.isMarked
                //   )
                //     ? "btn-warning"
                //     : "btn-success"
                // }
                //  btn-block`}
              >
                <b>
                  <i
                    className={`${
                      this.state.listYangSudahDijawab.find(
                        (q) => q._id === soal._id && q.isMarked
                      )
                        ? "fa fa-exclamation-triangle" //fa fa-exclamation-triangle
                        : ""
                    }`}
                  />{" "}
                  Ragu Ragu
                </b>
              </button>
              <button
                type="button"
                onClick={
                  selectedNumber === daftarSoal.length
                    ? null
                    : this.questionNavigation("Next")
                }
                style={{
                  margin: 0,
                  zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  // boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-lg btn-primary btn-block"
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 16,
                  }}
                >
                  <i className="fa fa-forward" /> Next
                </Text>
              </button>
            </View>
            {/* <button
          type="button"
          onClick={this.handleSubmitQuestion}
          style={{
            zIndex: 9999,
            backgroundColor: "#ff3d00",
            height: 50,
            borderWidth: 0,
            borderRadius: 0,
            boxShadow: "0px -2px 5px 2px #ddd",
          }}
          className="btn btn-lg btn-primary btn-block"
        >
          <Text
            style={{
              color: "white",
              fontSize: 20,
            }}
          >
            <i className="fa fa-flag-checkered" /> Finish
          </Text>
        </button> */}
          </div>
        </Fragment>
      );
    } else {
      return <div></div>;
    }
  }

  handleSubmitQuestion = (type) => async (e) => {
    // if (e) e.preventDefault();

    try {
      // let listJawaban = await localforage.getItem(
      //   `yangSudahDijawab_${urlValues.studentId}_${urlValues.subjectId}_${urlValues.packageId}`,
      //   (err, val) => {
      //     if (err !== null) {
      //       return null;
      //     }
      //     return val;
      //   }
      // );

      let listJawaban = this.state.listYangSudahDijawab;

      let confirmed = false;
      if (type === "byCountDown") {
        confirmed = true;
      } else if (type === "direct") {
        if (
          window.confirm("Apakah anda yakin untuk menyelesaikan ujian ini?")
        ) {
          confirmed = true;
        } else {
          confirmed = false;
        }
      }
      // if (type === "direct") {
      //   if (
      //     window.confirm("Apakah anda yakin untuk menyelesaikan ujian ini?")
      //   ) {
      //     if (listJawaban && listJawaban.filter((j) => j.isMarked).length > 0) {
      //       if (
      //         window.confirm(
      //           `Terdapat ${
      //             listJawaban.filter((j) => j.isMarked).length
      //           } soal ragu - ragu, lanjutkan ? `
      //         )
      //       ) {
      //         confirmed = true;
      //       }
      //     } else {
      //       confirmed = true;
      //     }
      //   }
      // } else if (type === "byCountDown") {
      //   confirmed = true;
      // }

      if (confirmed === true) {
        // if (listJawaban) {
        //   for (const jawaban of JSON.parse(listJawaban)) {
        //     await mwvClient.mutate({
        //       mutation: UPDATE_ANSWER,
        //       variables: {
        //         studentId: urlValues.studentId,
        //         packageId: urlValues.packageId,
        //         academicYear: urlValues.academicYear,
        //         subjectId: urlValues.subjectId,
        //         questionId: jawaban.qId,
        //         answer: jawaban.alphabet,
        //       },
        //       fetchPolicy: "no-cache",
        //     });
        //   }
        // }

        await mwvClient.mutate({
          mutation: SUBMITED_ANSWER,
          variables: {
            studentId: urlValues.studentId,
            packageId: urlValues.packageId,
            academicYear: urlValues.academicYear,
            subjectId: urlValues.subjectId,
          },
          fetchPolicy: "no-cache",
        });

        this.initiate();

        addNotification({
          message: "Jawaban telah disimpan",
          level: "success",
        });
        this.setState({
          isStarted: false,
        });

        clearInterval(this.timer);

        setTimeout(() => this.initiate(), 1500);
      }
    } catch (err) {
      handleError(err);
    }
  };

  handleAnswer = (soal, answer) => async (e) => {
    if (e) e.preventDefault();

    const { studentAnswer } = this.state;
    const { listYangSudahDijawab } = this.state;

    let daftarSoal = studentAnswer ? JSON.parse(studentAnswer.questions) : [];

    const foundList = listYangSudahDijawab.find((l) => l._id === soal._id);

    try {
      if (!foundList) {
        let tmp = [
          ...listYangSudahDijawab,
          {
            _id: soal._id,
            alphabet: answer.alphabet,
            isMarked: soal.isMarked ? soal.isMarked : false,
          },
        ];
        this.setState({
          listYangSudahDijawab: tmp,
        });

        await mwvClient.mutate({
          mutation: UPDATE_ANSWER,
          variables: {
            studentId: urlValues.studentId,
            packageId: urlValues.packageId,
            academicYear: urlValues.academicYear,
            subjectId: urlValues.subjectId,
            questionId: soal._id,

            answer: answer.alphabet,
            isMarked: soal.isMarked ? soal.isMarked : false,
          },
          fetchPolicy: "no-cache",
        });
        this.initiate();
      } else {
        let tmp = listYangSudahDijawab.map((list) =>
          list._id !== soal._id
            ? list
            : {
                _id: soal._id,
                alphabet: answer.alphabet,
                isMarked: list.isMarked ? list.isMarked : false,
              }
        );
        this.setState({
          listYangSudahDijawab: tmp,
        });
        await mwvClient.mutate({
          mutation: UPDATE_ANSWER,
          variables: {
            studentId: urlValues.studentId,
            packageId: urlValues.packageId,
            academicYear: urlValues.academicYear,
            subjectId: urlValues.subjectId,
            questionId: soal._id,

            answer: answer.alphabet,
            isMarked: soal.isMarked ? soal.isMarked : false,
          },
          fetchPolicy: "no-cache",
        });
        this.initiate();
      }

      if (
        daftarSoal.length > 1 &&
        daftarSoal.length > this.state.selectedNumber
      ) {
        this.setState({
          selectedAnswer: {
            alphabet: answer.alphabet,
            _id: soal._id,
          },
          selectedNumber: this.state.selectedNumber + 1,
        });
      } else {
        this.setState({
          selectedAnswer: {
            alphabet: answer.alphabet,
            _id: soal._id,
          },
          selectedNumber: 1,
        });
      }
    } catch (err) {
      handleError(err);
    }
  };

  markedQuestion = (soal) => async (e) => {
    if (e) e.preventDefault();
    const { listYangSudahDijawab } = this.state;

    const foundList = listYangSudahDijawab.find((l) => l._id === soal._id);

    try {
      if (!foundList) {
        let tmp = [
          ...listYangSudahDijawab,
          {
            _id: soal._id,
            alphabet: soal.answer ? soal.answer : "",
            isMarked: true,
          },
        ];
        this.setState({
          listYangSudahDijawab: tmp,
        });

        await mwvClient.mutate({
          mutation: UPDATE_ANSWER,
          variables: {
            studentId: urlValues.studentId,
            packageId: urlValues.packageId,
            academicYear: urlValues.academicYear,
            subjectId: urlValues.subjectId,
            questionId: soal._id,

            answer: soal.answer,
            isMarked: true,
          },
          fetchPolicy: "no-cache",
        });
        this.initiate();
      } else {
        // console.log("Sebelum", listYangSudahDijawab);
        let tmp = listYangSudahDijawab.map((list) =>
          list._id !== soal._id
            ? list
            : {
                _id: soal._id,
                alphabet: list.alphabet ? list.alphabet : "",
                isMarked: !list.isMarked,
              }
        );
        this.setState({
          listYangSudahDijawab: tmp,
        });

        await mwvClient.mutate({
          mutation: UPDATE_ANSWER,
          variables: {
            studentId: urlValues.studentId,
            packageId: urlValues.packageId,
            academicYear: urlValues.academicYear,
            subjectId: urlValues.subjectId,
            questionId: soal._id,

            answer: soal.answer,
            isMarked: soal.isMarked === false ? true : false,
          },
          fetchPolicy: "no-cache",
        });
        this.initiate();
      }
    } catch (err) {
      handleError(err);
    }
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUESTION_LISTS_QUERIES = gql`
  query listQuestions(
    $studentId: String!
    $packageId: String!
    $academicYear: String!
    $subjectId: String!
  ) {
    studentAnswer(
      packageId: $packageId
      academicYear: $academicYear
      subjectId: $subjectId
      studentId: $studentId
    ) {
      _id
      Package {
        _id
        name
        time
      }
      Subject {
        _id
        name
      }
      questions
      status
      startedAt
      endAt
      isMarked
    }
    studentById(studentId: $studentId) {
      _id
      name
      Classroom {
        _id
        name
        academicYear
      }
      SchoolInformation {
        _id
        name
      }
      status
    }
    packageById(packageId: $packageId) {
      _id
      name
    }
  }
`;

const UPDATE_ANSWER = gql`
  mutation updateAnswer(
    $studentId: String
    $packageId: String
    $academicYear: String
    $subjectId: String
    $questionId: String
    $answer: String
    $isMarked: Boolean
  ) {
    updateAnswer(
      studentId: $studentId
      packageId: $packageId
      academicYear: $academicYear
      subjectId: $subjectId
      questionId: $questionId
      answer: $answer
      isMarked: $isMarked
    )
  }
`;

const SUBMITED_ANSWER = gql`
  mutation submitedAnswer(
    $studentId: String
    $packageId: String
    $academicYear: String
    $subjectId: String
  ) {
    submitedAnswer(
      studentId: $studentId
      packageId: $packageId
      academicYear: $academicYear
      subjectId: $subjectId
    )
  }
`;

const UPDATE_TIMER = gql`
  mutation updateTimer($packageId: String!, $studentId: String!) {
    updateTimer(packageId: $packageId, studentId: $studentId)
  }
`;

export default withRouter(CBTStart);
