import React, { PureComponent } from "react";
import { View, TouchableOpacity, Image, Text, Linking } from "react-native";
import dayjs from "dayjs";

class BookBorrowingTransaction extends PureComponent {
  state = {
    numberOfLines: 3,
    bufferedAudio: "",
    audioPlayState: "STOP",
  };
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, BookBorrowingTransaction, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        Student,
        BookBorrowingTransaction,
        _createdAt,
      });
    }
  };
  render() {
    const { BookBorrowingTransaction, _createdAt } = this.props;

    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              marginHorizontal: 5,
              paddingBottom: 4,
              borderRadius: 2,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 2,
              elevation: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  source={require("../../assets/buku_in.png")}
                />
              </View>
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    alignItems: "left",

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 13,
                      fontWeight: "bold",
                      marginTop: 2,
                    }}
                  >
                    <i
                      className="fa fa-check-circle"
                      style={{
                        color: "#27ae60",
                      }}
                    />{" "}
                    PEMINJAMAN BUKU
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#00a8ff",
                      marginTop: 2,
                    }}
                  >
                    {dayjs(_createdAt).format("dddd, DD MMMM YYYY HH:mm")}
                    {/* {format(_createdAt, "dddd, DD MMMM YYYY   HH:mm", {
                      locale,
                    })} */}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    marginBottom: 5,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Buku:{" "}
                    {BookBorrowingTransaction.bookName
                      ? BookBorrowingTransaction.bookName
                      : ""}
                  </Text>
                </View>
                <View
                  style={{
                    // flex: 1,
                    // alignItems: "flex-start",
                    marginBottom: 15,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Oleh: {BookBorrowingTransaction.studentName}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#2196f3",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    <i className="fa fa-calendar" /> Tgl. Pinjam{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#2196f3",
                      marginEnd: 12,
                    }}
                  >
                    {BookBorrowingTransaction.borrowDate}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#EF5350",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    <i className="fa fa-calendar" /> Tenggat Pinjam{" "}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#EF5350",
                      marginEnd: 12,
                    }}
                  >
                    {BookBorrowingTransaction.dueDate}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default BookBorrowingTransaction;
