import React, { Component, Fragment } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import reactStringReplace from "react-string-replace";

import queryString from "query-string";
import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import HeaderBar from "../components/HeaderBar";
import BackgroundImage from "../components/BackgroundImage";
import { FormModal } from "../components/Modal";
import { showLoadingSpinner, hideLoadingSpinner } from "../App";
import { handleError } from "../libs/errors";
const BOARD_MESSAGE_LIMIT_REQUEST = 5;

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const SubjectModal = ({ subjects, handleSubject, type }) => (
  <div>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginBottom: 10,
      }}
    >
      <View style={{ width: 250 }} />
      <View style={{ width: 80 }}>
        {type === "assessment" ? <Text>Jml. Tugas</Text> : null}
      </View>
    </View>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {subjects.map((sub) => (
        <div key={sub._id}>
          <TouchableOpacity onPress={handleSubject(sub, type)}>
            <section
              className="card"
              style={{
                padding: 10,
                backgroundColor:
                  sub.countAssignment === 0 || type === "feed"
                    ? "#fff"
                    : "#ef6c00",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color:
                          sub.countAssignment === 0 || type === "feed"
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      <i className="fa fa-book-open" /> {sub.name}
                    </Text>
                  </View>
                  <View style={{ width: 50, alignItems: "center" }}>
                    {type === "assessment" ? (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color: sub.countAssignment === 0 ? "#000" : "#fff",
                        }}
                      >
                        {sub.countAssignment > 99
                          ? "99+"
                          : sub.countAssignment === 0
                          ? "-"
                          : sub.countAssignment}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </div>
      ))}
    </div>
  </div>
);

const ExtracurricularModal = ({ extracurriculars, handleExtra, type }) => (
  <div>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        marginBottom: 10,
      }}
    >
      <View style={{ width: 250 }} />
      <View style={{ width: 80 }}>
        {type === "assessment" ? <Text>Jml. Tugas</Text> : null}
      </View>
    </View>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {extracurriculars.map((extra) => (
        <div key={extra._id}>
          <TouchableOpacity onPress={handleExtra(extra, type)}>
            <section
              className="card"
              style={{
                padding: 10,
                backgroundColor:
                  extra.countClassworkExtra === 0 || type === "feed"
                    ? "#fff"
                    : "#ef6c00",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <View style={{ width: 250 }}>
                    <Text
                      style={{
                        fontWeight: "bold",
                        color:
                          extra.countClassworkExtra === 0 || type === "feed"
                            ? "#000"
                            : "#fff",
                      }}
                    >
                      <i className="fa fa-book-open" /> {extra.name}
                    </Text>
                  </View>
                  <View style={{ width: 50, alignItems: "center" }}>
                    {type === "assessment" ? (
                      <Text
                        style={{
                          fontWeight: "bold",
                          color:
                            extra.countClassworkExtra === 0 ? "#000" : "#fff",
                        }}
                      >
                        {extra.countClassworkExtra > 99
                          ? "99+"
                          : extra.countClassworkExtra === 0
                          ? "-"
                          : extra.countClassworkExtra}
                      </Text>
                    ) : null}
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>
        </div>
      ))}
    </div>
  </div>
);

class BoardMessages extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    filterModalStudentVisible: false,
    boardMessagesList: [],

    subjectList: [],
    subjectModalVisible: false,

    extracurricularList: [],
    extracurricularModalVisible: false,
    isLoading: true,

    extraModalType: "",
    subjectModalType: "",

    allBoardMessages: [],
    currentPage: 0,
    pages: 1,
    hasMore: true,
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });
    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/papan_kelas",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&classroomId=${urlValues.classroomId}&academicYear=${urlValues.academicYear}`,
      });

      const res = await mwvClient.query({
        query: BOARD_MESSAGES,
        variables: {
          classroomId: urlValues.classroomId,
          studentId: urlValues.studentId,
          academicYear: urlValues.academicYear,
        },
      });

      this.setState({
        boardMessagesList: res.data.allBoardMessages,
        subjectList: res.data.classroomSubject,
        extracurricularList: res.data.countClassworkExtracurricular,
      });
    }
    this.fetchAllBoardMessages();
    // else {
    //   this.props.history.replace({
    //     pathname: "/classroom_list",
    //     search: `?sessionId=${accountSession._id}&studentId=${
    //       students[0] ? students[0]._id : ""
    //     }&PREFIX=${students[0] ? students[0].PREFIX : ""}`,
    //   });
    // }
    this.setState({
      allStudents: [...students],
      isLoading: false,
    });
  };

  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  openFilterStudentModal = () => {
    this.setState({
      filterModalStudentVisible: true,
      filterModalVisible: false,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/classroom_list",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
      filterModalStudentVisible: false,
    });
  };

  closeFilterStudentModalVisible = () => {
    this.setState({
      filterModalVisible: true,
      filterModalStudentVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  visitClasswork = (message) => (e) => {
    try {
      if (message.startTime) {
        const startDate = message.startDate;
        const startTime = message.startTime;

        const timeString = `${startDate} ${startTime}`;
        const currentTime = dayjs().format("YYYY-MM-DD HH:mm");

        const different = dayjs(currentTime).diff(timeString, "minute");

        if (different < 0) {
          throw {
            message: `Tidak diizinkan membuka sebelum tanggal ${startDate} jam ${startTime}`,
          };
        }

        let url =
          this.props.location.search +
          `&assessmentId=${message._id}&teacherId=${
            message.Teacher ? message.Teacher._id : "-"
          }`;

        this.props.history.replace({
          pathname:
            message.type === "CLASSWORK" ? "/classwork" : "/classwork_ekstra",
          search: url,
        });
      } else {
        let url =
          this.props.location.search +
          `&assessmentId=${message._id}&teacherId=${
            message.Teacher ? message.Teacher._id : "-"
          }`;

        this.props.history.replace({
          pathname:
            message.type === "CLASSWORK" ? "/classwork" : "/classwork_ekstra",
          search: url,
        });
      }
    } catch (err) {
      handleError(err);
    }
  };

  handleSubject = (subject, type) => (e) => {
    urlValues = queryString.parse(this.props.location.search);

    urlValues = {
      ...urlValues,
      subjectId: subject._id,
      subjectName: subject.name,
      semester: urlValues.semester ? urlValues.semester : defaultSemester(),
    };

    if (type === "assessment") {
      this.props.history.replace({
        pathname: "/daftar_tugas",
        search: `?${queryString.stringify(urlValues)}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/daftar_feed",
        search: `?${queryString.stringify(urlValues)}`,
      });
    }
  };

  openSubject = (type) => (e) => {
    this.setState({
      subjectModalVisible: true,
      filterModalVisible: false,
      subjectModalType: type,
    });
  };

  openExtra = (type) => (e) => {
    this.setState({
      extracurricularModalVisible: true,
      filterModalVisible: false,
      extraModalType: type,
    });
  };

  handleExtra = (extracurricular, type) => (e) => {
    urlValues = queryString.parse(this.props.location.search);

    urlValues = {
      ...urlValues,
      extracurricularId: extracurricular._id,
      extracurricularName: extracurricular.name,
      semester: defaultSemester(),
    };
    if (type === "assessment") {
      this.props.history.replace({
        pathname: "/daftar_tugas_ekstra",
        search: `?${queryString.stringify(urlValues)}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/daftar_feed_ekstra",
        search: `?${queryString.stringify(urlValues)}`,
      });
    }
  };

  fetchAllBoardMessages = async () => {
    showLoadingSpinner();
    const { currentPage } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    try {
      var result = await mwvClient.query({
        query: ALL_BOARD_MESSAGES,
        variables: {
          ...urlValues,
          limit: BOARD_MESSAGE_LIMIT_REQUEST,
          page: currentPage,
        },
        fetchPolicy: "network-only",
      });
      const allBoardMessages = result.data.allBoardMessages.rows;
      const pages = result.data.allBoardMessages.pages;
      const hasMore =
        this.state.allBoardMessages.length < allBoardMessages.length;
      // console.log({
      //   allBoardMessages: allBoardMessages,
      //   hasMore,
      //   pages,
      // });
      this.setState({
        allBoardMessages,
        currentPage: currentPage + 1,
        pages,
        hasMore,
      });
    } catch (err) {
      handleError(err);
    }
    this.setState({
      isLoading: false,
    });
    hideLoadingSpinner();
  };

  fetchMoreData = async () => {
    let { currentPage, pages } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    try {
      currentPage = currentPage + 1;
      const result = await mwvClient.query({
        query: ALL_BOARD_MESSAGES,
        variables: {
          ...urlValues,
          page: currentPage - 1,
          limit: BOARD_MESSAGE_LIMIT_REQUEST,
        },
        fetchPolicy: "network-only",
      });
      const allBoardMessages = [
        ...this.state.allBoardMessages,
        ...result.data.allBoardMessages.rows,
      ];
      const hasMore = currentPage < pages;
      this.setState({
        allBoardMessages,
        currentPage,
        hasMore,
      });
    } catch (err) {
      handleError(err);
    }
  };

  handleDownloadFile = (link) => (e) => {
    if (e) e.preventDefault();
    window.location.href = link;
    // let fileName = link.split("/");
    // fileName = fileName[fileName.length - 1];
    // window.ReactNativeWebView &&
    //   window.ReactNativeWebView.postMessage(
    //     JSON.stringify({ link, fileName, type: "DOWNLOAD_FILE" })
    //   );
  };

  renderFeed = (ms) => {
    return (
      <Fragment>
        <h5 style={{ margin: 0 }} className="text-right">
          Feed
        </h5>
        <hr
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        />
        <div className="form-group" style={{ margin: 0 }}>
          <div className="row">
            <div className="col-2" style={{ paddingRight: 0, paddingLeft: 10 }}>
              <Image
                style={{ width: 40, height: 40 }}
                source={require("../assets/user-dummy.jpg")}
              />
            </div>
            <div className="col-10">
              <b>
                {ms.Teacher ? ms.Teacher.name : ""} - {ms.title}
              </b>{" "}
              <br />
              <br />
              <div
                style={{
                  marginBottom: 0,
                }}
              >
                {ms.Subject ? (
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Mapel : <b>{ms.Subject.name}</b>
                  </p>
                ) : ms.Extracurricular ? (
                  <p
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Ekstra : <b>{ms.Extracurricular.name}</b>
                  </p>
                ) : null}
              </div>
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                {reactStringReplace(
                  ms.description,
                  /(https?:\/\/\S+)/g,
                  (match, i) => (
                    <a
                      key={i}
                      target="_blank"
                      rel="noopener noreferrer"
                      href={match}
                    >
                      {match}
                    </a>
                  )
                )}
              </p>
              {/* <p
                style={{
                  marginBottom: 0,
                }}
              >
                {ms.description}
              </p> */}
              <br />
              <p
                style={{
                  marginBottom: 0,
                }}
              >
                Link to File:{" "}
                {ms.attachmentFileUrl ? (
                  <>
                    {ms.attachmentFileUrl.includes("stspace") ||
                    ms.attachmentFileUrl.includes("localhost") ? (
                      <a
                        href="#"
                        onClick={this.handleDownloadFile(ms.attachmentFileUrl)}
                      >
                        {`${ms.attachmentFileUrl.slice(0, 30)}...`}
                      </a>
                    ) : (
                      <a href={ms.attachmentFileUrl}>
                        {`${ms.attachmentFileUrl.slice(0, 30)}...`}
                      </a>
                    )}
                  </>
                ) : (
                  "-"
                )}
              </p>
            </div>
          </div>
          <hr
            style={{
              marginBottom: 5,
              marginTop: 10,
            }}
          />
        </div>
      </Fragment>
    );
  };

  renderClasswork = (ms) => {
    return (
      <Fragment>
        <h5 style={{ margin: 0 }} className="text-right">
          Tugas / Classwork
        </h5>
        <hr
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
        />
        <div
          style={{
            marginBottom: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            <View>
              <Image
                style={{
                  width: 65,
                  height: 65,
                  overflow: "hidden",
                  borderRadius: 50,
                }}
                source={
                  ms.Teacher && ms.Teacher.photoUrl
                    ? ms.Teacher && ms.Teacher.photoUrl
                    : require("../assets/owl-laptop-study.png")
                }
              />
            </View>
            <View
              style={{
                paddingLeft: 10,
                flex: 1,
                marginBottom: 15,
              }}
            >
              <Text
                style={{
                  fontSize: "16px",
                  marginBottom: 10,
                }}
              >
                {ms.type === "CLASSWORK" ? "Guru" : "Pembina"}:{" "}
                <b>{ms.Teacher ? ms.Teacher.name : ""}</b>
              </Text>

              {ms.type === "CLASSWORK" ? (
                <Text>
                  Mapel: <b>{ms.Subject ? ms.Subject.name : ""}</b>
                </Text>
              ) : (
                <Text>
                  Ekstrakurikuler:{" "}
                  <b>{ms.Extracurricular ? ms.Extracurricular.name : ""}</b>
                </Text>
              )}

              <Text
                style={{
                  marginTop: 5,
                }}
              >
                Tgl: <b>{dayjs(ms._createdAt).format("YYYY-MM-DD - HH:mm")}</b>
              </Text>

              <Text style={{ marginTop: 20, marginBottom: 10 }}>
                Tugas: <b>{ms.title}</b>
              </Text>
              <Text style={{ marginBottom: 5 }}>Deskripsi:</Text>
              <Text>
                <p
                  align="justify"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  {ms.description}
                </p>
              </Text>
              <Text
                style={{
                  color: "#2196f3",
                }}
              >
                <b>{ms.isSubmitted ? "Tugas Diserahkan" : ""}</b>
              </Text>
            </View>
          </View>
          <View
            style={{
              alignItems: "flex-end",
            }}
          >
            <TouchableOpacity onPress={this.visitClasswork(ms)}>
              <button className="btn btn-sm btn-primary">
                <i className="fa fa-sign-in-alt" /> Visit
              </button>
            </TouchableOpacity>
          </View>
          <hr
            style={{
              marginTop: 5,
              marginBottom: 0,
              // height: "1px",
              // borderTop: "1px solid",
            }}
          />
        </div>
      </Fragment>
    );
  };

  renderContent = (item, index) => {
    if (item.type === "FEED") {
      return this.renderFeed(item);
    } else if (item.type === "EXTRA_CLASSWORK" || item.type === "CLASSWORK") {
      return this.renderClasswork(item);
    }
  };

  render() {
    const {
      allStudents,
      boardMessagesList,
      subjectList,
      subjectModalVisible,
      extracurricularList,
      extracurricularModalVisible,
      isLoading,
      extraModalType,
      subjectModalType,
      allBoardMessages,
    } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    // console.log(boardMessagesList, subjectList);
    // const feedMessages = boardMessagesList.filter((m) => m.type === "FEED");
    // const classworkMessages = boardMessagesList.filter(
    //   (m) => m.type === "CLASSWORK" || m.type === "EXTRA_CLASSWORK"
    // );

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-filter" /> Filter
            </span>
          }
          visible={this.state.filterModalVisible}
          onClose={this.closeFilterModalVisible}
          size="md"
        >
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              marginBottom: 50,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                marginBottom: 10,
              }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#9c27b0",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "200px",
                }}
                onClick={this.openSubject("assessment")}
              >
                <i className="fa fa-book-open" /> Mata Pelajaran
              </button>
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#9c27b0",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "100px",
                }}
                onClick={this.openSubject("feed")}
              >
                <i className="fa fa-book-open" /> Materi
              </button>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#43a047",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "200px",
                }}
                onClick={this.openExtra("assessment")}
              >
                <i className="fa fa-basketball-ball" /> Ekstrakurikuler
              </button>
              <button
                className="btn btn-sm"
                style={{
                  backgroundColor: "#43a047",
                  color: "#fff",
                  fontWeight: "bold",
                  width: "100px",
                }}
                onClick={this.openExtra("feed")}
              >
                <i className="fa fa-basketball-ball" /> Materi
              </button>
            </View>
          </View>
          <button
            // className="btn btn-block"
            type="button"
            onClick={this.openFilterStudentModal}
            style={{
              backgroundColor: "#00a8ff",
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa Lain"}
            </Text>
          </button>
        </FormModal>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalStudentVisible}
          size="md"
          onClose={this.closeFilterStudentModalVisible}
          // hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-book-open" />{" "}
              {subjectModalType === "assessment"
                ? "Mata Pelajaran"
                : "Feed/Materi"}
            </span>
          }
          visible={subjectModalVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
          onClose={(e) => {
            this.setState({
              subjectModalVisible: false,
              filterModalVisible: true,
            });
          }}
        >
          <SubjectModal
            subjects={subjectList}
            handleSubject={this.handleSubject}
            type={subjectModalType}
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-basketball-ball" />{" "}
              {extraModalType === "assessment"
                ? "Ekstrakurikuler"
                : "Feed/Materi"}
            </span>
          }
          visible={extracurricularModalVisible}
          size="md"
          // onSubmit={this.handleApplyFilter}
          onClose={(e) => {
            this.setState({
              extracurricularModalVisible: false,
              filterModalVisible: true,
            });
          }}
        >
          <ExtracurricularModal
            extracurriculars={extracurricularList}
            handleExtra={this.handleExtra}
            type={extraModalType}
          />
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"School Space"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="btn btn-sm btn-block"
                style={{
                  zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  // height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  boxShadow: "0px -2px 5px 2px #ddd",
                  // width: "200px",
                }}
                onClick={!isLoading ? this.openFilterModal : null}
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                >
                  <i className="fa fa-filter" /> {"Filter"}
                </Text>
              </button>
              {/* <button
                className="btn btn-sm btn-block"
                // type="button"
                onClick={!isLoading ? this.openFilterModal : null}
                style={{
                  zIndex: 9999,
                  backgroundColor: "#00a8ff",
                  // height: 50,
                  borderWidth: 0,
                  borderRadius: 0,
                  boxShadow: "0px -2px 5px 2px #ddd",
                }}
                className="btn btn-lg btn-primary btn-block"
              >
                <Text
                  style={{
                    color: "white",
                    fontSize: 20,
                  }}
                >
                  <i className="fa fa-filter" /> {"Filter"}
                </Text>
              </button> */}
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            // flex: 1,
            marginTop: "30vh",
            // flexDirection: "column",
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 25,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-chalkboard" /> {"Papan Kelas"}
            </Text>
          </View>
        </View>

        {isLoading ? (
          <View
            style={{
              flex: 1,
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              style={{
                width: 240,
                height: 240,
                resizeMode: "contain",
                opacity: 0.8,
              }}
              source={require("../assets/teacher-chalkboard.png")}
            />
            <Text
              style={{
                fontSize: 17,
                color: "#737373",
                textAlign: "center",
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
            >
              <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
              papan kelas Anda
            </Text>
          </View>
        ) : (
          <View
            style={{
              paddingLeft: 8,
              paddingRight: 8,
              marginBottom: "300px",
              // position: "relative",
            }}
          >
            <section
              className="card"
              style={{
                padding: 20,
                borderRadius: 10,
              }}
            >
              <InfiniteScroll
                dataLength={allBoardMessages.length} //This is important field to render the next data
                next={this.fetchMoreData}
                hasMore={this.state.hasMore}
                loader={
                  <h4 className="text-center my-4 text-info">
                    <i className="fa fa-circle-notch fa-spin" /> Loading
                  </h4>
                }
                endMessage={
                  <h4 className="text-center my-4 text-secondary">
                    <i className="fa fa-info-circle" /> No more feed and
                    classwork at the moment.
                  </h4>
                }
                // onScroll={this.listenScrollToTop}
                // below props only if you need pull down functionality
                refreshFunction={this.refresh}
                pullDownToRefresh
                pullDownToRefreshContent={
                  <h4 className="text-center my-4 text-info">
                    {allBoardMessages.length === 0 ? (
                      <span>&nbsp;</span>
                    ) : (
                      <span>&#8595; Pull down to refresh</span>
                    )}
                  </h4>
                }
                pullDownToRefreshThreshold={200}
                releaseToRefreshContent={
                  <h4 className="text-center my-4 text-info">
                    &#8593; Release to refresh
                  </h4>
                }
              >
                {allBoardMessages.map(this.renderContent)}
              </InfiniteScroll>
              {/* <View
                style={{
                  // flex: 1,
                  alignItems: "flex-end",
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {"Feed"}
                </Text>
              </View>

              <hr
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
              />

              {feedMessages.length === 0 ? (
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "#e0e0e0",
                      fontSize: 20,
                    }}
                  >
                    Tidak Ada Feed
                  </Text>
                </View>
              ) : (
                feedMessages.map((feed) => (
                  <div
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                      }}
                    >
                      <View>
                        <Image
                          style={{
                            width: 65,
                            height: 65,
                            overflow: "hidden",
                            borderRadius: 50,
                          }}
                          source={
                            feed.Teacher && feed.Teacher.photoUrl
                              ? feed.Teacher && feed.Teacher.photoUrl
                              : require("../assets/owl-laptop-study.png")
                          }
                        />
                      </View>
                      <View
                        style={{
                          paddingLeft: 10,
                          flex: 1,
                          marginBottom: 15,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "16px",
                            marginBottom: 10,
                          }}
                        >
                          <b>{feed.Teacher ? feed.Teacher.name : ""}</b>
                        </Text>
                        <Text
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          Feed: <b>{feed.title}</b>
                        </Text>
                        <Text
                          style={{
                            marginBottom: 10,
                          }}
                        >
                          Deskripsi: {feed.description}
                        </Text>

                        <Text
                          style={{
                            whiteSpace: "nowrap",
                            width: "200px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          Link to File:{" "}
                          {feed.attachmentFileUrl ? (
                            <a href={feed.attachmentFileUrl}>
                              {feed.attachmentFileUrl}
                            </a>
                          ) : (
                            "-"
                          )}
                        </Text>
                      </View>
                    </View>

                    <hr
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        // height: "1px",
                        // borderTop: "1px solid",
                      }}
                    />
                  </div>
                ))
              )} */}

              {/* <View>
              {feedMessages.length !== 0 ? (
                feedMessages.map((ms) => (
                  <div className="form-group" style={{ margin: 0 }}>
                    <div className="row">
                      <div
                        className="col-2"
                        style={{ paddingRight: 0, paddingLeft: 10 }}
                      >
                        <img
                          className="img img-fluid"
                          src={
                            ms.Teacher && ms.Teacher.photoUrl
                              ? ms.Teacher.photoUrl
                              : "/static/images/user-dummy.jpg"
                          }
                          style={{
                            borderRadius: "50%",
                            // maxHeight: 50,
                          }}
                        />
                      </div>
                      <div className="col-10">
                        <b>
                          {ms.Teacher ? ms.Teacher.name : ""} - {ms.title}
                        </b>{" "}
                        <br />
                        <p
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {ms.description}
                        </p>
                      </div>
                    </div>
                    <hr
                      style={{
                        marginBottom: 5,
                        marginTop: 10,
                      }}
                    />
                  </div>
                ))
              ) : (
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "#e0e0e0",
                      fontSize: 20,
                    }}
                  >
                    Tidak Ada Feed
                  </Text>
                </View>
              )}
            </View> */}

              {/* <View
                style={{
                  // flex: 1,
                  alignItems: "flex-end",
                  marginTop: 40,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: "bold",
                  }}
                >
                  {"Tugas/Classwork"}
                </Text>
              </View>

              <hr
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
              />

              {classworkMessages.length === 0 ? (
                <View
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      color: "#e0e0e0",
                      fontSize: 20,
                    }}
                  >
                    Tidak Ada Tugas/Classwork
                  </Text>
                </View>
              ) : (
                classworkMessages.map((ms) => (
                  <div
                    style={{
                      marginBottom: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                      }}
                    >
                      <View>
                        <Image
                          style={{
                            width: 65,
                            height: 65,
                            overflow: "hidden",
                            borderRadius: 50,
                          }}
                          source={
                            ms.Teacher && ms.Teacher.photoUrl
                              ? ms.Teacher && ms.Teacher.photoUrl
                              : require("../assets/owl-laptop-study.png")
                          }
                        />
                      </View>
                      <View
                        style={{
                          paddingLeft: 10,
                          flex: 1,
                          marginBottom: 15,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "16px",
                            marginBottom: 10,
                          }}
                        >
                          {ms.type === "CLASSWORK" ? "Guru" : "Pembina"}:{" "}
                          <b>{ms.Teacher ? ms.Teacher.name : ""}</b>
                        </Text>

                        {ms.type === "CLASSWORK" ? (
                          <Text>
                            Mapel: <b>{ms.Subject ? ms.Subject.name : ""}</b>
                          </Text>
                        ) : (
                          <Text>
                            Ekstrakurikuler:{" "}
                            <b>
                              {ms.Extracurricular
                                ? ms.Extracurricular.name
                                : ""}
                            </b>
                          </Text>
                        )}

                        <Text
                          style={{
                            marginTop: 5,
                          }}
                        >
                          Tgl:{" "}
                          <b>
                            {dayjs(ms._createdAt).format("YYYY-MM-DD - HH:mm")}
                          </b>
                        </Text>

                        <Text style={{ marginTop: 20, marginBottom: 10 }}>
                          Tugas: <b>{ms.title}</b>
                        </Text>
                        <Text style={{ marginBottom: 5 }}>Deskripsi:</Text>
                        <Text>
                          <p
                            align="justify"
                            style={{
                              marginBottom: 20,
                            }}
                          >
                            {ms.description}
                          </p>
                        </Text>
                        <Text
                          style={{
                            color: "#2196f3",
                          }}
                        >
                          <b>{ms.isSubmitted ? "Tugas Diserahkan" : ""}</b>
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        alignItems: "flex-end",
                      }}
                    >
                      <TouchableOpacity onPress={this.visitClasswork(ms)}>
                        <button className="btn btn-sm btn-primary">
                          <i className="fa fa-sign-in-alt" /> Visit
                        </button>
                      </TouchableOpacity>
                    </View>
                    <hr
                      style={{
                        marginTop: 5,
                        marginBottom: 0,
                        // height: "1px",
                        // borderTop: "1px solid",
                      }}
                    />
                  </div>
                ))
              )} */}
            </section>
          </View>
        )}

        {/* <div className="fixed-bottom">
          <button
            type="button"
            onClick={!isLoading ? this.openFilterModal : null}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-filter" /> {"Filter"}
            </Text>
          </button>
        </div> */}
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const ALL_BOARD_MESSAGES = gql`
  query allBoardMessages(
    $classroomId: String!
    $studentId: String!
    $limit: Int
    $page: Int
  ) {
    allBoardMessages(
      classroomId: $classroomId
      studentId: $studentId
      limit: $limit
      page: $page
    ) {
      pages
      rows {
        _id
        title
        description
        type
        date
        time
        isSubmitted

        isScheduled
        startDate
        startTime
        dueDate
        dueTime

        _createdAt
        attachmentFileUrl
        Teacher {
          _id
          name
          photoUrl
        }
        Subject {
          _id
          name
        }
        Extracurricular {
          _id
          name
        }
      }
    }
  }
`;

const BOARD_MESSAGES = gql`
  query boardMessagesQuery(
    $classroomId: String!
    $studentId: String!
    $academicYear: String!
  ) {
    classroomSubject(classroomId: $classroomId, studentId: $studentId) {
      _id
      name
      countAssignment
    }

    countClassworkExtracurricular(
      studentId: $studentId
      academicYear: $academicYear
    ) {
      _id
      name
      countClassworkExtra
    }
  }
`;

export default withRouter(BoardMessages);

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
