import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";

let urlValues;
const FilterModal = ({
  students,
  handleSelectStudent,
  selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#3498db" : "#bdc3c7",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  ) : (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginTop: "0.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class PresensiSiswa extends Component {
  state = {
    session: {},
    students: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    date: dayjs().date,
    loading: false,
    filterModalVisible: false,

    subjectAttendance: [],
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await this.props.client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: ALL_RECORDS,
      variables: {
        sessionId: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/presensi_siswa",
        search: `?sessionId=${accountSession._id}&studentId=${
          urlValues.studentId
        }&month=${dayjs().month() + 1}&year=${dayjs().year()}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/presensi_siswa",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&month=${dayjs().month() + 1}&year=${dayjs().year()}`,
      });
    }
  };

  fetchingAttSubject = async ({ studentId, month, year, date }) => {
    this.setState({
      loading: true,
    });
    try {
      urlValues = queryString.parse(this.props.location.search);
      let result = await mwvClient.query({
        query: STUDENT_ATT_BY_SUBJECT,
        variables: {
          studentId,
          month,
          year,
          date,
        },
        fetchPolicy: "no-cache",
      });
      // console.log(result.data);
      this.setState({
        subjectAttendance: result.data.studentAttendancePerSubjectByStudentId,
      });
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    this.props.history.replace({
      pathname: "/presensi_siswa",
      search: `?sessionId=${urlValues.sessionId}&studentId=${student._id}&month=${urlValues.month}&year=${urlValues.year}`,
    });

    this.fetchingAttSubject({
      studentId: student._id,
      month: parseInt(urlValues.month),
      year: parseInt(urlValues.year),
      date: dayjs().date(),
    });
    this.closeFilterModalVisible();
  };
  handleChangeCalendar = (key) => (e) => {
    urlValues = queryString.parse(this.props.location.search);
    if (
      (e[0] !== dayjs().month() + 1 && e[1] !== dayjs().year()) ||
      e[2] !== undefined
    ) {
      this.props.history.replace({
        pathname: "/presensi_siswa",
        search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&month=${e[0]}&year=${e[1]}`,
      });

      this.fetchingAttSubject({
        studentId: urlValues.studentId,
        month: parseInt(e[0]),
        year: parseInt(e[1]),
        date: parseInt(e[2]),
      });
      // Router.replace({
      //   pathname: "/presensi_siswa",
      //   query: {
      //     sessionId: this.props.router.query.sessionId,
      //     studentId: this.props.router.query.studentId,
      //     date: e[2],
      //     month: e[0],
      //     year: e[1],
      //   }
      // });
    } else {
      this.props.history.replace({
        pathname: "/presensi_siswa",
        search: `?sessionId=${urlValues.sessionId}&studentId=${
          urlValues.studentId
        }&month=${dayjs().month() + 1}&year=${dayjs().year()}`,
      });
    }
  };
  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };
  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };
  refresh = () => {
    window.location.reload();
  };
  render() {
    const { loading, subjectAttendance } = this.state;
    const allStudents = this.props.allStudents;
    const student =
      allStudents && allStudents !== 0
        ? allStudents.find((student) => student._id === urlValues.studentId)
        : [];
    const presence = this.props.allRecords;

    // console.log(subjectAttendance);
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-clock" style={{ fontSize: 20 }} />{" "}
              {translate("Presensi Siswa")}
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {student && student.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{student.name}
                            <br />
                            <b>
                              {student.SchoolInformation
                                ? student.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : (
                          "Loading...."
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Filter Siswa")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            studentId={urlValues.studentId}
          />
        </FormModal>
        <div className="container">
          <Calendar
            subjectAttendance={subjectAttendance}
            presensi={presence}
            month={urlValues.month}
            year={urlValues.year}
            onChangeCalendar={this.handleChangeCalendar("calendar")}
            t={translate}
          />
        </div>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ALL_RECORDS = gql`
  query allStudentAttendanceRecords(
    $sessionId: String!
    $studentId: ID
    $month: Int
    $year: Int
  ) {
    allStudentAttendanceRecords(
      sessionId: $sessionId
      studentId: $studentId
      month: $month
      year: $year
    ) {
      _id
      scheduleName
      classScheduleId
      day
      month
      year
      date

      checkInStatus
      checkInMode
      checkInMinutes

      checkOutStatus
      checkOutMode
      checkOutMinutes

      isActiveDay

      _createdAt
    }
    allStudentBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
  }
`;

const STUDENT_ATT_BY_SUBJECT = gql`
  query studentAttendancePerSubjectByStudentId(
    $studentId: ID
    $month: Int
    $year: Int
    $date: Int
  ) {
    studentAttendancePerSubjectByStudentId(
      studentId: $studentId
      month: $month
      year: $year
      date: $date
    ) {
      _id
      status
      description
      Subject {
        _id
        name
      }
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;
// export default withRouter(props => (
//   <ApolloConsumer>
//     {client => (
//       <PresensiSiswa
//         {...props}
//         client={client}
//       />
//     )}
//   </ApolloConsumer>
// ));

export default withRouter(
  withI18n("attendance")((props) => {
    urlValues = queryString.parse(props.location.search);

    let studentId = urlValues.studentId ? urlValues.studentId : "";
    let month = urlValues.month ? parseInt(urlValues.month) : "";
    let year = urlValues.year ? parseInt(urlValues.year) : "";
    return (
      <Query
        query={ALL_RECORDS}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
          studentId,
          month,
          year,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <PresensiSiswa
              {...props}
              error={error}
              loading={loading}
              allRecords={
                data && data.allStudentAttendanceRecords
                  ? data.allStudentAttendanceRecords
                  : []
              }
              allStudents={
                data && data.allStudentBySessionId
                  ? data.allStudentBySessionId
                  : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
