import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import dayjs from "dayjs";
import { Query, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { handleError } from "../libs/errors";
import NumberFormat from "react-number-format";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;
const STATUS_COLOR = {
  SETORAN_TABUNGAN_SISWA: "green",
  PENARIKAN_TABUNGAN_SISWA: "red",
};

const depositColors = ["#1abc9c", "#2ecc71", "#3498db"];

const withdrawColros = ["#f1c40f", "#e67e22", "#e74c3c"];

// let hexColors = [
//   "#0961a5",
//   "#1abc9c",
//   "#0984e3",
//   "#e67e22",
//   "#e74c3c",
//   "#f39c12",
//   "#4834d4",
//   "#be2edd"
// ];

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);
class StudentSaving extends Component {
  state = {
    session: {},
    transactionLimit: 5,
    filterModalVisible: false,
    allStudents: [],

    topUpModal: false,

    topUpData: {
      nominal: 0,
      attachmentFileUrl: "",
    },
    mutasiModal: {
      visible: false,
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    },
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: SAVING_QUERIES,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // let savings =
    //   result.data && result.data.studentSaving ? result.data.studentSaving : [];

    if (accountSession) {
      if (urlValues.studentId) {
        this.props.history.push({
          pathname: "/student_saving",
          search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&limit=${urlValues.limit}`,
        });

        result = await mwvClient.query({
          query: SAVING_QUERIES,
          variables: {
            _id: accountSession._id,
            studentId: urlValues.studentId,
            limit: parseInt(urlValues.limit),
          },
        });

        // savings =
        //   result.data && result.data.studentSaving
        //     ? result.data.studentSaving
        //     : [];
      } else {
        this.props.history.push({
          pathname: "/student_saving",
          search: `?sessionId=${accountSession._id}&studentId=${
            students[0] ? students[0]._id : ""
          }&limit=${5}`,
        });

        result = await mwvClient.query({
          query: SAVING_QUERIES,
          variables: {
            _id: accountSession._id,
            studentId: urlValues.studentId,
            limit: parseInt(urlValues.limit),
          },
        });

        // savings =
        //   result.data && result.data.studentSaving
        //     ? result.data.studentSaving
        //     : [];
      }
    } else {
      this.props.history.replace({
        pathname: "/student_saving",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&limit=${5}`,
      });
    }

    this.setState({
      allStudents: [...students],
    });
  };

  handleLimit = (e) => {
    this.setState({
      transactionLimit: e.target.value,
    });

    urlValues = {
      ...urlValues,
      limit: e.target.value,
    };

    this.props.history.replace({
      pathname: "/student_saving",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&limit=${e.target.value}`,
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
    };

    this.setState({
      transactionLimit: 5,
    });
    this.props.history.replace({
      pathname: "/student_saving",
      search: `?sessionId=${urlValues.sessionId}&studentId=${
        urlValues.studentId
      }&limit=${5}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openTopUpModal = () => {
    this.setState({
      topUpModal: true,
      topUpData: {
        nominal: 0,
        attachmentFileUrl: "",
      },
    });
  };

  handleUpload = (key) => (e) => {
    if (key === "attachmentFileUrl") {
      const file = e.target.files[0];
      if (file.size > 20480 * 1024) {
        return handleError({
          message: "Maximum allowed image size is 20MB!",
        });
      }

      let reader = new FileReader();
      reader.onloadend = async () => {
        this.setState({
          topUpData: {
            ...this.state.topUpData,
            attachmentFileUrl: reader.result,
          },
        });
      };
      // console.log(reader);
      reader.readAsDataURL(file);
    } else {
      this.setState({
        topUpData: {
          ...this.state.topUpData,
          nominal: e.floatValue,
        },
      });
    }
  };

  openHistoryTopUp = () => {
    urlValues = queryString.parse(this.props.location.search);

    const params = queryString.stringify(urlValues);
    this.props.history.replace({
      pathname: "/student_saving_top_up_history",
      search: `?${params}`,
    });
  };
  submitTopUp = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      const { topUpData } = this.state;
      if (!topUpData.attachmentFileUrl) {
        throw {
          message: "Bukti pembayaran belum diinput",
        };
      }

      if (topUpData.nominal === 0) {
        throw {
          message: "Nominal top up tidak boleh 0",
        };
      }
      let result = await mwvClient.mutate({
        mutation: TOP_UP_SAVING,
        variables: {
          studentId: urlValues.studentId,
          ...topUpData,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        level: "success",
        message: "Top up tabungan berhasil",
      });

      this.setState({
        topUpModal: false,
      });

      const urlParams = queryString.stringify(urlValues);
      setTimeout(() => {
        this.props.history.replace({
          pathname: "/student_saving_top_up_history",
          search: `?${urlParams}`,
        });
      }, 2000);
    } catch (err) {
      handleError(err);
    }

    hideLoadingSpinner();
  };

  openMutasiModal = () => {
    this.setState({
      mutasiModal: {
        ...this.state.mutasiModal,
        visible: true,
      },
    });
  };
  closeMutasiModal = () => {
    this.setState({
      mutasiModal: {
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().format("YYYY-MM-DD"),
        visible: false,
      },
    });
  };

  handleChangeTanggalMutasi = (key) => (e) => {
    this.setState({
      mutasiModal: {
        ...this.state.mutasiModal,
        [key]: e.target.value,
      },
    });
  };

  submitMutasi = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      const { mutasiModal } = this.state;

      urlValues = queryString.parse(this.props.location.search);

      const result = await mwvClient.mutate({
        mutation: PDFReportMutation,
        variables: {
          variables: JSON.stringify({
            startDate: mutasiModal.startDate,
            endDate: mutasiModal.endDate,
            studentId: urlValues.studentId,
          }),
          mutation: `
          mutation dailyReportStudentSavingFromMobile(
            $studentId: ID
            $startDate: String
            $endDate: String
            $paymentMethodId: [ID]
            $officerPhone: String
            $SCHOOL_PREFIX: String
            $PREFIX: String
          ) {
            dailyReportStudentSavingFromMobile(
              input: {
                studentId: $studentId
                startDate: $startDate
                endDate: $endDate
                paymentMethodId: $paymentMethodId
                officerPhone: $officerPhone
                SCHOOL_PREFIX: $SCHOOL_PREFIX
                PREFIX: $PREFIX
              }
            )
          }
            `,
        },
        fetchPolicy: "no-cache",
      });

      if (result.data.generatePDFReportMutation) {
        this.setState({
          mutasiModal: {
            visible: false,
            startDate: dayjs().format("YYYY-MM-DD"),
            endDate: dayjs().format("YYYY-MM-DD"),
          },
        });

        window.open(
          `https://docs.google.com/viewer?url=${result.data.generatePDFReportMutation}`,
          "__blank"
        );
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  render() {
    const { allStudents, topUpData, mutasiModal } = this.state;
    const { studentSaving, topUpStudentSavingCashes } = this.props;

    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    const dataTabungan =
      studentSaving.length !== 0
        ? studentSaving.filter((s) => s.Student._id === urlValues.studentId)
        : [];

    const latestSaving = dataTabungan.find(
      (s) => s.Student._id === urlValues.studentId
    );

    // const latestSaving = null;

    let counter = 0;
    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Mutasi Tabungan
            </span>
          }
          visible={mutasiModal.visible}
          size="md"
          onClose={this.closeMutasiModal}
          // hideCloseButton
          onSubmit={this.submitMutasi}
        >
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label>Dari Tanggal</label>
                <input
                  className="form-control"
                  type="date"
                  value={mutasiModal.startDate}
                  onChange={this.handleChangeTanggalMutasi("startDate")}
                />
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label>Sampai Tanggal</label>
                <input
                  className="form-control"
                  type="date"
                  value={mutasiModal.endDate}
                  onChange={this.handleChangeTanggalMutasi("endDate")}
                />
              </div>
            </div>
          </div>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-wallet" /> Top Up Tabungan
            </span>
          }
          visible={this.state.topUpModal}
          size="md"
          onClose={(e) => {
            this.setState({
              topUpModal: false,
            });
          }}
        >
          {topUpStudentSavingCashes.length > 0 ? (
            <View>
              <View>
                <Text fontSize="16">
                  Untuk top up tabungan, harap transfer pada rekening berikut:
                </Text>
              </View>

              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                {topUpStudentSavingCashes.map((key, index) => (
                  <View style={{ marginBottom: 10 }}>
                    <center>
                      <View>
                        <Text style={{ fontSize: 18 }}>
                          <b>{key.name}</b>
                        </Text>
                      </View>
                      <View>
                        <Text style={{ fontSize: 14 }}>
                          {key.accountNumber}
                        </Text>
                      </View>
                    </center>
                  </View>
                ))}
              </View>

              <View>
                <Text
                  style={{
                    fontSize: 12,
                  }}
                >
                  Pastikan nominal transfer sesuai dengan bukti pembayaran.
                </Text>
              </View>

              <hr
                style={{
                  margin: 5,
                  height: "1px",
                  borderTop: "1px solid",
                }}
              />

              <View
                style={{
                  flex: 1,
                  flexDirection: "column",
                  marginTop: 10,
                }}
              >
                <View
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <Text>Bukti Pembayaran* (max: 20mb)</Text>
                </View>
                <View
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <input
                    style={{ margin: 0, padding: 0, fontSize: 12 }}
                    className=""
                    type="file"
                    name="attachedFile"
                    accept="image/*"
                    onChange={this.handleUpload("attachmentFileUrl")}
                  />
                </View>

                <View
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <Text>Nominal</Text>
                </View>
                <View
                  style={{
                    marginBottom: 10,
                  }}
                >
                  <NumberFormat
                    className="form-control"
                    value={topUpData.nominal}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    // onChange={handleInput("nominal")}
                    prefix={`${"Rp"}. `}
                    onValueChange={this.handleUpload("nominal")}
                  />
                </View>

                <View>
                  {topUpData.attachmentFileUrl ? (
                    <Image
                      style={{ width: 250, height: 500 }}
                      source={{
                        uri: topUpData.attachmentFileUrl,
                      }}
                    />
                  ) : null}
                </View>

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    // justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={this.submitTopUp}
                    style={{
                      marginBottom: 15,
                    }}
                  >
                    <View
                      style={{
                        backgroundColor: "#f44336",
                        paddingTop: "0.5rem",
                        paddingRight: "0.5rem",
                        paddingBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                        borderRadius: 23,
                        borderColor: "#ced4da",
                        borderWidth: "1px",
                        width: window.innerWidth * 0.7,
                        textAlign: "center",
                        marginTop: 5,
                      }}
                    >
                      <Text style={{ color: "#fff", fontSize: "18px" }}>
                        <i className="fa fa-wallet" /> Top Up
                      </Text>
                    </View>
                  </TouchableOpacity>

                  <TouchableOpacity
                    activeOpacity={0.8}
                    onPress={this.openHistoryTopUp}
                  >
                    <View
                      style={{
                        backgroundColor: "#43a047",
                        paddingTop: "0.5rem",
                        paddingRight: "0.5rem",
                        paddingBottom: "0.5rem",
                        paddingLeft: "0.5rem",
                        borderRadius: 23,
                        borderColor: "#ced4da",
                        borderWidth: "1px",
                        width: window.innerWidth * 0.7,
                        textAlign: "center",
                        marginTop: 5,
                      }}
                    >
                      <Text style={{ color: "#fff", fontSize: "18px" }}>
                        <i className="fa fa-history" /> Riwayat
                      </Text>
                    </View>
                  </TouchableOpacity>
                </View>
              </View>
            </View>
          ) : (
            <View
              style={{
                flex: 1,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                <center>
                  Rekening tidak ditemukan, harap hubungi Administrator
                </center>
              </Text>
            </View>
          )}
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-archive"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Tabungan Siswa")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                            <Text
                              style={{
                                paddingRight: 10,
                                fontSize: 12,
                                paddingVertical: 2,
                              }}
                            >
                              <i
                                className="fa fa-info-circle"
                                style={{
                                  fontSize: 15,
                                }}
                              />
                            </Text>
                            {"Rp"}.{" "}
                            {Number(latestSaving ? latestSaving.balance : 0)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleLimit}
                      value={this.state.transactionLimit}
                    >
                      <option value="5">
                        5 {translate("Transaksi Terakhir")}
                      </option>
                      <option value="10">
                        10 {translate("Transaksi Terakhir")}
                      </option>
                      <option value="20">
                        20 {translate("Transaksi Terakhir")}
                      </option>
                      <option value="100">
                        100 {translate("Transaksi Terakhir")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-md btn-block"
                    onClick={this.openMutasiModal}
                    style={{
                      background: "#8E24AA",
                      color: "#fff",
                    }}
                  >
                    Mutasi
                  </button>
                </div>
              </div>
            </div>
          </section>
        </div>

        {dataTabungan.length === 0 ? (
          <View
            style={{
              marginTop: "250px",
              marginBottom: "50px",
            }}
          >
            <section className="card">
              <div className="card-block">
                <center>
                  <Image
                    style={{ width: 250, height: 250 }}
                    source={require("../assets/money-angel.png")}
                  />
                  <h5>
                    {translate("Tabungan Tidak Ditemukan, Ayo Menabung")}!!
                  </h5>
                </center>
              </div>
            </section>
          </View>
        ) : (
          <View
            style={{
              marginTop: "8rem",
              marginBottom: 50,
              paddingTop: 100,
            }}
          >
            {dataTabungan.map((tabungan) => (
              <section
                key={tabungan._id}
                className={"card card-" + STATUS_COLOR[tabungan.type]}
                style={{ padding: 20, marginBottom: 8 }}
              >
                <div className="card-block">
                  <div className="row">
                    <div className="col-md-9">
                      <span
                        className="dot"
                        style={{
                          height: "100px",
                          width: "100px",
                          backgroundColor:
                            // hexColors[counter % hexColors.length],
                            tabungan.type === "SETORAN_TABUNGAN_SISWA"
                              ? depositColors[counter % depositColors.length]
                              : withdrawColros[counter % withdrawColros.length],
                          borderRadius: "50px",
                          padding: "10px",
                          color: "#fff",
                          fontWeight: 600,
                          marginTop: "10px",
                        }}
                      >
                        {" "}
                        {tabungan.type.slice(0, 2)}
                      </span>
                      &nbsp;&nbsp;
                      <b>{tabungan.description}</b>
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp; {translate("Jumlah")}: {"Rp"}.{" "}
                      <font size="4">
                        {tabungan.type === "SETORAN_TABUNGAN_SISWA"
                          ? Number(tabungan.debit)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")
                          : Number(tabungan.credit)
                              .toLocaleString("id-ID")
                              .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </font>
                      <br />
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      &nbsp; {translate("No Transaksi")}:{" "}
                      {tabungan.transactionNumber}
                    </div>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <font color="#00a8ff">
                    {translate("Tanggal")}:&nbsp;
                    {dayjs(tabungan.date).format("DD-MM-YYYY")}, Jam{" "}
                    {dayjs(tabungan._createdAt).get("hour")}:
                    {dayjs(tabungan._createdAt).get("minute")}
                  </font>
                  <font color="#fff">{counter++}</font>
                </div>
              </section>
            ))}
          </View>
        )}

        <div className="fixed-bottom">
          <View
            style={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            <button
              type="button"
              onClick={this.openFilterModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#00a8ff",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                // boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 16,
                }}
              >
                <i className="fa fa-search" /> {translate("Pilih Siswa")}
              </Text>
            </button>
            <button
              type="button"
              onClick={this.openTopUpModal}
              style={{
                margin: 0,
                zIndex: 9999,
                backgroundColor: "#43a047",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                // boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 16,
                }}
              >
                <i className="fa fa-wallet" /> {translate("Top Up")}
              </Text>
            </button>
          </View>
        </div>
      </View>
    );
  }
}
const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const SAVING_QUERIES = gql`
  query listQuery($_id: String, $limit: Int, $studentId: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
    studentSaving(studentId: $studentId, limit: $limit) {
      _id
      date
      transactionNumber
      debit
      credit
      type
      balance
      Student {
        _id
        name
      }
      description
      _createdAt
    }

    topUpStudentSavingCashes(studentId: $studentId) {
      _id
      name
      accountNumber
    }
  }
`;

const TOP_UP_SAVING = gql`
  mutation topUpStudentSaving(
    $studentId: String!
    $nominal: Int!
    $attachmentFileUrl: String!
  ) {
    topUpStudentSaving(
      studentId: $studentId
      nominal: $nominal
      attachmentFileUrl: $attachmentFileUrl
    )
  }
`;

const PDFReportMutation = gql`
  mutation generatePDFReportMutation($variables: String!, $mutation: String!) {
    generatePDFReportMutation(variables: $variables, mutation: $mutation)
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);

    let studentId = urlValues.studentId ? urlValues.studentId : "";
    let limit = urlValues.limit ? parseInt(urlValues.limit) : 5;
    // let x = urlValues.studentId ? urlValues.studentId : "";
    return (
      <Query
        query={SAVING_QUERIES}
        client={mwvClient}
        variables={{
          _id: urlValues.sessionId,
          limit,
          studentId,
          // x: studentId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <StudentSaving
              {...props}
              error={error}
              loading={loading}
              studentSaving={
                data && data.studentSaving ? data.studentSaving : []
              }
              topUpStudentSavingCashes={
                data && data.topUpStudentSavingCashes
                  ? data.topUpStudentSavingCashes
                  : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
