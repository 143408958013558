import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";
import dayjs from "dayjs";

const lang = encryptedLS.get("______lang") || "en";

class ClassworkExtra extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, ClassworkExtra, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType:
          ClassworkExtra.type === "ClassworkExtra" ? "ClassworkExtra" : "FeedExtra",
        Student,
        ClassworkExtra,
        _createdAt,
      });
    }
  };

  render() {
    const { Student, ClassworkExtra, _createdAt } = this.props;
    const initLang = lang ? lang : "id";
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 6,
              paddingBottom: 4,
              borderRadius: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  source={
                    ClassworkExtra.type === "FeedExtra"
                      ? require("../../assets/Ekstra-Info.png")
                      : require("../../assets/Ekstra-Tugas.png")
                  }
                />
              </View>
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {ClassworkExtra.type === "FeedExtra"
                      ? "Feed Ekstra Baru"
                      : "Tugas Ekstra Baru"}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {ClassworkExtra.title}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    marginBottom: 5,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Ekstrakurikuler: <b>{ClassworkExtra.extracurricularName}</b>
                  </Text>
                </View>
                <View
                  style={
                    {
                      // flex: 1,
                      // alignItems: "flex-start",
                      // justifyContent: "center",
                    }
                  }
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Oleh: {ClassworkExtra.teacherName}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#2196f3",
                    }}
                  >
                    {dayjs(ClassworkExtra._createdAt).format(
                      "dddd, DD MMMM YYYY [  ] HH:mm"
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(ClassworkExtra);
