import React, { Component } from "react";

import { View, Text } from "react-native-web";

class CheckoutBills extends Component {
  render() {
    const {
      paymentMethod,
      phoneNumber,
      handlePhoneNumber,
      totalBills,
      fee,
      finalBills,
    } = this.props;

    return (
      <View style={{ flex: 1 }}>
        <Text>
          Biaya transaksi dengan LINK AJA akan Rp. 5000 dari total
          tagihan Anda
        </Text>
        <View style={{ marginTop: 10 }}>
          {paymentMethod === "ovo" || paymentMethod === "linkAja" ? (
            <View>
              <center>
                <Text style={{ fontSize: 20 }}>
                  Akun {paymentMethod === "ovo" ? "OVO" : "LINK AJA"}*
                </Text>
              </center>
              <input
                style={{ textAlign: "center" }}
                className="form-control"
                value={phoneNumber}
                onChange={handlePhoneNumber}
                required
                placeholder={"081XXXXXXXXX"}
              />
            </View>
          ) : null}
        </View>

        <View
          style={{
            marginTop: 5,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <View style={{ width: 100 }}>
            <Text>Total Tagihan</Text>
          </View>
          <View style={{ width: 50 }}>
            <Text>Rp:</Text>
          </View>
          <View>
            <Text>
              {Number(totalBills ? totalBills : 0)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 5,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: 100 }}>
            <Text>Fee Admin</Text>
          </View>
          <View style={{ width: 70 }}>
            <Text>Rp:</Text>
          </View>
          <View>
            <Text>
              {Number(fee ? fee : 0)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 5,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ width: 100 }}>
            <Text>Total</Text>
          </View>
          <View style={{ width: 50 }}>
            <Text>Rp:</Text>
          </View>
          <View>
            <Text>
              {Number(finalBills ? finalBills : 0)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
            </Text>
          </View>
        </View>
      </View>
    );
  }
}

export default CheckoutBills;
