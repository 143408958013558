import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { withI18n, translate } from "../libs/withI18n";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row py-2">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      TA{" "}
                      {student.Classroom ? student.Classroom.academicYear : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class StudentBillAndPayment extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/student_bill_and_payment",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
      });
    } else {
      if (students[0]._id === "fa3da32e-54f2-434d-a9c6-631976ff2018") {
        this.props.history.replace({
          pathname: "/student_bill/student_bill_new_v2",
          search: `?sessionId=${accountSession._id}&studentId=${
            students[0] ? students[0]._id : ""
          }&PREFIX=${students[0].PREFIX}`,
        });
        // Router.replace({
        //   pathname: "/tagihan_siswa_new_with_v2",
        //   query: {
        //     sessionId: this.props.router.query.sessionId,
        //     studentId: studentIds[0]._id,
        //     PREFIX: studentIds[0].PREFIX,
        //   },
        // });
      } else {
        this.props.history.replace({
          pathname: "/student_bill/student_bill_new",
          search: `?sessionId=${accountSession._id}&studentId=${
            students[0] ? students[0]._id : ""
          }&PREFIX=${students[0].PREFIX}`,
        });
      }

      // if (
      //   students[0] &&
      //   students[0]._id === "12bfa1aa-5e2e-4d55-a86a-4ae5ea42389e"
      // ) {
      //   this.props.history.replace({
      //     pathname: "/student_bill/student_bill_new",
      //     search: `?sessionId=${accountSession._id}&studentId=${
      //       students[0] ? students[0]._id : ""
      //     }&PREFIX=${students[0].PREFIX}`,
      //   });
      // } else {
      //   if (students && students.length > 0) {
      //     this.props.history.replace({
      //       pathname: "/student_bill_and_payment",
      //       search: `?sessionId=${accountSession._id}&studentId=${
      //         students[0] ? students[0]._id : ""
      //       }&PREFIX=${students[0].PREFIX}`,
      //     });
      //   }
      // }
    }

    this.setState({
      allStudents: [...students],
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
    };

    this.props.history.replace({
      pathname: "/student_bill_and_payment",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openPage = (page) => (e) => {
    if (page === "paymentHistory") {
      this.props.history.replace({
        pathname: "/payment_history",
        search: this.props.location.search,
      });
    } else {
      this.props.history.replace({
        pathname: "/bill",
        // pathname: "/bill_experiment",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { allStudents } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];
    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-invoice"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Tagihan dan Pembayaran")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <View style={{ flex: 1, marginTop: 180 }}>
          <TouchableOpacity
            style={{
              // marginBottom: 5,
              paddingRight: 20,
              paddingLeft: 20,
              marginBottom: 50,
            }}
            onPress={this.openPage("billLists")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <Image
                    style={{ width: 100, height: 100 }}
                    source={require("../assets/inspecting-bill-finance-document.png")}
                  />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 30,
                      }}
                    >
                      {translate("Daftar Tagihan Siswa")}
                    </Text>
                  </View>
                </View>
              </div>
            </section>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginBottom: 20,
              paddingRight: 20,
              paddingLeft: 20,
            }}
            onPress={this.openPage("paymentHistory")}
          >
            <section
              className="card"
              style={{
                padding: 10,
                marginBottom: 20,
                borderRadius: 0,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="card-block">
                <View
                  style={{
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <Image
                    style={{ width: 100, height: 100 }}
                    source={require("../assets/receipt.png")}
                  />
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                      marginLeft: 10,
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 30,
                      }}
                    >
                      {translate("Riwayat Pembayaran")}
                    </Text>
                  </View>
                </View>

                {/* <div className="row">
                  <div className="col-4">
                    <Image
                      style={{ width: 100, height: 100 }}
                      source={require("../assets/receipt.png")}
                    />
                  </div>
                  <div className="col-8" style={{ paddingTop: 15 }}>
                    <Text
                      style={{
                        fontSize: 30,
                      }}
                    >
                      {translate("Riwayat Pembayaran")}
                    </Text>
                  </div>
                </div> */}
              </div>
            </section>
          </TouchableOpacity>
        </View>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      Classroom {
        _id
        academicYear
      }
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

export default withRouter(
  withI18n("student_bill_and_payment")(StudentBillAndPayment)
);
