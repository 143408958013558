import React, { PureComponent } from "react";
import { View, TouchableOpacity, Image, Text, Linking } from "react-native";
import dayjs from "dayjs";
import { handleError } from "../../libs/errors";
import reactStringReplace from "react-string-replace";

class UserPost extends PureComponent {
  state = {
    numberOfLines: 3,
    bufferedAudio: "",
    audioPlayState: "STOP",
  };

  handleClickImage = (imageUrl) => () => {
    const { _id, _createdAt, BroadcastMessage } = this.props;

    if (this.props.onPress) {
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_IMAGE_PRESSED",
        imageUrl,
        activityType: "BroadcastMessage",
        BroadcastMessage,
        _createdAt,
      });
    }

    try {
      window.open(imageUrl, "_blank");
    } catch (err) {
      handleError(err);
    }
  };

  handleClickPDF = (pdfUrl) => () => {
    // console.log("handleClickPDF", { pdfUrl });
    if (this.props.onPress) {
      const { _id, _createdAt, BroadcastMessage } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PDF_PRESSED",
        pdfUrl,
        activityType: "BroadcastMessage",
        BroadcastMessage,
        _createdAt,
      });
      window.open(pdfUrl, "_blank");
    }
  };

  handlePostPressed = () => {
    this.setState({
      numberOfLines: this.state.numberOfLines === 0 ? 3 : 0,
    });
  };

  handleLinkOpenBrowserPressed = (url) => () => {
    if (this.props.onPress) {
      const { _id, _createdAt, BroadcastMessage } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_OPEN_LINK_BROWSER_PRESSED",
        url,
        activityType: "BroadcastMessage",
        BroadcastMessage,
        _createdAt,
      });
      window.open(url, "_blank");
    }
  };

  render() {
    const { BroadcastMessage, _createdAt } = this.props;
    const { numberOfLines } = this.state;
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <View
          style={{
            marginHorizontal: 5,
            paddingBottom: 4,
            borderRadius: 2,
            backgroundColor: "white",
            shadowColor: "#000",
            shadowOffset: { width: 0, height: 2 },
            shadowOpacity: 0.2,
            shadowRadius: 2,
            elevation: 1,
          }}
        >
          <View
            style={{
              paddingVertical: 8,
              paddingHorizontal: 12,
            }}
          >
            <TouchableOpacity
              style={{
                flexDirection: "row",
                marginBottom: 6,
              }}
            >
              <Image
                style={{
                  height: 40,
                  width: 40,
                  borderRadius: 20,
                }}
                onError={() => {
                  this.setState({
                    profileImageUrl: null,
                  });
                }}
                source={require("../../assets/avatar-sign-orange.png")}
              />
              <View
                style={{ flex: 1, paddingVertical: 2, paddingHorizontal: 10 }}
              >
                <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                  {BroadcastMessage.authorName}
                </Text>
                <Text style={{ fontSize: 13, marginTop: 2 }}>
                  Menulis berita baru
                </Text>
                <Text style={{ fontSize: 12, color: "#1696ff", marginTop: 2 }}>
                  {dayjs(_createdAt).format("dddd, DD MMMM YYYY [  ] HH:mm")}
                </Text>
              </View>
            </TouchableOpacity>

            <View
              style={{
                flexDirection: "row",
                marginVertical: 4,
              }}
            >
              {BroadcastMessage.attachedImages.length > 0 ? (
                <TouchableOpacity
                  onPress={this.handleClickImage(
                    BroadcastMessage.attachedImages[0]
                  )}
                >
                  <Image
                    style={{
                      width: 120,
                      height: 100,
                      resizeMode: "cover",
                      borderRadius: 5,
                      borderColor: "#eee",
                      borderWidth: 2,
                      marginLeft: 4,
                    }}
                    defaultSource={{
                      uri: "/static/images/placeholder-grey.jpg",
                    }}
                    source={{
                      uri: BroadcastMessage.attachedImages[0],
                    }}
                  />
                </TouchableOpacity>
              ) : (
                <i
                  className={"fa fa-bullhorn fa-2x"}
                  style={{
                    paddingLeft: 6,
                    color: "#1696ff",
                  }}
                />
              )}

              <View style={{ flex: 1, marginLeft: 10 }}>
                <Text
                  style={{ fontWeight: "bold", fontSize: 18 }}
                  numberOfLines={numberOfLines === 0 ? 0 : 1}
                >
                  {BroadcastMessage.attachedImages.length > 0 ? (
                    <span>
                      <i className="fa fa-bullhorn" />{" "}
                    </span>
                  ) : (
                    ""
                  )}
                  {BroadcastMessage.title}
                </Text>

                {BroadcastMessage.content ? (
                  <Text
                    style={{ fontSize: 14, marginTop: 8 }}
                    numberOfLines={numberOfLines}
                    selectable={true}
                  >
                    {/* {BroadcastMessage.content} */}
                    {reactStringReplace(
                      BroadcastMessage.content,
                      // BroadcastMessage.content.match(/(https?:\/\/\S+)/g),
                      /(https?:\/\/\S+)/g,
                      (match, i) => (
                        // <a target="_blank" href={match}>{match}</a>
                        <TouchableOpacity
                          onPress={this.handleLinkOpenBrowserPressed(match)}
                        >
                          <Text style={{ color: "#1696ff" }}>{match}</Text>
                        </TouchableOpacity>
                      )
                    )}
                  </Text>
                ) : null}

                <View
                  style={{
                    display: "block",
                  }}
                >
                  <TouchableOpacity
                    onClick={this.handlePostPressed}
                    activeOpacity={0.6}
                    style={{
                      float: "right",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        color: "#1696ff",
                        fontSize: 14,
                      }}
                    >
                      View More
                    </Text>
                  </TouchableOpacity>
                </View>

                {BroadcastMessage.attachedImages.length > 0 ? (
                  <Text
                    style={{ fontSize: 14, marginTop: 8, color: "#1696ff" }}
                  >
                    <i className="fa fa-info-circle" /> Klik gambar untuk
                    memperbesar.
                  </Text>
                ) : null}

                {BroadcastMessage.attachedDocuments.map((docUrl) => (
                  <TouchableOpacity
                    key={docUrl}
                    onPress={this.handleClickPDF(docUrl)}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        paddingTop: 12,
                      }}
                    >
                      <i
                        className="fa fa-file-pdf"
                        style={{
                          paddingRight: 10,
                          color: "red",
                          fontSize: 20,
                        }}
                      />
                      <Text
                        style={{ marginTop: 2, color: "red", flex: 1 }}
                        numberOfLines={1}
                      >
                        Klik di sini untuk melihat lampiran dokumen
                      </Text>
                    </View>
                  </TouchableOpacity>
                ))}
              </View>
            </View>
          </View>
        </View>
      </View>
    );
  }
}

export default UserPost;
