import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
let urlValues;

class StudentLists extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    classroomLists: [],
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });

    this.props.history.replace({
      pathname: "/student_list",
      search: `?sessionId=${accountSession._id}`,
    });

    this.setState({
      allStudents: [...students],
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  refresh = () => {
    window.location.reload();
  };

  openStudentData = (student) => (e) => {
    urlValues = queryString.parse(this.props.location.search);
    this.props.history.replace({
      pathname: "/student_data",
      search: `?sessionId=${urlValues.sessionId}&studentId=${student._id}`,
    });
  };

  render() {
    const { allStudents } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-graduation-cap"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Student"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <BackgroundImage />

        <View
          style={{
            flex: 1,
            marginTop: 190,

            // flexDirection: "column",
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-graduation-cap" /> {"Daftar Siswa"}
            </Text>
          </View>
        </View>

        <View
          style={{
            marginTop: 30,
            marginBottom: "100vh",
          }}
        >
          {allStudents.map((student) => (
            <TouchableOpacity onClick={this.openStudentData(student)}>
              <section
                className="card"
                style={{
                  padding: 5,
                  marginBottom: 10,
                  borderRadius: 5,
                  width: "380px",
                }}
              >
                <div className="card-block">
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <View>
                      <Image
                        style={{
                          width: 65,
                          height: 65,
                          overflow: "hidden",
                          borderRadius: 50,
                        }}
                        source={
                          student.photoUrl
                            ? student.photoUrl
                            : require("../assets/owl-laptop-study.png")
                        }
                      />
                    </View>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        marginLeft: 10,
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                        }}
                      >
                        <b>{student.name}</b>
                      </Text>
                      <Text>
                        {student.SchoolInformation
                          ? student.SchoolInformation.name
                          : ""}
                      </Text>
                    </View>
                  </View>
                </div>
              </section>
            </TouchableOpacity>
          ))}
        </View>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

export default withRouter(StudentLists);
