import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

import EmployeeForm from "../components/Employee/EmployeeForm";
import EmployeeDocument from "../components/Employee/EmployeeDocument";
import { handleError } from "../libs/errors";
import uuidV4 from "uuid/v4";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;

class EmployeeData extends Component {
  state = {
    employeeData: null,
    isLoading: true,
    selectedIndex: 1,

    documentObj: null,
    documentLists: [],
    allEmployeeDocumentTypes: [],
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: DATA_EMPLOYEE_QUERY,
      variables: {
        employeeId: urlValues.employeeId,
      },
      fetchPolicy: "no-cache",
    });

    let employee =
      result.data && result.data.employeeById ? result.data.employeeById : null;

    const documentLists =
      result.data && result.data.employeeById
        ? result.data.employeeById.Documents
        : [];

    const allEmployeeDocumentTypes =
      result.data && result.data.allEmployeeDocumentTypes
        ? result.data.allEmployeeDocumentTypes
        : [];
    this.setState({
      employeeData: employee,
      isLoading: false,
      documentLists,
      allEmployeeDocumentTypes,
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  refresh = () => {
    window.location.reload();
  };

  handleSelect = (sel) => (e) => {
    if (e) e.preventDefault();
    if (sel === "data") {
      this.setState({
        selectedIndex: 1,
      });
    } else {
      this.setState({
        selectedIndex: 2,
      });
    }
  };

  addNewDocument = () => {
    this.setState({
      documentObj: {
        _id: uuidV4(),
        url: "",
        caption: "",
        description: "",
        employeeDocumentTypeId: "",
      },
    });
  };

  openEditDocument = (doc) => (e) => {
    const { __typename, ...d } = doc;
    this.setState({
      documentObj: {
        ...d,
        employeeDocumentTypeId: d.employeeDocumentTypeId
          ? d.employeeDocumentTypeId
          : "",
        status: "edit",
      },
    });
  };

  cancelEdit = () => {
    this.setState({
      documentObj: null,
    });
  };

  handleInputDocument = (key) => (e) => {
    if (key === "url") {
      const file = e.target.files[0];
      if (file.size > 10240 * 1024) {
        return handleError({
          message: "Maximum allowed image size is 10MB!",
        });
      }
      let reader = new FileReader();

      reader.onloadend = async () => {
        // console.log("READER", reader);
        this.setState({
          documentObj: {
            ...this.state.documentObj,
            url: reader.result,
          },
        });
      };

      reader.readAsDataURL(file);
    } else {
      this.setState({
        documentObj: {
          ...this.state.documentObj,
          [key]: e.target.value,
        },
      });
    }
  };

  selectEmployee = () => {
    urlValues = queryString.parse(this.props.location.search);
    this.props.history.replace({
      pathname: "/employee_list",
      search: `sessionId=${urlValues.sessionId}`,
    });
  };

  handleInputUpdate = (key) => (e) => {
    if (key === "pin") {
      this.setState({
        employeeData: {
          ...this.state.employeeData,
          pin: String(e.target.value),
        },
      });
    }
  };

  render() {
    const {
      employeeData,
      isLoading,
      selectedIndex,
      documentObj,
      documentLists,
      allEmployeeDocumentTypes,
    } = this.state;
    if (!employeeData || isLoading) {
      return (
        <View style={{ flex: 1 }}>
          <div className="fixed-top">
            <StatusBar
              backgroundColor="#1696ff"
              barStyle="light-content"
              animated={true}
            />
          </div>

          <div className="fixed-top">
            <HeaderBar
              title={
                <Text>
                  <i
                    className="fa fa-graduation-cap"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {"Employee"}
                </Text>
              }
              right={
                <TouchableOpacity onPress={this.refresh}>
                  <Text
                    style={{
                      paddingRight: 12,
                      paddingLeft: 20,
                      color: "white",
                      fontSize: 16,
                      paddingVertical: 2,
                    }}
                  >
                    <i
                      className="fa fa-sync-alt"
                      style={{
                        fontSize: 20,
                        marginBottom: 5,
                      }}
                    />
                  </Text>
                </TouchableOpacity>
              }
            />
          </div>

          <BackgroundImage />

          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 17,
                color: "#737373",
                textAlign: "center",
                paddingHorizontal: 30,
                marginBottom: 30,
              }}
            >
              <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
              pegawai
            </Text>
          </View>
        </View>
      );
    }
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-graduation-cap"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Employee"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <Image
                  style={{ width: 65, height: 65 }}
                  source={
                    employeeData.photoUrl
                      ? employeeData.photoUrl
                      : require("../assets/owl-laptop-study.png")
                  }
                />

                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    <b>{employeeData.name}</b>
                  </Text>
                  <Text>
                    {employeeData.SchoolInformation
                      ? employeeData.SchoolInformation.name
                      : ""}
                  </Text>
                  <Text>{employeeData.status ? employeeData.status : ""}</Text>
                </View>
              </View>
            </div>
          </section>
        </div>

        <BackgroundImage />
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            justifyContent: "center",
            marginTop: 190,
            marginBottom: 50,
          }}
        >
          <View>
            <button
              className="btn btn-sm btn-rounded shadow"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 1 ? "#00a8ff" : "#fff",
                color: selectedIndex === 1 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("data")}
            >
              Pegawai
            </button>
          </View>
          <View>
            <button
              className="btn btn-sm shadow btn-rounded"
              style={{
                borderRadius: "30px",
                backgroundColor: selectedIndex === 2 ? "#00a8ff" : "#fff",
                color: selectedIndex === 2 ? "#fff" : "#bdbdbd",
                marginRight: 10,
                paddingLeft: 20,
                paddingRight: 20,
              }}
              onClick={this.handleSelect("berkas")}
            >
              Berkas
            </button>
          </View>
        </View>

        <View
          style={{
            marginBottom: "100vh",
          }}
        >
          {selectedIndex === 1 ? (
            <EmployeeForm employee={employeeData} mwvClient={mwvClient} />
          ) : (
            <EmployeeDocument
              document={documentObj}
              allEmployeeDocumentTypes={allEmployeeDocumentTypes}
              documentLists={documentLists}
              handleAddDoc={this.addNewDocument}
              handleInputDocument={this.handleInputDocument}
              saveEmployeeDocuments={this.saveEmployeeDocuments}
              openEditDocument={this.openEditDocument}
              deleteDocument={this.deleteDocument}
              cancelEdit={this.cancelEdit}
            />
          )}
        </View>

        {/* <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.selectEmployee}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa Lain"}
            </Text>
          </button>
        </div> */}
      </View>
    );
  }

  saveEmployeeDocuments = async (e) => {
    showLoadingSpinner();

    const { documentObj } = this.state;

    if (!documentObj.url) {
      throw {
        message: "File belum diinput",
      };
    }
    if (!documentObj.caption) {
      throw {
        message: "Nama dokumen belum diinput",
      };
    }

    try {
      urlValues = queryString.parse(this.props.location.search);

      let result = await mwvClient.mutate({
        mutation: UPLOAD_EMPLOYEE_DOCUMENTS,
        variables: {
          employeeId: urlValues.employeeId,
          ...documentObj,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        title: "SUCCESS",
        message: "Berhasil disimpan",
        level: "success",
      });

      this.setState({
        documentObj: null,
      });

      this.initiate();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  deleteDocument = (doc) => async (e) => {
    showLoadingSpinner();
    try {
      if (
        window.confirm(
          `Apakah Anda yakin untuk menghapus dokumen ${doc.caption} ini ?`
        )
      ) {
        await mwvClient.mutate({
          mutation: DELETE_DOCUMENT,
          variables: {
            _id: doc._id,
          },
          fetchPolicy: "no-cache",
        });

        addNotification({
          title: "SUCCESS",
          message: `Dokumen berhasil dihapus`,
          level: "success",
        });

        this.setState({
          documentObj: null,
        });

        this.initiate();
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const DATA_EMPLOYEE_QUERY = gql`
  query listQueries($employeeId: String) {
    employeeById(employeeId: $employeeId) {
      _id
      name
      titleAhead
      titleBehind
      gender
      nickName
      regNumber
      nuptk
      status

      # employeePosition
      phoneNumber
      attNumber
      photoUrl

      email
      workEntryDate
      decreeNumber
      hometown
      birthDate
      address
      cityOfResidence
      emergencyPhoneNumber
      marriedStatus
      religion
      language
      weight
      height
      diseaseHistory
      foodAllergies
      beverageAllergies

      PREFIX

      Documents {
        _id
        url
        caption
        description
        employeeDocumentTypeId
      }
    }

    allEmployeeDocumentTypes(employeeId: $employeeId) {
      _id
      name
      description
    }
  }
`;

const UPLOAD_EMPLOYEE_DOCUMENTS = gql`
  mutation saveEmployeeDocuments(
    $employeeId: String!
    $_id: ID!
    $url: String!
    $caption: String!
    $description: String
    $employeeDocumentTypeId: String
  ) {
    saveEmployeeDocuments(
      employeeId: $employeeId
      document: {
        _id: $_id
        url: $url
        caption: $caption
        description: $description
        employeeDocumentTypeId: $employeeDocumentTypeId
      }
    )
  }
`;
const DELETE_DOCUMENT = gql`
  mutation deleteEmployeeDocument($_id: String!) {
    deleteEmployeeDocument(_id: $_id)
  }
`;

export default withRouter(EmployeeData);
