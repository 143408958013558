import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { formatCurrency } from "../../libs/numbers";
import dayjs from "dayjs";

import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";

const lang = encryptedLS.get("______lang") || "en";

const COLOR_MAP = {
  SETORAN: "#4a69bd",
  PENARIKAN: "#e55039",
};

class EmployeeSavingTransaction extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const {
        _id,
        Employee,
        EmployeeSavingTransaction,
        _createdAt,
      } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "EmployeeSavingTransaction",
        Employee,
        EmployeeSavingTransaction,
        _createdAt,
      });
    }
  };

  render() {
    const { Employee, EmployeeSavingTransaction, _createdAt } = this.props;
    const initLang = lang ? lang : "id";
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              marginHorizontal: 6,
              paddingBottom: 4,
              borderRadius: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
              flexDirection: "row",
            }}
          >
            <View>
              {EmployeeSavingTransaction.type === "SETORAN" ? (
                <Image
                  style={{
                    width: 140,
                    height: 140,
                    resizeMode: "cover",
                    marginLeft: 10,
                  }}
                  source={require("../../assets/personal-safe.png")}
                />
              ) : (
                <Image
                  style={{
                    width: 140,
                    height: 140,
                    resizeMode: "cover",
                    marginLeft: 10,
                  }}
                  source={require("../../assets/personal-safe-orange.png")}
                />
              )}
            </View>

            <View
              style={{
                flex: 1,
                paddingVertical: 8,
                paddingHorizontal: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: COLOR_MAP[EmployeeSavingTransaction.type],
                  marginTop: 2,
                }}
              >
                {translate(EmployeeSavingTransaction.type)}{" "}
                {translate("TABUNGAN PEGAWAI")}
              </Text>
              <Text
                style={{
                  fontSize: 12,
                  color: COLOR_MAP[EmployeeSavingTransaction.type],
                  marginTop: 2,
                }}
              >
                {dayjs(_createdAt)
                  .locale(initLang)
                  .format("dddd, DD MMMM YYYY   HH:mm")}
              </Text>
              <Text style={{ marginVertical: 6, fontSize: 20 }}>
                {EmployeeSavingTransaction.transactionNumber}
              </Text>
              <Text
                style={{
                  marginBottom: 4,
                  color: COLOR_MAP[EmployeeSavingTransaction.type],
                }}
              >
                <i className="fa fa-contact" />
                {/* <Ionicon name="md-contact" size={16} />{" "} */}
                {Employee ? Employee.name : ""}
              </Text>
              <Text>{EmployeeSavingTransaction.description}</Text>
              <View
                style={{
                  marginTop: 6,
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ fontWeight: "bold", fontSize: 16, marginRight: 10 }}
                >
                  {translate("Rp")}.{" "}
                  {formatCurrency(EmployeeSavingTransaction.amount)}{" "}
                </Text>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default EmployeeSavingTransaction;
