import React from "react";
import { TouchableOpacity, View, Text } from "react-native-web";
import withPreventDoubleClick from "../libs/withPreventDoubleClick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const EnhancedTouchableOpacity = withPreventDoubleClick(TouchableOpacity);

const TouchableListItem = ({ onPress, icon, label }) => (
  <EnhancedTouchableOpacity
    style={{
      flex: 1,
      flexDirection: "row",
      justifyContent: "flex-start",
      borderBottomWidth: 1,
      borderBottomColor: "#eee",
      padding: 4
    }}
    onPress={onPress}
  >
    {icon ? (
      <View style={{ padding: 12, paddingLeft: 18 }}>
        <Text style={{ color: "#555" }}>
          {icon.iconName ? (
            <FontAwesomeIcon
              icon={icon}
              // size="2x"
              style={{
                fontSize: 25
              }}
            />
          ) : (
            icon
          )}
        </Text>
      </View>
    ) : null}

    <View
      style={{
        flex: 1,
        paddingLeft: 6,
        paddingTop: 18,
        paddingBottom: 14
      }}
    >
      {typeof label === "string" ? (
        <Text style={{ fontSize: 16 }}>{label}</Text>
      ) : (
        label
      )}
    </View>
  </EnhancedTouchableOpacity>
);

export default TouchableListItem;
