import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;
const FilterModal = ({
  employees,
  handleSelectEmployee,
  selectedEmployeeId,
  employeeId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {employees.map((employee) => (
        <div key={employee._id}>
          <section
            className="card"
            onClick={handleSelectEmployee(employee)}
            style={{
              borderWidth: "3px",
              borderColor: employee._id === employeeId ? "#3498db" : "#bdc3c7",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {employee.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  ) : (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginTop: "0.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {employee.name}
                    </b>
                    <br />
                    {employee.SchoolInformation
                      ? employee.SchoolInformation.name
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class InkaPengembanganDiri extends Component {
  state = {
    session: {},
    employees: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    loading: false,
    filterModalVisible: false,

    filterCriteria: {
      employeeId: "",
      academicYear: "",
      semester: "",
    },
    listTahunAjaran: [],
    listData: [],

    attachmentVisible: false,
    name: "",
    attachmentFileUrl: "",

    employeeSelfDevelopmentMasterId: "",

    reuploadFormModal: {
      _id: "",
      visible: false,
      attachmentFileUrl: "",
    },

    allEmployeeSelfDevelopmentMasters: [],
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await this.props.client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    console.log("fetching...");
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUERIES,
      variables: {
        ...urlValues,
      },
    });

    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];

    let allEmployeeSelfDevelopmentMasters =
      result.data && result.data.allEmployeeSelfDevelopmentMasters
        ? result.data.allEmployeeSelfDevelopmentMasters
        : [];
    const listTahunAjaran =
      result.data && result.data.allAcademicYears
        ? result.data.allAcademicYears
        : [];

    const listData =
      result.data && result.data.allEmployeeSelfDevelopment
        ? result.data.allEmployeeSelfDevelopment
        : [];

    this.setState({
      employees,
      listTahunAjaran,
      listData,
      allEmployeeSelfDevelopmentMasters,
    });

    if (urlValues.employeeId) {
      this.props.history.replace({
        pathname: "/inka_pengembangan_diri",
        search: `?sessionId=${accountSession._id}&employeeId=${urlValues.employeeId}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/inka_pengembangan_diri",
        search: `?sessionId=${accountSession._id}&employeeId=${
          employees[0] ? employees[0]._id : ""
        }&academicYear=${urlValues.academicYear}&semester=${
          urlValues.semester
        }&PREFIX=${employees[0].PREFIX}`,
      });
    }
  };

  handleSelectEmployee = (employee) => async (e) => {
    this.props.history.replace({
      pathname: "/inka_pengembangan_diri",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${employee._id}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };
  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };
  refresh = () => {
    window.location.reload();
  };

  handleFilterCriteria = (key) => (e) => {
    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
      [key]: e.target.value,
    };

    urlValues = queryString.stringify(urlValues);
    window.location.href = `/inka_pengembangan_diri/?${urlValues}`;
  };

  handleCreate = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      const { name, attachmentFileUrl, employeeSelfDevelopmentMasterId } =
        this.state;
      await mwvClient.mutate({
        mutation: CREATE_DEVELOPMENT,
        variables: {
          ...urlValues,
          name,
          attachmentFileUrl,
          employeeSelfDevelopmentMasterId,
        },
      });

      addNotification({
        level: "success",
        message: "Data berhasil disimpan",
      });

      this.setState({
        attachmentVisible: false,
        name: "",
        attachmentFileUrl: "",
      });

      setTimeout(() => {
        window.location.reload();
      }, 1500);
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  deleteFile = (file) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      if (window.confirm("Apakah anda yakin untuk menghapus berkas ini")) {
        await mwvClient.mutate({
          mutation: DELETE_DEVELOPMENT,
          variables: {
            _id: file._id,
          },
        });

        addNotification({
          level: "success",
          message: "Data berhasil dihapus",
        });

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  openReuploadFile = (data) => (e) => {
    this.setState({
      reuploadFormModal: {
        _id: data._id,
        visible: true,
        attachmentFileUrl: "",
      },
    });
  };
  render() {
    urlValues = queryString.parse(this.props.location.search);
    const {
      filterCriteria,
      listTahunAjaran,
      listData,
      name,
      attachmentFileUrl,
      attachmentVisible,
      employeeSelfDevelopmentMasterId,
      reuploadFormModal,
      allEmployeeSelfDevelopmentMasters,
    } = this.state;
    const { loading } = this.state;
    const allEmployees = this.state.employees;
    const employee =
      allEmployees && allEmployees !== 0
        ? allEmployees.find((employee) => employee._id === urlValues.employeeId)
        : [];

    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-star" style={{ fontSize: 20 }} />{" "}
              {translate("Assessmen Pegawai")}
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {employee && employee.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{employee.name}
                            <br />
                            <b>
                              {employee.SchoolInformation
                                ? employee.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Tahun Ajaran</label>
                    <select
                      className="form-control"
                      value={urlValues.academicYear}
                      onChange={this.handleFilterCriteria("academicYear")}
                      required
                    >
                      <option value="" disabled>
                        Pilih Tahun Ajaran
                      </option>
                      {listTahunAjaran.map((ta) => (
                        <option value={ta.label}>
                          {ta.label} {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Semester</label>
                    <select
                      className="form-control"
                      value={urlValues.semester}
                      onChange={this.handleFilterCriteria("semester")}
                      required
                    >
                      <option value="" disabled>
                        Pilih Semester
                      </option>
                      <option value={"GANJIL"}>GANJIL</option>
                      <option value={"GENAP"}>GENAP</option>
                    </select>
                  </div>
                </div>
              </div>

              <br />
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Filter Pegawai")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            employees={allEmployees}
            handleSelectEmployee={this.handleSelectEmployee}
            employeeId={urlValues.employeeId}
          />
        </FormModal>
        <FormModal
          title={
            <span>
              <i className="fa fa-sync-alt" /> Re Upload File
            </span>
          }
          visible={reuploadFormModal.visible}
          size="md"
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              reuploadFormModal: {
                _id: "",
                visible: false,
                attachmentFileUrl: "",
              },
            });
          }}
          onSubmit={async (e) => {
            if (e) e.preventDefault();
            showLoadingSpinner();
            try {
              await mwvClient .mutate({
                mutation: UPDATE_DEVELOPMENT,
                variables: {
                  _id: reuploadFormModal._id,
                  attachmentFileUrl: reuploadFormModal.attachmentFileUrl,
                },
              });

              addNotification({
                level: "success",
                message: "Data berhasil disimpan",
              });

              this.setState({
                reuploadFormModal: {
                  _id: "",
                  visible: false,
                  attachmentFileUrl: "",
                },
              });

              setTimeout(() => {
                window.location.reload();
              }, 1500);
            } catch (err) {
              handleError(err);
            }
            hideLoadingSpinner();
          }}
        >
          <input
            style={{ margin: 0, padding: 0, fontSize: 12 }}
            type="file"
            name="attachedFile"
            accept="*"
            onChange={(e) => {
              const file = e.target.files[0];
              if (file && file.size > 20480 * 10 * 1024) {
                return handleError({
                  message: "Maximum allowed image size is 200MB!",
                });
              }

              let reader = new FileReader();
              reader.onloadend = async () => {
                this.setState({
                  reuploadFormModal: {
                    ...reuploadFormModal,
                    attachmentFileUrl: reader.result,
                  },
                });
              };
              reader.readAsDataURL(file);
            }}
          />
        </FormModal>

        <div className="container">
          <div className="float-right text-right">
            {attachmentVisible ? (
              <div>
                <button
                  className="btn btn-md btn-danger mx-2"
                  onClick={(e) => {
                    this.setState({
                      attachmentVisible: false,
                      name: "",
                      attachmentFileUrl: "",
                    });
                  }}
                >
                  <i className="fa fa-times" />
                  Batalkan
                </button>
                <button
                  className="btn btn-md btn-success"
                  onClick={this.handleCreate}
                >
                  <i className="fa fa-save" />
                  Simpan
                </button>
              </div>
            ) : (
              <button
                className="btn btn-md btn-success"
                onClick={(e) => {
                  this.setState({
                    attachmentVisible: true,
                    name: "",
                    attachmentFileUrl: "",
                  });
                }}
              >
                <i className="fa fa-plus" />
                Berkas Baru
              </button>
            )}
          </div>

          <br />
          <br />

          {attachmentVisible ? (
            <div
              style={{
                marginBottom: 10,
              }}
            >
              <div className="form-group">
                <label>Nama File</label>
                <input
                  className="form-control"
                  value={name}
                  onChange={(e) => {
                    this.setState({
                      name: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group">
                <label>Jenis PD:</label>
                <select
                  className="form-control"
                  value={employeeSelfDevelopmentMasterId}
                  onChange={(e) => {
                    this.setState({
                      employeeSelfDevelopmentMasterId: e.target.value,
                    });
                  }}
                >
                  <option value={""} disabled>
                    Pilih Jenis PD
                  </option>
                  {allEmployeeSelfDevelopmentMasters.map((selfDev) => (
                    <option value={selfDev._id}>{selfDev.name}</option>
                  ))}
                </select>
              </div>

              <input
                style={{ margin: 0, padding: 0, fontSize: 12 }}
                type="file"
                name="attachedFile"
                accept="*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file && file.size > 20480 * 10 * 1024) {
                    return handleError({
                      message: "Maximum allowed image size is 200MB!",
                    });
                  }

                  let reader = new FileReader();
                  reader.onloadend = async () => {
                    this.setState({
                      attachmentFileUrl: reader.result,
                    });
                  };
                  reader.readAsDataURL(file);
                }}
              />
            </div>
          ) : null}

          <div
            className="row"
            style={{
              borderWidth: "100px",
            }}
          >
            <div
              className="col-6"
              style={{
                paddingRight: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "#2980b9",
                  color: "#fff",
                }}
              >
                <center>
                  <b>File Name</b>
                </center>
              </div>
            </div>
            <div
              className="col-6"
              style={{
                paddingLeft: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "#2980b9",
                  color: "#fff",
                }}
              >
                <center>
                  <b>Utilites</b>
                </center>
              </div>
            </div>
          </div>

          {listData.map((dev) => (
            <div className="row">
              <div
                className="col-6"
                style={{
                  paddingRight: 0,
                }}
              >
                <div
                  className="border  border-1"
                  style={{
                    backgroundColor: "#fff",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 10,
                    paddingBottom: 10,
                    height: "100%",
                    borderWidth: "10px",
                    borderColor: "blue",
                  }}
                >
                  {dev.name}
                  <br />
                  <br />
                  <b>Jenis PD:</b>
                  <br />
                  <b>
                    {dev.EmployeeSelfDevelopmentMaster
                      ? dev.EmployeeSelfDevelopmentMaster.name
                      : ""}
                  </b>
                </div>
              </div>
              <div
                className="col-6"
                style={{
                  paddingLeft: 0,
                }}
              >
                <div
                  className="border border-1"
                  style={{
                    backgroundColor: "#fff",
                    paddingLeft: 5,
                    paddingRight: 5,
                    paddingTop: 10,
                    paddingBottom: 10,
                    height: "100%",
                  }}
                >
                  <center>
                    <Text
                      style={{
                        color: "blue",
                      }}
                    >
                      <a href={`${dev.attachmentFileUrl}`} target="__blank">
                        <i className="fa fa-download" />
                        Download
                      </a>
                    </Text>
                  </center>

                  <center>
                    <a href="#" onClick={this.openReuploadFile(dev)}>
                      <Text
                        style={{
                          color: "purple",
                        }}
                      >
                        <i className="fa fa-sync-alt" />
                        Re-Upload
                      </Text>
                    </a>
                  </center>

                  {/* <center>
                    <a href="#" onClick={this.deleteFile(dev)}>
                      <Text
                        style={{
                          color: "red",
                        }}
                      >
                        <i className="fa fa-times" />
                        Delete
                      </Text>
                    </a>
                  </center> */}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQueries(
    $sessionId: String!
    $employeeId: String!
    $academicYear: String!
    $semester: String!
    $PREFIX: String!
  ) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      PREFIX
    }
    allEmployeeSelfDevelopment(
      employeeId: $employeeId
      academicYear: $academicYear
      semester: $semester
    ) {
      _id
      name
      attachmentFileUrl

      EmployeeSelfDevelopmentMaster {
        _id
        name
      }
    }

    allAcademicYears(PREFIX: $PREFIX) {
      label
    }

    allEmployeeSelfDevelopmentMasters(
      academicYear: $academicYear
      semester: $semester
      employeeId: $employeeId
    ) {
      _id
      name
    }
  }
`;

const CREATE_DEVELOPMENT = gql`
  mutation createEmployeeSelfDevelopment(
    $employeeId: String!
    $academicYear: String!
    $semester: String!
    $name: String!
    $attachmentFileUrl: String!
    $employeeSelfDevelopmentMasterId: String
  ) {
    createEmployeeSelfDevelopment(
      employeeId: $employeeId
      academicYear: $academicYear
      semester: $semester
      name: $name
      attachmentFileUrl: $attachmentFileUrl
      employeeSelfDevelopmentMasterId: $employeeSelfDevelopmentMasterId
    )
  }
`;
const UPDATE_DEVELOPMENT = gql`
  mutation updateEmployeeSelfDevelopment(
    $_id: ID!
    $employeeId: String
    $academicYear: String
    $semester: String
    $name: String
    $attachmentFileUrl: String
    $employeeSelfDevelopmentMasterId: String
  ) {
    updateEmployeeSelfDevelopment(
      _id: $_id
      employeeId: $employeeId
      academicYear: $academicYear
      semester: $semester
      name: $name
      attachmentFileUrl: $attachmentFileUrl
      employeeSelfDevelopmentMasterId: $employeeSelfDevelopmentMasterId
    )
  }
`;

const DELETE_DEVELOPMENT = gql`
  mutation deleteEmployeeSelfDevelopment($_id: ID!) {
    deleteEmployeeSelfDevelopment(_id: $_id)
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;
// export default withRouter(props => (
//   <ApolloConsumer>
//     {client => (
//       <InkaPengembanganDiri
//         {...props}
//         client={client}
//       />
//     )}
//   </ApolloConsumer>
// ));

export default withRouter(withI18n("menu_kepegawaian")(InkaPengembanganDiri));

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
