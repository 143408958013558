import React, { Component } from "react";

import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";

class PaymentMethod extends Component {
  render() {
    return (
      <View>
        <center>
          <Image
            style={{ width: 200, height: 200 }}
            source={require("../../assets/smartphone-payment.jpg")}
          />
        </center>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("dana")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "dana"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            display: "none",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                source={require("../../assets/dana.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>DANA</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("ovo")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "ovo"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            display: "none",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                source={require("../../assets/OVO.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>OVO</Text>
            </View>
          </View>
        </section>

        <div className="mb-4">
          <h5>E-Wallet</h5>
          <section
            className="card"
            onClick={
              this.props.handleSelectPayment("linkAja")
              // this.props.PREFIX === "DEMO"
              //   ? this.props.handleSelectPayment("linkAja")
              //   : null
            }
            style={{
              borderWidth: "4px",
              borderColor:
                this.props.selectedPaymentMethod === "linkAja"
                  ? "#00a8ff"
                  : "#bdc3c7",
              marginBottom: "5px",
              borderRadius: "10px",
              // border: "block",
              padding: 10,
              marginBottom: 10,
              display:
                this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <View style={{ height: 50 }}>
                <Image
                  style={{ width: 50, height: 50 }}
                  source={require("../../assets/linkAja.png")}
                />
              </View>
              <View
                style={{
                  width: 250,
                  height: 50,
                  justifyContent: "center",
                  marginLeft: 10,
                }}
              >
                <Text style={{ fontSize: 20 }}>LINK AJA</Text>
              </View>
            </View>
          </section>
        </div>

        {/* {this.props.PREFIX === "DEMO" ? (
          <div className="mb-4">
            <h5>QRIS</h5>
            <section
              className="card"
              onClick={this.props.handleSelectPayment("qris")}
              style={{
                borderWidth: "4px",
                borderColor:
                  this.props.selectedPaymentMethod === "qris"
                    ? "#00a8ff"
                    : "#bdc3c7",
                marginBottom: "5px",
                borderRadius: "10px",
                // border: "block",
                padding: 10,
                marginBottom: 10,
                display:
                  this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
              }}
            >
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <View style={{ height: 50 }}>
                  <Image
                    style={{ width: 50, height: 50 }}
                    resizeMode="contain"
                    source={require("../../assets/icon-qr-scan.png")}
                  />
                </View>
                <View style={{ width: 250, height: 50, marginLeft: 10 }}>
                  <Text style={{ marginTop: 13, fontSize: 20 }}>QRIS</Text>
                </View>
              </View>
            </section>
          </div>
        ) : null} */}
        <h5>E-Commerce</h5>
        <section
          className="card"
          onClick={this.props.handleSelectPayment("tokopedia")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "tokopedia"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX !== "DEMO" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/Tokopedia.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Tokopedia</Text>
            </View>
          </View>
        </section>
        <section
          className="card"
          onClick={this.props.handleSelectPayment("shopee")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "shopee"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX !== "DEMO" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/Shopee.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Shopee</Text>
            </View>
          </View>
        </section>
        <h5>Virtual Account</h5>
        <section
          className="card"
          onClick={this.props.handleSelectPayment("mandiri")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "mandiri"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/Logo-Bank-Mandiri.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank Mandiri</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("bni")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "bni"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            // display: "none",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/Logo-BNI-46.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank BNI</Text>
            </View>
          </View>
        </section>
        <section
          className="card"
          onClick={this.props.handleSelectPayment("bri")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "bri"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            // display: "none",
            padding: 10,
            marginBottom: 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/Logo-Bank-BRI.png")}
              />
            </View>
            <View style={{ width: 250, height: 50 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank BRI</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("bca")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "bca"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/bca-logo.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank BCA</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("bsi")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "bsi"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/BSI_logo.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank BSI</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("permata")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "permata"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/permata.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank Permata</Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("cimb")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "cimb"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "DEMO" ? "block" : "none", //this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/cimb.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>
                Bank CIMB Niaga
              </Text>
            </View>
          </View>
        </section>

        <section
          className="card"
          onClick={this.props.handleSelectPayment("cimb")}
          style={{
            borderWidth: "4px",
            borderColor:
              this.props.selectedPaymentMethod === "cimb"
                ? "#00a8ff"
                : "#bdc3c7",
            marginBottom: "5px",
            // border: "block",
            padding: 10,
            marginBottom: 10,
            display: this.props.PREFIX === "DEMO" ? "block" : "none", //this.props.PREFIX === "RAHMATANSCHOOL" ? "none" : "block",
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View style={{ height: 50 }}>
              <Image
                style={{ width: 50, height: 50 }}
                resizeMode="contain"
                source={require("../../assets/muamalat.png")}
              />
            </View>
            <View style={{ width: 250, height: 50, marginLeft: 10 }}>
              <Text style={{ marginTop: 13, fontSize: 20 }}>Bank Muamalat</Text>
            </View>
          </View>
        </section>
      </View>
    );
  }
}

export default PaymentMethod;
