/* eslint-disable */
import { ApolloClient, InMemoryCache } from "apollo-boost";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink } from "apollo-link";
import localforage from "localforage";

const GRAPHQL_API_ENDPOINT =
  "https://api.activity.softwaresekolah.co.id/graphql";
  // "http://localhost:8021/graphql";

const create = (initialState) => {
  const httpLink = createHttpLink({
    uri: GRAPHQL_API_ENDPOINT,
    credentials: "same-origin",
  });

  const errorLink = onError(({ networkError, graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.map((error) => {
        const { message, locations, path } = error;
        // console.log({ error });

        console.warn(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
            locations,
            null,
            4
          )}, Path: ${path ? path : "N/A"}`
        );
      });
    }
    // if (networkError) console.warn(`[Network error]: ${networkError}`);
  });

  const authLink = setContext((_, { headers }) => {
    return localforage
      .getItem("accountSession")
      .then((session) => {
        if (session) {
          session = JSON.parse(session);
        }
        //   console.log("Load Saved Session:", session);
        const authorizationHeader =
          session && session.token ? `Bearer ${session.token}` : null;
        return {
          headers: {
            authorization: authorizationHeader,
            "schoollife-master-key":
              "$2y$12$2OSXFuGHLC9BXc5qO7YhMuZ1yZhGblfDnF70GZxFtppx3pVOGA4Kq",
          },
        };
      })
      .catch((err) => {
        console.log("Error localforage =>", err);
      });
  });

  const link = ApolloLink.from([errorLink, authLink, httpLink]);
  return new ApolloClient({
    link,
    cache: new InMemoryCache().restore(initialState || {}),
  });
};

let apolloClient = null;
export default function initApollo(initialState) {
  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = create(initialState);
  }
  return apolloClient;
}
