import { View, Text } from "react-native-web";
import React, { Component } from "react";
class MobileBanking extends Component {
  render() {
    const { bankCode } = this.props;
    if (bankCode === "MANDIRI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Buka Aplikasi Mandiri Online - Pilih Bayar</Text>
          <Text>2. Buat Pembayaran Baru - Pilih MultiPayment</Text>
          <Text>3. Pilih Penyedia Jasa "Xendit" (88908)</Text>
          <Text>4. Masukkan Kode VA/Pelanggan</Text>
          <Text>5. Konfirmasi Pembayaran</Text>
          <Text>6. Konfirmasi PIN Mandiri Online</Text>
          <Text>7. Selesai</Text>
        </View>
      );
    } else if (bankCode === "BNI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Buka BNI Mobile Banking - Pilih Transfer</Text>
          <Text>2. Pilih Virtual Account Billing</Text>
          <Text>3. Masukkan Kode VA Anda</Text>
          <Text>4. Konfirmasi Pembayaran</Text>
          <Text>5. Konfirmasi Password</Text>
          <Text>6. Selesai</Text>
        </View>
      );
    } else if (bankCode === "BRI") {
      return (
        <View style={{ paddingLeft: 10 }}>
          <Text>1. Login BRI Mobile Banking</Text>
          <Text>2. Pilih Menu Pembayaran</Text>
          <Text>3. Pilih Menu BRIVA</Text>
          <Text>4. Masukkan Kode VA Anda</Text>
          <Text>5. Masukkan No Pin Anda - Klik OK</Text>
          <Text>6. SMS Notifikasi akan masuk ke No. HP anda</Text>
        </View>
      );
    } else {
      return (
        <View>
          <Text>{""}</Text>
        </View>
      );
    }
  }
}

export default MobileBanking;
