import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import dayjs from "dayjs";
import { handleError } from "../../libs/errors";
import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";

const lang = encryptedLS.get("______lang") || "en";

class Article extends Component {
  state = {};

  handleClick = () => {
    const { _id, Article, _createdAt } = this.props;

    if (this.props.onPress) {
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "Article",
        Article,
        _createdAt,
      });
    }

    try {
      const articleUrl =
        "https://support.softwaresekolah.co.id/artikel/article_content?articleId=" +
        Article._id;
      window.open(articleUrl, "_blank");
    } catch (err) {
      handleError(err);
    }
  };

  render() {
    const { Article, _createdAt } = this.props;

    const initLang = lang ? lang : "id";

    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 4,
              elevation: 1,
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 14,
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  marginBottom: 6,
                }}
                activeOpacity={0.6}
              >
                <Image
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                  }}
                  // onError={() => {
                  //   this.setState({
                  //     profileImageUrl: null
                  //   });
                  // }}
                  defaultSource={require("../../assets/logo-small.png")}
                  source={
                    this.state.profileImageUrl
                      ? {
                          uri: this.state.profileImageUrl,
                        }
                      : require("../../assets/logo-small.png")
                  }
                />
                <View
                  style={{ flex: 1, paddingVertical: 2, paddingHorizontal: 10 }}
                >
                  <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                    School Talk
                  </Text>
                  <Text style={{ fontSize: 13, marginTop: 2 }}>
                    {translate("Membuat artikel baru")}
                  </Text>
                  <Text
                    style={{ fontSize: 12, color: "#1696ff", marginTop: 2 }}
                  >
                    {dayjs(_createdAt)
                      .locale(initLang)
                      .format("dddd, DD MMMM YYYY   HH:mm")}
                  </Text>
                </View>
              </TouchableOpacity>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                }}
              >
                <Image
                  style={{
                    width: 140,
                    height: 90,
                    resizeMode: "cover",
                    borderRadius: 2,
                    marginLeft: 4,
                    marginRight: 4,
                    marginTop: 0,
                  }}
                  defaultSource={require("../../assets/placeholder-grey.jpg")}
                  source={{
                    uri: Article.thumbnail
                      ? Article.thumbnail
                      : require("../../assets/placeholder-grey.jpg"),
                  }}
                />

                <View style={{ flex: 1, marginLeft: 8 }}>
                  <Text
                    style={{ fontWeight: "bold", fontSize: 16 }}
                    numberOfLines={3}
                  >
                    {Article.title}
                  </Text>
                  <Text style={{ marginTop: 8 }} numberOfLines={2}>
                    {Article.content
                      .substr(0, 100)
                      .replace(/(<([^>]+)>)/gi, "")}
                  </Text>
                  <Text style={{ marginTop: 4, color: "#1696ff" }}>
                    See More
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(Article);
