import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeAsia,
  faStar,
  faQuestionCircle,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import { View, Text, TouchableOpacity } from "react-native-web";

import { withI18n, translate } from "../libs/withI18n";

class Navigation extends Component {
  openPage = (page) => (e) => {
    const {
      // session,
      history,
    } = this.props;
    // console.log(session);
    history.push({
      pathname: `/${page}`,
      // search: `?sessionId=${session._id}`
    });
  };
  render() {
    const { history } = this.props;
    // console.log({ history });
    return (
      <div className="fixed-bottom">
        <section className="card" style={{ borderRadius: 0, border: "none" }}>
          <div
            className="card-block"
            style={{ borderRadius: 0, border: "none" }}
          >
            <View
              style={{
                flexDirection: "row",
                paddingTop: 7,
                paddingBottom: 7,
                shadowColor: "#000",
                shadowOffset: { width: 3, height: 4 },
                shadowOpacity: 0.2,
                shadowRadius: 12,
                elevation: 2,
              }}
            >
              <TouchableOpacity
                onPress={this.openPage("explore")}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <FontAwesomeIcon
                    icon={faGlobeAsia}
                    color={
                      history.location.pathname === "/explore"
                        ? "#00a8ff"
                        : "black"
                    }
                    // size="2x"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 14,
                    color:
                      history.location.pathname === "/explore"
                        ? "#00a8ff"
                        : "black",
                  }}
                >
                  {translate("Eksplor")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={this.openPage("activity")}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <FontAwesomeIcon
                    icon={faStar}
                    color={
                      history.location.pathname === "/activity"
                        ? "#00a8ff"
                        : "black"
                    }
                    // size="2x"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 14,
                    color:
                      history.location.pathname === "/activity"
                        ? "#00a8ff"
                        : "black",
                  }}
                >
                  {translate("Aktifitas")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={this.openPage("help")}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    color={
                      history.location.pathname === "/help"
                        ? "#00a8ff"
                        : "black"
                    }
                    // size="2x"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 14,
                    color:
                      history.location.pathname === "/help"
                        ? "#00a8ff"
                        : "black",
                  }}
                >
                  {translate("Bantuan")}
                </Text>
              </TouchableOpacity>
              <TouchableOpacity
                onPress={this.openPage("account")}
                style={{
                  flex: 1,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <FontAwesomeIcon
                    icon={faUserCircle}
                    color={
                      history.location.pathname === "/account"
                        ? "#00a8ff"
                        : "black"
                    }
                    // size="2x"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </View>
                <Text
                  style={{
                    fontSize: 14,
                    color:
                      history.location.pathname === "/account"
                        ? "#00a8ff"
                        : "black",
                  }}
                >
                  {translate("Akun")}
                </Text>
              </TouchableOpacity>
            </View>
          </div>
        </section>
      </div>
    );
  }
}

export default withI18n(["navigation", "school_life_menu"])(Navigation);
