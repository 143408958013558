import React, { Component } from "react";
import { View, Image, TouchableOpacity, Text, StyleSheet } from "react-native";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

const HORIZONTAL_MARGIN = 10;
const SLIDE_WIDTH = window.innerWidth - 35;
const SLIDER_WIDTH = window.innerWidth;
const ITEM_WIDTH = SLIDE_WIDTH + HORIZONTAL_MARGIN * 2;
const ITEM_HEIGHT = 130;
const BORDER_WIDTH = 3;

const SCHOOLTALK_ARTICLE_URL = "https://support.softwaresekolah.co.id";

const SUPPORT_GRAPHQL_API_ENDPOINT =
  "https://api.support.softwaresekolah.co.id/graphql";

const client = new ApolloClient({
  uri: SUPPORT_GRAPHQL_API_ENDPOINT,
  credentials: "same-origin"
});

const styles = StyleSheet.create({
  container: {
    marginTop: 6,
    marginBottom: 4
  },
  featuredArticleTouchable: {
    height: ITEM_HEIGHT,
    width: ITEM_WIDTH,
    paddingRight: HORIZONTAL_MARGIN,
    paddingVertical: 2
  },
  featuredArticleContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    borderColor: "white",
    borderWidth: BORDER_WIDTH,
    borderRadius: 2,

    shadowColor: "#000",
    shadowOffset: { width: 4, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    elevation: 1
  },
  featuredImage: {
    flex: 1,
    resizeMode: "cover",
    paddingHorizontal: 3,
    width: ITEM_WIDTH - 16
  },
  featuredLabel: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between"
  }
});

class FeaturedArticles extends Component {
  state = {};

  handleOpenArticle = item => () => {
    const articleUrl =
      SCHOOLTALK_ARTICLE_URL + "/artikel/article_content?articleId=" + item._id;
    window.open(articleUrl, "_blank");
  };

  renderFeaturedItem = items => {
    return items.map((item, index) => (
      <Slide index={index} key={item._id}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={styles.featuredArticleTouchable}
          onPress={this.handleOpenArticle(item)}
        >
          <View style={styles.featuredArticleContainer}>
            <Image
              style={styles.featuredImage}
              defaultSource={require("../assets/placeholder-grey.jpg")}
              source={{
                uri: item.thumbnail
              }}
            />
          </View>
        </TouchableOpacity>
      </Slide>
    ));
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <Query query={PROMOTED_ARTICLES}>
          {({ error, loading, data }) => {
            const recentPromotedArticles =
              data && data.recentPromotedArticles
                ? data.recentPromotedArticles
                : [];
            return (
              <View style={styles.container}>
                {!recentPromotedArticles ||
                recentPromotedArticles.length === 0 ? null : (
                  <View style={styles.featuredLabel}>
                    <Text style={{ flex: 1, fontWeight: "bold", fontSize: 15 }}>
                      Featured
                    </Text>
                  </View>
                )}

                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={
                    loading || !recentPromotedArticles
                      ? 0
                      : recentPromotedArticles.length
                  }
                  isPlaying={true}
                  interval={4000}
                  style={{
                    marginLeft: HORIZONTAL_MARGIN,
                    marginRight: HORIZONTAL_MARGIN,
                    height: ITEM_HEIGHT + 10,
                    width: SLIDER_WIDTH
                  }}
                >
                  <Slider>
                    {this.renderFeaturedItem(
                      loading || !recentPromotedArticles
                        ? []
                        : recentPromotedArticles
                    )}
                  </Slider>
                </CarouselProvider>
              </View>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

const PROMOTED_ARTICLES = gql`
  query recentPromotedArticles {
    recentPromotedArticles {
      _id
      title
      isPopUp
      thumbnail
      author
      _createdAt
    }
  }
`;

export default FeaturedArticles;
