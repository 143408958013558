import React, { PureComponent } from "react";
import { View, TouchableOpacity, Image, Text, Linking } from "react-native";
import dayjs from "dayjs";

class CommBook extends PureComponent {
  state = {
    numberOfLines: 3,
    bufferedAudio: "",
    audioPlayState: "STOP",
  };
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, CommBook, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "CommBook",
        Student,
        CommBook,
        _createdAt,
      });
    }
  };
  render() {
    const { CommBook, _createdAt } = this.props;

    // console.log("CommBook", CommBook);
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              marginHorizontal: 5,
              paddingBottom: 4,
              borderRadius: 2,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 2,
              elevation: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <View style={{ flex: 1, justifyContent: "center" }}>
                  <i
                    className={"fa fa-tasks fa-3x"}
                    style={{
                      paddingLeft: 6,
                      color: "#1696ff",
                    }}
                  />
                </View>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    marginBottom: 10,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                    }}
                  >
                    Checklist Kegiatan Siswa
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    // justifyContent: "center",
                    // marginBottom: -15,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {CommBook.studentName ? CommBook.studentName : " "}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginBottom: 10,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Oleh: {CommBook.employeeName}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    // numberOfLines={1}
                    // ellipsizeMode="tail"
                  >
                    Tanggal: {dayjs(CommBook.date).format("DD MMMM YYYY")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default CommBook;
