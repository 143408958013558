import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import { minutesToTimeString } from "../../libs/time";
import dayjs from "dayjs";

import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";

const lang = encryptedLS.get("______lang") || "en";

class StudentAttendancePost extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, StudentAttendanceRecord, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "StudentAttendanceRecord",
        Student,
        StudentAttendanceRecord,
        _createdAt,
      });
    }
  };

  renderCheckIn = (record) => {
    if (
      record.checkInStatus === "Masuk" ||
      record.checkInStatus === "Terlambat"
    ) {
      if (record.checkInMode === "Mesin") {
        return `${translate(record.checkInStatus)} ${translate(
          "Pukul"
        )} ${minutesToTimeString(record.checkInMinutes)}`;
      } else {
        return `${record.checkInStatus}`;
      }
    } else if (record.checkInStatus === "Belum Presensi") {
      return translate("Belum Presensi");
    } else {
      return record.checkInStatus;
    }
  };

  renderCheckOut = (record) => {
    if (
      record.checkOutStatus === "Pulang" ||
      record.checkOutStatus === "Pulang Dulu"
    ) {
      if (record.checkOutMode === "Mesin") {
        return `${translate(record.checkOutStatus)} ${translate(
          "Pukul"
        )} ${minutesToTimeString(record.checkOutMinutes)}`;
      } else {
        return `${record.checkOutStatus}`;
      }
    } else if (record.checkOutStatus === "Belum Presensi") {
      return translate("Belum Presensi");
    } else {
      return "";
    }
  };

  render() {
    const { Student, StudentAttendanceRecord, _createdAt } = this.props;
    const initLang = lang ? lang : "id";

    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              paddingBottom: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12,
              }}
            >
              <Text
                style={{
                  fontSize: 13,
                  fontWeight: "bold",
                  color: "#1696ff",
                  marginTop: 2,
                }}
              >
                <i className="fa fa-exclamation-circle" />{" "}
                {translate("Update Presensi Siswa")}
              </Text>
              <Text style={{ fontSize: 12, color: "#1696ff", marginTop: 2 }}>
                {dayjs(_createdAt)
                  .locale(initLang)
                  .format("dddd, DD MMMM YYYY   HH:mm")}
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                  borderColor: "#eee",
                  borderWidth: 2,
                  borderRadius: 4,
                }}
              >
                <View
                  style={{
                    margin: 4,
                    padding: 0,
                    backgroundColor:
                      Student && Student.profileImageUrl ? "white" : "#037cdd",
                    borderRadius: 3,
                    justifyContent: "center",
                  }}
                >
                  {Student && Student.profileImageUrl ? (
                    <Image
                      source={{
                        uri: Student.profileImageUrl,
                      }}
                      style={{
                        minWidth: 80,
                        minHeight: 135,
                      }}
                      resizeMode="cover"
                    />
                  ) : (
                    <View
                      style={{
                        minWidth: 80,
                        justifyContent: "center",
                        alignItems: "center",
                        paddingHorizontal: 20,
                      }}
                    >
                      <i
                        className="fa fa-user-graduate fa-2x"
                        style={{
                          color: "white",
                        }}
                      />
                    </View>
                  )}
                </View>
                <View
                  style={{
                    flex: 1,
                    marginTop: 4,
                    marginBottom: 8,
                    marginLeft: 6,
                  }}
                >
                  <Text
                    style={{
                      marginTop: 6,
                      fontSize: 17,
                      fontWeight: "bold",
                    }}
                  >
                    Jadwal {StudentAttendanceRecord.scheduleName}
                  </Text>
                  <Text
                    style={{
                      marginBottom: 10,
                      fontSize: 14,
                      fontWeight: "bold",
                    }}
                  >
                    {dayjs(
                      new Date(
                        StudentAttendanceRecord.year,
                        StudentAttendanceRecord.month - 1,
                        StudentAttendanceRecord.date,
                        1,
                        1,
                        1,
                        1
                      )
                    )
                      .locale(initLang)
                      .format("dddd, DD MMMM YYYY")}
                  </Text>
                  <Text
                    style={{ marginBottom: 4, color: "#1696ff", fontSize: 15 }}
                    numberOfLines={1}
                  >
                    <i className="fa fa-user-clock" /> {Student.name}
                  </Text>
                  <Text>
                    <i className="fa fa-calendar-day" />{" "}
                    {translate("Status Masuk")}:{" "}
                    {this.renderCheckIn(StudentAttendanceRecord)}
                  </Text>
                  <Text style={{ marginTop: 2 }}>
                    <i className="fa fa-calendar-day" />{" "}
                    {translate("Status Pulang")}:{" "}
                    {this.renderCheckOut(StudentAttendanceRecord)}
                  </Text>
                  <Text style={{ marginTop: 4, color: "#1696ff" }}>
                    {translate("Klik untuk melihat riwayat presensi")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(StudentAttendancePost);
