/**
 * REQUIRED:
 * @param sessionId => id sesi yang didapatkan setelah login
 *
 * OPTIONAL:
 * @param filter => digunakan untuk mengecek apakah menggunakan filter atau tidak
 * @param filterType => digunakan untuk memilih tipe  filter
 * @param fromDate => digunakan untuk memilih filter Start Date
 * @param toDate => digunakan untuk memilih filter End Date
 */

import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../../components/Overlay";
import { FormModal } from "../../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../../libs/errors";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
// import momentId from 'moment/locale/id';
// moment.locale('id', momentId);

let urlValues;

const CardBook = ({ sessionId, book, counter, onPress, t }) => (
  <div className="col-md-6" key={book._id} onClick={onPress}>
    <style jsx="true">
      {`
        .card {
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
        }
      `}
    </style>
    <div
      className={"card card-material mb-3"}
      style={{ border: "none", borderRadius: "4px" }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-5">
            <img
              alt={book.coverImageUrl}
              src={book.coverImageUrl}
              className="img-fluid"
            />
          </div>
          <div className="col-7">
            <h5 style={{ marginBottom: 0 }} className="card-title">
              <b>{book.name}</b>
            </h5>
            <small className="text-small text-info">{book.author}</small>
            <p className="card-text text-secondary">{book.type}</p>
            <a
              href={`/library/book_detail?sessionId=${sessionId}&studentId=${urlValues.studentId}&bookId=${book._id}&for=STUDENT`}
              className="btn btn-primary"
            >
              Lihat Detail
            </a>
          </div>
        </div>
      </div>
      <div className="card-footer">
        {/* <i className="fa fa-info-circle" /> {t("Tentang Kegiatan Ini")}: */}
        {/* <p className="card-text ml-5">{book.description}</p> */}
      </div>
    </div>
  </div>
);

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row py-2">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                  <div>
                    <p style={{ marginBottom: 0 }}>
                      TA{" "}
                      {student.Classroom ? student.Classroom.academicYear : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);
class ListBook extends Component {
  state = {
    session: {},
    allStudents: [],
    employees: [],
    loading: false,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    startDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
    endDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
    isCheckedMonth: true,
    isCheckedDate: false,

    viewAgendaVisible: false,
    filterFormVisible: false,

    activeBorrows: {
      visible: false,
      Books: [],
    },
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/library/list_book",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
      });
    } else {
      if (students && students.length > 0) {
        this.props.history.replace({
          pathname: "/library/list_book",
          search: `?sessionId=${accountSession._id}&studentId=${
            students[0] ? students[0]._id : ""
          }&PREFIX=${students[0].PREFIX}`,
        });
      }
    }

    // if (accountSession !== null) {
    //   this.setState({
    //     session: accountSession,
    //   });
    //   this.props.history.replace({
    //     pathname: "/library/list_book",
    //     search: `?sessionId=${accountSession._id}`,
    //   });
    // } else {
    //   this.props.history.push({
    //     pathname: "/login",
    //   });
    // }

    this.setState({
      allStudents: [...students],
    });
  };

  handleFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  handleChangeRadio = (key) => (e) => {
    if (key === "isCheckedDate") {
      this.setState({
        [key]: !this.state.isCheckedDate,
        isCheckedMonth: !this.state.isCheckedMonth,
      });
    }
    this.setState({
      [key]: !this.state.isCheckedMonth,
      isCheckedDate: !this.state.isCheckedDate,
    });
  };
  handleChangeMonth = (key) => (e) => {
    if (key === "month") {
      this.setState({
        month: parseInt(e.target.value),
      });
    } else {
      this.setState({
        [key]: e.target.value,
      });
    }
  };
  handleChangeDate = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  handleCloseFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
    };

    this.props.history.replace({
      pathname: "/library/list_book",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  checkActiveBorrow = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);

      const result = await mwvClient.mutate({
        mutation: CHECK_ACTIVE_BORROW,
        variables: {
          studentId: urlValues.studentId,
        },
        fetchPolicy: "no-cache",
      });
      this.setState({
        activeBorrows: {
          visible: true,
          Books: result.data.checkActiveBorrowByStudent,
        },
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  render() {
    let counter = 0;
    const { loading, allStudents, activeBorrows } = this.state;
    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i className="fa fa-book" style={{ fontSize: 20 }} /> Daftar
                Buku
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 20,
                    paddingVertical: 2,
                  }}
                >
                  <i className="fa fa-redo" />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{
                paddingTop: 20,
                paddingLeft: 20,
                paddingRight: 20,
                boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-book" /> Peminjaman Aktif
            </span>
          }
          visible={activeBorrows.visible}
          size="md"
          onClose={(e) => {
            this.setState({
              activeBorrows: {
                visible: false,
                Books: [],
              },
            });
          }}
          hideCloseButton
        >
          {activeBorrows.Books.length > 0 ? (
            activeBorrows.Books.map((b) => (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: 10,
                }}
              >
                <Image
                  style={{ width: 135, height: 135 }}
                  source={b.Book.coverImageUrl}
                />
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 10,
                    }}
                  >
                    {b.Book.name}
                  </Text>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                        }}
                      >
                        Tgl. Pinjam
                      </Text>
                    </View>

                    <View>
                      <Text
                        style={{
                          fontSize: 12,
                        }}
                      >
                        {b.borrowDate}
                      </Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 12,
                      }}
                    >
                      Tgl. Kembali
                    </Text>
                    <Text
                      style={{
                        fontSize: 12,
                      }}
                    >
                      {b.dueDate}
                    </Text>
                  </View>
                </View>

                <hr
                  style={{
                    marginTop: 0,
                    marginBottom: 0,
                    borderColor: "#000",
                  }}
                />
              </View>
            ))
          ) : (
            <View
              style={{
                flex: 1,
                alignItems: "center",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                }}
              >
                Tidak Ada Peminjaman
              </Text>
            </View>
          )}
        </FormModal>
        <div
          className="container-fluid"
          style={{ position: "relative", bottom: "30px", marginTop: 180 }}
        >
          <div className="d-flex justify-content-center mb-2">
            <button
              className="btn btn-sm btn-info"
              onClick={this.checkActiveBorrow}
            >
              <i className="fa fa-info-circle" /> Peminjaman Aktif
            </button>
          </div>
          <div className="row">
            <div className="col">
              <br />
              <div>
                <div
                  className="row"
                  style={
                    this.props.allBooks.length < 1
                      ? { height: "100vh", alignItems: "center" }
                      : {}
                  }
                >
                  {this.props.allBooks.length ? (
                    this.props.allBooks.map((book, index) => (
                      <CardBook
                        key={book._id}
                        sessionId={urlValues.sessionId}
                        book={book}
                        counter={counter++}
                        // onPress={() => }
                        t={translate}
                      />
                    ))
                  ) : (
                    <div className="col text-center">
                      <Image
                        style={{ width: 135, height: 135, margin: "auto" }}
                        source={require("../../assets/books.png")}
                      />
                      <h5 className="text-center text-secondary">
                        {this.props.loading ? (
                          <span>
                            <i className="fa fa-circle-notch fa-spin" />{" "}
                            {translate("Sedang Mengambil Daftar Buku")}.
                          </span>
                        ) : (
                          translate("Tidak ada buku")
                        )}
                      </h5>
                    </div>
                  )}
                </div>
                <hr className="m-6" />
              </div>
            </div>
          </div>
        </div>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
  handleSearchAgenda = async (e) => {
    try {
      if (this.state.isCheckedMonth) {
        this.props.history.replace({
          pathname: "/library/list_book",
          search: `?sessionId=${urlValues.sessionId}&fromDate=${dayjs()
            .startOf("month")
            .set("month", parseInt(this.state.month))
            .set("year", this.state.year)
            .toISOString()}&toDate=${dayjs()
            .endOf("month")
            .set("month", parseInt(this.state.month))
            .set("year", this.state.year)
            .toISOString()}`,
        });
      } else {
        this.props.history.replace({
          pathname: "/library/list_book",
          search: `?sessionId=${urlValues.sessionId}&fromDate=${dayjs(
            this.state.startDateTime
          )
            .startOf("day")
            .toISOString()}&toDate=${dayjs(this.state.endDateTime)
            .endOf("day")
            .toISOString()}`,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  handleAgendaToday = async (e) => {
    try {
      if (this.state.isCheckedMonth) {
        this.props.history.replace({
          pathname: "/library/list_book",
          search: `?sessionId=${urlValues.sessionId}`,
        });
      } else {
        this.props.history.replace({
          pathname: "/library/list_book",
          search: `?sessionId=${urlValues.sessionId}`,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const CHECK_ACTIVE_BORROW = gql`
  mutation checkActiveBorrowByStudent($studentId: String!) {
    checkActiveBorrowByStudent(studentId: $studentId) {
      _id
      Book {
        _id
        name
        coverImageUrl
      }
      borrowDate
      dueDate
    }
  }
`;

const ALL_QUERIES = gql`
  query allBooks($sessionId: String!, $studentId: String) {
    allBooks(sessionId: $sessionId, studentId: $studentId) {
      _id
      name
      coverImageUrl
      author
      type
    }
  }
`;

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      Classroom {
        _id
        academicYear
      }
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

export default withRouter(
  withI18n("agenda")((props) => {
    urlValues = queryString.parse(props.location.search);
    let employeeId = urlValues.employeeId ? urlValues.employeeId : "";
    let studentId = urlValues.studentId ? urlValues.studentId : "";
    let fromDate = urlValues.fromDate ? urlValues.fromDate : "";
    let toDate = urlValues.toDate ? urlValues.toDate : "";
    return (
      <Query
        query={ALL_QUERIES}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
          studentId,
          employeeId,
          fromDate,
          toDate,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <ListBook
              {...props}
              error={error}
              loading={loading}
              allBooks={data && data.allBooks ? data.allBooks : []}
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
