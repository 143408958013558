import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import uuidV4 from "uuid/v4";
import ListSection from "../components/ListSection";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const PaymentsHistory = ({ studentPaymentHistory }) =>
  studentPaymentHistory.length !== 0 ? (
    studentPaymentHistory.map((payment) => (
      <ListSection>
        <section
          className="card"
          key={payment._id}
          style={{
            marginBottom: 8,
            padding: 20,
            borderRadius: 0,
            border: "hidden",
          }}
        >
          <div className="card-block">
            <Text style={{ fontSize: 20 }}>
              <i className="fa fa-receipt" /> {payment.description}
            </Text>
            <br />
            <Text style={{ fontWeight: "bold" }}>
              Nomor Transaksi: {payment.transactionNumber}
            </Text>
            <br />
            <span className="text-info">Tanggal: {payment.date}</span>
            <br />
            <View style={{ flex: 1, flexDirection: "row-reverse" }}>
              <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                Jumlah: Rp.{" "}
                {Number(payment.debit !== 0 ? payment.debit : payment.credit)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </Text>
            </View>

            {/* <h4 className="m-0 mt-2 text-right">
              Jumlah: Rp.{" "}
              {Number(payment.debit !== 0 ? payment.debit : payment.credit)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
            </h4> */}
          </div>
        </section>
      </ListSection>
    ))
  ) : (
    <div>
      <center>
        <Image
          style={{ width: 250, height: 250 }}
          source={require("../assets/owl-laptop-study.png")}
        />
        <h4>
          <b>Riwayat Pembayaran Tidak Ditemukan</b>
        </h4>
      </center>
    </div>
  );
class PaymentHistory extends Component {
  state = {
    filterModalVisible: false,

    criteria: {
      academicYear: "",
      limit: 5,
    },
    allStudents: [],
    allAcademicYears: [],
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);
    let academicYear = "";
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let result = await mwvClient.query({
      query: LIST_QUERIES,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    let terms = [];

    if (urlValues.studentId && urlValues.academicYear && urlValues.limit) {
      this.props.history.replace({
        pathname: "/payment_history",
        search: `?sessionId=${accountSession._id}&studentId=${
          urlValues.studentId
        }&PREFIX=${urlValues.PREFIX}&limit=${
          urlValues.limit ? urlValues.limit : 5
        }&academicYear=${urlValues.academicYear}`,
      });

      terms = await mwvClient.query({
        query: ACADEMIC_YEARS,
        variables: {
          PREFIX: urlValues.PREFIX,
        },
      });

      this.setState({
        criteria: {
          ...this.state.criteria,
          academicYear: urlValues.academicYear,
        },
      });
    } else {
      this.props.history.replace({
        pathname: "/payment_history",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&PREFIX=${
          students[0] ? students[0].PREFIX : ""
        }&limit=${5}&academicYear=${academicYear}`,
      });

      terms = await mwvClient.query({
        query: ACADEMIC_YEARS,
        variables: {
          PREFIX: students[0] ? students[0].PREFIX : "",
        },
      });

      this.setState({
        criteria: {
          ...this.state.criteria,
          academicYear: "",
        },
      });
    }

    this.setState({
      allStudents: [...students],
      allAcademicYears: terms.data.allAcademicYears,
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/payment_history",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&limit=${urlValues.limit}&academicYear=${urlValues.academicYear}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  handleCriteria = (key) => (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [key]: e.target.value,
      },
    });

    urlValues = queryString.parse(this.props.location.search);

    urlValues = {
      ...urlValues,
      [key]: e.target.value,
    };

    this.props.history.replace({
      pathname: "/payment_history",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&limit=${urlValues.limit}&academicYear=${urlValues.academicYear}`,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  generateAcademicYears = () => {
    let listYears = [];
    let initYear = new Date().getFullYear() - 3;
    let counter = 1;
    for (let i = 0; i < 7; i++) {
      let ay = initYear + i + "/" + (initYear + (counter + i));
      listYears.push({
        _id: uuidV4(),
        label: ay,
      });
    }

    return listYears;
  };
  render() {
    const { allStudents, allAcademicYears } = this.state;
    const { studentPaymentHistory } = this.props;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    const foundTerm = allAcademicYears.find((y) => y.isMonthly);

    // const TA = this.generateAcademicYears();

    // let academicYearNow;
    // if (new Date().getMonth() < 6) {
    //   academicYearNow = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYearNow = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={<span>Pilih Siswa</span>}
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-invoice"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                Riwayat Pembayaran
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("limit")}
                      value={this.state.criteria.limit}
                    >
                      <option value="1">1 Transaksi Terakhir</option>
                      <option value="5">5 Transaksi Terakhir</option>
                      <option value="10">10 Transaksi Terakhir</option>
                      <option value="20">20 Transaksi Terakhir</option>
                      <option value="100">100 Transaksi Terakhir</option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("academicYear")}
                      value={this.state.criteria.academicYear}
                    >
                      <option value="" disabled>
                        {foundTerm ? "Pilih Term" : "Pilih Tahun Ajaran"}
                      </option>
                      {allAcademicYears.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === urlValues.academicYear}
                        >
                          {foundTerm ? "Term" : "Tahun Ajaran"} {ta.label}{" "}
                          {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                      {/* {TA.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === academicYearNow}
                        >
                          Tahun Ajaran {ta.label}
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <View style={{ marginTop: 200, padding: 20, marginBottom: 50 }}>
          <PaymentsHistory studentPaymentHistory={studentPaymentHistory} />
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fas fa-search" /> Pilih Siswa
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const LIST_QUERIES = gql`
  query listQuery(
    $_id: String
    $studentId: String
    $academicYear: String
    $limit: Int
  ) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
    studentPaymentHistory(
      studentId: $studentId
      academicYear: $academicYear
      limit: $limit
    ) {
      _id
      transactionNumber
      date
      debit
      credit
      description
    }
  }
`;

const ACADEMIC_YEARS = gql`
  query listAcademicYear($PREFIX: String) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      isMonthly
      inProgress
    }
  }
`;

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  let studentId = urlValues.studentId ? urlValues.studentId : "";
  let limit = urlValues.limit ? parseInt(urlValues.limit) : 5;
  let academicYear = urlValues.academicYear;

  return (
    <Query
      query={LIST_QUERIES}
      client={mwvClient}
      variables={{
        _id: urlValues.studentId,
        studentId,
        limit,
        academicYear,
      }}
    >
      {({ error, loading, data, refetch }) => (
        <div>
          {/* {console.log(data)} */}
          <PaymentHistory
            {...props}
            error={error}
            loading={loading}
            refetch={refetch}
            studentPaymentHistory={
              data && data.studentPaymentHistory
                ? data.studentPaymentHistory
                : []
            }
          />
        </div>
      )}
    </Query>
  );
});
