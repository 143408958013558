import React from "react";
import { View, StatusBar, Text } from "react-native-web";
// import {
//   CircleIndicator
//   // BallIndicator,
//   // BarIndicator
//   // DotIndicator,
//   // MaterialIndicator,
//   // PacmanIndicator,
//   // PulseIndicator,
//   // SkypeIndicator,
//   // UIActivityIndicator,
//   // WaveIndicator
// } from "react-indicators";
import BarLoader from "react-spinners/BarLoader";

export const Overlay = props => (
  <View
    style={{
      backgroundColor: `rgba(0,0,0,${props.alpha ? props.alpha : "0.2"})`,
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 100,
      width: "100%",
      height: props.visible ? "100%" : 0,
      flex: 1,
      justifyContent: "center"
    }}
  >
    {props.children}
  </View>
);

export const LoadingOverlay = props =>
  props.visible ? (
    <View
      style={{
        backgroundColor: `rgba(0,0,0,${props.alpha ? props.alpha : "0.4"})`,
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 100,
        width: "100%",
        height: props.visible ? "100%" : 0,
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <StatusBar
        backgroundColor={`rgba(0,0,0,${props.alpha ? props.alpha : "0.3"})`}
        barStyle="dark-content"
      />
      <Text
        style={{
          color: "white",
          fontSize: 16,
          marginTop: 0,
          marginBottom: 12,
          fontWeight: "bold"
        }}
      >
        {/* <i className="fa fa-exclamation-circle" /> &nbsp;NOW LOADING */}
        SEDANG MEMUAT
      </Text>
      <BarLoader
        sizeUnit={"px"}
        width={window.innerWidth * 0.7}
        height={7}
        color={"white"}
      />
    </View>
  ) : null;
