import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import { addNotification } from "../../App";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

class KegiatanSekolah extends Component {
  state = {
    clickedGroup: [],
  };

  handleShowAspekDetail = (group) => (e) => {
    const { clickedGroup } = this.state;
    if (clickedGroup.length === 0) {
      this.setState({
        clickedGroup: [group._id],
      });
    } else {
      const idx = clickedGroup.findIndex((g) => g === group._id);

      if (idx < 0) {
        this.setState({
          clickedGroup: [...clickedGroup, group._id],
        });
      } else {
        this.setState({
          clickedGroup: clickedGroup.filter((g) => g !== group._id),
        });
      }
    }
  };
  render() {
    const { clickedGroup } = this.state;
    const {
      allAspectActivityGroups,
      aspectActivityScoreDescriptionPerDate,
      date,
    } = this.props;

    return (
      <View
        style={{
          padding: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            margin: 0,
          }}
        >
          <b>Kegiatan Siswa</b>
        </p>
        <p
          style={{
            fontSize: 12,
            margiBottom: "10px",
          }}
        >
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        <View
          style={{
            backgroundColor: "#fff",
          }}
        >
          {allAspectActivityGroups.map((group) => {
            const idx = clickedGroup.findIndex((g) => g === group._id);

            return (
              <div>
                <div
                  style={{
                    backgroundColor: "#d1f1f1",
                    padding: "10px",
                  }}
                  onClick={this.handleShowAspekDetail(group)}
                >
                  <b>
                    <i
                      className={`fa ${
                        idx === -1 ? "fa-caret-down" : "fa-caret-up"
                      }`}
                    />{" "}
                    {group.name}
                  </b>
                </div>

                {group.Aspects.map((aspect) => (
                  <div className={idx === -1 ? "d-none" : "d-block"}>
                    <div
                      className={"row"}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div
                        className="col-1"
                        style={{
                          padding: "0px",
                        }}
                      >
                        <div className="text-center">
                          <p
                            style={{
                              color: aspect.AspectActivityScore
                                ? "#2029f8"
                                : "",
                              marginBottom: "0px",
                            }}
                          >
                            {aspect.AspectActivityScore ? (
                              <i className="fa fa-check" />
                            ) : (
                              ""
                            )}
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-11"
                        style={{
                          padding: "0px",
                          marginBottom: "5px",
                        }}
                      >
                        ({aspect.code}) {aspect.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}

          <div
            style={{
              padding: 10,
            }}
          >
            <p
              style={{
                marginBottom: "5px",
                marginTop: "10px",
              }}
            >
              Catatan Sekolah
            </p>
            <textarea
              style={{
                marginTop: 10,
              }}
              className="form-control disabled"
              placeholder="Catatan Oleh Sekolah"
              disabled
              value={aspectActivityScoreDescriptionPerDate}
            />
          </div>
        </View>
      </View>
    );
  }
}

const ASPECT_GROUP_QUERY = gql`
  query listQuery(
    $academicYear: String!
    $studentId: String!
    $date: String!
    $category: String!
  ) {
    allAspectActivityGroups(
      academicYear: $academicYear
      studentId: $studentId
      category: $category
      date: $date
    ) {
      _id
      name
      Aspects {
        _id
        code
        name
        aspectGroupId
        AspectActivityScore(studentId: $studentId, date: $date)
      }
    }

    aspectActivityScoreDescriptionPerDate(
      studentId: $studentId
      date: $date
      category: $category
    )
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  // console.log(urlValues);
  return (
    <ApolloConsumer>
      {(client) => (
        <Query
          query={ASPECT_GROUP_QUERY}
          client={mwvClient}
          variables={{
            _id: urlValues.sessionId,
            ...props,
          }}
        >
          {({ error, loading, data, refetch }) => (
            <div>
              <KegiatanSekolah
                {...props}
                client={client}
                error={error}
                loading={loading}
                allAspectActivityGroups={
                  data && data.allAspectActivityGroups
                    ? data.allAspectActivityGroups
                    : []
                }
                aspectActivityScoreDescriptionPerDate={
                  data ? data.aspectActivityScoreDescriptionPerDate : ""
                }
                refetch={refetch}
              />
            </div>
          )}
        </Query>
      )}
    </ApolloConsumer>
  );
});
