import React, { Component } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import dayjs from "dayjs";
import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";

const lang = encryptedLS.get("______lang") || "en";

class SchoolAgenda extends Component {
  state = {};

  handleClick = () => {
    if (this.props.onPress) {
      const { _id, _createdAt, SchoolAgenda } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "SchoolAgenda",
        SchoolAgenda,
        _createdAt,
      });
    }
  };

  render() {
    const { _createdAt, SchoolAgenda } = this.props;

    let initLang = lang ? lang : "id";
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 4,
              elevation: 2,
            }}
          >
            <View
              style={{
                paddingVertical: 8,
                paddingHorizontal: 12,
              }}
            >
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  marginBottom: 6,
                }}
              >
                <Image
                  style={{
                    height: 40,
                    width: 40,
                    borderRadius: 20,
                  }}
                  // onError={() => {
                  //   this.setState({
                  //     profileImageUrl: null
                  //   });
                  // }}
                  defaultSource={require("../../assets/logo-small.png")}
                  source={
                    this.state.profileImageUrl
                      ? {
                          uri: this.state.profileImageUrl,
                        }
                      : require("../../assets/logo-small.png")
                  }
                />
                <View
                  style={{ flex: 1, paddingVertical: 2, paddingHorizontal: 10 }}
                >
                  <Text style={{ fontWeight: "bold" }} numberOfLines={1}>
                    School Talk
                  </Text>
                  <Text style={{ fontSize: 13, marginTop: 2 }}>
                    {translate("Membuat agenda kegiatan baru")}.
                  </Text>
                  <Text
                    style={{ fontSize: 12, color: "#1696ff", marginTop: 2 }}
                  >
                    {dayjs(_createdAt)
                      .locale(initLang)
                      .format("dddd, DD MMMM YYYY   HH:mm")}
                  </Text>
                </View>
              </TouchableOpacity>

              <View
                style={{
                  flexDirection: "row",
                  marginVertical: 4,
                  borderColor: "#eee",
                  borderWidth: 2,
                  borderRadius: 4,
                  marginRight: 6,
                }}
              >
                <Image
                  style={{
                    height: 100,
                    width: 100,
                    marginHorizontal: 6,
                  }}
                  defaultSource={require("../../assets/calendar.png")}
                  source={
                    this.state.profileImageUrl
                      ? {
                          uri: this.state.profileImageUrl,
                        }
                      : require("../../assets/calendar.png")
                  }
                />
                <View style={{ flex: 1, paddingVertical: 6 }}>
                  <Text
                    style={{ fontWeight: "bold", fontSize: 16 }}
                    numberOfLines={3}
                  >
                    <i className="fa fa-calendar-check" /> {SchoolAgenda.title}
                  </Text>
                  <View>
                    <Text
                      style={{ fontSize: 12, color: "#1696ff", marginTop: 4 }}
                    >
                      {translate("Pada tanggal")}{" "}
                      {dayjs(SchoolAgenda.startDateTime)
                        .locale(initLang)
                        .format("dddd, DD MMMM YYYY   HH:mm")}
                    </Text>
                  </View>
                  <Text style={{ marginTop: 6 }} numberOfLines={2}>
                    {SchoolAgenda.description.substr(0, 100)}
                  </Text>
                  <Text style={{ marginTop: 6, color: "#1696ff" }}>
                    See More
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(SchoolAgenda);
