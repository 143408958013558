/**
 * REQUIRED:
 * @param sessionId => id sesi yang didapatkan setelah login
 *
 * OPTIONAL:
 * @param filter => digunakan untuk mengecek apakah menggunakan filter atau tidak
 * @param filterType => digunakan untuk memilih tipe  filter
 * @param fromDate => digunakan untuk memilih filter Start Date
 * @param toDate => digunakan untuk memilih filter End Date
 */

import React, { Component } from "react";
import {
  Text,
  View,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";
// import momentId from 'moment/locale/id';
// moment.locale('id', momentId);

let urlValues;
let colorMonth = ["#2980b9", "#16a085", "#f39c12", "#2c3e50", "#8e44ad"];
let arrMonths = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];
let arrYear = [];
for (let i = 1970; i <= 2070; i++) {
  arrYear.push(i);
}
const CardAgenda = ({ agenda, counter, t }) => (
  <div className="col-md-6" key={agenda._id}>
    <style jsx="true">
      {`
        .card {
          box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
            0px 1px 1px 0px rgba(0, 0, 0, 0.14),
            0px 2px 1px -1px rgba(0, 0, 0, 0.12);
          border: none;
        }
      `}
    </style>
    <div
      className={"card card-material mb-3"}
      style={{ border: "none", borderRadius: "4px" }}
    >
      <div className="card-body">
        <div className="row">
          <div className="col-2">
            <i
              className="fa fa-calendar-day fa-3x"
              style={{ color: colorMonth[counter % colorMonth.length] }}
            />
          </div>
          <div className="col-10">
            <h5 style={{ marginBottom: 0 }} className="card-title">
              <b>{agenda.title}</b>
            </h5>
            <small className="text-small text-info">
              {dayjs(agenda.startDateTime).format("YYYY-MM-DD") ===
              dayjs(agenda.endDateTime).format("YYYY-MM-DD")
                ? dayjs(agenda.startDateTime).format("YYYY-MM-DD")
                : dayjs(agenda.startDateTime).format("YYYY-MM-DD") +
                  " sampai tanggal " +
                  dayjs(agenda.endDateTime).format("YYYY-MM-DD")}
            </small>
            <p className="card-text">
              <i className="fa fa-university" /> {agenda.SchoolInformation.name}
            </p>
          </div>
        </div>
      </div>
      <div className="card-footer">
        <i className="fa fa-info-circle" /> {t("Tentang Kegiatan Ini")}:
        {/* <p className="card-text ml-5">{agenda.description}</p> */}
        <p
          className="card-text ml-5"
          dangerouslySetInnerHTML={{
            __html: agenda.description,
          }}
        />
      </div>
    </div>
  </div>
);

const FilterForm = ({
  formData,
  handleChangeRadio,
  handleChangeDate,
  handleChangeMonth,
  handleSearchAgenda,
  handleAgendaToday,
  t,
}) => (
  <div className="card card-blue">
    <div className="card-body">
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="radioMonth"
          onChange={handleChangeRadio("isCheckedMonth")}
          checked={formData.isCheckedMonth}
        />
        <label className="form-check-label" htmlFor="radioMonth">
          {t("Filter bulan")}
        </label>
      </div>
      <div
        className="form-row"
        style={{
          display: formData.isCheckedMonth ? "" : "none",
        }}
      >
        <div className="col mt-2">
          <select
            className="form-control"
            onChange={handleChangeMonth("month")}
            value={formData.month}
          >
            <option disabled hidden value="" />
            {arrMonths.map((month, index) => (
              <option key={month} value={index}>
                {t(month)}
              </option>
            ))}
          </select>
        </div>
        <div className="col mt-2">
          <select
            className="form-control"
            onChange={handleChangeMonth("year")}
            value={formData.year}
          >
            {arrYear.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select>
        </div>
      </div>
      <br />
      <div className="form-check">
        <input
          className="form-check-input"
          type="radio"
          id="radioDate"
          onChange={handleChangeRadio("isCheckedDate")}
          checked={formData.isCheckedDate}
        />
        <label className="form-check-label" htmlFor="radioDate">
          {t("Filter tanggal")}
        </label>
      </div>
      <div
        className="form-row"
        style={{
          display: formData.isCheckedDate ? "" : "none",
        }}
      >
        <div className="col mt-3">
          <label>Start Date</label>
          <input
            type="date"
            className="form-control"
            value={formData.startDateTime}
            onChange={handleChangeDate("startDateTime")}
          />
        </div>
        <div className="col mt-3">
          <label>End Date</label>
          <input
            type="date"
            className="form-control"
            value={formData.endDateTime}
            onChange={handleChangeDate("endDateTime")}
          />
        </div>
      </div>
      <br />

      <button
        type="submit"
        className="btn btn-primary btn-block"
        onClick={handleSearchAgenda}
        style={{
          boxShadow: "0px 2px 5px 2px #ddd",
        }}
      >
        <i className="fa fa-check-circle" /> {t("Terapkan")}
      </button>
      <hr />
      <button
        type="submit"
        className="btn btn-info mr-3 btn-block"
        onClick={handleAgendaToday}
        style={{
          boxShadow: "0px 2px 5px 2px #ddd",
        }}
      >
        <i className="fa fa-calendar-check" /> {t("Agenda Hari ini")}
      </button>
    </div>
  </div>
);

class Agenda extends Component {
  state = {
    session: {},
    employees: [],
    loading: false,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    startDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
    endDateTime: dayjs(new Date()).format("YYYY-MM-DD"),
    isCheckedMonth: true,
    isCheckedDate: false,

    viewAgendaVisible: false,
    filterFormVisible: false,
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);
    if (accountSession !== null) {
      this.setState({
        session: accountSession,
      });
      this.props.history.replace({
        pathname: "/agenda",
        search: `?sessionId=${accountSession._id}`,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  handleFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  handleChangeRadio = (key) => (e) => {
    if (key === "isCheckedDate") {
      this.setState({
        [key]: !this.state.isCheckedDate,
        isCheckedMonth: !this.state.isCheckedMonth,
      });
    }
    this.setState({
      [key]: !this.state.isCheckedMonth,
      isCheckedDate: !this.state.isCheckedDate,
    });
  };
  handleChangeMonth = (key) => (e) => {
    if (key === "month") {
      this.setState({
        month: parseInt(e.target.value),
      });
    } else {
      this.setState({
        [key]: e.target.value,
      });
    }
  };
  handleChangeDate = (key) => (e) => {
    this.setState({
      [key]: e.target.value,
    });
  };
  handleCloseFilter = (e) => {
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  refresh = () => {
    window.location.reload();
  };
  render() {
    let counter = 0;
    const { loading } = this.state;
    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-calendar" style={{ fontSize: 20 }} /> Agenda
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <FormModal
          title={
            <span>
              <i className="fa fa-filter" />{" "}
              {translate("Filter Agenda Sekolah")}
            </span>
          }
          visible={this.state.filterFormVisible}
          onClose={this.handleCloseFilter}
          size="lg"
          hideCloseButton
        >
          <FilterForm
            formData={this.state}
            handleChangeRadio={this.handleChangeRadio}
            handleChangeDate={this.handleChangeDate}
            handleChangeMonth={this.handleChangeMonth}
            handleSearchAgenda={this.handleSearchAgenda}
            handleAgendaToday={this.handleAgendaToday}
            t={translate}
          />
        </FormModal>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.handleFilter}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <i className="fa fa-filter" /> Filter Agenda
          </button>
        </div>
        <div
          className="container-fluid mt-3"
          style={{ position: "relative", bottom: "30px" }}
        >
          <div className="row">
            <div className="col">
              <br />
              {urlValues.fromDate && urlValues.toDate ? (
                <div>
                  <div className="row">
                    <div className="col mt-3 mb-5">
                      <h4>{"Semua Agenda"}</h4>
                      <span>
                        <i className="fa fa-filter" /> {translate("Antara")}{" "}
                        {dayjs(urlValues.fromDate).format("DD MMMM")}{" "}
                        {translate("sampai")}{" "}
                        {dayjs(urlValues.toDate).format("DD MMMM")}.
                      </span>
                    </div>
                  </div>
                  <div className="row mb-5">
                    {this.props.filteredAgenda.length ? (
                      this.props.filteredAgenda.map((agenda, index) => (
                        <CardAgenda
                          key={agenda._id}
                          agenda={agenda}
                          counter={counter++}
                          t={translate}
                        />
                      ))
                    ) : (
                      <div className="col text-center">
                        <Image
                          style={{ width: 135, height: 135, margin: "auto" }}
                          source={require("../assets/03-calendar.png")}
                        />
                        <h5 className="text-center text-secondary">
                          {translate("Tidak ada Agenda")}
                        </h5>
                      </div>
                    )}
                  </div>
                  <hr className="m-6" />
                </div>
              ) : (
                <div>
                  <div className="row my-3">
                    <div className="col">
                      <h4>
                        <i className="fa fa-calendar-check" />
                        &nbsp; {translate("Agenda Hari Ini")}
                      </h4>
                    </div>
                  </div>
                  <div className="row">
                    {this.props.todayAgenda.length ? (
                      this.props.todayAgenda.map((agenda, index) => (
                        <CardAgenda
                          key={agenda._id}
                          agenda={agenda}
                          counter={counter++}
                          t={translate}
                        />
                      ))
                    ) : (
                      <div className="col text-center">
                        <Image
                          style={{ width: 135, height: 135, margin: "auto" }}
                          source={require("../assets/03-calendar.png")}
                        />
                        <h5 className="text-center text-secondary">
                          {this.props.loading ? (
                            <span>
                              <i className="fa fa-circle-notch fa-spin" />{" "}
                              {translate("Sedang Mengambil Agenda")}.
                            </span>
                          ) : (
                            translate("Tidak ada Agenda")
                          )}
                        </h5>
                      </div>
                    )}
                  </div>
                  <hr className="m-6" />
                  <div className="row mb-5">
                    <div className="col-12 mb-3">
                      <h4>
                        <i className="fa fa-calendar-day" />
                        &nbsp; {translate("Agenda Akan Datang")}
                      </h4>
                    </div>
                    {this.props.upcomingAgenda.length > 0 ? (
                      this.props.upcomingAgenda.map((agenda) => (
                        <CardAgenda
                          key={agenda._id}
                          agenda={agenda}
                          counter={counter++}
                          t={translate}
                        />
                      ))
                    ) : (
                      <div className="col text-center">
                        <Image
                          style={{ width: 135, height: 135, margin: "auto" }}
                          source={require("../assets/03-calendar.png")}
                        />
                        <h5 className="text-center text-secondary">
                          {this.props.loading ? (
                            <span>
                              <i className="fa fa-circle-notch fa-spin" />{" "}
                              {translate("Sedang Mengambil Agenda")}.
                            </span>
                          ) : (
                            translate("Tidak ada Agenda")
                          )}
                        </h5>
                      </div>
                    )}
                  </div>
                  <hr className="m-6" />
                </div>
              )}
            </div>
          </div>
        </div>
      </View>
    );
  }
  handleSearchAgenda = async (e) => {
    try {
      if (this.state.isCheckedMonth) {
        this.props.history.replace({
          pathname: "/agenda",
          search: `?sessionId=${urlValues.sessionId}&fromDate=${dayjs()
            .startOf("month")
            .set("month", parseInt(this.state.month))
            .set("year", this.state.year)
            .toISOString()}&toDate=${dayjs()
            .endOf("month")
            .set("month", parseInt(this.state.month))
            .set("year", this.state.year)
            .toISOString()}`,
        });
      } else {
        this.props.history.replace({
          pathname: "/agenda",
          search: `?sessionId=${urlValues.sessionId}&fromDate=${dayjs(
            this.state.startDateTime
          )
            .startOf("day")
            .toISOString()}&toDate=${dayjs(this.state.endDateTime)
            .endOf("day")
            .toISOString()}`,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
  handleAgendaToday = async (e) => {
    try {
      if (this.state.isCheckedMonth) {
        this.props.history.replace({
          pathname: "/agenda",
          search: `?sessionId=${urlValues.sessionId}`,
        });
      } else {
        this.props.history.replace({
          pathname: "/agenda",
          search: `?sessionId=${urlValues.sessionId}`,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      filterFormVisible: !this.state.filterFormVisible,
    });
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ALL_AGENDA = gql`
  query allAgendas($sessionId: String!, $fromDate: String, $toDate: String) {
    todayAgenda(sessionId: $sessionId) {
      _id
      title
      description
      startDateTime
      endDateTime
      SchoolInformation {
        _id
        name
      }
      FoundationInformation {
        _id
        name
      }
    }
    upcomingAgenda(sessionId: $sessionId) {
      _id
      title
      description
      startDateTime
      endDateTime
      SchoolInformation {
        _id
        name
      }
      FoundationInformation {
        _id
        name
      }
    }
    filteredAgenda(
      sessionId: $sessionId
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      title
      description
      startDateTime
      endDateTime
      SchoolInformation {
        _id
        name
      }
      FoundationInformation {
        _id
        name
      }
    }
  }
`;

export default withRouter(
  withI18n("agenda")((props) => {
    urlValues = queryString.parse(props.location.search);
    let employeeId = urlValues.employeeId ? urlValues.employeeId : "";
    let fromDate = urlValues.fromDate ? urlValues.fromDate : "";
    let toDate = urlValues.toDate ? urlValues.toDate : "";
    return (
      <Query
        query={ALL_AGENDA}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
          employeeId,
          fromDate,
          toDate,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <Agenda
              {...props}
              error={error}
              loading={loading}
              todayAgenda={data && data.todayAgenda ? data.todayAgenda : []}
              upcomingAgenda={
                data && data.upcomingAgenda ? data.upcomingAgenda : []
              }
              filteredAgenda={
                data && data.filteredAgenda ? data.filteredAgenda : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
