import React from "react";
import { View, TouchableOpacity, Text } from "react-native-web";
// import Ionicon from "react-native-vector-icons/Ionicons";
import withPreventDoubleClick from "../libs/withPreventDoubleClick";
import { faArrowLeft, faUserCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const EnhancedTouchableOpacity = withPreventDoubleClick(TouchableOpacity);

const HeaderBar = ({ title, right, backgroundColor }) => (
  <View
    style={{
      backgroundColor: backgroundColor ? backgroundColor : "#1696ff",
      padding: 14,
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 2,
      elevation: 3,
      height: 55,
      // position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      flexDirection: "row",
      alignItems: "center",
      zIndex: 101,
    }}
  >
    <Text
      onClick={() => window.history.back()}
      style={{
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 8,
        marginRight: 15,
      }}
    >
      <FontAwesomeIcon
        icon={faArrowLeft}
        color="white"
        style={{
          fontSize: 20,
        }}
      />
    </Text>
    <Text
      style={{
        color: "white",
        fontSize: 20,
        fontWeight: "bold",
        marginLeft: 8,
        flex: 1,
      }}
    >
      {title}
    </Text>
    <View style={{ flexDirection: "row", justifyContent: "flex-end" }}>
      {right}
    </View>
  </View>
);
export default HeaderBar;

export const composeHeaderBar = ({
  navigation,
  title,
  icon,
  rightLabel,
  backgroundColor,
}) => ({
  title: title,
  headerStyle: {
    backgroundColor: backgroundColor ? backgroundColor : "#1696ff",
    // padding: 0
  },
  headerBackTitleStyle: {
    // padding: 0,
    margin: 0,
  },
  headerLeft: null,
  headerTitle: (
    <View
      style={{
        paddingVertical: 15,
        paddingHorizontal: 10,
      }}
    >
      <Text
        style={{
          color: "white",
          fontSize: 20,
          fontWeight: "bold",
          marginLeft: 8,
          flex: 1,
        }}
      >
        {icon ? (
          <FontAwesomeIcon
            icon={faUserCircle}
            color="white"
            // size="2x"
            style={{
              fontSize: 20,
            }}
          />
        ) : null}
        &nbsp; {title}
      </Text>
    </View>
  ),
  headerRight: (
    <EnhancedTouchableOpacity
      onPress={(e) => {
        navigation.goBack(null);
      }}
    >
      <Text
        style={{
          paddingVertical: 20,
          paddingRight: 12,
          paddingLeft: 20,
          color: "white",
          fontSize: 16,
        }}
      >
        {rightLabel ? rightLabel : "CLOSE"}
      </Text>
    </EnhancedTouchableOpacity>
  ),
});
