import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { formatCurrency } from "../../libs/numbers";
import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";
import dayjs from "dayjs";

const lang = encryptedLS.get("______lang") || "en";

class StudentBillPayment extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, StudentBillPayment, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: "StudentBillPayment",
        Student,
        StudentBillPayment,
        _createdAt,
      });
    }
  };

  render() {
    const { Student, StudentBillPayment, _createdAt } = this.props;
    const initLang = lang ? lang : "id";
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 6,
              paddingBottom: 4,
              borderRadius: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  source={require("../../assets/banking-service-counter-female-asian.png")}
                />
              </View>

              <View
                style={{
                  flex: 1,
                  paddingVertical: 8,
                  paddingHorizontal: 12,
                }}
              >
                <Text
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: 2,
                  }}
                >
                  <i className="fa fa-check-circle" /> {StudentBillPayment.type}{" "}
                  {translate("PEMBAYARAN TAGIHAN")}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    color: "#44bd32",
                    marginTop: 2,
                  }}
                >
                  {dayjs(_createdAt)
                    .locale(initLang)
                    .format("dddd, DD MMMM YYYY   HH:mm")}
                </Text>
                <Text style={{ marginVertical: 6, fontSize: 20 }}>
                  {StudentBillPayment.transactionNumber}
                </Text>
                <Text
                  style={{
                    marginBottom: 4,
                    color: "#44bd32",
                    fontWeight: "bold",
                  }}
                >
                  <i className="fa fa-contact" />
                  {/* <Ionicon name="md-contact" size={16} />{" "} */}
                  {Student ? Student.name : ""}
                </Text>
                <Text>{StudentBillPayment.description}</Text>
                <View
                  style={{
                    marginTop: 6,
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      marginRight: 10,
                    }}
                  >
                    Total {translate("Rp")}.{" "}
                    {formatCurrency(StudentBillPayment.total)}{" "}
                  </Text>
                </View>
              </View>
            </View>

            <View
              style={{
                flex: 1,
                borderTopColor: "#e0e0e0",
                borderTopWidth: 1,
                marginTop: 4,
                paddingVertical: 8,
                paddingHorizontal: 24,
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginTop: 2,
                }}
              >
                <i className="fa fa-info-circle" />{" "}
                {translate("Tagihan yang Dibayar")}:
              </Text>

              {StudentBillPayment.details.map((detail) => (
                <View
                  key={detail._id}
                  style={{
                    flex: 1,
                    marginTop: 10,
                    marginLeft: 6,
                    marginBottom: 2,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      textAlign: "right",
                    }}
                  >
                    <i className="fa fa-check-circle" /> {translate("Tagihan")}{" "}
                    {detail.studentBillName}
                    {detail.academicYear && detail.month
                      ? ` (${translate(detail.month)} ${translate("TA")}. ${
                          detail.academicYear
                        })`
                      : ""}
                    {"\n"}
                    {translate("Pembayaran Sebesar")}{" "}
                    <b>
                      {translate("Rp")}. {formatCurrency(detail.amount)}
                    </b>
                  </Text>
                </View>
              ))}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(StudentBillPayment);
