import React, { Component } from "react";
import { View, TouchableOpacity, Text, Image } from "react-native";
import { withI18n, translate } from "../../libs/withI18n";
import encryptedLS from "../../libs/encryptedLS";
import dayjs from "dayjs";

const lang = encryptedLS.get("______lang") || "en";

class Classwork extends Component {
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, Classwork, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        activityType: Classwork.type === "Classwork" ? "Classwork" : "Feed",
        Student,
        Classwork,
        _createdAt,
      });
    }
  };

  render() {
    const { Student, Classwork, _createdAt } = this.props;
    const initLang = lang ? lang : "id";
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              flexDirection: "column",
              marginHorizontal: 6,
              paddingBottom: 4,
              borderRadius: 4,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 1 },
              shadowOpacity: 0.5,
              shadowRadius: 2,
              elevation: 2,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  source={
                    Classwork.type === "Feed"
                      ? require("../../assets/Surat-Info.png")
                      : require("../../assets/Surat-Tugas.png")
                  }
                />
              </View>
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    {Classwork.type === "Feed" ? "Feed Baru" : "Tugas Baru"}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {Classwork.title}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    marginBottom: 5,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Mapel: <b>{Classwork.subjectName}</b>
                  </Text>
                </View>
                <View
                  style={
                    {
                      // flex: 1,
                      // alignItems: "flex-start",
                      // justifyContent: "center",
                    }
                  }
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Oleh: {Classwork.teacherName}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#2196f3",
                    }}
                  >
                    {dayjs(Classwork._createdAt).format(
                      "dddd, DD MMMM YYYY [  ] HH:mm"
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default withI18n(["activity", "navigation"])(Classwork);
