import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import ListSection from "../components/ListSection";
import PaymentMethod from "../components/SchoolPay/PaymentMethod";
import EWalletConfirmation from "../components/SchoolPay/EWalletConfirmation";
import { withI18n, translate } from "../libs/withI18n";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MobileBanking from "../components/PaymentTutorial/MobileBanking";
import ATM from "../components/PaymentTutorial/ATM";
import { handleError } from "../libs/errors";
import dayjs from "dayjs";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

import QRCode from "qrcode.react";
import Countdown, { zeroPad } from "react-countdown";
import FileSaver from "file-saver";
import * as NQRCode from "qrcode";

let urlValues;

const CheckoutBills = ({ checkOutBills }) => {
  const totalBills = checkOutBills
    .map((c) => c.unpaid)
    .reduce((acc, curr) => acc + curr, 0);

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <View
        style={{
          flex: 1,
          alignItems: "center",
        }}
      >
        <Image
          style={{ width: 300, height: 300 }}
          source={require("../assets/mobile-smartphone-credit-card-payment.jpg")}
        />
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text>Daftar Tagihan</Text>
        <Text>Nominal Tagihan</Text>
      </View>
      <View
        style={{
          marginTop: 10,
        }}
      >
        {checkOutBills.map((bill) => (
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              marginBottom: 5,
              justifyContent: "space-between",
            }}
          >
            <Text>
              ({bill.month}) - {bill.StudentBillType.name}
            </Text>
            <View
              style={{
                flex: 1,
                alignItems: "flex-end",
              }}
            >
              <Text>
                <b>
                  Rp:{" "}
                  {Number(bill.unpaid)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </b>
              </Text>
            </View>

            <hr style={{ borderWidth: "2px", margin: 0 }} />
          </View>
        ))}

        <View
          style={{
            marginTop: 20,
            flex: 1,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              fontSize: 18,
            }}
          >
            <b>Total Tagihan</b>
          </Text>
          <View
            style={{
              flex: 1,
              alignItems: "flex-end",
            }}
          >
            <Text
              style={{
                fontSize: 18,
              }}
            >
              <b>
                Rp:{" "}
                {Number(totalBills)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
};

class StudentBillDetail extends Component {
  state = {
    selectedBills: [],
    checkOutBills: [],

    vaCopyValue: "",
    vaCopied: false,

    clickedBill: {},

    listBillsVisible: true,
    listPaymentsVisible: false,
    filterModalVisible: false,
    listPaymentByBillIdVisible: false,

    paymentMethod: "",
    otherPaymentVisible: false,
    bankTransferConfirmationVisible: false,
    phoneNumber: "",
    selectedPaymentMethod: "",

    selectTutorial: {
      mobile: false,
      atm: false,
    },
    qrisObject: {
      qrisModalVisible: false,
    },
    fee: 0,
  };

  refresh = () => {
    window.location.reload();
  };

  selectBill = (bill) => (e) => {
    let currentBills = this.state.selectedBills;

    if (currentBills.indexOf(bill._id) === -1) {
      this.setState({
        selectedBills: [...currentBills, bill._id],
        checkOutBills: [...this.state.checkOutBills, bill],
      });
    } else {
      this.setState({
        selectedBills: [
          ...this.state.selectedBills.filter((b) => b !== bill._id),
        ],
        checkOutBills: [
          ...this.state.checkOutBills.filter((b) => b._id !== bill._id),
        ],
      });
    }
  };

  openCheckoutModal = () => {
    this.setState({
      checkOutModalVisible: true,
    });
  };

  openPaymentMethod = (e) => {
    if (e) e.preventDefault();
    this.setState({
      paymentMethodVisible: true,
      selectedPaymentMethod: "",
    });
  };

  handleSelectPayment = (key) => (e) => {
    this.setState({
      paymentMethod: key,
      selectedPaymentMethod: key,
    });
  };

  openOtherPayment = (payment) => (e) => {
    if (e) e.preventDefault();
    const { checkOutBills } = this.state;

    const totalBills = checkOutBills
      .map((c) => c.unpaid)
      .reduce((acc, curr) => acc + curr, 0);

    let fee = 0;
    if (payment === "linkAja") {
      fee = 4500; //5000
    } else {
      fee = Math.round(totalBills * 0.01);
    }

    this.setState({
      otherPaymentVisible: true,
      paymentMethodVisible: false,
      checkOutModalVisible: false,
      totalBills,
      fee,
      finalBills: totalBills + fee,
    });
  };

  openBankConfirmation = (e) => {
    if (e) e.preventDefault();
    const { checkOutBills, paymentMethod } = this.state;
    const totalBills = checkOutBills
      .map((c) => c.unpaid)
      .reduce((acc, curr) => acc + curr, 0);
    // const fee = 5000;

    let fee = 0;
    if (paymentMethod === "bca") {
      fee = 4500;
    } else if (paymentMethod === "bsi") {
      fee = 5000;
    } else if (paymentMethod === "tokopedia" || paymentMethod === "shopee") {
      fee = 1000;
    } else {
      fee = 3500;
    }

    this.setState({
      paymentMethodVisible: false,
      bankTransferConfirmationVisible: true,
      checkOutModalVisible: false,
      totalBills,
      fee,
      finalBills: totalBills + fee,
    });
  };

  handlePhoneNumber = (e) => {
    this.setState({
      phoneNumber: e.target.value.replace(/\D/g, ""),
    });
  };

  openTutorial = (bankCode, type) => (e) => {
    if (type === "mobile") {
      this.setState({
        selectTutorial: {
          mobile: true,
          atm: false,
          bankCode,
        },
      });
    } else {
      this.setState({
        selectTutorial: {
          mobile: false,
          atm: true,
          bankCode,
        },
      });
    }
  };

  getTick = async (e) => {
    if (e.seconds % 5 === 0) {
      const getQueries = await mwvClient.query({
        query: QRIS_POLLING_QUERY,
        variables: {
          _id: this.state.qrisObject.external_id,
        },
        fetchPolicy: "no-cache",
      });

      if (
        getQueries &&
        getQueries.data &&
        getQueries.data.schoolPayBillQRISPollingCheck
      ) {
        if (getQueries.data.schoolPayBillQRISPollingCheck === "paid") {
          this.setState({
            qrisObject: {
              qrisModalVisible: false,
            },

            showVAFormVisible: false,
            paymentMethodVisible: false,
            bankTransferConfirmationVisible: false,
            checkOutModalVisible: false,
            otherPaymentMethodVisible: false,

            selectedBills: [],
            checkOutBills: [],
            paymentMethod: "",
          });
          this.props.refetch();
          setTimeout(() => window.location.reload(), 1000);
        }
      }
    }
  };

  download = async () => {
    const base64 = await NQRCode.toDataURL(this.state.qrisObject.qr_string);

    var byteString = atob(base64.split(",")[1]);
    // separate out the mime component
    var mimeString = base64.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    FileSaver.saveAs(blob, `qrcode`);
  };

  render() {
    const { groupedStudentBillsByIds } = this.props;
    const {
      clickedBill,
      selectedBills,
      checkOutBills,
      totalBills,
      fee,
      finalBills,
      phoneNumber,
      paymentMethod,

      selectTutorial,
    } = this.state;

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-qrcode" />{" "}
            </span>
          }
          visible={this.state.qrisObject.qrisModalVisible}
          onClose={(e) => {
            this.setState({
              qrisObject: {
                qrisModalVisible: false,
              },
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              otherPaymentVisible: false,
              bankTransferConfirmationVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            });
          }}
        >
          <div className="HpQrcode">
            <div className="text-center">
              <h5>
                Silahkan Scan QR Code dibawah ini untuk membayar. Atau
                screenshoot pada bagian ini
              </h5>
              <p>
                QR Code akan otomatis tertutup apabila pembayaran telah berhasil
                dilakukan.
              </p>
              {this.state.qrisObject.qr_string ? (
                <div>
                  <QRCode
                    value={this.state.qrisObject.qr_string}
                    imageSettings={{
                      src: require("../assets/logo-small.png"),
                      height: 25,
                      width: 25,
                      excavate: true,
                    }}
                    size={256}
                    renderAs={"canvas"}
                  />

                  <br />
                  <br />
                  <a
                    // ref={(ref) => (this.downloadRef = ref)}
                    onClick={this.download}
                  >
                    <buttonc
                      className="btn btn-sm"
                      style={{
                        backgroundColor: "#fb8c00",
                        color: "#fff",
                      }}
                    >
                      Download
                    </buttonc>
                  </a>

                  <p>Segera bayar dalam</p>
                  <Countdown
                    date={this.state.qrisObject.timeCountDown}
                    onTick={this.getTick}
                    onComplete={this.handleExpiredQRIS}
                  />
                  <h5>Trx. Date {dayjs().format("YYYY-MM-DD HH:mm:ss")}</h5>
                </div>
              ) : null}
            </div>
          </div>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" /> {"Review Detil Tagihan"}
            </span>
          }
          visible={
            this.state.checkOutModalVisible
              ? this.state.checkOutModalVisible
              : false
          }
          size="md"
          onSubmit={this.openPaymentMethod}
          submitButtonLabel={
            <span>
              <i className="fa fa-check-circle " /> {"Bayar "}
            </span>
          }
          onClose={(e) =>
            this.setState({
              checkOutModalVisible: false,
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              otherPaymentVisible: false,
              bankTransferConfirmationVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            })
          }
        >
          <CheckoutBills checkOutBills={this.state.checkOutBills} />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-credit-card" /> {"Pilih Metode Pembayaran"}
            </span>
          }
          visible={
            this.state.paymentMethodVisible
              ? this.state.paymentMethodVisible
              : false
          }
          size="md"
          onClose={(e) =>
            this.setState({
              paymentMethodVisible: false,
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              otherPaymentVisible: false,
              bankTransferConfirmationVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            })
          }
          onSubmit={
            !this.state.paymentMethod
              ? null
              : this.state.paymentMethod === "ovo" ||
                this.state.paymentMethod === "dana" ||
                this.state.paymentMethod === "linkAja" ||
                this.state.paymentMethod === "qris"
              ? this.openOtherPayment(this.state.paymentMethod)
              : this.openBankConfirmation
            // : this.submitCheckout
          }
          submitButtonLabel={
            <span>
              <i className="fa fa-check-circle " /> {"Pilih dan Checkout"}
            </span>
          }
        >
          <PaymentMethod
            handleSelectPayment={this.handleSelectPayment}
            selectedPaymentMethod={
              this.state.selectedPaymentMethod
                ? this.state.selectedPaymentMethod
                : ""
            }
            PREFIX={urlValues.PREFIX}
          />
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" />{" "}
              {paymentMethod === "tokopedia"
                ? "Pembayaran TOKOPEDIA"
                : paymentMethod === "shopee"
                ? "Pembayaran SHOPEE"
                : "Pembayaran via Transfer Bank"}
            </span>
          }
          visible={this.state.bankTransferConfirmationVisible}
          size="md"
          onSubmit={this.submitCheckout}
          onClose={(e) =>
            this.setState({
              bankTransferConfirmationVisible: false,
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              otherPaymentVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            })
          }
          submitButtonLabel={
            <span>
              <i className="fa fa-check-circle " /> {"Setuju & Bayar"}
            </span>
          }
        >
          <View>
            <Text>
              Biaya transaksi dengan Transfer{" "}
              {paymentMethod === "tokopedia" ? (
                <b>tokopedia</b>
              ) : paymentMethod === "shopee" ? (
                <b>shopee</b>
              ) : (
                "Bank"
              )}{" "}
              akan dikenakan biaya administrasi Rp. {fee}/transaksi
            </Text>

            {paymentMethod === "tokopedia" || paymentMethod === "shopee" ? (
              <Text
                style={{
                  marginTop: 10,
                }}
              >
                Biaya berikut ini belum termasuk admin dari pihak{" "}
                <b>{paymentMethod.toUpperCase()}</b>
              </Text>
            ) : null}

            <View
              style={{
                marginTop: 15,
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <View style={{ width: 100 }}>
                <Text>Total Tagihan</Text>
              </View>
              <View style={{ width: 50 }}>
                <Text>{"Rp"}:</Text>
              </View>
              <View>
                <Text>
                  {Number(totalBills ? totalBills : 0)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </Text>
              </View>
            </View>

            <View
              style={{
                marginTop: 5,
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <View style={{ width: 100 }}>
                <Text>Fee Admin</Text>
              </View>
              <View style={{ width: 50 }}>
                <Text>{"Rp"}:</Text>
              </View>
              <View>
                <Text>
                  {Number(fee ? fee : 0)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </Text>
              </View>
            </View>

            <View
              style={{
                marginTop: 5,
                flex: 1,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <View style={{ width: 100 }}>
                <Text>Total</Text>
              </View>
              <View style={{ width: 50 }}>
                <Text>{"Rp"}:</Text>
              </View>
              <View>
                <Text>
                  {Number(finalBills ? finalBills : 0)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </Text>
              </View>
            </View>
          </View>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" />{" "}
              {this.state.bankCode === "TOKOPEDIA" ||
              this.state.bankCode === "SHOPEE"
                ? `Pembayaran ${this.state.bankCode}`
                : `Pembayaran via Bank ${this.state.bankCode}`}
            </span>
          }
          visible={this.state.showVAFormVisible}
          onClose={(e) => {
            this.setState({
              showVAFormVisible: false,
              selectTutorial: {
                mobile: false,
                atm: false,
              },
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              otherPaymentVisible: false,
              bankTransferConfirmationVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            });
          }}
        >
          <View
            style={{
              flex: 1,
            }}
          >
            <View
              style={{
                flex: 1,
                alignItems: "center",
              }}
            >
              {paymentMethod === "tokopedia" || paymentMethod === "shopee" ? (
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  Harap Bayar Tagihan Dengan Kode Pembayaran
                </Text>
              ) : (
                <Text style={{ fontSize: 16, marginBottom: 10 }}>
                  Harap Bayar Tagihan Anda Pada VA:
                </Text>
              )}

              <Text
                style={{
                  fontSize: 16,
                  fontWeight: "bold",
                  marginBottom: 10,
                  color: "#0277bd",
                }}
              >
                {this.state.vaNumber ? this.state.vaNumber : "-"}
              </Text>

              <CopyToClipboard
                text={this.state.vaNumber}
                onCopy={() => {
                  this.setState({ vaCopied: true });
                  addNotification({
                    level: "success",
                    message: "Copied",
                  });
                }}
              >
                <button className="btn btn-sm btn-primary">
                  <i className="fa fa-copy" /> Copy to Clipboard
                </button>
              </CopyToClipboard>

              {this.state.paymentMethod === "bca" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>BCA Mobile Banking/M-BCA </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/EaYfU2nnOpw">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/EaYfU2nnOpw
                      </Text>
                    </a>
                  </View>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>ATM BCA </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/HyJrgj_OhWQ">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/HyJrgj_OhWQ
                      </Text>
                    </a>
                  </View>
                </View>
              ) : this.state.paymentMethod === "mandiri" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>Mandiri Mobile Banking </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/Kb-ItKFKAUk">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/Kb-ItKFKAUk
                      </Text>
                    </a>
                  </View>
                </View>
              ) : this.state.paymentMethod === "bri" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>BRI Mobile Banking </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/FDi7mUy-ms4">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/FDi7mUy-ms4
                      </Text>
                    </a>
                  </View>
                </View>
              ) : this.state.paymentMethod === "bni" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>BNI Mobile Banking </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/rpIM1rxd-Zk">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/rpIM1rxd-Zk
                      </Text>
                    </a>
                  </View>
                </View>
              ) : this.state.paymentMethod === "cimb" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>CIMB Mobile Banking </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/WG9Ur233zuY">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/WG9Ur233zuY
                      </Text>
                    </a>
                  </View>
                </View>
              ) : this.state.paymentMethod === "permata" ? (
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 20,
                  }}
                >
                  <Text>
                    Kunjungi link dibawah ini untuk petunjuk pembayaran:{" "}
                  </Text>
                  <View
                    style={{
                      marginTop: 10,
                    }}
                  >
                    <Text>Permata Mobile Banking </Text>
                  </View>
                  <View>
                    <a href="https://youtu.be/A2RBUUaMST8">
                      <Text
                        style={{
                          color: "#0277bd",
                        }}
                      >
                        https://youtu.be/A2RBUUaMST8
                      </Text>
                    </a>
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </FormModal>

        <FormModal
          title={
            <span>
              <i className="fa fa-money-bill-wave" />{" "}
              {paymentMethod === "linkAja"
                ? "Pembayaran via E-Wallet"
                : "Pembayaran via QRIS"}
            </span>
          }
          visible={this.state.otherPaymentVisible}
          size="md"
          onSubmit={this.submitCheckout}
          onClose={(e) =>
            this.setState({
              otherPaymentVisible: false,
              selectedBills: [],
              checkOutBills: [],
              paymentMethod: "",
              bankTransferConfirmationVisible: false,
              phoneNumber: "",
              selectedPaymentMethod: "",
            })
          }
          submitButtonLabel={
            <span>
              <i className="fa fa-check-circle " /> {"Setuju & Bayar"}
            </span>
          }
        >
          <div>
            <p>
              <i className="fa fa-info-circle" /> Biaya transaksi dengan &nbsp;
              {paymentMethod === "linkAja" ? "LINK AJA" : "QRIS"}
              &nbsp; akan dikenakan &nbsp;{" "}
              {paymentMethod === "linkAja" ? `Rp.${fee}` : "1%"} &nbsp; dari
              total tagihan Anda
            </p>
            {paymentMethod === "ovo" ? (
              <div>
                <hr style={{ marginBottom: 5, marginTop: 0 }} />
                <center>
                  <h5>Akun OVO*</h5>
                </center>
                {paymentMethod === "linkAja" ? null : (
                  <input
                    style={{ textAlign: "center" }}
                    className="form-control"
                    value={phoneNumber}
                    onChange={this.handlePhoneNumber}
                    required
                    placeholder={"081XXXXXXXXX"}
                  />
                )}

                <br />
              </div>
            ) : null}

            <div className="row">
              <div className="col-6">Total Tagihan</div>
              <div className="col-2">Rp: </div>
              <div className="col-4 text-right">
                {Number(totalBills ? totalBills : 0)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </div>
            </div>
            <div className="row">
              <div className="col-6">Fee</div>
              <div className="col-2">Rp: </div>
              <div className="col-4 text-right">
                {Number(fee ? fee : 0)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </div>
            </div>
            <hr style={{ marginTop: 5, marginBottom: 0 }} />
            <div className="row">
              <div className="col-6">Total</div>
              <div className="col-2">Rp: </div>
              <div className="col-4 text-right">
                {Number(finalBills ? finalBills : 0)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </div>
            </div>
          </div>
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-invoice"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                Daftar Tagihan
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fas fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            padding: 20,
            marginTop: 50,
            marginBottom: "25vh",
          }}
        >
          {groupedStudentBillsByIds.map((bill) => {
            let groupStatus = "";
            if (bill.unpaid > 0) {
              groupStatus = "BELUM LUNAS";
            } else {
              groupStatus = "LUNAS";
            }

            return (
              <section
                className="card"
                key={bill.month}
                style={{
                  marginBottom: 8,
                  borderWidth: "5px",
                  borderRadius: 10,
                }}
                onClick={groupStatus === "LUNAS" ? null : this.selectBill(bill)}
              >
                <div
                  className="card-header d-flex flex-row justify-content-center align-items-center"
                  style={{
                    padding: 10,
                    margin: 0,
                    backgroundColor:
                      bill.status === "LUNAS"
                        ? "#039be5"
                        : selectedBills.findIndex((b) => b === bill._id) > -1 &&
                          bill.status !== "LUNAS"
                        ? "#388e3c"
                        : "#fb8c00",
                    color: "#fff",
                  }}
                >
                  <div>
                    <b>
                      Tagihan {bill.month}-{bill.academicYear}
                    </b>
                  </div>
                </div>

                <div className="card-body">
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      Tagihan: <b>{bill.StudentBillType.name}</b>
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      Bayar: Rp.
                      <b>
                        {Number(bill.paid)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </b>
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                      }}
                    >
                      Sisa Tagihan: Rp.
                      <b>
                        {Number(bill.unpaid)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </b>
                    </Text>
                  </View>
                </div>

                <div
                  className="card-footer"
                  style={{
                    backgroundColor:
                      bill.status === "LUNAS"
                        ? "#039be5"
                        : selectedBills.findIndex((b) => b === bill._id) > -1 &&
                          bill.status !== "LUNAS"
                        ? "#388e3c"
                        : "#fb8c00",
                    color: "#fff",
                    fontSize: 16,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <center>
                    <b>{bill.status}</b>
                  </center>
                </div>
              </section>
            );
          })}
        </View>

        <div className="fixed-bottom">
          {selectedBills.length > 0 ? (
            <button
              type="button"
              onClick={this.openCheckoutModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#27ae60",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text style={{ color: "white", fontSize: 20 }}>
                <i className="fa fa-money-bill-wave" /> {"Bayar"}{" "}
                {selectedBills.length} {"Tagihan"}
              </Text>
            </button>
          ) : (
            <button
              type="button"
              // onClick={this.openFilterModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#00a8ff",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                }}
              >
                <i className="fas fa-search" /> {"Pilih Siswa"}
              </Text>
            </button>
          )}
        </div>
      </View>
    );
  }

  submitCheckout = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      const {
        selectedBillIds,
        paymentMethod,
        checkOutBills,
        selectedBills,
        fee,
      } = this.state;
      if (paymentMethod === "ovo" || paymentMethod === "linkAja") {
        const phoneCode = this.state.phoneNumber.slice(0, 2);

        if (phoneCode === "62") {
          throw {
            message: `Format akun ${
              paymentMethod === "ovo" ? "OVO" : "LINK AJA"
            } invalid!. Gunakan format 081XXXXXXXXX`,
          };
        }
      }
      // if (paymentMethod === "bri_xfers") {
      //   throw {
      //     message: "Pembayaran Via BRI dalam perbaikan",
      //   };
      // }

      // if (
      //   paymentMethod === "ovo" ||
      //   // paymentMethod === "linkAja" ||
      //   paymentMethod === "dana"
      // ) {
      //   throw {
      //     message: "Pembayaran E-Wallet dalam perbaikan",
      //   };
      // }

      const nowHours = dayjs().hour();
      if (nowHours >= 21 || nowHours <= 4) {
        throw {
          message:
            "Cut Off. Silahkan lakukan pembayaran setelah jam 04:00 - 21:00",
        };
      }
      const totalBills = checkOutBills
        .map((c) => c.unpaid)
        .reduce((acc, curr) => acc + curr, 0);

      let histories = checkOutBills.map((bill) => {
        return {
          studentBillId: bill._id,
          paid: bill.unpaid,
        };
      });

      let result = await mwvClient.mutate({
        mutation: CREATE_SCHOOL_PAY_BILL,
        variables: {
          sessionId: urlValues.sessionId,
          studentBillIds: selectedBills,
          paymentMethod,
          total: totalBills,
          phoneNumber: this.state.phoneNumber,
          histories,
          PWAMode: true,
          fee,
        },
        fetchPolicy: "no-cache",
      });

      addNotification({
        title: "SUCCESS",
        message: "Checkout tagihan berhasil",
        level: "success",
      });

      const bill = result.data.createSchoolPayBill;
      if (bill.paymentMethod === "dana" || bill.paymentMethod === "linkAja") {
        window.location.href = bill.checkoutUrl;
        return;
      }

      if (
        paymentMethod === "mandiri" ||
        paymentMethod === "bri" ||
        paymentMethod === "bni" ||
        paymentMethod === "bca" ||
        paymentMethod === "bsi" ||
        paymentMethod === "permata" ||
        paymentMethod === "cimb" ||
        paymentMethod === "muamalat" ||
        paymentMethod === "tokopedia" ||
        paymentMethod === "shopee"
      ) {
        if (result.data.createSchoolPayBill.VAPaymentDetail) {
          const vaDetailResult = JSON.parse(
            result.data.createSchoolPayBill.VAPaymentDetail
          );

          if (vaDetailResult.provider && vaDetailResult.provider === "ZIPAY") {
            this.setState({
              vaNumber: vaDetailResult.vaAcctNumber,
              bankCode:
                vaDetailResult.bankCode === "BMRI" ? "MANDIRI" : "LAINNYA",
              showVAFormVisible: true,
              paymentMethodVisible: false,
              bankTransferConfirmationVisible: false,
              checkOutModalVisible: false,
            });
          } else if (
            vaDetailResult.provider &&
            vaDetailResult.provider === "FASPAY"
          ) {
            this.setState({
              vaNumber: vaDetailResult.va_number,
              bankCode: vaDetailResult.bankCode,
              showVAFormVisible: true,
              paymentMethodVisible: false,
              bankTransferConfirmationVisible: false,
              checkOutModalVisible: false,
            });
          } else if (
            vaDetailResult.provider &&
            vaDetailResult.provider === "MCP"
          ) {
            this.setState({
              vaNumber: vaDetailResult.payment_code,
              bankCode: vaDetailResult.bankCode,
              showVAFormVisible: true,
              paymentMethodVisible: false,
              bankTransferConfirmationVisible: false,
              checkOutModalVisible: false,
            });
          } else if (
            vaDetailResult.provider &&
            vaDetailResult.provider === "SEVIMA"
          ) {
            this.setState({
              vaNumber: vaDetailResult.virtual_account,
              bankCode: vaDetailResult.bankCode,
              showVAFormVisible: true,
              paymentMethodVisible: false,
              bankTransferConfirmationVisible: false,
              checkOutModalVisible: false,
            });
          } else {
            let vaNumber = "";
            if (vaDetailResult.bank_code === "BSI") {
              vaNumber = vaDetailResult.account_number.slice(
                4,
                vaDetailResult.account_number.length
              );
            } else {
              vaNumber = vaDetailResult.account_number;
            }

            this.setState({
              vaNumber,
              bankCode:
                // result.data.createSchoolPayBill.VAPaymentDetail.bank_code,
                vaDetailResult.bank_code,
              showVAFormVisible: true,
              paymentMethodVisible: false,
              bankTransferConfirmationVisible: false,
              checkOutModalVisible: false,
            });
          }
        }
      } else if (paymentMethod === "qris") {
        const qrisDetailResult = JSON.parse(
          result.data.createSchoolPayBill.VAPaymentDetail
        );
        this.setState({
          qrisObject: {
            ...qrisDetailResult,
            timeCountDown: dayjs().add(1, "hour").format(), // 45 Detik
            qrisModalVisible: true,
          },
          paymentMethodVisible: false,
          bankTransferConfirmationVisible: false,
          checkOutModalVisible: false,
          otherPaymentVisible: false,
        });
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleExpiredQRIS = async () => {
    try {
      if (this.state.qrisObject.external_id) {
        await mwvClient.mutate({
          mutation: QRIS_EXPIRY,
          variables: {
            schoolPayId: this.state.qrisObject.external_id,
          },
        });
        this.props.refetch();
        this.setState({
          qrisObject: {
            qrisModalVisible: false,
          },
        });
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (err) {
      handleError(err);
    }
  };
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const BILL_DETAILS = gql`
  query groupedStudentBillsByIds($billIds: [String]) {
    groupedStudentBillsByIds(billIds: $billIds) {
      _id
      academicYear
      StudentBillType {
        _id
        name
      }
      amount
      month
      tags
      status
      paid
      unpaid
    }
  }
`;

const CREATE_SCHOOL_PAY_BILL = gql`
  mutation createSchoolPayBill(
    $sessionId: String!
    $studentBillIds: [String!]!
    $paymentMethod: String!
    $total: Int!
    $fee: Int!
    $histories: [CreatePaidHistory]
    $phoneNumber: String
    $PWAMode: Boolean
  ) {
    createSchoolPayBill(
      input: {
        sessionId: $sessionId
        studentBillIds: $studentBillIds
        paymentMethod: $paymentMethod
        total: $total
        fee: $fee
        histories: $histories
        phoneNumber: $phoneNumber
        PWAMode: $PWAMode
      }
    ) {
      _id
      expiredAt
      paymentMethod
      total
      transactionNumber
      status
      checkoutUrl

      VAPaymentDetail
    }
  }
`;

const CREATE_VA = gql`
  mutation createVA($sessionId: String!, $bankCode: String) {
    createVA(input: { sessionId: $sessionId, bankCode: $bankCode })
  }
`;

const CHECK_VA = gql`
  mutation vaByAccountId($sessionId: String!, $bankCode: String!) {
    vaByAccountId(input: { sessionId: $sessionId, bankCode: $bankCode }) {
      _id
      bankCode
      VANumber
      response
    }
  }
`;

const QRIS_POLLING_QUERY = gql`
  query schoolPayBillQRISPollingCheck($_id: String!) {
    schoolPayBillQRISPollingCheck(_id: $_id)
  }
`;
const QRIS_EXPIRY = gql`
  mutation expiredQRIS($schoolPayId: String!) {
    expiredQRIS(schoolPayId: $schoolPayId)
  }
`;

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  let billIds = JSON.parse(urlValues.ids);

  return (
    <Query
      query={BILL_DETAILS}
      client={mwvClient}
      variables={{
        billIds,
      }}
    >
      {({ error, loading, data, refetch }) => (
        <div>
          <StudentBillDetail
            {...props}
            error={error}
            loading={loading}
            refetch={refetch}
            groupedStudentBillsByIds={
              data && data.groupedStudentBillsByIds
                ? data.groupedStudentBillsByIds
                : []
            }
          />
        </div>
      )}
    </Query>
  );
});
