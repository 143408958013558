import React from "react";
import { View } from "react-native-web";

const ListSection = ({ children }) => (
  <View
    style={{
      marginTop: 6,
      marginBottom: 6,
      backgroundColor: "white",
      shadowColor: "#000",
      shadowOffset: { width: 0, height: 2 },
      shadowOpacity: 0.2,
      shadowRadius: 4,
      elevation: 2
    }}
  >
    <View
      style={{
        backgroundColor: "white",
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 2,
        paddingRight: 2
      }}
    >
      {children}
    </View>
  </View>
);

export default ListSection;
