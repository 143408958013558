import React, { PureComponent } from "react";
import { View, TouchableOpacity, Image, Text, Linking } from "react-native";
import dayjs from "dayjs";

class SubjectVideoMeeting extends PureComponent {
  state = {
    numberOfLines: 3,
    bufferedAudio: "",
    audioPlayState: "STOP",
  };
  handleClick = () => {
    if (this.props.onPress) {
      const { _id, Student, SubjectVideoMeeting, _createdAt } = this.props;
      this.props.onPress({
        _id,
        type: "ACTIVITY_ITEM_PRESSED",
        Student,
        SubjectVideoMeeting,
        _createdAt,
      });
    }
  };
  render() {
    const { SubjectVideoMeeting, _createdAt } = this.props;

    // console.log("SubjectVideoMeeting", SubjectVideoMeeting);
    return (
      <View
        style={{
          paddingVertical: 7,
        }}
      >
        <TouchableOpacity onClick={this.handleClick} activeOpacity={0.6}>
          <View
            style={{
              marginHorizontal: 5,
              paddingBottom: 4,
              borderRadius: 2,
              backgroundColor: "white",
              shadowColor: "#000",
              shadowOffset: { width: 0, height: 2 },
              shadowOpacity: 0.2,
              shadowRadius: 2,
              elevation: 1,
            }}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View style={{ padding: 10 }}>
                <Image
                  style={{
                    width: 100,
                    height: 100,
                    resizeMode: "cover",
                    marginLeft: 10,
                    marginTop: 10,
                  }}
                  source={require("../../assets/video-meeting.png")}
                />
              </View>
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: 10,
                  flex: 1,
                  flexDirection: "column",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                    }}
                  >
                    Video Meeting Baru
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,

                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    {SubjectVideoMeeting.topic}
                  </Text>
                </View>

                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    marginBottom: 5,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Mapel: {SubjectVideoMeeting.subjectName}
                  </Text>
                </View>
                <View
                  style={{
                    // flex: 1,
                    // alignItems: "flex-start",
                    marginBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    Oleh: {SubjectVideoMeeting.teacherName}
                  </Text>
                </View>
                <View>
                  <Text
                    style={{
                      fontSize: 14,
                      color: "red",
                    }}
                    numberOfLines={1}
                    ellipsizeMode="tail"
                  >
                    <i className="fa fa-eye" /> Lihat daftar meeting
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    alignItems: "flex-start",
                    marginTop: 10,
                    // justifyContent: "center",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#2196f3",
                    }}
                  >
                    {dayjs(SubjectVideoMeeting._createdAt).format(
                      "YYYY-MM-DD HH:mm"
                    )}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}

export default SubjectVideoMeeting;
