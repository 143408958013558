import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import dayjs from "dayjs";
import { handleError } from "../../libs/errors";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                    {student.status && student.status ? student.status : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class CBTPackages extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    subjectLists: [],
    semester: defaultSemester(),

    cbtPackages: [],
    student: null,
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUERIES,
      variables: {
        _id: accountSession._id,
        studentId: urlValues.studentId,
        semester: urlValues.semester,
        academicYear: urlValues.academicYear,
        subjectId: urlValues.subjectId,
      },
      fetchPolicy: "no-cache",
    });

    const studentObj =
      result.data && result.data.studentById ? result.data.studentById : null;

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    this.setState({
      allStudents: [...students],
      cbtPackages:
        result.data && result.data.questionPackages
          ? result.data.questionPackages
          : [],
      student: studentObj,
    });
  };

  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/cbt/cbt_subject_list",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}`,
    });
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleSemester = (e) => {
    this.props.history.replace({
      pathname: "/cbt/cbt_packages",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${urlValues.academicYear}&semester=${e.target.value}`,
    });

    this.initiate();
  };

  startCBT = (paket) => async (e) => {
    try {
      // await mwvClient.query({
      //   query: QUERIES,
      //   variables: {
      //     studentId: urlValues.studentId,
      //     menu: "CBT",
      //   },
      //   fetchPolicy: "no-cache",
      // });

      const classroomId = this.state.student.Classroom._id;
      const url =
        this.props.location.search +
        `&packageId=${paket._id}&classroomId=${classroomId}`;

      this.props.history.replace({
        pathname: "/cbt/cbt_start",
        search: url,
      });
    } catch (err) {
      handleError(err);
    }
  };
  render() {
    const { allStudents, cbtPackages } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-desktop"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"CBT"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                            <b>
                              {getStudent[0].status ? getStudent[0].status : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>

                      <select
                        className="form-control"
                        value={urlValues.semester}
                        onChange={this.handleSemester}
                      >
                        <option value="GANJIL">GANJIL</option>
                        <option value="GENAP">GENAP</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            marginTop: 210,
          }}
        >
          <View
            style={{
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <Text
              style={{
                fontSize: 18,
              }}
            >
              Daftar Paket
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            {cbtPackages.length === 0 ? (
              <section
                className="card"
                style={{
                  paddingBottom: 15,
                }}
              >
                <div className="card-block">
                  <View
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <Image
                      style={{ width: 240, height: 240 }}
                      source={require("../../assets/macbook-hand.png")}
                    />
                    <Text>Paket CBT Tidak Ditemukan!</Text>
                  </View>
                </div>
              </section>
            ) : (
              cbtPackages.map((paket) => (
                <section
                  className="card"
                  style={{
                    marginBottom: 10,
                    padding: "20px 10px 20px 10px",
                  }}
                >
                  <div className="card-block">
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          width: 80,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          Paket
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          : <b>{paket.name}</b>
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          width: 80,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          Pertanyaan
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          : <b>{paket.countQuestions}</b>
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row",
                        marginBottom: 10,
                      }}
                    >
                      <View
                        style={{
                          width: 80,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          Waktu
                        </Text>
                      </View>
                      <View>
                        <Text
                          style={{
                            fontSize: 14,
                          }}
                        >
                          : <b>{paket.time} Menit</b>
                        </Text>
                      </View>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: "row-reverse",
                      }}
                    >
                      <div>
                        <button
                          className="btn btn-sm btn-primary"
                          onClick={this.startCBT(paket)}
                        >
                          Start <i className="fa fa-arrow-right" />
                        </button>
                      </div>
                    </View>
                  </div>
                </section>
              ))
            )}
          </View>
        </View>

        <View
          style={{
            marginTop: 100,
          }}
        >
          <Text>SchoolTalk</Text>
        </View>

        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {"Pilih Siswa/Mapel Lain"}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const CHECK_STUDENT_IS_ACTIVE = gql`
  query checkStudentIfActive($studentId: String!, $menu: String!) {
    checkStudentIfActive(studentId: $studentId, menu: $menu)
  }
`;

const QUERIES = gql`
  query listQuery(
    $_id: String
    $studentId: String!
    $semester: String!
    $academicYear: String!
    $subjectId: String!
  ) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      status
      photoUrl
      PREFIX
    }
    studentById(studentId: $studentId) {
      _id
      name
      Classroom {
        _id
        name
        academicYear
      }
      status
    }
    questionPackages(
      studentId: $studentId
      academicYear: $academicYear
      subjectId: $subjectId
      semester: $semester
    ) {
      _id
      academicYear
      name
      semester
      grade
      countQuestions
      time
    }
  }
`;

export default withRouter(CBTPackages);

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
