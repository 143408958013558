import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import uuidV4 from "uuid/v4";
import dayjs from "dayjs";

import { withI18n, translate } from "../libs/withI18n";

let urlValues;

let hexColors = [
  "#0961a5",
  "#1abc9c",
  "#0984e3",
  "#e67e22",
  "#e74c3c",
  "#f39c12",
  "#4834d4",
  "#be2edd",
];

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",
              borderRadius: 0,
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const ListSubjects = ({ allSubjects, openScore }) =>
  allSubjects.length !== 0 ? (
    <View style={{ marginTop: 10 }}>
      {allSubjects.map((subject) => (
        <div key={subject._id}>
          <section
            className="card"
            style={{
              padding: 20,
              marginBottom: 5,
              boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
              borderRadius: 0,
            }}
            onClick={openScore(subject)}
          >
            <div className="card-block">
              <Text style={{ fontSize: 18 }}>{subject.name}</Text>
            </div>
          </section>
        </div>
      ))}
    </View>
  ) : (
    <section
      className="card"
      style={{
        padding: 20,
        marginTop: 20,
        boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
        borderRadius: 0,
      }}
    >
      <div className="card-block">
        <center>
          <Image
            style={{ width: 250, height: 250 }}
            source={require("../assets/desktop-laptop-studying-pen.png")}
          />
          <Text stye={{ fontSize: 20 }}>
            {translate("Mata Pelajaran Tidak Ditemukan")}
          </Text>
        </center>
      </div>
    </section>
  );

const AcademicScoreModal = ({ allAcademicScores, colorCounter, hexColors }) =>
  allAcademicScores.length !== 0 ? (
    <div>
      {allAcademicScores.map((score) => (
        <section
          className="card"
          style={{
            marginBottom: 5,
            padding: "10px 10px 0 10px",
            boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
            borderRadius: 0,
          }}
          key={score._id}
        >
          <div style={{ display: "none" }}>{colorCounter++}</div>
          <div className="card-block">
            <div className="row">
              <div className="col-7">
                <div style={{ marginTop: 20 }}>
                  <Text style={{ fontSize: 18 }}>
                    <center>{score.assessmentTypeName}</center>
                  </Text>
                </div>
                <div style={{ fontSize: "0.8em", marginBottom: 10 }}>
                  <Text style={{ fontSize: 16 }}>
                    KD:{" "}
                    {score.AcademicCompetence
                      ? score.AcademicCompetence.description
                      : ""}
                  </Text>
                </div>
                <div style={{ fontSize: "0.8em" }}>
                  <Text style={{ fontSize: 16 }}>
                    KI:{" "}
                    {score.AcademicCompetenceIndicator
                      ? score.AcademicCompetenceIndicator.description
                      : ""}
                  </Text>
                </div>
              </div>
              <div className="col-5">
                <div style={{ marginBottom: 25, paddingTop: 20 }}>
                  <center>
                    <View style={{ marginBottom: 20 }}>
                      <Text style={{ fontSize: 16 }}>{translate("Nilai")}</Text>
                    </View>
                    <Text style={{ marginTop: 10, fontSize: 16 }}>
                      <span
                        className="dot"
                        style={{
                          height: "100px",
                          width: "100px",
                          backgroundColor:
                            hexColors[colorCounter % hexColors.length],
                          borderRadius: "5px",
                          padding: "10px",
                          color: "#fff",
                          fontWeight: 600,
                        }}
                      >
                        {score.score}
                      </span>
                    </Text>
                  </center>
                </div>

                <center>
                  <Text
                    style={{
                      color: "#00a8ff",
                      fontSize: 16,
                      fontWeight: "bold",
                    }}
                  >
                    {translate("Tanggal Rilis")}
                    <br />
                    {dayjs(score._createdAt).format("DD-MM-YYYY")}
                  </Text>
                </center>
              </div>
            </div>
            <br />
          </div>
        </section>
      ))}
    </div>
  ) : (
    <section
      className="card"
      style={{ padding: 20, marginTop: 20, borderRadius: 0 }}
    >
      <div className="card-block">
        <center>
          <Image
            style={{ width: 250, height: 250 }}
            source={require("../assets/education-aid.png")}
          />
          <Text stye={{ fontSize: 30 }}>
            {translate("Nilai Tidak Ditemukan")}
          </Text>
        </center>
      </div>
    </section>
  );

class AcademicScoresPage extends Component {
  state = {
    allStudents: [],
    allAcademicYears: [],
    filterModalVisible: false,
    semester: "",
    academicYear: "",

    listSubjectVisible: true,
    academicScoreVisible: false,
    subjectName: "",
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let semester;
    if (new Date().getMonth() < 6) {
      semester = "GENAP";
    } else {
      semester = "GANJIL";
    }

    let terms = [];
    terms = await mwvClient.query({
      query: ACADEMIC_YEARS,
      variables: {
        PREFIX: urlValues.PREFIX,
      },
    });

    const foundTerm = terms.data.allAcademicYears.find((y) => y.isMonthly);
    if (foundTerm) {
      semester = "";
    }

    let result = await mwvClient.query({
      query: ACADEMIC_SCORE_QUERIES,
      variables: {
        _id: accountSession._id,
        PREFIX: urlValues.PREFIX,
        semester: urlValues.semester,
        academicYear: urlValues.academicYear,
        studentId: urlValues.studentId,
        subjectId: urlValues.subjectId ? urlValues.subjectId : "",
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    let openFromNotif = {};
    if (urlValues.openFromNotif === "true") {
      openFromNotif = {
        academicScoreVisible: true,
        listSubjectVisible: false,
        subjectName: urlValues.subjectName,
      };
    }
    this.setState({
      allStudents: [...students],
      allAcademicYears: terms.data.allAcademicYears,
      academicYear: urlValues.academicYear,
      semester,
      ...openFromNotif,
    });
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    let academicYear;
    if (new Date().getMonth() < 6) {
      academicYear = `${
        new Date().getFullYear() - 1
      }/${new Date().getFullYear()}`;
    } else {
      academicYear = `${new Date().getFullYear()}/${
        new Date().getFullYear() + 1
      }`;
    }

    let semester;
    if (new Date().getMonth() < 6) {
      semester = "GENAP";
    } else {
      semester = "GANJIL";
    }

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/student_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${academicYear}&semester=${semester}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleCriteria = (key) => (e) => {
    if (key === "academicYear") {
      this.setState({
        academicYear: e.target.value,
      });
      urlValues = {
        ...urlValues,
        academicYear: e.target.value,
      };
    } else {
      this.setState({
        semester: e.target.value,
      });

      urlValues = {
        ...urlValues,
        semester: e.target.value,
      };
    }

    this.props.history.replace({
      pathname: "/academic_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${
        urlValues.studentId
      }&PREFIX=${urlValues.PREFIX}&academicYear=${
        urlValues.academicYear
      }&semester=${urlValues.semester}&subjectId=${
        urlValues.subjectId && urlValues.subjectId !== ""
          ? urlValues.subjectId
          : ""
      }`,
    });
  };

  openAcademicScore = (subject) => (e) => {
    this.setState({
      academicScoreVisible: true,
      listSubjectVisible: false,
      subjectName: subject.name,
    });

    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
      subjectId: subject._id,
      subjectName: subject.name,
    };
    this.props.history.replace({
      pathname: "/academic_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&subjectId=${subject._id}&subjectName=${subject.name}`,
    });
  };

  backToStudentPerformance = () => {
    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    // let semester;
    // if (new Date().getMonth() < 6) {
    //   semester = "GENAP";
    // } else {
    //   semester = "GANJIL";
    // }
    this.props.history.replace({
      pathname: "/student_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}`,
    });
  };

  backToSubject = () => {
    this.setState({
      academicScoreVisible: false,
      listSubjectVisible: true,
    });

    urlValues = queryString.parse(this.props.location.search);

    this.props.history.replace({
      pathname: "/academic_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}`,
    });
  };

  generateAcademicYears = () => {
    let listYears = [];
    let initYear = new Date().getFullYear() - 3;
    let counter = 1;
    for (let i = 0; i < 7; i++) {
      let ay = initYear + i + "/" + (initYear + (counter + i));
      listYears.push({
        _id: uuidV4(),
        label: ay,
      });
    }

    return listYears;
  };

  render() {
    const { allStudents, allAcademicYears } = this.state;
    const { allSubjects, allAcademicScores } = this.props;
    urlValues = queryString.parse(this.props.location.search);
    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    const foundTerm = allAcademicYears.find((y) => y.isMonthly);

    // const TA = this.generateAcademicYears();

    // let academicYearNow;
    // if (new Date().getMonth() < 6) {
    //   academicYearNow = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYearNow = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    // const kkmScore = allAcademicScores[0] ? allAcademicScores[0].KKMScore : 0;
    // const averageScore = allAcademicScores
    //   ? allAcademicScores
    //       .map(s => s.score)
    //       .reduce((acc, curr) => acc + curr, 0) / allAcademicScores.length
    //   : 0;
    // let roundUpScore = Math.round(averageScore * 100) / 100;

    let counter = 0;
    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-star"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Nilai Akademik")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="row">
                <div className={foundTerm ? "col-12" : "col-8"}>
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("academicYear")}
                      value={this.state.academicYear}
                    >
                      <option value="" disabled>
                        {foundTerm
                          ? translate("Pilih Term")
                          : translate("Pilih Tahun Ajaran")}
                      </option>
                      {allAcademicYears.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === urlValues.academicYear}
                        >
                          {foundTerm ? "Term" : translate("Tahun Ajaran")}{" "}
                          {ta.label} {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                      {/* {TA.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === academicYearNow}
                        >
                          Tahun Ajaran {ta.label}
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>
                {!foundTerm ? (
                  <div className="col-4">
                    <div className="form-group">
                      <select
                        className="form-control"
                        onChange={this.handleCriteria("semester")}
                        value={this.state.semester}
                      >
                        <option
                          value="GANJIL"
                          selected={new Date().getMonth() + 1 > 6}
                        >
                          {translate("GANJIL")}
                        </option>
                        <option value="GENAP">{translate("GENAP")}</option>
                      </select>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </section>
        </div>

        <View
          style={{
            flex: 1,
            marginTop: 250,
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 100,
          }}
        >
          {this.state.listSubjectVisible ? (
            <div>
              <TouchableOpacity onPress={this.backToStudentPerformance}>
                <button
                  className="btn btn-md btn-primary btn-block"
                  style={{ backgroundColor: "#00a8ff", border: "none" }}
                >
                  <i className="fa fa-arrow-left" /> {translate("Kembali")}
                </button>
              </TouchableOpacity>
              <ListSubjects
                allSubjects={allSubjects ? allSubjects : []}
                openScore={this.openAcademicScore}
              />
            </div>
          ) : this.state.academicScoreVisible ? (
            <View>
              <section
                className="card"
                style={{
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
                  borderRadius: 0,
                }}
              >
                <div
                  className="card-block"
                  style={{ padding: "20px 20px 5px 20px" }}
                >
                  <center>
                    <Text style={{ fontSize: 20, color: "#e67e22" }}>
                      {translate("MATA PELAJARAN")}
                    </Text>
                  </center>
                  <div className="row">
                    <div className="col-12" style={{ paddingTop: 25 }}>
                      {this.state.subjectName ? (
                        <center>
                          <h5>
                            <b>{this.state.subjectName}</b>
                          </h5>
                        </center>
                      ) : null}
                    </div>

                    {/* <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              KKM
                              <br />
                              <br />
                              <div style={{ fontSize: "1em" }}>Rata-rata</div>
                            </div>
                            <div className="col-6">
                              <div className="text-right">
                                <span
                                  className="dot"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    backgroundColor: "#2ecc71",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    color: "#fff",
                                    fontWeight: 600
                                  }}
                                >
                                  {kkmScore}
                                </span>
                                <br />
                                <br />

                                <span
                                  className="dot"
                                  style={{
                                    height: "100px",
                                    width: "100px",
                                    backgroundColor: "#9b59b6",
                                    borderRadius: "5px",
                                    padding: "10px",
                                    color: "#fff",
                                    fontWeight: 600
                                  }}
                                >
                                  {isNaN(roundUpScore) ? "-" : roundUpScore}
                                </span>
                              </div>
                            </div>
                          </div>

                          <br />
                        </div> */}
                  </div>
                </div>
                <button
                  className="btn btn-md btn-primary btn-block"
                  style={{ backgroundColor: "#00a8ff", border: "none" }}
                  onClick={this.backToSubject}
                >
                  <i className="fa fa-arrow-left" /> {translate("Mapel Lain")}
                </button>
              </section>

              <section
                className="card"
                style={{
                  marginTop: 20,
                  paddingTop: 5,
                  marginBottom: 10,
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.4)",
                  borderRadius: 0,
                }}
              >
                <div className="card-block">
                  <center>
                    <Text style={{ fontSize: 20 }}>
                      {translate("DAFTAR PENILAIAN")}
                    </Text>
                  </center>
                </div>
              </section>
              <AcademicScoreModal
                allAcademicScores={allAcademicScores ? allAcademicScores : []}
                colorCounter={counter}
                hexColors={hexColors}
              />
            </View>
          ) : null}
        </View>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const ACADEMIC_SCORE_QUERIES = gql`
  query listQuery(
    $_id: String
    $PREFIX: String
    $semester: String
    $academicYear: String
    $studentId: String
    $subjectId: String
  ) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
    allSubjects(
      studentId: $studentId
      sessionId: $_id
      semester: $semester
      academicYear: $academicYear
      PREFIX: $PREFIX
    ) {
      _id
      name
    }
    allAcademicScores(
      semester: $semester
      academicYear: $academicYear
      studentId: $studentId
      subjectId: $subjectId
    ) {
      _id
      score
      assessmentCategoryName
      assessmentTypeName
      CurriculumName
      KKMScore
      Subject {
        _id
        name
      }
      AcademicCompetence {
        _id
        description
      }
      AcademicCompetenceIndicator {
        _id
        description
      }
      _createdAt
    }
  }
`;

const ACADEMIC_YEARS = gql`
  query listAcademicYear($PREFIX: String) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      isMonthly
      inProgress
    }
  }
`;

export default withRouter(
  withI18n("student_performance")((props) => {
    urlValues = queryString.parse(props.location.search);
    let PREFIX = urlValues.PREFIX;
    let semester = urlValues.semester;
    let academicYear = urlValues.academicYear;
    let studentId = urlValues.studentId;
    let subjectId = urlValues.subjectId ? urlValues.subjectId : "";
    return (
      <Query
        query={ACADEMIC_SCORE_QUERIES}
        client={mwvClient}
        variables={{
          _id: urlValues.sessionId,
          PREFIX,
          semester,
          academicYear,
          studentId,
          subjectId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            {/* {console.log(data)} */}
            <AcademicScoresPage
              {...props}
              error={error}
              loading={loading}
              refetch={refetch}
              allSubjects={data && data.allSubjects ? data.allSubjects : []}
              allAcademicScores={
                data && data.allAcademicScores ? data.allAcademicScores : []
              }
            />
          </div>
        )}
      </Query>
    );
  })
);
