import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../../components/BackgroundImage";
import HeaderBar from "../../components/HeaderBar";
import { Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../../components/Modal";
import { withI18n, translate } from "../../libs/withI18n";
import { handleError } from "../../libs/errors";
import { ShimmerThumbnail } from "react-shimmer-effects";
import { LoadingOverlay } from "../../components/Overlay";
import {
  showLoadingSpinner,
  hideLoadingSpinner,
  addNotification,
} from "../../App";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyToClipboard } from "react-copy-to-clipboard";
import dayjs from "dayjs";
const HISTORY_LIMIT_REQUEST = 10;

let urlValues;
class SchoolPayHistory extends Component {
  state = {
    selectedSiswa: null,

    allHistories: [],
    currentPage: 0,
    height: 0,
    pages: 1,
    hasMore: true,

    historyLoading: true,

    paymentDetails: {
      virtualAccount: "",
      details: [],
      total: 0,
      paymentMethod: "",
      visible: false,
      history: {},
      fee: 0,
    },
    paymentDetailsV2: null,

    listSiswa: [],
    listTahunAjaran: [],
    filterCriteria: {
      studentId: "",
      academicYear: "",
    },
    showFilter: false,
  };

  componentWillReceiveProps = () => {
    // this.componentDidMount();
  };
  componentDidMount = async () => {
    urlValues = queryString.parse(this.props.location.search);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let selectedSiswa = null;

    const res = await mwvClient.query({
      query: ALL_STUDENT_QUERIES,
      variables: {
        _id: urlValues.sessionId,
        PREFIX: urlValues.PREFIX,
      },
    });

    let allStudents = [];
    if (res.data.allStudentBySessionId) {
      allStudents = res.data.allStudentBySessionId;
    }
    let listTahunAjaran =
      res.data && res.data.allAcademicYears ? res.data.allAcademicYears : [];

    if (!urlValues.studentId) {
      selectedSiswa = allStudents[0];
    } else {
      selectedSiswa = allStudents.find(
        (siswa) => siswa._id === urlValues.studentId
      );
    }

    await this.fetchAllSchoolPayHistories();

    this.setState({
      selectedSiswa,
      listTahunAjaran,
      listSiswa: allStudents,
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  resize() {
    if (this.divElement) {
      const height = this.divElement.clientHeight;
      this.setState({
        height,
      });
    }

    let currentHideNav = window.innerWidth <= 760;
    if (currentHideNav !== this.state.hideNav) {
      this.setState({ hideNav: currentHideNav });
    }
  }

  fetchAllSchoolPayHistories = async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    const { currentPage } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    const tmpPage = 0;
    try {
      var result = await mwvClient.query({
        query: PAYMENT_HISTORY_QUERIES,
        variables: {
          ...urlValues,
          academicYear: urlValues.academicYear,
          limit: HISTORY_LIMIT_REQUEST,
          page: tmpPage,
        },
        fetchPolicy: "no-cache",
      });
      const allHistories = result.data.paginatedSchoolPayHistory.rows;
      const pages = result.data.paginatedSchoolPayHistory.pages;
      const hasMore = this.state.allHistories.length < allHistories.length;
      // console.log({
      //   allHistories: allHistories,
      //   hasMore,
      //   pages,
      // });
      this.setState({
        allHistories,
        currentPage: tmpPage + 1,
        pages,
        hasMore,
      });
    } catch (err) {
      handleError(err);
    }
    this.setState({
      historyLoading: false,
    });
    hideLoadingSpinner();
  };

  fetchMoreData = async () => {
    let { currentPage, pages } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    try {
      currentPage = currentPage + 1;
      console.log(currentPage);
      const result = await mwvClient.query({
        query: PAYMENT_HISTORY_QUERIES,
        variables: {
          ...urlValues,
          academicYear: urlValues.academicYear,
          page: currentPage - 1,
          limit: HISTORY_LIMIT_REQUEST,
        },
        fetchPolicy: "network-only",
      });
      const allHistories = [
        ...this.state.allHistories,
        ...result.data.paginatedSchoolPayHistory.rows,
      ];
      const hasMore = currentPage < pages;

      // console.log({
      //   allHistories: allHistories,
      //   hasMore,
      //   pages,
      // });
      this.setState({
        allHistories,
        currentPage,
        hasMore,
      });
    } catch (err) {
      handleError(err);
    }
  };

  refresh = () => {
    window.location.reload();
  };

  openHistoryDetail = (history) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();

    try {
      if (history.paymentUrl) {
        const results = await this.props.client.query({
          query: HISTORY_DETAIL_QUERY,
          variables: {
            _id: history._id,
          },
          fetchPolicy: "no-cache",
        });

        let resV2 = await this.props.client.query({
          query: GET_SCHOOL_PAY_V2,
          variables: {
            _id: history._id,
          },
          fetchPolicy: "no-cache",
        });

        resV2 = JSON.parse(resV2.data.getSchoolPayV2);

        let virtualAccount = "";
        if (resV2.provider === "MCP") {
          virtualAccount = resV2.payment_code;
        } else if (resV2.provider === "XENDIT") {
          virtualAccount = resV2.account_number;
        } else if (resV2.provider === "FASPAY") {
          if (!resV2.redirect_url) {
            virtualAccount = resV2.va_number;
          }
        } else if (resV2.provider === "SEVIMA") {
          virtualAccount = resV2.virtual_account;
        }

        let totalTagihan = 0;
        if (resV2.fee) {
          totalTagihan = resV2.totalAmount + resV2.fee;
        } else {
          totalTagihan = resV2.totalAmount;
        }
        this.setState({
          paymentDetailsV2: {
            details: results.data.schoolPayDetails,
            total: totalTagihan,
            paymentMethod: resV2.paymentMethod ? resV2.paymentMethod : "",
            fee: resV2.fee ? resV2.fee : 0,
            visible: true,
            history,
          },
        });
      } else {
        const paymentDetail = JSON.parse(history.paymentMethodDetail);
        let virtualAccount = "";
        if (paymentDetail.provider === "MCP") {
          // virtualAccount = paymentDetail.payment_code;
          virtualAccount = paymentDetail.payment_details.va_number;
        } else if (paymentDetail.provider === "XENDIT") {
          virtualAccount = paymentDetail.account_number;
        } else if (paymentDetail.provider === "FASPAY") {
          virtualAccount = paymentDetail.va_number;
        } else if (paymentDetail.provider === "SEVIMA") {
          virtualAccount = paymentDetail.virtual_account;
        } else if (paymentDetail.provider === "PAYLABS") {
          if (paymentDetail.paymentType === "BSIVA") {
            virtualAccount = paymentDetail.vaCode.slice(4);
          } else {
            virtualAccount = paymentDetail.vaCode;
          }
        } else if (paymentDetail.provider === "SMARTLINK") {
          virtualAccount = paymentDetail.data.payment_details.payment_code;
        } else if (paymentDetail.provider === "DUITKU") {
          virtualAccount = paymentDetail.vaNumber;
        }
        const results = await mwvClient.query({
          query: HISTORY_DETAIL_QUERY,
          variables: {
            _id: history._id,
          },
          fetchPolicy: "no-cache",
        });

        const totalTagihan = results.data.schoolPayDetails
          .map((det) => det.paid)
          .reduce((acc, curr) => acc + curr, 0);

        let fee = 0;
        if (history.paymentMethod === "bsi") {
          fee = 5000;
        } else if (history.paymentMethod === "bca") {
          fee = 4500;
        } else if (history.paymentMethod === "linkAja") {
          fee = 4000;
        } else if (
          history.paymentMethod === "tokopedia" ||
          history.paymentMethod === "shopee"
        ) {
          fee = 1000;
        } else {
          fee = 3500;
        }

        this.setState({
          paymentDetails: {
            virtualAccount,
            details: results.data.schoolPayDetails,
            total: totalTagihan + fee,
            paymentMethod: history.paymentMethod,
            visible: true,
            history,
            fee,
          },
        });
      }
    } catch (err) {}
    hideLoadingSpinner();
  };

  closeDetail = (e) => {
    this.setState({
      paymentDetails: {
        virtualAccount: "",
        details: [],
        total: 0,
        paymentMethod: "",
        visible: false,
        history: {},
        fee: 0,
      },
    });
  };

  handleFilterCriteria = (key) => async (e) => {
    if (e) e.preventDefault();
    const { client } = this.props;
    const { filterCriteria } = this.state;

    if (key === "studentId") {
      this.setState({
        filterCriteria: {
          ...this.state.filterCriteria,
          studentId: e.target.value,
        },
      });
      const resTA = await mwvClient.query({
        query: ALL_ACADEMIC_YEAR_BY_STUDENT,
        variables: {
          studentId: e.target.value,
        },
        fetchPolicy: "no-cache",
      });
      const tahunAjaranAktifDefault =
        resTA.data.allAcademicYearsByStudent.filter((ta) => ta.inProgress)[0]
          .label;

      this.setState({
        listTahunAjaran: resTA.data.allAcademicYearsByStudent,
        filterCriteria: {
          ...this.state.filterCriteria,
          academicYear: tahunAjaranAktifDefault,
        },
      });
    } else {
      this.setState({
        filterCriteria: {
          ...filterCriteria,
          academicYear: e.target.value,
        },
      });
    }
  };

  openFilter = async (e) => {
    if (e) e.preventDefault();

    // const { router, client } = this.props;
    const listSiswa = this.state.listSiswa;
    urlValues = queryString.parse(this.props.location.search);

    const selectedSiswaInFilter = listSiswa.find(
      (s) => s._id === urlValues.studentId
    );
    const listTahunAjaran = this.state.listTahunAjaran;
    this.setState({
      filterCriteria: {
        studentId: selectedSiswaInFilter._id,
        academicYear: urlValues.academicYear,
        visible: true,
      },
      listSiswa,
      listTahunAjaran,
      showFilter: true,
    });
  };

  submitFilter = async (e) => {
    if (e) e.preventDefault();
    const { router } = this.props;
    const { filterCriteria } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    // Router.replace({
    //   pathname: "/school_pay_history_new",
    //   query: {
    //     ...router.query,
    //     studentId: filterCriteria.studentId,
    //     academicYear: filterCriteria.academicYear,
    //   },
    // });

    this.setState({
      showFilter: false,
      currentPage: 0,
      allHistories: [],
      historyLoading: true,
    });

    let urlParams = {
      ...urlValues,
      studentId: filterCriteria.studentId,
      academicYear: filterCriteria.academicYear,
    };

    urlParams = queryString.stringify(urlParams);
    setTimeout(() => {
      window.location.href = `/student_bill/school_pay_history_new?${urlParams}`;
    }, 1000);
  };

  render() {
    const { loading } = this.props;
    const {
      selectedSiswa,
      historyLoading,
      allHistories,
      paymentDetails,
      filterCriteria,
      showFilter,
      listSiswa,
      listTahunAjaran,
      paymentDetailsV2,
    } = this.state;

    // console.log({ filterCriteria, listTahunAjaran });
    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <FormModal
          title={<span>Filter</span>}
          visible={showFilter}
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              showFilter: false,
            });
          }}
          onSubmit={this.submitFilter}
        >
          <div className="form-group">
            <label>Siswa</label>
            <select
              className="form-control"
              value={filterCriteria.studentId}
              onChange={this.handleFilterCriteria("studentId")}
              required
            >
              <option value="" disabled>
                Pilih Siswa
              </option>
              {listSiswa.map((siswa) => (
                <option value={siswa._id}>{siswa.name}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Tahun Ajaran</label>
            <select
              className="form-control"
              value={filterCriteria.academicYear}
              onChange={this.handleFilterCriteria("academicYear")}
              required
            >
              <option value="" disabled>
                Pilih Tahun Ajaran
              </option>
              {listTahunAjaran.map((ta) => (
                <option value={ta.label}>
                  {ta.label} {ta.inProgress ? "(On Going)" : ""}
                </option>
              ))}
            </select>
          </div>
        </FormModal>

        <FormModal
          visible={paymentDetails.visible}
          title={
            <span>
              <i className="fa fa-info-circle" /> Detil Transaksi
            </span>
          }
          onClose={this.closeDetail}
        >
          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">No. Trans</p>
            <p className="mb-0">{paymentDetails.history.transactionNumber}</p>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">Tgl. Trans</p>
            <p className="mb-0">
              {dayjs(paymentDetails.history._createdAt).format(
                "DD/MM/YY HH:mm:ss"
              )}
            </p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">Exp. Date</p>
            <p
              className="mb-0"
              style={{
                color: "rgb(250, 46, 32)",
              }}
            >
              {dayjs(paymentDetails.history.expiredAt).format(
                "DD/MM/YY HH:mm:ss"
              )}
            </p>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">Mtd. Bayar</p>
            <p className="mb-0">{paymentDetails.paymentMethod.toUpperCase()}</p>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">No VA</p>
            <p className="mb-0">{paymentDetails.virtualAccount}</p>
          </div>
          {/* {paymentDetails.history.status === "waiting" ? (
            <div className="d-flex justify-content-between mb-2">
              <p className="mb-0">No. VA</p>
              <p
                className="mb-0"
                style={{
                  color: "rgb(45, 14, 237)",
                }}
              >
                <b>{paymentDetails.virtualAccount}</b>
              </p>
            </div>
          ) : null} */}

          {paymentDetails.history.status === "waiting" ? (
            <div className="d-flex justify-content-end mb-2">
              <CopyToClipboard
                text={paymentDetails.virtualAccount}
                onCopy={() => {
                  this.setState({ vaCopied: true });
                  addNotification({
                    level: "success",
                    message: "Copied",
                  });
                }}
              >
                <button className="btn btn-sm btn-primary">
                  <i className="fa fa-copy" /> Salin
                </button>
              </CopyToClipboard>
            </div>
          ) : null}

          <center>Detil Transaksi</center>
          {paymentDetails.paymentMethod === "tokopedia" ||
          paymentDetails.paymentMethod === "shopee" ? (
            <div className="mb-2">
              <p style={{ textAlign: "justify" }}>
                Biaya transaksi dengan{" "}
                {paymentDetails.paymentMethod.toUpperCase()} akan dikenakan
                biaya administrasi Rp. 1000 /transaksi.
              </p>

              <p style={{ textAlign: "justify" }}>
                <b>
                  Biaya admin tersebut belum termasuk biaya admin dari{" "}
                  {paymentDetails.paymentMethod.toUpperCase()}
                </b>
              </p>
            </div>
          ) : null}

          <div className="d-flex justify-content-between mb-2">
            <p
              className="mb-0"
              // style={{
              //   fontSize: 16,
              // }}
            >
              Tagihan
            </p>
            <p
              className="mb-0"
              // style={{
              //   fontSize: 16,
              // }}
            >
              Nominal Tagihan (Rp)
            </p>
          </div>
          <hr style={{ marginTop: 0, marginBottom: 0, borderColor: "#000" }} />
          {paymentDetails.details.map((det) => (
            <div>
              <div className="d-flex justify-content-between">
                <p
                  className="mb-0"
                  // style={{
                  //   fontSize: 14,
                  // }}
                >
                  {det.studentBillName}
                </p>
                <p
                  className="mb-0"
                  // style={{
                  //   fontSize: 14,
                  // }}
                >
                  {Number(det.paid)
                    .toLocaleString("id-ID")
                    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                </p>
              </div>
            </div>
          ))}
          <hr style={{ marginTop: 0, marginBottom: 0, borderColor: "#000" }} />

          <div className="d-flex justify-content-between mb-1 mt-4">
            <p className="mb-0">Total Tagihan</p>
            <p className="mb-0">
              <b>
                Rp.{" "}
                {Number(
                  paymentDetails.details
                    .map((d) => d.paid)
                    .reduce((acc, curr) => acc + curr, 0)
                )
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between mb-1">
            <p className="mb-0">Administrasi</p>
            <p className="mb-0">
              Rp.{" "}
              {Number(paymentDetails.fee)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
            </p>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <p className="mb-0">Sub. Total</p>
            <p className="mb-0">
              <b>
                Rp.{" "}
                {Number(paymentDetails.total)
                  .toLocaleString("id-ID")
                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              </b>
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <p className="mb-0">Status</p>
            <p
              className="mb-0"
              style={{
                color:
                  paymentDetails.history.status === "paid"
                    ? "rgb(114, 248, 8)"
                    : paymentDetails.history.status === "waiting"
                    ? "rgb(247, 224, 22)"
                    : "rgb(250, 46, 32)",
              }}
            >
              <b>
                {paymentDetails.history.status
                  ? paymentDetails.history.status.toUpperCase()
                  : ""}
              </b>
            </p>
          </div>

          {paymentDetails.history.status === "waiting" ? (
            <div className="mt-4">
              <div
                className="d-flex justify-content-center"
                onClick={this.handleCancel(paymentDetails.history)}
              >
                <div className="btn btn-sm btn-danger">
                  <i className="fa fa-times-circle" /> Batalkan Transaksi
                </div>
              </div>
            </div>
          ) : null}
        </FormModal>

        <FormModal
          visible={
            paymentDetailsV2 && paymentDetailsV2.visible
              ? paymentDetailsV2.visible
              : false
          }
          title={
            <span>
              <i className="fa fa-info-circle" /> Detil Transaksi
            </span>
          }
          onClose={(e) => {
            if (e) e.preventDefault();
            this.setState({
              paymentDetailsV2: null,
            });
          }}
        >
          {paymentDetailsV2 ? (
            <div>
              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">No. Trans</p>
                <p className="mb-0">
                  {paymentDetailsV2.history.transactionNumber}
                </p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Tgl. Trans</p>
                <p className="mb-0">
                  {dayjs(paymentDetailsV2.history._createdAt).format(
                    "DD/MM/YY HH:mm:ss"
                  )}
                </p>
              </div>
              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Exp. Date</p>
                <p
                  className="mb-0"
                  style={{
                    color: "rgb(250, 46, 32)",
                  }}
                >
                  {dayjs(paymentDetailsV2.history.expiredAt).format(
                    "DD/MM/YY HH:mm:ss"
                  )}
                </p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Mtd. Bayar</p>
                <p className="mb-0">
                  {paymentDetailsV2.paymentMethod.toUpperCase()}
                </p>
              </div>

              <center>Detil Transaksi</center>
              {paymentDetailsV2.paymentMethod === "tokopedia" ||
              paymentDetailsV2.paymentMethod === "shopee" ? (
                <div className="mb-2">
                  <p style={{ textAlign: "justify" }}>
                    Biaya transaksi dengan{" "}
                    {paymentDetailsV2.paymentMethod.toUpperCase()} akan
                    dikenakan biaya administrasi Rp. 1000 /transaksi.
                  </p>

                  <p style={{ textAlign: "justify" }}>
                    <b>
                      Biaya admin tersebut belum termasuk biaya admin dari{" "}
                      {paymentDetailsV2.paymentMethod.toUpperCase()}
                    </b>
                  </p>
                </div>
              ) : null}

              <div className="d-flex justify-content-between mb-2">
                <p
                  className="mb-0"
                  style={{
                    fontSize: 12,
                  }}
                >
                  Tagihan
                </p>
                <p
                  className="mb-0"
                  style={{
                    fontSize: 12,
                  }}
                >
                  Nominal Tagihan (Rp)
                </p>
              </div>
              <hr
                style={{ marginTop: 0, marginBottom: 0, borderColor: "#000" }}
              />
              {paymentDetailsV2.details.map((det) => (
                <div>
                  <div className="d-flex justify-content-between">
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {det.studentBillName}
                    </p>
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {Number(det.paid)
                        .toLocaleString("id-ID")
                        .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                    </p>
                  </div>
                </div>
              ))}
              <hr
                style={{ marginTop: 0, marginBottom: 0, borderColor: "#000" }}
              />

              <div className="d-flex justify-content-between mb-1 mt-4">
                <p className="mb-0">Total Tagihan</p>
                <p className="mb-0">
                  <b>
                    Rp.{" "}
                    {Number(
                      paymentDetailsV2.details
                        .map((d) => d.paid)
                        .reduce((acc, curr) => acc + curr, 0)
                    )
                      .toLocaleString("id-ID")
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                  </b>
                </p>
              </div>
              <div className="d-flex justify-content-between mb-1">
                <p className="mb-0">Administrasi</p>
                <p className="mb-0">
                  <b>Rp. 5.000</b>
                </p>
              </div>

              <div className="d-flex justify-content-between mb-2">
                <p className="mb-0">Sub. Total</p>
                <p className="mb-0">
                  <b>
                    Rp.{" "}
                    {Number(paymentDetailsV2.total)
                      .toLocaleString("id-ID")
                      .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                  </b>
                </p>
              </div>
              <div className="d-flex justify-content-between">
                <p className="mb-0">Status</p>
                <p
                  className="mb-0"
                  style={{
                    color:
                      paymentDetailsV2.history.status === "paid"
                        ? "rgb(114, 248, 8)"
                        : paymentDetailsV2.history.status === "waiting"
                        ? "rgb(247, 224, 22)"
                        : "rgb(250, 46, 32)",
                  }}
                >
                  <b>
                    {paymentDetailsV2.history.status
                      ? paymentDetailsV2.history.status.toUpperCase()
                      : ""}
                  </b>
                </p>
              </div>

              {paymentDetailsV2.history.status === "waiting" ? (
                <div className="mt-4">
                  <div
                    className="d-flex justify-content-center"
                    onClick={this.handleCancelV2(paymentDetailsV2.history)}
                  >
                    <div className="btn btn-sm btn-danger">
                      <i className="fa fa-times-circle" /> Batalkan Transaksi
                    </div>
                  </div>

                  <div className="d-flex justify-content-center">
                    <a
                      href={paymentDetailsV2.history.paymentUrl}
                      target="__blank"
                    >
                      <div className="btn btn-sm btn-success">
                        <i className="fa fa-paper-plane" /> Lanjutkan
                      </div>
                    </a>
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-history"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Riwayat Pembayaran")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <div
            className="fixed-top"
            style={{
              backgroundColor: "#fff",
            }}
            ref={(divElement) => {
              this.divElement = divElement;
            }}
          >
            <div>
              {loading ? (
                <ShimmerThumbnail className="m-0" rounded />
              ) : (
                <div className="py-2 px-2">
                  <div className="row justify-content-center">
                    <div className="col-3">
                      <Image
                        style={{ width: 80, height: 80 }}
                        // source={require("../assets/owl-laptop-study.png")}
                        source={require("../../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-9 p-0 d-flex align-items-center">
                      <div
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <p
                          style={{
                            fontSize: 24,
                            margin: 0,
                          }}
                        >
                          {selectedSiswa ? selectedSiswa.name : ""}
                        </p>

                        <p className="m-0">
                          <b>
                            {selectedSiswa && selectedSiswa.SchoolInformation
                              ? selectedSiswa.SchoolInformation.name
                              : "-"}
                          </b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <View
          style={{
            marginTop:
              this.state.height + 10 >= 266 ? "8rem" : this.state.height + 10,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              justifyContent: "center",
              marginBottom: 20,
            }}
          >
            <button
              className="btn btn-md btn-light mr-2"
              onClick={(e) => {
                if (e) e.preventDefault();
                urlValues = queryString.parse(this.props.location.search);
                let urlParams = {
                  ...urlValues,
                  studentId: this.state.selectedSiswa._id,
                  academicYear: urlValues.academicYear,
                };

                if (urlValues.isV2) {
                  delete urlValues.isV2;
                  urlParams = queryString.stringify(urlParams);
                  window.location.href = `/student_bill/student_bill_new_v2?${urlParams}`;
                } else {
                  urlParams = queryString.stringify(urlParams);
                  window.location.href = `/student_bill/student_bill_new?${urlParams}`;
                }
              }}
            >
              Daftar Tagihan
            </button>
            <button className="btn btn-md btn-primary">
              Riwayat Pembayaran
            </button>
          </View>

          <center>
            <h3>Riwayat Pembayaran</h3>
          </center>
          {historyLoading ? (
            <ShimmerThumbnail className="m-0" rounded />
          ) : (
            <InfiniteScroll
              dataLength={allHistories.length} //This is important field to render the next data
              next={this.fetchMoreData}
              hasMore={this.state.hasMore}
              loader={
                <h4 className="text-center my-4 text-info">
                  <i className="fa fa-circle-notch fa-spin" /> Loading
                </h4>
              }
              endMessage={
                <h4 className="text-center my-4 text-secondary">
                  <i className="fa fa-info-circle" /> No more payment.
                </h4>
              }
              // onScroll={this.listenScrollToTop}
              // below props only if you need pull down functionality
              refreshFunction={this.refresh}
              pullDownToRefresh
              pullDownToRefreshContent={
                <h4 className="text-center my-4 text-info">
                  {allHistories.length === 0 ? (
                    <span>&nbsp;</span>
                  ) : (
                    <span>&#8595; Pull down to refresh</span>
                  )}
                </h4>
              }
              pullDownToRefreshThreshold={200}
              releaseToRefreshContent={
                <h4 className="text-center my-4 text-info">
                  &#8593; Release to refresh
                </h4>
              }
            >
              {allHistories.map((history) => (
                <div
                  className="mb-2 px-2 py-2 mx-2"
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    border:
                      history.status === "paid"
                        ? "3px solid rgb(114, 248, 8)"
                        : history.status === "waiting"
                        ? "3px solid rgb(247, 224, 22)"
                        : "3px solid rgb(250, 46, 32)",
                  }}
                  onClick={this.openHistoryDetail(history)}
                >
                  <div>
                    <p
                      className="mb-2"
                      style={{
                        fontSize: 11,
                      }}
                    >
                      <b>Trx ID: {history._id}</b>
                    </p>
                    <div className="d-flex justify-content-between align-items-center">
                      <div
                        className="d-flex flex-column"
                        style={{
                          flex: 1,
                          paddingRight: "1rem",
                        }}
                      >
                        <div className="row">
                          <div
                            className="col-5"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">No. Trans</p>
                          </div>
                          <div
                            className="col-7"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">
                              <b>{history.transactionNumber}</b>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-5"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">Tgl. Trans</p>
                          </div>
                          <div
                            className="col-7"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">
                              {dayjs(history._createdAt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-5"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">Nominal</p>
                          </div>
                          <div
                            className="col-7"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">
                              <b>
                                Rp.{" "}
                                {Number(history.total)
                                  .toLocaleString("id-ID")
                                  .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                              </b>
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div
                            className="col-5"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <p className="mb-0">Exp Date</p>
                          </div>
                          <div
                            className="col-7"
                            style={{
                              fontSize: 12,
                            }}
                          >
                            {history.status === "expired" ? (
                              <p
                                className="mb-0"
                                style={{
                                  margin: 0,
                                  color: "rgb(250, 46, 32)",
                                }}
                              >
                                <b>
                                  {dayjs(history.expiredAt).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </b>
                              </p>
                            ) : (
                              <p
                                className="mb-0"
                                style={{
                                  margin: 0,
                                }}
                              >
                                <b>
                                  {dayjs(history.expiredAt).format(
                                    "DD/MM/YYYY HH:mm:ss"
                                  )}
                                </b>
                              </p>
                            )}
                          </div>
                        </div>
                        {history.paymentUrl ? null : (
                          <div className="row">
                            <div
                              className="col-5"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              <p className="mb-0">Mtd. Byr</p>
                            </div>
                            <div
                              className="col-7"
                              style={{
                                fontSize: 12,
                              }}
                            >
                              <p className="mb-0">
                                <b>{history.paymentMethod.toUpperCase()}</b>
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <Image
                          style={{ width: 80, height: 80 }}
                          source={
                            history.status === "paid"
                              ? require("../../assets/st-bill-img-pay-done.svg")
                              : history.status === "waiting"
                              ? require("../../assets/st-payment-waiting.svg")
                              : require("../../assets/st-bill-img-pay.svg")
                          }
                        />
                        <center>
                          <p
                            className="mb-0"
                            style={{
                              color:
                                history.status === "paid"
                                  ? "#66BB6A"
                                  : history.status === "waiting"
                                  ? "#FFC107"
                                  : "#F44336",
                            }}
                          >
                            <b>{history.status.toUpperCase()}</b>
                          </p>
                        </center>
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <p
                      className="mb-0"
                      style={{
                        fontSize: 11,
                      }}
                    >
                      <b>Trx ID: {history._id}</b>
                    </p>
                    <hr style={{ margin: 0, borderColor: "#000" }} />
                    <div className="d-flex justify-content-between mt-2">
                      <p className="mb-0">No. Trans</p>
                      <p className="mb-0">
                        <b>{history.transactionNumber}</b>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <p className="mb-0">Tgl. Trans</p>
                      <p
                        style={{
                          fontSize: 12,
                          margin: 0,
                        }}
                      >
                        {dayjs(history._createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <p className="mb-0">Nominal</p>
                      <p
                        className="mb-0"
                        style={{
                          fontSize: 16,
                        }}
                      >
                        <b>
                          Rp.{" "}
                          {Number(history.total)
                            .toLocaleString("id-ID")
                            .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                        </b>
                      </p>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <p className="mb-0">Exp. Date</p>

                      {history.status === "expired" ? (
                        <p
                          className="mb-0"
                          style={{
                            fontSize: 12,
                            margin: 0,
                            color: "rgb(250, 46, 32)",
                          }}
                        >
                          <b>
                            {dayjs(history.expiredAt).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </b>
                        </p>
                      ) : (
                        <p
                          className="mb-0"
                          style={{
                            fontSize: 12,
                            margin: 0,
                          }}
                        >
                          <b>
                            {dayjs(history.expiredAt).format(
                              "DD/MM/YYYY HH:mm:ss"
                            )}
                          </b>
                        </p>
                      )}
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <p className="mb-0">Status</p>
                      <p
                        className="mb-0"
                        style={{
                          color:
                            history.status === "paid"
                              ? "rgb(114, 248, 8)"
                              : history.status === "waiting"
                              ? "rgb(247, 224, 22)"
                              : "rgb(250, 46, 32)",
                        }}
                      >
                        <b>{history.status.toUpperCase()}</b>
                      </p>
                    </div>

                    <div className="d-flex justify-content-between mb-2">
                      <p className="mb-0">Mtd. Bayar</p>
                      <p className="mb-0">
                        <b>{history.paymentMethod.toUpperCase()}</b>
                      </p>
                    </div>
                  </div> */}
                </div>
              ))}
            </InfiniteScroll>
          )}
        </View>
        {/* <div className="fixed-bottom py-2">
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-sm btn-primary mx-2"
              style={{
                borderRadius: "50%",
                width: "50px",
                height: "50px",
              }}
              onClick={(e) => {
                if (e) e.preventDefault();
                urlValues = queryString.parse(this.props.location.search);
                let urlParams = {
                  ...urlValues,
                  studentId: this.state.selectedSiswa._id,
                  academicYear: urlValues.academicYear,
                };
                urlParams = queryString.stringify(urlParams);
                window.location.href = `/student_bill/student_bill_new?${urlParams}`;
              }}
            >
              <i className="fa fa-arrow-left" />
            </button>

            <button
              className="btn btn-sm btn-success mx-2"
              style={{
                borderRadius: "50%",
                width: "50px",
                height: "50px",
              }}
              onClick={this.openFilter}
            >
              <i className="fa fa-filter" />
            </button>
          </div>
        </div> */}
      </View>
    );
  }

  handleCancel = (history) => async (e) => {
    if (e) e.preventDefault();
    showLoadingSpinner();
    try {
      if (
        window.confirm(
          "Apakah Anda yakin untuk membatalkan transaksi ini? Transaksi yang dibatalkan tidak dapat digunakan kembali"
        )
      ) {
        await mwvClient.mutate({
          mutation: CANCEL_WAITING_TRANSACTION,
          variables: {
            _id: history._id,
          },
          fetchPolicy: "no-cache",
        });

        this.closeDetail();
        setTimeout(() => window.location.reload(), 1000);
      }
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const HISTORY_DETAIL_QUERY = gql`
  query schoolPayDetails($_id: String!) {
    schoolPayDetails(_id: $_id) {
      _id
      studentBillName
      paid
      _createdAt
      _updatedAt
    }
  }
`;
const PAYMENT_HISTORY_QUERIES = gql`
  query paginatedSchoolPayHistory(
    $studentId: String!
    $academicYear: String!
    $limit: Int
    $page: Int
  ) {
    paginatedSchoolPayHistory(
      studentId: $studentId
      academicYear: $academicYear
      limit: $limit
      page: $page
    ) {
      pages
      rows {
        _id
        studentBillIds
        type
        total
        paymentMethod
        paymentMethodDetail
        expiredAt
        status
        transactionNumber
        paymentUrl
        _createdAt
      }
    }
  }
`;

const ALL_STUDENT_QUERIES = gql`
  query listQuery($_id: String, $PREFIX: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      Classroom {
        _id
        academicYear
      }
      PREFIX
      photoUrl
    }
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      inProgress
    }
  }
`;

const CANCEL_WAITING_TRANSACTION = gql`
  mutation cancelTransaction($_id: String!) {
    cancelTransaction(_id: $_id)
  }
`;

const ALL_ACADEMIC_YEAR_BY_STUDENT = gql`
  query allAcademicYearsByStudent($studentId: String) {
    allAcademicYearsByStudent(studentId: $studentId) {
      _id
      label
      inProgress
    }
  }
`;

const GET_SCHOOL_PAY_V2 = gql`
  query getSchoolPayV2($_id: String!) {
    getSchoolPayV2(_id: $_id)
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);
  return (
    <Query
      query={ALL_STUDENT_QUERIES}
      client={mwvClient}
      variables={{
        PREFIX: urlValues.PREFIX ? urlValues.PREFIX : "",
        _id: urlValues.sessionId,
        ...urlValues,
        // x: studentId,
      }}
    >
      {({ error, loading, data, refetch }) => (
        <div>
          <SchoolPayHistory
            {...props}
            error={error}
            loading={loading}
            allStudents={
              data && data.allStudentBySessionId
                ? data.allStudentBySessionId
                : []
            }
            allAcademicYears={
              data && data.allAcademicYears ? data.allAcademicYears : []
            }
          />
        </div>
      )}
    </Query>
  );
});
