import React, { Component } from "react";
import {
  View,
  Image,
  StatusBar,
  Text,
  TouchableOpacity
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";

let urlValues;
class FindHelp extends Component {
  state = {
    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {}
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession);

    urlValues = queryString.parse(this.props.location.search);

    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name
      });
      this.props.history.push({
        pathname: "/find_help",
        search: `?sessionId=${accountSession._id}&keyword=${urlValues.keyword}`
      });
    } else {
      this.props.history.push({
        pathname: "/login"
      });
    }
  };

  handleInput = e => {
    // console.log(key, text);
    this.setState({
      keyword: e.target.value
    });
  };

  openPage = item => e => {
    // console.log(item);
    this.props.history.push({
      pathname: "/help_item_descriptions",
      search: `?sessiondId=${urlValues.sessionId}&categoryId=${item.Category._id}&categoryName=${item.Category.name}&itemId=${item._id}`
    });
  };

  refresh = () => {
    window.location.reload();
  };

  handleSearch = e => {
    if (e) e.preventDefault();
    urlValues = {
      ...urlValues,
      keyword: this.state.keyword
    };
    this.props.history.push({
      pathname: "/find_help",
      search: `?sessionId=${this.state.session._id}&keyword=${this.state.keyword}`
    });
  };

  render() {
    const { findHelpItems } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className={"fa fa-question-circle"}
                  style={{
                    fontSize: 20
                  }}
                />{" "}
                Help
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 80,
            marginTop: 65
          }}
        >
          <div className="row" style={{ marginBottom: 10 }}>
            <div className="col-4">
              <div className="fa-pull-left">
                <TouchableOpacity
                  onPress={() =>
                    this.props.history.push({
                      pathname: "/help",
                      search: `?sessionId=${
                        urlValues && urlValues.sessionId
                          ? urlValues.sessionId
                          : ""
                      }`
                    })
                  }
                >
                  <Text style={{ color: "#00a8ff" }}>
                    <i
                      className="fa fa-arrow-left"
                      style={{
                        fontSize: 20
                      }}
                    />{" "}
                    Kembali
                  </Text>
                </TouchableOpacity>
              </div>
            </div>
          </div>
          <section
            className="card card-blue"
            style={{ padding: 20, margin: "3px 0" }}
          >
            <div className="card-block">
              <form onSubmit={this.handleSearch}>
                <div>
                  <h5>Help Center</h5>
                </div>
                <div className="form-group" style={{ marginBottom: 6 }}>
                  <label>
                    <i className="fa fa-search" /> Search
                  </label>
                  <input
                    className="form-control"
                    placeholder="Find something"
                    value={this.state.keyword}
                    onChange={this.handleInput}
                  />
                </div>
              </form>
            </div>
          </section>

          {findHelpItems && findHelpItems.length === 0 ? (
            <div>
              <center>
                <Image
                  style={{ width: 250, height: 250 }}
                  source={require("../assets/owl-laptop-study.png")}
                />
              </center>
              <Text>
                <h5 className="text-center" style={{ fontWeight: "bold" }}>
                  Oops, item bantuan tidak ditemukan.
                  <br />
                  Silahkan mencoba menggunakan kata kunci pencarian yang
                  berbeda.
                </h5>
              </Text>
            </div>
          ) : null}
          {findHelpItems
            ? findHelpItems.map(item => (
                <TouchableOpacity onPress={this.openPage(item)}>
                  <section
                    className="card"
                    style={{
                      padding: 20,
                      margin: "3px 0",
                      borderColor: item.Category.iconColor,
                      color: item.Category.iconColor
                    }}
                  >
                    <div className="card-block">
                      <div className="fa-pull-left">
                        <i
                          className={"fa fa-" + item.icon}
                          style={{
                            fontSize: 20
                          }}
                        />{" "}
                        {item.topic}
                        <br />
                        <i>Kategor {item.Category.name}</i>
                      </div>
                      <div className="fa-pull-right">
                        <i
                          className="fa fa-caret-right"
                          style={{
                            fontSize: 20
                          }}
                        />
                      </div>
                      <div className="clearfix" />
                    </div>
                  </section>
                </TouchableOpacity>
              ))
            : null}
          <br />
          <section className="card" style={{ padding: 20 }}>
            <div className="card-block">
              <p>
                <b>Hubungi Kami</b>
                <br />
                Jika ada pertanyaan yang belum tercakup, Anda dapat menghubungi
                Kami menggunakan informasi dibawah ini
              </p>
              <p>
                www.softwaresekolah.co.id
                <br />
                Perumahan Pondok Maritim Indah Blok DD 18
                <br />
                Surabaya, Jawa Timur
                <br />
                Indonesia
                <br />
                info@softwaresekolah.co.id
                <br />
                087722663637
              </p>
            </div>
          </section>
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const supportClient = new ApolloClient({
  uri: "https://api.support.softwaresekolah.co.id/graphql"
});

const FIND_HELPS_QUERY = gql`
  query findHelpItems($keyword: String!) {
    findHelpItems(keyword: $keyword) {
      _id
      keywords
      Category {
        _id
        name
        iconKey
        iconColor
      }
      topic
      content
      author
    }
  }
`;
export default withRouter(props => (
  <Query
    query={FIND_HELPS_QUERY}
    client={supportClient}
    variables={{
      keyword: urlValues && urlValues.keyword ? urlValues.keyword : ""
    }}
  >
    {({ error, loading, data, refetch }) => (
      <div>
        <FindHelp
          {...props}
          error={error}
          loading={loading}
          refetch={refetch}
          findHelpItems={data && data.findHelpItems ? data.findHelpItems : []}
        />
      </div>
    )}
  </Query>
));
