import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";

let urlValues;
const FilterModal = ({
  employees,
  handleSelectEmployee,
  selectedEmployeeId,
  employeeId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {employees.map((employee) => (
        <div key={employee._id}>
          <section
            className="card"
            onClick={handleSelectEmployee(employee)}
            style={{
              borderWidth: "3px",
              borderColor: employee._id === employeeId ? "#3498db" : "#bdc3c7",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {employee.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  ) : (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginTop: "0.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {employee.name}
                    </b>
                    <br />
                    {employee.SchoolInformation
                      ? employee.SchoolInformation.name
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class MenuKepegawaian extends Component {
  state = {
    session: {},
    employees: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    loading: false,
    filterModalVisible: false,
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await this.props.client.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUERIES,
      variables: {
        sessionId: accountSession._id,
      },
    });

    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];

    this.setState({
      employees,
    });
    if (urlValues.employeeId) {
      this.props.history.replace({
        pathname: "/list_menu_kepegawaian",
        search: `?sessionId=${accountSession._id}&employeeId=${urlValues.employeeId}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/list_menu_kepegawaian",
        search: `?sessionId=${accountSession._id}&employeeId=${
          employees[0] ? employees[0]._id : ""
        }`,
      });
    }
  };

  handleSelectEmployee = (employee) => async (e) => {
    this.props.history.replace({
      pathname: "/list_menu_kepegawaian",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${employee._id}`,
    });
    this.closeFilterModalVisible();
  };

  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };
  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };
  refresh = () => {
    window.location.reload();
  };

  gotoMenu = (page, emp) => (e) => {
    console.log("AAA");
    urlValues = queryString.parse(this.props.location.search);
    let urlParams = {
      ...urlValues,
      PREFIX: emp.SchoolInformation.PREFIX,
      academicYear: defaultAcademicYear(),
      semester: defaultSemester(),
    };
    if (emp.SchoolInformation.PREFIX) {
      urlParams = queryString.stringify(urlParams);

      if (page === "inka_pengembangan_diri") {
        window.location.href = `/inka_pengembangan_diri?${urlParams}`;
      } else if (page === "inka_pembinaan_pegawai") {
        window.location.href = `/inka_pembinaan_pegawai?${urlParams}`;
      } else {
        window.location.href = `/employee_data?${urlParams}`;
      }
    }
  };

  render() {
    const { loading } = this.state;
    const allEmployees = this.props.allEmployees;
    const employee =
      allEmployees && allEmployees !== 0
        ? allEmployees.find((employee) => employee._id === urlValues.employeeId)
        : [];

    const PREFIX = employee && employee.PREFIX ? employee.PREFIX : "";

    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-star" style={{ fontSize: 20 }} />{" "}
              {translate("Assessmen Pegawai")}
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {employee && employee.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{employee.name}
                            <br />
                            <b>
                              {employee.SchoolInformation
                                ? employee.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Filter Pegawai")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            employees={allEmployees}
            handleSelectEmployee={this.handleSelectEmployee}
            employeeId={urlValues.employeeId}
          />
        </FormModal>
        <div className="container">
          <section
            className="card"
            style={{ padding: "10px 20px 10px 20px", marginBottom: 10 }}
            onClick={this.gotoMenu("employee_data", employee)}
          >
            <div className="card-block">
              <div className="d-flex justify-content-start">
                <Image
                  style={{ width: 40, height: 40 }}
                  source={require("../assets/100- desktop-work-desk-planner.png")}
                />

                <div className="d-flex flex-column align-self-center">
                  <div>
                    <p
                      align="justify"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      Data Pegawai
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="card"
            style={{
              padding: "10px 20px 10px 20px",
              marginBottom: 10,
              display: PREFIX === "INKA" ? "block" : "none",
            }}
            onClick={this.gotoMenu("inka_pengembangan_diri", employee)}
          >
            <div className="card-block">
              <div className="d-flex justify-content-start">
                <Image
                  style={{ width: 40, height: 40 }}
                  source={require("../assets/icon-pengembangan-pegawai.png")}
                />

                <div className="d-flex flex-column align-self-center">
                  <div>
                    <p
                      align="justify"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      Pengembangan Diri
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section
            className="card"
            style={{
              padding: "10px 20px 10px 20px",
              marginBottom: 10,
              // display: employee.PREFIX === "INKA" ? "block" : "none",
            }}
            onClick={this.gotoMenu("inka_pembinaan_pegawai", employee)}
          >
            <div className="card-block">
              <div className="d-flex justify-content-start">
                <Image
                  style={{ width: 40, height: 40 }}
                  source={require("../assets/icon-pembinaan-pegawai.png")}
                />

                <div className="d-flex flex-column align-self-center">
                  <div>
                    <p
                      align="justify"
                      style={{
                        marginBottom: 0,
                        paddingLeft: 10,
                        fontSize: 18,
                      }}
                    >
                      Keaktifan Pembinaan Rutin
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <div className="row">
            <div className="col-6">
              <section
                className="card"
                onClick={this.gotoMenu("inka_pengembangan_diri", employee)}
                style={{
                  height: "20vh",
                  width: "auto",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: "80%", height: "80%" }}
                    source={require("../assets/icon-pengembangan-pegawai.png")}
                  />

                  <Text>Pengembangan Diri</Text>
                </View>
              </section>
            </div>
            <div className="col-6">
              <section
                className="card"
                onClick={this.gotoMenu("inka_pembinaan_pegawai", employee)}
                style={{
                  height: "20vh",
                  width: "auto",
                }}
              >
                <View
                  style={{
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Image
                    style={{ width: "80%", height: "80%" }}
                    source={require("../assets/icon-pembinaan-pegawai.png")}
                  />

                  <Text>Keaktifan Pembinaan Rutin</Text>
                </View>
              </section>
            </div>
          </div> */}
        </div>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQueries($sessionId: String!) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name

        PREFIX
      }
      PREFIX
    }
  }
`;
const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;
// export default withRouter(props => (
//   <ApolloConsumer>
//     {client => (
//       <MenuKepegawaian
//         {...props}
//         client={client}
//       />
//     )}
//   </ApolloConsumer>
// ));

export default withRouter(
  withI18n("menu_kepegawaian")((props) => {
    urlValues = queryString.parse(props.location.search);

    return (
      <Query
        query={QUERIES}
        client={mwvClient}
        variables={{
          sessionId: urlValues.sessionId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <MenuKepegawaian
              {...props}
              error={error}
              loading={loading}
              allEmployees={
                data && data.allEmployeesBySessionId
                  ? data.allEmployeesBySessionId
                  : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
