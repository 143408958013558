import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
  Button,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { Query, Mutation } from "react-apollo";
import uuidV4 from "uuid/v4";
import ListSection from "../components/ListSection";
import CheckoutBills from "../components/SchoolPay/CheckoutBills";
import PaymentMethod from "../components/SchoolPay/PaymentMethod";
import EWalletConfirmation from "../components/SchoolPay/EWalletConfirmation";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MobileBanking from "../components/PaymentTutorial/MobileBanking";
import ATM from "../components/PaymentTutorial/ATM";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

const ListBills = ({ allBills, openGroup }) =>
  allBills.length !== 0 ? (
    allBills.map((bill) => {
      let groupStatus = "";
      if (bill.totalUnpaid > 0) {
        groupStatus = "BELUM LUNAS";
      } else {
        groupStatus = "LUNAS";
      }
      return (
        <section
          className="card"
          key={bill.month}
          style={{
            marginBottom: 8,
            borderWidth: "5px",
          }}
          onClick={openGroup(bill)}
        >
          <div
            className="card-header d-flex flex-row justify-content-center align-items-center"
            style={{
              padding: 10,
              margin: 0,
              backgroundColor:
                groupStatus === "BELUM LUNAS" ? "#fb8c00" : "#2196f3",
              color: "#fff",
            }}
          >
            <div>
              <b>
                Tagihan {bill.bulan} - {groupStatus}
              </b>
            </div>
          </div>
          <div className="card-body" style={{ paddingTop: 5, paddingLeft: 10 }}>
            {bill.tagihan.slice(0, 2).map((t) => {
              return (
                <View
                  style={{
                    marginBottom: 5,
                  }}
                >
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "row",
                      marginBottom: 5,
                      paddingTop: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        flexDirection: "column",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          marginBottom: 5,
                        }}
                      >
                        <b>{t.name}</b>
                      </Text>
                      <Text style={{ marginBottom: 5 }}>
                        Nominal: Rp{" "}
                        {Number(t.amount)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </Text>
                      <Text style={{ marginBottom: 5 }}>
                        Terbayar: Rp{" "}
                        {Number(t.paid)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </Text>
                      <Text style={{ marginBottom: 5 }}>
                        Sisa: Rp{" "}
                        {Number(t.unpaid)
                          .toLocaleString("id-ID")
                          .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                      </Text>
                    </View>

                    <View
                      style={{
                        flex: 1,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-end",
                      }}
                    >
                      {t.status === "LUNAS" ? (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 50, color: "#76ff03" }}>
                            <i className="fa fa-check-circle" />
                          </Text>
                          <Text style={{ fontSize: 18, color: "#76ff03" }}>
                            {t.status}
                          </Text>
                        </View>
                      ) : (
                        <View
                          style={{
                            flex: 1,
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Text style={{ fontSize: 50, color: "#ff9800" }}>
                            <i className="fa fa-exclamation-circle" />
                          </Text>
                          <Text style={{ fontSize: 18, color: "#ff9800" }}>
                            BELUM
                          </Text>
                          <Text style={{ fontSize: 18, color: "#ff9800" }}>
                            LUNAS
                          </Text>
                        </View>
                      )}
                    </View>
                  </View>

                  <hr style={{ margin: 0 }} />
                </View>
                // <div style={{ marginBottom: 5 }}>
                //   <div className="row">
                //     <div className="col-9">
                //       <b>{t.name}</b>
                //       <br />
                //       <small style={{ fontSize: "80%" }}>
                //         Nominal: Rp{" "}
                //         {Number(t.amount)
                //           .toLocaleString("id-ID")
                //           .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                //         <br />
                //         Terbayar: Rp{" "}
                //         {Number(t.paid)
                //           .toLocaleString("id-ID")
                //           .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                //         <br />
                //         Sisa: Rp{" "}
                //         {Number(t.unpaid)
                //           .toLocaleString("id-ID")
                //           .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
                //       </small>
                //     </div>
                //     <div className="col-3">
                //       <center>
                //         {t.status === "LUNAS" ? (
                //           <div className="text-success">
                //             <i className="fa fa-check-circle fa-2x" />
                //             <br />
                //             <small
                //               style={{
                //                 fontSize: "70%",
                //               }}
                //             >
                //               <b>{t.status}</b>
                //             </small>
                //           </div>
                //         ) : (
                //           <div className="text-warning">
                //             <i className="fa fa-exclamation-circle fa-2x" />
                //             <br />
                //             <small
                //               style={{
                //                 fontSize: "70%",
                //               }}
                //             >
                //               <b>{t.status}</b>
                //             </small>
                //           </div>
                //         )}
                //       </center>
                //     </div>
                //   </div>
                //   <hr style={{ margin: 0 }} />
                // </div>
              );
            })}
            {bill.tagihan.length > 2 ? (
              <center>
                <div
                  style={{
                    fontWeight: "bold",
                    color: "#2196f3",
                  }}
                >
                  + {bill.tagihan.length - 2} Tagihan lagi
                </div>
              </center>
            ) : null}
          </div>
        </section>
      );
    })
  ) : (
    <div>
      <center>
        <img
          src="/static/images/18-receipt.png"
          className="img img-responsive"
          style={{ maxWidth: 240 }}
        />
        <h4>
          <b>{translate("Tagihan Tidak Ditemukan")}</b>
        </h4>
      </center>
    </div>
  );

const PaymentsByBillId = ({ paymentsByStudentBillId }) =>
  paymentsByStudentBillId.length !== 0 ? (
    paymentsByStudentBillId.map((payment) => (
      <ListSection key={payment._id}>
        <section
          className="card"
          style={{ marginBottom: 5, padding: 20, border: "none" }}
        >
          <div className="card-block">
            <Text style={{ fontSize: 20, fontWeight: "bold" }}>
              {payment.description}
            </Text>
            <Text style={{ fontSize: 18 }}>
              <br />
              Tanggal: {payment.date}
              <br />
              Jumlah:{" "}
              {Number(payment.debit !== 0 ? payment.debit : payment.credit)
                .toLocaleString("id-ID")
                .replace(/(\d)(?=(\d{3})+\.)/g, "$1,")}
              <br />
              Nomor Transaksi: {payment.transactionNumber}
            </Text>
          </div>
        </section>
      </ListSection>
    ))
  ) : (
    <View style={{ flex: 1 }}>
      <center>
        <Image
          style={{ width: 250, height: 250 }}
          source={require("../assets/receipt.png")}
        />
        <Text style={{ fontSize: 25, fontWeight: "bold" }}>
          Riwayat Pembayaran Tidak Ditemukan
        </Text>
      </center>
    </View>
  );

class BillExperiment extends Component {
  state = {
    criteria: {
      billType: "Semua Jenis Tagihan",
      billName: "",
      academicYear: "",
      limit: 5,
    },

    selectTutorial: {
      atm: false,
      mobile: false,
    },

    vaCopyValue: "",
    vaCopied: false,

    allAcademicYears: [],

    listBillsVisible: true,
    listPaymentsVisible: false,
    filterModalVisible: false,
    listPaymentByBillIdVisible: false,
    allStudents: [],
    clickedBill: {},

    selectedBillIds: [],
    checkOutBills: [],

    paymentMethod: "",
    ewalletConfirmationVisible: false,
    bankTransferConfirmationVisible: false,
    phoneNumber: "",
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);
    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let result = await mwvClient.query({
      query: LIST_QUERIES,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    let terms = [];
    terms = await mwvClient.query({
      query: ACADEMIC_YEARS,
      variables: {
        PREFIX: students[0] ? students[0].PREFIX : "",
      },
    });

    if (urlValues.studentId && urlValues.PREFIX) {
      terms = await mwvClient.query({
        query: ACADEMIC_YEARS,
        variables: {
          PREFIX: urlValues ? urlValues.PREFIX : "",
        },
      });

      this.props.history.replace({
        pathname: "/bill_experiment",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&billType=${urlValues.billType}&academicYear=${urlValues.academicYear}`,
      });
      this.setState({
        criteria: {
          ...this.state.criteria,
          academicYear:
            urlValues.academicYear !== "undefined"
              ? urlValues.academicYear
              : "",
          billType: urlValues.billType,
        },
      });
    } else {
      let initialStudent = students.filter(
        (s) => s._id === urlValues.studentId
      );
      this.props.history.replace({
        pathname: "/bill_experiment",
        search: `?sessionId=${accountSession._id}&studentId=${
          initialStudent[0] ? initialStudent[0]._id : ""
        }&PREFIX=${
          initialStudent[0] ? initialStudent[0].PREFIX : ""
        }&billType=${"Semua Jenis Tagihan"}&academicYear=${""}`,
      });

      this.setState({
        criteria: {
          ...this.state.criteria,
          academicYear: "",
        },
      });
    }

    this.setState({
      allStudents: [...students],
      allAcademicYears: terms.data.allAcademicYears,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    let terms = [];
    terms = await mwvClient.query({
      query: ACADEMIC_YEARS,
      variables: {
        PREFIX: urlValues ? urlValues.PREFIX : "",
      },
    });

    this.setState({
      allAcademicYears: terms.data.allAcademicYears,
    });

    this.props.history.replace({
      pathname: "/bill_experiment",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}&billType=${urlValues.billType}&academicYear=${urlValues.academicYear}`,
    });
    this.closeFilterModalVisible();
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  handleCriteria = (key) => (e) => {
    this.setState({
      criteria: {
        ...this.state.criteria,
        [key]: e.target.value,
      },
    });
    urlValues = {
      ...urlValues,
      [key]: e.target.value,
    };

    this.props.history.replace({
      pathname: "/bill_experiment",
      search: `?sessionId=${urlValues.sessionId}&studentId=${
        urlValues.studentId
      }&PREFIX=${urlValues.PREFIX}&billType=${
        urlValues.billType
      }&studentBillId=${urlValues.studentBillId}&academicYear=${
        urlValues.academicYear
      }&billName=${urlValues.billName ? urlValues.billName : ""}`,
    });

    urlValues = queryString.parse(this.props.location.search);
  };

  refresh = () => {
    window.location.reload();
  };

  generateAcademicYears = () => {
    let listYears = [];
    let initYear = new Date().getFullYear() - 3;
    let counter = 1;
    for (let i = 0; i < 7; i++) {
      let ay = initYear + i + "/" + (initYear + (counter + i));
      listYears.push({
        _id: uuidV4(),
        label: ay,
      });
    }

    return listYears;
  };

  openGroup = (group) => (e) => {
    const ids = JSON.stringify(group.tagihan.map((g) => g._id));

    let url = this.props.location.search;

    url = url + `&ids=${ids}`;

    this.props.history.replace({
      pathname: "/bill_detail",
      search: url,
    });
  };
  render() {
    const {
      allStudents,

      selectedBillIds,

      allAcademicYears,
    } = this.state;
    const { allStudentBills, paymentsByStudentBillId } = this.props;

    urlValues = queryString.parse(this.props.location.search);
    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    const foundTerm = allAcademicYears.find((y) => y.isMonthly);
    const daftarTagihan =
      allStudentBills && allStudentBills.length !== 0
        ? JSON.parse(allStudentBills)
        : [];

    // console.log(daftarTagihan);

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Pilih Siswa")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>

        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-invoice"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Daftar Tagihan")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fas fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0, paddingBottom: 10 }}>
            <div
              className="card-block"
              style={{ paddingTop: 10, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("billType")}
                      value={this.state.criteria.billType}
                    >
                      <option value="Semua Jenis Tagihan">
                        {translate("Semua Jenis Tagihan")}
                      </option>
                      <option value="Bulanan">
                        {translate("Tagihan Bulanan")}
                      </option>
                      <option value="Insidental">
                        {translate("Tagihan Insidental")}
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <select
                      className="form-control"
                      onChange={this.handleCriteria("academicYear")}
                      value={this.state.criteria.academicYear}
                    >
                      <option value="" disabled>
                        {foundTerm
                          ? translate("Pilih Term")
                          : translate("Pilih Tahun Ajaran")}
                      </option>
                      {allAcademicYears.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === urlValues.academicYear}
                        >
                          {foundTerm ? "Term" : translate("Tahun Ajaran")}{" "}
                          {ta.label} {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                      {/* {TA.map((ta) => (
                        <option
                          value={ta.label}
                          key={ta._id}
                          selected={ta.label === academicYearNow}
                        >
                          Tahun Ajaran {ta.label}
                        </option>
                      ))} */}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <View
          style={{
            marginTop: 250,
            marginBottom: "100vh",
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          <ListBills allBills={daftarTagihan} openGroup={this.openGroup} />
        </View>

        <div className="fixed-bottom">
          {selectedBillIds.length > 0 ? (
            <button
              type="button"
              onClick={this.openCheckoutModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#27ae60",
                height: 60,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text style={{ color: "white", fontSize: 20 }}>
                <i className="fa fa-money-bill-wave" /> {translate("Bayar")}{" "}
                {selectedBillIds.length} {translate("Tagihan")}
              </Text>
            </button>
          ) : (
            <button
              type="button"
              onClick={this.openFilterModal}
              style={{
                zIndex: 9999,
                backgroundColor: "#00a8ff",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                }}
              >
                <i className="fas fa-search" /> {translate("Pilih Siswa")}
              </Text>
            </button>
          )}
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const LIST_QUERIES = gql`
  query listQuery(
    $_id: String
    $PREFIX: String
    $studentId: String
    $studentBillId: String
    $billType: String
    $academicYear: String
    $billName: String
    $description: String
  ) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      PREFIX
      photoUrl
    }
    allStudentBills(
      studentId: $studentId
      billType: $billType
      academicYear: $academicYear
      billName: $billName
      PREFIX: $PREFIX
    ) {
      _id
      academicYear
      StudentBillType {
        _id
        name
      }
      amount
      month
      tags
      status
      paid
      unpaid
    }
    paymentsByStudentBillId(
      studentBillId: $studentBillId
      studentId: $studentId
      description: $description
    ) {
      _id
      transactionNumber
      date
      debit
      credit
      description
    }

    groupedStudentBills(
      studentId: $studentId
      billType: $billType
      academicYear: $academicYear
      billName: $billName
      PREFIX: $PREFIX
    )
  }
`;

const ACADEMIC_YEARS = gql`
  query listAcademicYear($PREFIX: String) {
    allAcademicYears(PREFIX: $PREFIX) {
      _id
      label
      isMonthly
      inProgress
    }
  }
`;

export default withRouter(
  withI18n("student_bill_and_payment")((props) => {
    urlValues = queryString.parse(props.location.search);
    let PREFIX = urlValues.PREFIX ? urlValues.PREFIX : "";

    let studentId = urlValues.studentId ? urlValues.studentId : "";

    let billType = urlValues.billType
      ? urlValues.billType
      : "Semua Jenis Tagihan";

    let academicYear = urlValues.academicYear ? urlValues.academicYear : "";

    let billName = urlValues.billName ? urlValues.billName : "";

    let studentBillId = urlValues.studentBillId ? urlValues.studentBillId : "";

    return (
      <Query
        query={LIST_QUERIES}
        client={mwvClient}
        variables={{
          _id: urlValues.studentId,
          PREFIX,
          studentId,
          billType,
          billName,
          academicYear,
          studentBillId,
          description: billName,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <BillExperiment
              {...props}
              error={error}
              loading={loading}
              refetch={refetch}
              allStudentBills={
                data && data.groupedStudentBills ? data.groupedStudentBills : []
              }
              paymentsByStudentBillId={
                data && data.paymentsByStudentBillId
                  ? data.paymentsByStudentBillId
                  : []
              }
            />
          </div>
        )}
      </Query>
    );
  })
);
