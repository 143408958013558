import React, { Component } from "react";
import { View, Image, TouchableOpacity } from "react-native";
import { ApolloProvider, Query } from "react-apollo";
import ApolloClient from "apollo-boost";
import gql from "graphql-tag";
import { CarouselProvider, Slider, Slide } from "pure-react-carousel";

const DEVICE_WIDTH = window.innerWidth;
const HEADER_MAX_HEIGHT = 150;

const DEFAULT_DATA = {
  _id: null,
  title: "",
  isPopUp: false,
  thumbnail: null,
  author: "",
  _createdAt: "",
  __typename: "Article"
};

const SCHOOLTALK_ARTICLE_URL = "https://support.softwaresekolah.co.id";

const SUPPORT_GRAPHQL_API_ENDPOINT =
  "https://api.support.softwaresekolah.co.id/graphql";

const client = new ApolloClient({
  uri: SUPPORT_GRAPHQL_API_ENDPOINT,
  credentials: "same-origin"
});

class HighlightedArticles extends Component {
  state = {
    carouselActiveSlideIndex: 0
  };

  handleOpenArticle = item => () => {
    const articleUrl =
      SCHOOLTALK_ARTICLE_URL + "/artikel/article_content?articleId=" + item._id;
    window.open(articleUrl, "_blank");
  };

  renderHighlightsItem = items => {
    return items.map((item, index) => (
      <Slide index={index} key={item._id}>
        <TouchableOpacity
          activeOpacity={0.8}
          style={{
            height: HEADER_MAX_HEIGHT,
            backgroundColor: "#1696ff",
            width: DEVICE_WIDTH
          }}
          onPress={this.handleOpenArticle(item)}
        >
          <Image
            style={{
              flex: 1,
              resizeMode: "cover",
              width: DEVICE_WIDTH
            }}
            defaultSource={require("../assets/placeholder-grey.jpg")}
            source={{
              uri: item.thumbnail
            }}
          />
        </TouchableOpacity>
      </Slide>
    ));
  };

  render() {
    return (
      <ApolloProvider client={client}>
        <Query query={HIGHLIGHTED_ARTICLES}>
          {({ error, loading, data }) => {
            // console.log({ error, SUPPORT_GRAPHQL_API_ENDPOINT, loading, data });
            const recentHighlightedArticles =
              data && data.recentHighlightedArticles
                ? data.recentHighlightedArticles
                : [];
            return (
              <View
                style={{
                  backgroundColor: "#1696ff"
                }}
              >
                <CarouselProvider
                  naturalSlideWidth={100}
                  naturalSlideHeight={125}
                  totalSlides={
                    loading || !recentHighlightedArticles
                      ? 1
                      : recentHighlightedArticles.length
                  }
                  isPlaying={true}
                  interval={5000}
                  style={{}}
                >
                  <Slider>
                    {this.renderHighlightsItem(
                      loading || !recentHighlightedArticles
                        ? [DEFAULT_DATA]
                        : recentHighlightedArticles
                    )}
                  </Slider>
                </CarouselProvider>
              </View>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

const HIGHLIGHTED_ARTICLES = gql`
  query recentHighlightedArticles {
    recentHighlightedArticles {
      _id
      title
      isPopUp
      thumbnail
      author
      _createdAt
    }
  }
`;

export default HighlightedArticles;
