import React from "react";
import { Image } from "react-native-web";
// import Dimensions from "Dimensions";
// const DEVICE_WIDTH = Dimensions.get("window").width;
// const DEVICE_HEIGHT = Dimensions.get("window").height;

const BackgroundImage = ({ opacity }) => (
  <Image
    style={{
      flex: 1,
      position: "fixed",
      top: 0,
      left: 0,
      resizeMode: "cover",
      width: "100%",
      height: "100%",
      zIndex: 0,
      opacity: opacity ? opacity : 1
    }}
    opacity={opacity ? opacity : 1}
    source={require("../assets/school-stuffs.jpg")}
  />
);

export default BackgroundImage;
