import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import { handleError } from "../libs/errors";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";

let urlValues;

class ListEmployee extends Component {
  state = {
    uniqueCode: "",
    Employee: {
      name: "Indra",
      regNumber: 192838383,
    },
    deleteEmployeeModalVisible: false,
    session: {},
    loading: false,
    accountSession: null,
  };

  refresh = () => {
    window.location.reload();
  };

  handleConfirmDeleteEmployee = (employee) => (e) => {
    if (e) e.preventDefault();
    this.setState({
      Employee: employee,
      deleteEmployeeModalVisible: true,
    });
  };

  closeDeleteEmployeeModal = (e) => {
    if (e) e.preventDefault();
    this.setState({
      deleteEmployeeModalVisible: false,
    });
  };

  handleSubmitDeleteEmployee = async (e) => {
    if (e) e.preventDefault();
    const { Employee } = this.state;
    try {
      let fcmToken = "";
      if (!firebase.apps.length) {
        // console.log("Initialize firebase...");
        firebase.initializeApp(firebaseConfig);
      }
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        fcmToken = await messaging.getToken();
      }
      await this.props.unclaimEmployeeForAccount({
        variables: {
          employeeId: Employee._id,
          fcmToken,
        },
      });

      this.props.refetch();

      addNotification({
        title: "SUCCESS",
        message: `${this.props.translate("Berhasil menghapus pegawai")} ${
          Employee.name
        }`,
        level: "success",
      });
    } catch (e) {
      console.log(e);
      if (e.message.indexOf("Invalid employee!") > -1) {
        this.setState({
          Employee: null,
          Errors: e.message,
          loading: false,
        });
        handleError({
          message: "Pegawai tidak bisa dihapus!",
        });
      }
    }
    this.setState({
      deleteEmployeeModalVisible: false,
    });
  };

  render() {
    const { Employee, Errors, loading } = this.state;
    const { allEmployees = [] } = this.props;
    urlValues = queryString.parse(this.props.location.search);
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <style jsx>
          {`
            .td-ellipsis {
              position: relative;
            }
            .td-ellipsis:before {
              content: "&nbsp;";
              visibility: hidden;
            }
            .td-ellipsis span {
              position: absolute;
              left: 0;
              right: 0;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <FormModal
          title={
            <span>
              <i className="fa fa-trash-alt" /> {translate("Hapus Pegawai")}
            </span>
          }
          visible={this.state.deleteEmployeeModalVisible}
          size="md"
          onClose={this.closeDeleteEmployeeModal}
          onSubmit={this.handleSubmitDeleteEmployee}
          verticalCentered={true}
        >
          <div className="text-center">
            {translate("Apakah anda yakin untuk menghapus pegawai atas nama")}{" "}
            <b>{Employee ? Employee.name : ""}</b>?
          </div>
        </FormModal>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-users"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Daftar Pegawai")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        {allEmployees.map((employee, indexEmployee) => (
          <div
            key={employee._id}
            className="card"
            style={{
              borderRadius: "20px",
              margin: `${
                indexEmployee === 0 ? "5rem 15px 1rem 15px" : "0 15px 1rem 15px"
              }`,
            }}
          >
            <div className="card-body">
              <div
                className="box-right"
                style={{
                  width: "8%",
                  position: "absolute",
                  top: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: "0 20px 20px 0",
                  textAlign: "center",
                  backgroundColor: "#e84118",
                }}
              >
                <div
                  className="icon-box"
                  onClick={this.handleConfirmDeleteEmployee(employee)}
                  style={{
                    position: "absolute",
                    left: 0,
                    top: "50%",
                    transform: "translateY(-50%)",
                    height: "20px",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  <i className="fa fa-trash-alt" />
                </div>
              </div>
              <div>
                <table className="w-100">
                  <thead>
                    <tr>
                      <th width="20%" />
                      <th width="5%" />
                      <th width="67%" />
                      <th width="8%" />
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <b>{translate("Nama")}</b>
                      </td>
                      <td>:</td>
                      <td className="td-ellipsis">
                        <span>{employee.name}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <b>NIP</b>
                      </td>
                      <td>:</td>
                      <td>{employee.regNumber}</td>
                    </tr>
                    <tr>
                      <td>
                        <b>{translate("Sekolah")}</b>
                      </td>
                      <td>:</td>
                      <td>
                        {employee.SchoolInformation
                          ? employee.SchoolInformation.name
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
        <hr />
        <div className="fixed-bottom">
          <TouchableOpacity
            onPress={() =>
              this.props.history.push({
                pathname: "/claim_employee",
              })
            }
          >
            <button
              type="button"
              style={{
                zIndex: 9999,
                backgroundColor: "#00a8ff",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 20,
                }}
              >
                <i className="fa fa-arrow-left" /> {translate("Kembali")}
              </Text>
            </button>
          </TouchableOpacity>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const LIST_EMPLOYEES = gql`
  query listQuery($_id: String) {
    allEmployeesBySessionId(sessionId: $_id) {
      _id
      name
      regNumber
      SchoolInformation {
        _id
        name
      }
    }
  }
`;
const UNCLAIM_EMPLOYEE = gql`
  mutation unclaimEmployeeForAccount($employeeId: ID!, $fcmToken: String) {
    unclaimEmployeeForAccount(employeeId: $employeeId, fcmToken: $fcmToken)
  }
`;
export default withRouter(
  withI18n("list_employees")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation mutation={UNCLAIM_EMPLOYEE}>
            {(unclaimEmployeeForAccount) => (
              <Query
                query={LIST_EMPLOYEES}
                client={mwvClient}
                variables={{
                  _id: urlValues.sessionId,
                }}
              >
                {({ error, loading, data, refetch }) => (
                  <div>
                    {/* {console.log(data)} */}
                    <ListEmployee
                      {...props}
                      error={error}
                      loading={loading}
                      refetch={refetch}
                      allEmployees={
                        data && data.allEmployeesBySessionId
                          ? data.allEmployeesBySessionId
                          : []
                      }
                      unclaimEmployeeForAccount={unclaimEmployeeForAccount}
                    />
                  </div>
                )}
              </Query>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  })
);
