import React, { Component } from "react";
import {
  Text,
  View,
  // ScrollView,
  StatusBar,
  TouchableOpacity,
  Image,
} from "react-native-web";
// import BackgroundImage from "../components/BackgroundImage";
import Calendar from "../components/Calendar";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import { withRouter } from "react-router";
// import ListSection from "../components/ListSection";
// import TextInput from "../components/TextInput";
import { LoadingOverlay } from "../components/Overlay";
import { FormModal } from "../components/Modal";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { handleError } from "../libs/errors";
// import { addNotification } from "../App";
import ApolloClient from "apollo-boost";
import dayjs from "dayjs";
import queryString from "query-string";
import { withI18n, translate } from "../libs/withI18n";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;
const FilterModal = ({
  employees,
  handleSelectEmployee,
  selectedEmployeeId,
  employeeId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {employees.map((employee) => (
        <div key={employee._id}>
          <section
            className="card"
            onClick={handleSelectEmployee(employee)}
            style={{
              borderWidth: "3px",
              borderColor: employee._id === employeeId ? "#3498db" : "#bdc3c7",
              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {employee.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  ) : (
                    <Image
                      style={{ width: 40, height: 40 }}
                      source={require("../assets/user-dummy.jpg")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      marginTop: "0.5rem",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "250px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {employee.name}
                    </b>
                    <br />
                    {employee.SchoolInformation
                      ? employee.SchoolInformation.name
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class InkaPembinaanPegawai extends Component {
  state = {
    session: {},
    employees: [],
    month: dayjs().month() + 1,
    year: dayjs().year(),
    loading: false,
    filterModalVisible: false,

    filterCriteria: {
      employeeId: "",
      academicYear: "",
      semester: "",
    },
    listTahunAjaran: [],

    coachingFormData: {
      routineCoachTypeId: "",
      employeeId: "",
      status: "",
      date: "",
    },
    listAgendas: [],
    listCoachingTypes: [],
    monthYear: "",
  };

  refetchData = async () => {
    this.setState({
      loading: true,
    });
    try {
      let result = await mwvClient.query({
        query: CURRENT_USER,
        fetchPolicy: "no-cache",
      });
      let Account = result.data.currentUser;
      if (Account) {
        this.setState({
          name: Account.name,
          email: Account.email,
          phone: Account.phone,
        });
      }
    } catch (err) {
      handleError(err);
    }
    this.setState({
      loading: false,
    });
  };

  fetchCoachingTypes = async ({ employeeId, academicYear, semester }) => {
    const results = await mwvClient.query({
      query: COACHING_ROUTINE_TYPES_QUERY,
      variables: {
        employeeId,
        academicYear,
        semester,
      },
      fetchPolicy: "no-cache",
    });

    this.setState({
      listCoachingTypes: results.data.allRoutineCoachingTypes,
    });
  };

  componentDidMount = async () => {
    console.log("fetching...");
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );
    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: QUERIES,
      variables: {
        ...urlValues,
      },
      fetchPolicy: "no-cache",
    });

    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];

    let allEmployeeSelfDevelopmentMasters =
      result.data && result.data.allEmployeeSelfDevelopmentMasters
        ? result.data.allEmployeeSelfDevelopmentMasters
        : [];
    const listTahunAjaran =
      result.data && result.data.allAcademicYears
        ? result.data.allAcademicYears
        : [];

    this.setState({
      employees,
      listTahunAjaran,

      allEmployeeSelfDevelopmentMasters,
    });

    if (urlValues.employeeId) {
      await this.fetchCoachingTypes({
        employeeId: urlValues.employeeId,
        academicYear: urlValues.academicYear,
        semester: urlValues.semester,
      });
      this.props.history.replace({
        pathname: "/inka_pembinaan_pegawai",
        search: `?sessionId=${accountSession._id}&employeeId=${urlValues.employeeId}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
      });
    } else {
      this.props.history.replace({
        pathname: "/inka_pembinaan_pegawai",
        search: `?sessionId=${accountSession._id}&employeeId=${
          employees[0] ? employees[0]._id : ""
        }&academicYear=${urlValues.academicYear}&semester=${
          urlValues.semester
        }&PREFIX=${employees[0].PREFIX}`,
      });
    }
  };

  handleSelectEmployee = (employee) => async (e) => {
    this.props.history.replace({
      pathname: "/inka_pembinaan_pegawai",
      search: `?sessionId=${urlValues.sessionId}&employeeId=${employee._id}&academicYear=${urlValues.academicYear}&semester=${urlValues.semester}&PREFIX=${urlValues.PREFIX}`,
    });
    this.closeFilterModalVisible();
  };

  handleInput = (key) => (e) => {
    this.setState({
      [key]: e,
    });
  };
  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };
  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };
  refresh = () => {
    window.location.reload();
  };

  handleFilterCriteria = (key) => async (e) => {
    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
      [key]: e.target.value,
    };

    urlValues = queryString.stringify(urlValues);
    window.location.href = `/inka_pembinaan_pegawai/?${urlValues}`;
  };

  handleChangeStatus = (agenda) => async (e) => {
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);
      const { monthYear, coachingFormData } = this.state;
      await mwvClient.mutate({
        mutation: UPDATE_COACHING_ATTENDANCE,
        variables: {
          coachingAgendaId: agenda.coachingAgendaId,
          employeeId: urlValues.employeeId,
          status: e.target.value,
        },
      });

      const result = await mwvClient.query({
        query: ALL_COUCHING_AGENDAS,
        variables: {
          date: monthYear,
          employeeId: urlValues.employeeId,
          coachingAgendaId: agenda.coachingAgendaId,
          routineCoachTypeId: coachingFormData.routineCoachTypeId,
        },
        fetchPolicy: "no-cache",
      });

      this.setState({
        listAgendas: result.data.agendaCoachingByDate,
      });

      addNotification({
        level: "success",
        message: "Status saved!",
      });
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  render() {
    urlValues = queryString.parse(this.props.location.search);
    const {
      listTahunAjaran,
      monthYear,
      coachingFormData,
      listAgendas,
      listCoachingTypes,
    } = this.state;
    const { loading } = this.state;
    const allEmployees = this.state.employees;
    const employee =
      allEmployees && allEmployees !== 0
        ? allEmployees.find((employee) => employee._id === urlValues.employeeId)
        : [];

    return (
      <View style={{ flex: 1 }}>
        <style jsx="true">
          {`
            .card {
              box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
                0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                0px 2px 1px -1px rgba(0, 0, 0, 0.12);
              border: true;
            }
          `}
        </style>
        <LoadingOverlay visible={loading} />
        <StatusBar backgroundColor="red" />
        <HeaderBar
          title={
            <Text>
              <i className="fa fa-star" style={{ fontSize: 20 }} />{" "}
              {translate("Assessmen Pegawai")}
            </Text>
          }
          right={
            <TouchableOpacity onPress={this.refresh}>
              <Text
                style={{
                  paddingRight: 12,
                  paddingLeft: 20,
                  color: "white",
                  fontSize: 20,
                  paddingVertical: 2,
                }}
              >
                <i className="fa fa-redo" />
              </Text>
            </TouchableOpacity>
          }
        />
        <div style={{ marginBottom: "1em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 40, height: 40 }}
                        source={require("../assets/user-dummy.jpg")}
                      />
                    </div>
                    <div className="col-10">
                      <div className="float-left text-left">
                        {employee && employee.length !== 0 ? (
                          <div
                            style={{
                              width: "250px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <i
                              className="fa fa-user-graduate"
                              style={{ color: "#656565" }}
                            />
                            &nbsp;{employee.name}
                            <br />
                            <b>
                              {employee.SchoolInformation
                                ? employee.SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Tahun Ajaran</label>
                    <select
                      className="form-control"
                      value={urlValues.academicYear}
                      onChange={this.handleFilterCriteria("academicYear")}
                      required
                    >
                      <option value="" disabled>
                        Pilih Tahun Ajaran
                      </option>
                      {listTahunAjaran.map((ta) => (
                        <option value={ta.label}>
                          {ta.label} {ta.inProgress ? "(On Going)" : ""}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Semester</label>
                    <select
                      className="form-control"
                      value={urlValues.semester}
                      onChange={this.handleFilterCriteria("semester")}
                      required
                    >
                      <option value="" disabled>
                        Pilih Semester
                      </option>
                      <option value={"GANJIL"}>GANJIL</option>
                      <option value={"GENAP"}>GENAP</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Bulan</label>
                <input
                  className="form-control"
                  type="month"
                  value={monthYear}
                  onChange={(e) => {
                    if (e) e.preventDefault();
                    this.setState({
                      monthYear: e.target.value,
                    });
                  }}
                />
              </div>

              <br />
            </div>
          </section>
        </div>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> {translate("Filter Pegawai")}
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            employees={allEmployees}
            handleSelectEmployee={this.handleSelectEmployee}
            employeeId={urlValues.employeeId}
          />
        </FormModal>

        <div
          className="container-fluid"
          style={{ marginTop: "1.5em", display: monthYear ? "block" : "none" }}
        >
          <div className="form-group">
            <label>Jenis Pembinaan:</label>
            <select
              className="form-control"
              value={coachingFormData.routineCoachTypeId}
              onChange={async (e) => {
                if (e) e.preventDefault();
                showLoadingSpinner();
                try {
                  const routineCoachTypeId = e.target.value;
                  const result = await mwvClient.query({
                    query: ALL_COUCHING_AGENDAS,
                    variables: {
                      date: monthYear,
                      employeeId: urlValues.employeeId,
                      coachingAgendaId: routineCoachTypeId,
                      routineCoachTypeId,
                    },
                    fetchPolicy: "no-cache",
                  });

                  this.setState({
                    coachingFormData: {
                      ...coachingFormData,
                      routineCoachTypeId,
                    },
                    listAgendas: result.data.agendaCoachingByDate,
                  });
                } catch (err) {
                  handleError(err);
                }
                hideLoadingSpinner();
              }}
              required
            >
              <option value="" disabled>
                Pilih Jenis Pembinaan
              </option>

              {listCoachingTypes.map((coaching) => (
                <option value={coaching._id}>{coaching.name}</option>
              ))}
            </select>
          </div>

          <br />
          {listAgendas.map((agenda) => (
            <section
              className="card card-blue"
              style={{
                padding: 10,
              }}
            >
              <div className="row">
                <div className="col-8">
                  <p style={{ margin: 0, fontWeight: "bold", fontSize: 12 }}>
                    Tgl: {dayjs(agenda.startDateTime).format("YYYY-MM-DD")}
                  </p>
                  <p style={{ margin: 0, fontSize: 16 }}>
                    {agenda.coachingAgendaName}
                  </p>
                </div>
                <div className="col-4">
                  <select
                    className="form-control"
                    value={agenda.status}
                    onChange={this.handleChangeStatus(agenda)}
                  >
                    <option value="">Status</option>
                    <option value="Hadir">Hadir</option>
                    <option value="Tidak Hadir">Tidak Hadir</option>
                  </select>
                </div>
              </div>
            </section>
          ))}
        </div>
        <div className="fixed-bottom">
          <button
            type="button"
            onClick={this.openFilterModal}
            style={{
              zIndex: 9999,
              backgroundColor: "#00a8ff",
              height: 50,
              borderWidth: 0,
              borderRadius: 0,
              boxShadow: "0px -2px 5px 2px #ddd",
            }}
            className="btn btn-lg btn-primary btn-block"
          >
            <Text
              style={{
                color: "white",
                fontSize: 20,
              }}
            >
              <i className="fa fa-search" /> {translate("Pilih Pegawai")}
            </Text>
          </button>
        </div>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQueries($sessionId: String!, $PREFIX: String!) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      PREFIX
    }

    allAcademicYears(PREFIX: $PREFIX) {
      label
    }
  }
`;

const CURRENT_USER = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
    }
  }
`;

const COACHING_ROUTINE_TYPES_QUERY = gql`
  query allRoutineCoachingTypes(
    $employeeId: String!
    $academicYear: String!
    $semester: String!
  ) {
    allRoutineCoachingTypes(
      employeeId: $employeeId
      academicYear: $academicYear
      semester: $semester
    ) {
      _id
      name
    }
  }
`;

const ALL_COUCHING_AGENDAS = gql`
  query agendaCoachingByDate(
    $date: String!
    $employeeId: String!
    $coachingAgendaId: String!
    $routineCoachTypeId: String!
  ) {
    agendaCoachingByDate(
      date: $date
      employeeId: $employeeId
      coachingAgendaId: $coachingAgendaId
      routineCoachTypeId: $routineCoachTypeId
    ) {
      _id
      coachingAgendaId
      coachingAgendaName
      startDateTime
      employeeId
      status
    }
  }
`;

const UPDATE_COACHING_ATTENDANCE = gql`
  mutation updateCoachingAttendance(
    $coachingAgendaId: String!
    $employeeId: String!
    $status: String!
  ) {
    updateCoachingAttendance(
      coachingAgendaId: $coachingAgendaId
      employeeId: $employeeId
      status: $status
    )
  }
`;

// export default withRouter(props => (
//   <ApolloConsumer>
//     {client => (
//       <InkaPembinaanPegawai
//         {...props}
//         client={client}
//       />
//     )}
//   </ApolloConsumer>
// ));

export default withRouter(withI18n("menu_kepegawaian")(InkaPembinaanPegawai));

const defaultAcademicYear = () => {
  let academicYear;
  if (new Date().getMonth() < 6) {
    academicYear = `${
      new Date().getFullYear() - 1
    }/${new Date().getFullYear()}`;
  } else {
    academicYear = `${new Date().getFullYear()}/${
      new Date().getFullYear() + 1
    }`;
  }
  return academicYear;
};

const defaultSemester = () => {
  if (new Date().getMonth() < 6) {
    return "GENAP";
  } else {
    return "GANJIL";
  }
};
