import React, { Component } from "react";
import { StyleSheet, View, Image, StatusBar } from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import { BrowserRouter as Router } from "react-router-dom";
import localforage from "localforage";
const styles = StyleSheet.create({
  splashContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#2196f3"
  },
  imgLogo: {
    width: 230,
    height: 171,
    marginBottom: 24
  }
});

class Splash extends Component {
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    if (accountSession !== null) {
      setTimeout(
        () =>
          this.props.history.push({
            pathname: "/explore",
            query: {
              ...accountSession
            }
          }),
        3000
      );
    } else {
      setTimeout(
        () =>
          this.props.history.push({
            pathname: "/login"
          }),
        3000
      );
    }
  };

  render() {
    return (
      <Router>
        <View style={styles.splashContainer}>
          <StatusBar backgroundColor="#54b0fb" barStyle="light-content" />
          <BackgroundImage opacity={0.3} />
          <Image
            style={styles.imgLogo}
            source={require("../assets/logo-st-label-below.png")}
          />
        </View>
      </Router>
    );
  }
}

export default Splash;
