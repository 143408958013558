import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../../App";
import { handleError } from "../../libs/errors";
import dayjs from "dayjs";

let urlValues;

class KegiatanRumah extends Component {
  state = {
    clickedGroup: [],
    aspectActivityGroup: [],
    aspectActivityScoreDescriptionPerDate: "",
  };

  componentDidMount = () => {
    this.componentWillReceiveProps(this.props);
  };
  componentWillReceiveProps = (nextProps) => {
    if (nextProps.allAspectActivityGroups) {
      this.setState({
        aspectActivityGroup: nextProps.allAspectActivityGroups,
        aspectActivityScoreDescriptionPerDate:
          nextProps.aspectActivityScoreDescriptionPerDate,
      });
    }
  };

  handleShowAspekDetail = (group) => (e) => {
    const { clickedGroup } = this.state;
    if (clickedGroup.length === 0) {
      this.setState({
        clickedGroup: [group._id],
      });
    } else {
      const idx = clickedGroup.findIndex((g) => g === group._id);

      if (idx < 0) {
        this.setState({
          clickedGroup: [...clickedGroup, group._id],
        });
      } else {
        this.setState({
          clickedGroup: clickedGroup.filter((g) => g !== group._id),
        });
      }
    }
  };

  handleCheckApsect = (aspek) => async (e) => {
    showLoadingSpinner();
    try {
      const { date, studentId } = this.props;
      urlValues = queryString.parse(this.props.location.search);
      // console.log({
      //   date,
      //   studentId,
      //   aspectActivityId: aspek._id,
      //   isChecked: e.target.checked,
      // });
      await mwvClient.mutate({
        mutation: SAVE_SCORE,
        variables: {
          sessionId: urlValues.sessionId,
          date,
          studentId,
          aspectActivityId: aspek._id,
          isChecked: e.target.checked,
        },
      });

      await this.props.refetch();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };

  handleDescription = (e) => {
    this.setState({
      aspectActivityScoreDescriptionPerDate: e.target.value,
    });
  };

  handleSelectAll = (type, gr) => async (e) => {
    showLoadingSpinner();
    try {
      urlValues = queryString.parse(this.props.location.search);

      const { aspectActivityGroup } = this.state;
      const { date, studentId, category } = this.props;
      for (const group of aspectActivityGroup.filter((g) => g._id === gr._id)) {
        for (const aspek of group.Aspects) {
          await mwvClient.mutate({
            mutation: SAVE_SCORE,
            variables: {
              sessionId: urlValues.sessionId,
              date,
              studentId,
              aspectActivityId: aspek._id,
              isChecked: type === "select" ? true : false,
            },
          });
        }
      }
      await this.props.refetch();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
  render() {
    const {
      clickedGroup,
      aspectActivityGroup,
      aspectActivityScoreDescriptionPerDate,
    } = this.state;
    const { date } = this.props;

    return (
      <View
        style={{
          padding: 10,
        }}
      >
        <p
          style={{
            fontSize: 18,
            margin: 0,
          }}
        >
          <b>Kegiatan Siswa</b>
        </p>
        <p
          style={{
            fontSize: 12,
            margiBottom: "10px",
          }}
        >
          {dayjs(date).format("DD MMMM YYYY")}
        </p>

        <View
          style={{
            backgroundColor: "#fff",
          }}
        >
          {aspectActivityGroup.map((group) => {
            const idx = clickedGroup.findIndex((g) => g === group._id);

            return (
              <div>
                <div
                  style={{
                    backgroundColor: "#d1f1f1",
                    padding: "10px",
                  }}
                  onClick={this.handleShowAspekDetail(group)}
                >
                  <b>
                    <i
                      className={`fa ${
                        idx === -1 ? "fa-caret-down" : "fa-caret-up"
                      }`}
                    />{" "}
                    {group.name}
                  </b>
                </div>

                {group.Aspects.map((aspect, index) => (
                  <div className={idx === -1 ? "d-none" : "d-block"}>
                    {index === 0 ? (
                      <div
                        className="d-flex flex-row-reverse"
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        <button
                          className="btn btn-sm btn-rounded"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "#4FC3F7",
                            borderRadius: "15px",
                            color: "#4FC3F7",
                          }}
                          onClick={this.handleSelectAll("select", group)}
                        >
                          <b>
                            <i className="fa fa-check" /> Semua
                          </b>
                        </button>
                        <button
                          className="btn btn-sm btn-rounded"
                          style={{
                            backgroundColor: "#fff",
                            borderColor: "#F44336",
                            borderRadius: "15px",
                            color: "#F44336",
                            marginRight: "10px",
                          }}
                          onClick={this.handleSelectAll("reset", group)}
                        >
                          <b>
                            <i className="fa fa-ban" /> Reset
                          </b>
                        </button>
                      </div>
                    ) : (
                      <div />
                    )}
                    <div
                      className={"row"}
                      style={{
                        paddingLeft: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div
                        className="col-1 d-flex align-items-center"
                        style={{
                          padding: "0px",
                        }}
                      >
                        <input
                          className="form-control"
                          type="checkbox"
                          style={{
                            height: "20px",
                            marginLeft: "10px",
                          }}
                          value={aspect.AspectActivityScore}
                          onChange={this.handleCheckApsect(aspect)}
                          checked={aspect.AspectActivityScore}
                        />
                      </div>
                      <div
                        className="col-11 d-flex align-items-center"
                        style={{
                          paddingTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        ({aspect.code}) {aspect.name}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            );
          })}

          <div
            style={{
              padding: 10,
            }}
          >
            <p
              style={{
                marginBottom: "5px",
                marginTop: "10px",
              }}
            >
              Catatan Orang Tua
            </p>
            <textarea
              style={{
                marginTop: 10,
              }}
              className="form-control"
              placeholder="Catatan Oleh Orang Tua"
              value={aspectActivityScoreDescriptionPerDate}
              onChange={this.handleDescription}
              onBlur={this.saveDescription}
            />
          </div>
        </View>
      </View>
    );
  }

  saveDescription = async (e) => {
    showLoadingSpinner();
    try {
      const { studentId, date, category } = this.props;
      const { aspectActivityScoreDescriptionPerDate } = this.state;
      await mwvClient.mutate({
        mutation: SAVE_DESCRIPTION,
        variables: {
          studentId,
          date,
          description: aspectActivityScoreDescriptionPerDate,
          category,
        },
      });

      await this.props.refetch();
    } catch (err) {
      handleError(err);
    }
    hideLoadingSpinner();
  };
}

const ASPECT_GROUP_QUERY = gql`
  query listQuery(
    $academicYear: String!
    $studentId: String!
    $date: String!
    $category: String!
  ) {
    allAspectActivityGroups(
      academicYear: $academicYear
      studentId: $studentId
      category: $category
      date: $date
    ) {
      _id
      name
      Aspects {
        _id
        code
        name
        aspectGroupId
        AspectActivityScore(studentId: $studentId, date: $date)
      }
    }

    aspectActivityScoreDescriptionPerDate(
      studentId: $studentId
      date: $date
      category: $category
    )
  }
`;

const SAVE_SCORE = gql`
  mutation saveActivityScore(
    $sessionId: String!
    $date: String!
    $studentId: String!
    $aspectActivityId: String!
    $isChecked: Boolean!
  ) {
    saveActivityScore(
      sessionId: $sessionId
      date: $date
      studentId: $studentId
      aspectActivityId: $aspectActivityId
      isChecked: $isChecked
    )
  }
`;

const SAVE_DESCRIPTION = gql`
  mutation saveActivityDescription(
    $studentId: String!
    $date: String!
    $description: String
    $category: String
  ) {
    saveActivityDescription(
      studentId: $studentId
      date: $date
      description: $description
      category: $category
    )
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  // console.log(urlValues);
  return (
    <ApolloConsumer>
      {(client) => (
        <Mutation mutation={SAVE_SCORE}>
          {(saveActivityScore) => (
            <Mutation mutation={SAVE_DESCRIPTION}>
              {(saveActivityDescription) => (
                <Query
                  query={ASPECT_GROUP_QUERY}
                  client={mwvClient}
                  variables={{
                    _id: urlValues.sessionId,
                    ...props,
                  }}
                >
                  {({ error, loading, data, refetch }) => (
                    <div>
                      <KegiatanRumah
                        {...props}
                        client={client}
                        error={error}
                        loading={loading}
                        allAspectActivityGroups={
                          data && data.allAspectActivityGroups
                            ? data.allAspectActivityGroups
                            : []
                        }
                        aspectActivityScoreDescriptionPerDate={
                          data ? data.aspectActivityScoreDescriptionPerDate : ""
                        }
                        refetch={refetch}
                        saveActivityDescription={saveActivityDescription}
                        saveActivityScore={saveActivityScore}
                      />
                    </div>
                  )}
                </Query>
              )}
            </Mutation>
          )}
        </Mutation>
      )}
    </ApolloConsumer>
  );
});
