import { addNotification } from "../App";
import localforage from "localforage";

export const handleError = (error, props) => {
  if (error && error.message) {
    if (error.message.indexOf("Network Error") >= 0) {
      error.message = "Network Error! Please try again later!";
    } else {
      error.message = error.message.replace("GraphQL Error: ", "");
    }
  } else {
    error.message = "Unknown Error! Please try again later!";
  }
  console.warn("Handling error: ", error.message);
  addNotification({
    title: "Terjadi Kesalahan",
    message: error.message,
    type: error.level ? error.level : "danger",
    // animationIn: ["animated", "fadeOut"],
    // animationOut: ["animated", "fadeIn"],
    dismiss: {
      duration: 5000,
      onScreen: true,
    },
  });

  if (error.message.toUpperCase().indexOf("INVALID SESSION")) {
    if (props && props.history) {
      localforage.clear();
      this.props.history.push({
        pathname: "/login",
      });
    }
  }
};
