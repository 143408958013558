import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import uuidV4 from "uuid/v4";
import { withI18n } from "../libs/withI18n";

let urlValues;

const FilterModal = ({
  students,
  handleSelectStudent,
  // selectedStudentId,
  studentId,
}) => (
  <div>
    <div style={{ height: "250px", overflow: "scroll", overflowX: "hidden" }}>
      {students.map((student) => (
        <div key={student._id}>
          <section
            className="card"
            onClick={handleSelectStudent(student)}
            style={{
              borderWidth: "3px",
              borderColor: student._id === studentId ? "#00a8ff" : "#bdc3c7",

              marginBottom: "5px",
            }}
          >
            <div className="card-block">
              <div className="row">
                <div className="col-3">
                  {student.profileImageUrl !== "" ? (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  ) : (
                    <Image
                      style={{ width: 50, height: 50 }}
                      source={require("../assets/owl-laptop-study.png")}
                    />
                  )}
                </div>
                <div className="col-9">
                  <div
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "200px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <b>
                      <i className="fa fa-user-graduate" /> {student.name}
                    </b>
                    <br />
                    {student.SchoolInformation && student.SchoolInformation.name
                      ? student.SchoolInformation.name
                      : ""}
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <br />
        </div>
      ))}
    </div>
  </div>
);

class ReportClassrooms extends Component {
  state = {
    allStudents: [],
    filterModalVisible: false,
    classroomLists: [],
    isLoading: true,
  };

  initiate = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    // let academicYear;
    // if (new Date().getMonth() < 6) {
    //   academicYear = `${
    //     new Date().getFullYear() - 1
    //   }/${new Date().getFullYear()}`;
    // } else {
    //   academicYear = `${new Date().getFullYear()}/${
    //     new Date().getFullYear() + 1
    //   }`;
    // }

    let result = await mwvClient.query({
      query: STUDENT_LISTS,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // console.log({ terms });

    let classrooms = [];
    if (urlValues.studentId) {
      this.props.history.replace({
        pathname: "/report_classroom_list",
        search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
      });

      const resClass = await mwvClient.query({
        query: CLASSROOM_QUERIES,
        variables: {
          sessionId: accountSession._id,
          academicYear: "",
          studentId: urlValues.studentId,
        },
      });
      classrooms = resClass.data.allClassrooms;
    } else {
      this.props.history.replace({
        pathname: "/report_classroom_list",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&PREFIX=${students[0] ? students[0].PREFIX : ""}`,
      });

      const resClass = await mwvClient.query({
        query: CLASSROOM_QUERIES,
        variables: {
          sessionId: accountSession._id,
          academicYear: "",
          studentId: students[0] ? students[0]._id : "",
        },
      });
      classrooms = resClass.data.allClassrooms;
    }

    this.setState({
      allStudents: [...students],
      classroomLists: classrooms,
      isLoading: false,
    });
  };
  componentDidMount = () => {
    this.initiate();
  };

  openFilterModal = () => {
    this.setState({
      filterModalVisible: true,
    });
  };

  handleSelectStudent = (student) => async (e) => {
    if (e) e.preventDefault();

    urlValues = {
      ...urlValues,
      studentId: student._id,
      PREFIX: student.PREFIX,
    };

    this.props.history.replace({
      pathname: "/student_performance",
      search: `?sessionId=${urlValues.sessionId}&studentId=${urlValues.studentId}&PREFIX=${urlValues.PREFIX}`,
    });
  };

  closeFilterModalVisible = () => {
    this.setState({
      filterModalVisible: false,
    });
  };

  openReport = (kelas) => (e) => {
    // console.log(kelas);

    urlValues = queryString.parse(this.props.location.search);
    urlValues = {
      ...urlValues,
      classroomId: kelas._id,
      academicYear: kelas.academicYear,
      classroomName: kelas.name,
      type: "ACADEMIC",
    };

    const url = queryString.stringify(urlValues);

    this.props.history.replace({
      pathname: "/student_reports",
      search: `?${url}`,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { allStudents, classroomLists, isLoading } = this.state;
    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    return (
      <View style={{ flex: 1 }}>
        <FormModal
          title={
            <span>
              <i className="fa fa-search" /> Pilih Siswa
            </span>
          }
          visible={this.state.filterModalVisible}
          size="md"
          onClose={this.closeFilterModalVisible}
          hideCloseButton
        >
          <FilterModal
            students={allStudents}
            handleSelectStudent={this.handleSelectStudent}
            // selectedStudentId={this.state.selectedStudentId}
            studentId={
              urlValues && urlValues.studentId ? urlValues.studentId : ""
            }
          />
        </FormModal>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-file-pdf"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {"Rapor"}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section
            className="card"
            style={{ margin: 0, boxShadow: "0px 2px 4px rgba(0,0,0,0.4)" }}
          >
            <div className="card-block" style={{ padding: 20 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                }}
              >
                <Image
                  style={{ width: 65, height: 65 }}
                  source={require("../assets/owl-laptop-study.png")}
                />

                {getStudent.length !== 0 ? (
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      paddingLeft: 10,
                    }}
                  >
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "start",
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: "bold",
                        }}
                        numberOfLines={1}
                        ellipsizeMode="tail"
                      >
                        {getStudent[0].name}
                      </Text>
                    </View>
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "start",
                        marginTop: 10,
                      }}
                    >
                      <Text numberOfLines={1} ellipsizeMode="tail">
                        {getStudent[0].SchoolInformation
                          ? getStudent[0].SchoolInformation.name
                          : ""}
                      </Text>
                    </View>
                    <View>
                      <button
                        onClick={!isLoading ? this.openFilterModal : null}
                        style={{
                          backgroundColor: "#00a8ff",
                          marginTop: 10,
                          borderWidth: 0,
                          borderRadius: 20,
                          boxShadow: "0px -2px 5px 2px #ddd",
                        }}
                        className="btn btn-sm btn-primary btn-block"
                      >
                        <Text
                          style={{
                            color: "white",
                          }}
                        >
                          <i className="fa fa-search" /> {"Pilih Siswa"}
                        </Text>
                      </button>
                    </View>
                  </View>
                ) : null}
              </View>
            </div>
          </section>
        </div>

        <BackgroundImage />

        <View
          style={{
            flex: 1,
            marginTop: "30vh",
            // flexDirection: "column",
          }}
        >
          <View
            style={{
              alignItems: "center",
            }}
          >
            <Text
              style={{
                fontSize: 18,
                marginBottom: 10,
              }}
            >
              <i className="fa fa-star" /> {"Daftar Kelas"}
            </Text>
          </View>

          <View
            style={{
              marginTop: 30,
            }}
          >
            {isLoading ? (
              <View
                style={{
                  flex: 1,
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Image
                  style={{
                    width: 240,
                    height: 240,
                    resizeMode: "contain",
                    opacity: 0.8,
                  }}
                  source={require("../assets/05- education-reward.png")}
                />
                <Text
                  style={{
                    fontSize: 17,
                    color: "#737373",
                    textAlign: "center",
                    paddingHorizontal: 30,
                    marginBottom: 30,
                  }}
                >
                  <i className="fa fa-spinner fa-spin" /> Sedang mengambil data
                </Text>
              </View>
            ) : (
              classroomLists.map((kelas) => (
                <TouchableOpacity onClick={this.openReport(kelas)}>
                  <section
                    className="card"
                    style={{
                      padding: 5,
                      marginBottom: 10,
                      borderRadius: 5,
                      width: "380px",
                    }}
                  >
                    <div className="card-block">
                      <View
                        style={{
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <View>
                          <Image
                            style={{
                              width: 65,
                              height: 65,
                              overflow: "hidden",
                              borderRadius: 50,
                            }}
                            source={require("../assets/high-school.png")}
                          />
                        </View>
                        <View
                          style={{
                            flex: 1,
                            justifyContent: "center",
                          }}
                        >
                          <Text>
                            {kelas.name} - {kelas.academicYear}
                          </Text>
                        </View>
                      </View>
                    </div>
                  </section>
                </TouchableOpacity>
              ))
            )}
          </View>
        </View>
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const STUDENT_LISTS = gql`
  query listQuery($_id: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
      PREFIX
    }
  }
`;

const CLASSROOM_QUERIES = gql`
  query listQueries(
    $sessionId: String
    $academicYear: String
    $studentId: String
  ) {
    allClassrooms(
      sessionId: $sessionId
      academicYear: $academicYear
      studentId: $studentId
    ) {
      _id
      name
      academicYear
    }
  }
`;

export default withRouter(ReportClassrooms);
