import React, { Component } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Text
} from "react-native-web";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import ListSection from "./ListSection";
import { formatCurrency } from "../libs/numbers";

class ProfileImage extends Component {
  state = {
    profileImageUrl: ""
  };

  render() {
    const {
      points,
      balance
      // navigation
    } = this.props;
    return (
      <ListSection>
        <Image
          source={require("../assets/mountain.jpg")}
          style={{
            height: 130,
            width: "100%",
            resizeMode: "cover"
          }}
        />
        <TouchableOpacity
          style={{
            position: "absolute",
            top: 55,
            left: (window.innerWidth - 106) / 2
          }}
          //   onPress={this.handleChangeProfileImage}
        >
          <Image
            source={
              this.props.profileImageUrl
                ? {
                    uri: this.props.profileImageUrl
                  }
                : require("../assets/avatar-sign-blue.png")
            }
            onError={() => {
              this.setState({
                profileImageUrl: null
              });
            }}
            defaultSource={require("../assets/avatar-sign-blue.png")}
            style={{
              height: 106,
              width: 106,
              borderRadius: 106 / 2,
              borderWidth: 2,
              borderColor: "#e0e0e0"
            }}
          />
        </TouchableOpacity>
        <View style={{ flex: 1, flexDirection: "row", height: 64 }}>
          <TouchableOpacity
            style={{ flex: 1 }}
            // onPress={() => {
            //   navigation.navigate("MyPoints");
            // }}
          >
            <View style={{ flex: 1, paddingRight: 25 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: 12
                }}
              >
                {points ? (
                  <Text
                    style={{
                      flex: 1,
                      textAlign: "center"
                    }}
                  >
                    {points} pts
                  </Text>
                ) : (
                  <View style={{ flex: 1, flexDirection: "row" }}>
                    <ActivityIndicator
                      size="small"
                      color="#777"
                      style={{
                        margin: 0,
                        flex: 1,
                        alignItems: "flex-end"
                      }}
                    />
                    <Text
                      style={{
                        flex: 1
                      }}
                    >
                      {" "}
                      pts
                    </Text>
                  </View>
                )}
              </View>
              <Text
                style={{
                  flex: 1,
                  paddingBottom: 12,
                  textAlign: "center",
                  fontWeight: "bold"
                }}
              >
                <FontAwesomeIcon
                  icon={faStar}
                  style={{
                    fontSize: 15
                  }}
                  color="#f1c40f"
                />{" "}
                Points
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ flex: 1 }}
            // onPress={() => {
            //   navigation.navigate("SchoolPay");
            // }}
          >
            <View style={{ flex: 1, paddingLeft: 25 }}>
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "center",
                  paddingTop: 12
                }}
              >
                {balance ? (
                  <Text
                    style={{
                      flex: 1,
                      textAlign: "center"
                    }}
                  >
                    Rp. {formatCurrency(balance)}
                  </Text>
                ) : (
                  <ActivityIndicator
                    size="small"
                    color="#777"
                    style={{
                      margin: 0,
                      flex: 1,
                      alignItems: "center"
                    }}
                  />
                )}
              </View>
              <Text
                style={{
                  flex: 1,
                  paddingBottom: 12,
                  textAlign: "center",
                  fontWeight: "bold"
                }}
              >
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{
                    fontSize: 15
                  }}
                  color="#f1c40f"
                />{" "}
                School Pay
              </Text>
            </View>
          </TouchableOpacity>
        </View>
      </ListSection>
    );
  }

  state = {
    profileImageUrl: null
  };
}

export default ProfileImage;
