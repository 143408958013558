import React, { Component } from "react";
import {
  Image,
  View,
  StatusBar,
  Text,
  TouchableOpacity,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import dayjs from "dayjs";
import lodash from "lodash";
import { defaultAcademicYear } from "../libs/time";

let urlValues;
class ListSubjectPresence extends Component {
  state = {
    // Adhe
    // username: __DEV__ ? "083854430431" : "",
    // password: __DEV__ ? "123123" : "",

    // Rama
    // username: __DEV__ ? "089624809625" : "",
    // password: __DEV__ ? "123123" : "",

    username: "083831114477",
    password: "3335557",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {},
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name,
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { allEmployees, allSubjects, classroom } = this.props;

    const getEmployee = allEmployees
      ? allEmployees.filter((s) => s._id === urlValues.employeeId)
      : [];
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={<Text>Daftar Mapel</Text>}
            right={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/explore",
                  })
                }
              >
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 18,
                    paddingVertical: 2,
                  }}
                >
                  Close
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <div style={{ marginTop: "10em" }}>
          <section className="card">
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      {/* height: 100px;
                      object-fit: cover; */}
                      {getEmployee && getEmployee.length !== 0 ? (
                        getEmployee[0].photoUrl ? (
                          <Image
                            style={{ width: 60, height: 80 }}
                            source={getEmployee[0].photoUrl}
                          />
                        ) : (
                          // <img src={getEmployee[0].photoUrl} width="80" alt={getEmployee[0].photoUrl} />
                          <Image
                            style={{ width: 60, height: 60 }}
                            source={require("../assets/user-dummy.jpg")}
                          />
                        )
                      ) : null}
                    </div>
                    <div className="col-10">
                      <div className="px-2 text-left">
                        {getEmployee && getEmployee.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              width: "250px",
                              textOverflow: "ellipsis",
                              marginBottom: 10,
                            }}
                          >
                            {getEmployee[0].name}
                            <br />
                            <b>
                              {getEmployee[0].SchoolInformation &&
                              getEmployee[0].SchoolInformation.name
                                ? getEmployee[0].SchoolInformation.name
                                : ""}
                            </b>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row py-2" style={{ marginTop: 10 }}>
                <div className="col-6">
                  Tanggal: <b>{dayjs().format("DD-MM-YYYY")}</b>
                  <br />
                  Tahun Ajaran: <b>{defaultAcademicYear()}</b>
                </div>
                <div className="col-6 text-right">
                  Semester:{" "}
                  <b>{new Date().getMonth() < 6 ? "GENAP" : "GANJIL"}</b>
                  <br />
                  Kelas: <b>{classroom ? classroom.name : "-"}</b>
                </div>
              </div>
            </div>
          </section>
        </div>
        <View style={{ marginTop: 10, marginBottom: 10, padding: 20 }}>
          <Text
            style={{ marginTop: 10, marginBottom: 10 }}
            className="text-center"
          >
            Daftar Mata Pelajaran
          </Text>
          {allSubjects && allSubjects.length !== 0 ? (
            allSubjects.map((subject) => (
              <View
                className="card"
                style={{
                  padding: 10,
                  marginBottom: 10,
                }}
                onClick={() =>
                  this.props.history.push({
                    pathname: "/presensi_siswa_per_mapel",
                    search: `?${queryString.stringify(urlValues)}&subjectId=${
                      subject._id
                    }&subjectName=${subject.name}`,
                  })
                }
              >
                <div className="card-block">{subject.name}</div>
              </View>
            ))
          ) : (
            <Text>Daftar Pelajaran Tidak Ditemukan</Text>
          )}
        </View>
        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const QUERIES = gql`
  query listQuery(
    $sessionId: String
    $semester: String
    $academicYear: String
    $classroomId: String
    $employeeId: String
  ) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }

    classroomById(classroomId: $classroomId) {
      _id
      name
    }

    allSubjectsByTeacherId(
      sessionId: $sessionId
      semester: $semester
      academicYear: $academicYear
      classroomId: $classroomId
      employeeId: $employeeId
    ) {
      _id
      code
      name
      PREFIX
    }
  }
`;

export default withRouter((props) => {
  urlValues = queryString.parse(props.location.search);

  return (
    <Query
      query={QUERIES}
      client={mwvClient}
      variables={{
        ...urlValues,
        sessionId: lodash.get(urlValues, "sessionId", ""),
        employeeId: lodash.get(urlValues, "employeeId", ""),
        semester: new Date().getMonth() < 6 ? "GENAP" : "GANJIL",
        academicYear: defaultAcademicYear(),
        classroomId: lodash.get(urlValues, "classroomId", ""),
      }}
    >
      {({ error, loading, data, refetch }) => (
        <div>
          <ListSubjectPresence
            {...props}
            error={error}
            loading={loading}
            refetch={refetch}
            allSubjects={
              data && data.allSubjectsByTeacherId
                ? data.allSubjectsByTeacherId
                : []
            }
            allEmployees={
              data && data.allEmployeesBySessionId
                ? data.allEmployeesBySessionId
                : []
            }
            classroom={data && data.classroomById ? data.classroomById : null}
          />
        </div>
      )}
    </Query>
  );
});
