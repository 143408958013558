import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import { LoadingOverlay } from "../components/Overlay";
import localforage from "localforage";
import queryString from "query-string";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { addNotification } from "../App";
import Fingerprint2 from "fingerprintjs2";
import { handleError } from "../libs/errors";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";
import * as firebaseui from "firebaseui";

let urlValues;

class LoginGoogle extends Component {
  state = {
    phoneNumber: "",
    Student: null,
    session: {},
    loading: false,
    accountSession: null,
  };

  componentDidMount = async () => {
    if (!firebase.apps.length) {
      // console.log("Initialize firebase...");
      firebase.initializeApp(firebaseConfig);
    }

    const ui = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        this.setState({
          loading: true
        })
        const { uid, displayName, email, phoneNumber, photoUrl } = user;
        const data = {
          uid,
          displayName,
          email,
          phoneNumber,
          photoUrl,
        };
        const response = await this.props.getAccountByEmail({
          variables: {
            email,
          },
          fetchPolicy: "no-cache",
        });
        const AccountSession =
          response.data && response.data.getAccountByEmail
            ? response.data.getAccountByEmail
            : "";
        if (!AccountSession) {
          this.props.history.replace(
            `/register?registeredBy=GOOGLE&email=${data.email}&displayName=${data.displayName}`
          );
        }else{
          return this.logInWithGoogleOrWhatsapp(AccountSession);
        }
      } else {
        ui.disableAutoSignIn();
        ui.start("#firebaseui-auth-container", {
          signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
          callbacks: {
            signInSuccessWithAuthResult: function (
              currentUser,
              credential,
              redirectUrl
            ) {
              const userId = currentUser.uid;
              redirectUrl = `/register`;
              return false;
            },
          },
          signInSuccessUrl: "/register",
        });
        
      }
    });
  };

  logInWithGoogleOrWhatsapp = async (AccountSession) => {
    let browserInfo = {};
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        Fingerprint2.get((components) => {
          var values = components.map((c) => {
            // console.log(c)
            return c.value;
          });
          var hashId = Fingerprint2.x64hash128(values.join(""), 31);
          browserInfo = {
            uniqueId: hashId,
          };
          resolve();
        });
      }, 800);
    });

    var objappVersion = navigator.appVersion;
    var objAgent = navigator.userAgent;
    var objbrowserName = navigator.appName;
    var objfullVersion = "" + parseFloat(navigator.appVersion);
    let objBrMajorVersion = parseInt(navigator.appVersion, 10);
    let objOffsetName, objOffsetVersion, ix;

    let browserVersion;
    // console.log(objAgent)
    if ((objOffsetVersion = objAgent.indexOf("Chrome")) !== -1) {
      objbrowserName = "Chrome";
      const version = objAgent.slice(objOffsetVersion + 7);
      browserVersion = version.slice(0, 12);
    } else if ((objOffsetVersion = objAgent.indexOf("Safari")) !== -1) {
      objbrowserName = "Safari";
      objfullVersion = objAgent.substring(objOffsetVersion + 7);
      if ((objOffsetVersion = objAgent.indexOf("Version")) !== -1) {
        objfullVersion = objAgent.substring(objOffsetVersion + 8);

        browserVersion = objfullVersion.slice(26, 32);
      }
    } else {
      objbrowserName = "Mozilla";
      browserVersion = objfullVersion;
    }

    browserInfo = {
      ...browserInfo,
      objbrowserName,
      browserVersion,
    };

    if (!firebase.apps.length) {
      // console.log("Initialize firebase...");
      firebase.initializeApp(firebaseConfig);
    }
    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      await messaging.requestPermission();
    }

    const session = AccountSession;
    // console.log("Session", session);
    localforage.setItem("accountSession", JSON.stringify(session));

    if (firebase.messaging.isSupported()) {
      const messaging = firebase.messaging();
      const fcmToken = await messaging.getToken();
      localforage.setItem("fcm-token", fcmToken);
      await this.props.resubscribeTopicForPushNotification({
        variables: {
          fcmToken,
        },
      });
    }

    this.setState({
      loading: false,
    });

    setTimeout(() => {
      window.location = "/explore";
    }, 1000);
  };

  refresh = () => {
    window.location.reload();
  };

  render() {
    const { Student, Errors, accountSession, loading } = this.state;
    urlValues = queryString.parse(this.props.location.search);
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>
        <LoadingOverlay visible={loading} />
        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <TouchableOpacity
                onPress={() =>
                  this.props.history.push({
                    pathname: "/login",
                  })
                }
              >
                <Text>
                  <i
                    className="fa fa-arrow-left"
                    style={{
                      fontSize: 20,
                    }}
                  />{" "}
                  {translate("Kembali")}
                </Text>
              </TouchableOpacity>
            }
          />
        </div>
        <div
          className="card"
          style={{ borderRadius: "20px", margin: "auto 15px" }}
        >
          <div className="card-header">
            <Image
              style={{
                width: 60,
                height: 60,
                margin: "auto",
                marginBottom: 15,
              }}
              source={require("../assets/icon-google.png")}
            />
            <Text
              style={{
                display: "block",
                fontSize: "24px",
                textAlign: "center",
              }}
            >
              Sign in
            </Text>
            <Text
              style={{
                display: "block",
                fontSize: "16px",
                textAlign: "center",
                paddingTop: 8,
              }}
            >
              Use your Google Account
            </Text>
          </div>
          <div className="card-body">
            <div id="firebaseui-auth-container"></div>
          </div>
        </div>
      </View>
    );
  }
}

const RESUBSCRIBE = gql`
  mutation resubscribeTopicForPushNotification($fcmToken: String!) {
    resubscribeTopicForPushNotification(fcmToken: $fcmToken)
  }
`;

const GET_ACCOUNT_BY_EMAIL = gql`
  mutation getAccountByEmail($email: String!) {
    getAccountByEmail(email: $email) {
      _id
      token
      expiresIn
      Account {
        _id
        name
        email
        phone
        status
        roles
        subscribedTopics
      }
      _createdAt
    }
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);
    return (
      <ApolloConsumer>
        {(client) => (
          <Mutation mutation={RESUBSCRIBE}>
            {(resubscribeTopicForPushNotification) => (
              <Mutation mutation={GET_ACCOUNT_BY_EMAIL}>
                {(getAccountByEmail) => (
                  <LoginGoogle
                    {...props}
                    client={client}
                    getAccountByEmail={getAccountByEmail}
                    resubscribeTopicForPushNotification={
                      resubscribeTopicForPushNotification
                    }
                  />
                )}
              </Mutation>
            )}
          </Mutation>
        )}
      </ApolloConsumer>
    );
  })
);
