import React from "react";
import { View, TouchableOpacity, Text } from "react-native-web";
// import Ionicon from "react-native-vector-icons/Ionicons";
import withPreventDoubleClick from "../libs/withPreventDoubleClick";
const EnhancedTouchableOpacity = withPreventDoubleClick(TouchableOpacity);

const BlockButton = ({
  icon,
  label,
  onPress,
  backgroundColor,
  borderColor,
  disabled
}) => (
  <View
    style={{
      flex: 1,
      borderBottomWidth: 1,
      borderBottomColor: "#eee",
      padding: 6
    }}
  >
    <EnhancedTouchableOpacity onPress={onPress} disabled={disabled}>
      <Text
        style={{
          paddingHorizontal: 20,
          paddingVertical: 12,
          margin: 2,
          fontSize: 16,
          textAlign: "center",
          color: "white",
          backgroundColor: disabled
            ? "#aaa"
            : backgroundColor
            ? backgroundColor
            : "#1696ff",
          borderRadius: 3,
          borderColor: disabled
            ? "#bbb"
            : borderColor
            ? borderColor
            : "#1696ff",
          borderWidth: 2
        }}
      >
        {/* <Ionicon name={icon} size={20} color="white" /> &nbsp; */}
        {label}
      </Text>
    </EnhancedTouchableOpacity>
  </View>
);

export default BlockButton;
