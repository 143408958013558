import React, { Component } from "react";
import {
  View,
  StatusBar,
  Text,
  TouchableOpacity,
  Image,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { FormModal } from "../components/Modal";
import dayjs from "dayjs";
import { Query, Mutation } from "react-apollo";
import { withI18n, translate } from "../libs/withI18n";
import { handleError } from "../libs/errors";
import NumberFormat from "react-number-format";
import {
  addNotification,
  showLoadingSpinner,
  hideLoadingSpinner,
} from "../App";

let urlValues;
const STATUS_COLOR = {
  SETORAN_TABUNGAN_SISWA: "green",
  PENARIKAN_TABUNGAN_SISWA: "red",
};

const depositColors = ["#1abc9c", "#2ecc71", "#3498db"];

const withdrawColros = ["#f1c40f", "#e67e22", "#e74c3c"];

class StudentSavingTopUpHistory extends Component {
  state = {
    session: {},
    transactionLimit: 5,
    filterModalVisible: false,
    allStudents: [],
  };
  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);
    // console.log(accountSession);
    urlValues = queryString.parse(this.props.location.search);

    let result = await mwvClient.query({
      query: SAVING_QUERIES,
      variables: {
        _id: accountSession._id,
      },
    });

    let students =
      result.data && result.data.allStudentBySessionId
        ? result.data.allStudentBySessionId
        : [];

    // let savings =
    //   result.data && result.data.studentSaving ? result.data.studentSaving : [];

    if (accountSession) {
      if (urlValues.studentId) {
        this.props.history.push({
          pathname: "/student_saving_top_up_history",
          search: `?sessionId=${accountSession._id}&studentId=${urlValues.studentId}&limit=${urlValues.limit}`,
        });

        result = await mwvClient.query({
          query: SAVING_QUERIES,
          variables: {
            _id: accountSession._id,
            studentId: urlValues.studentId,
            limit: parseInt(urlValues.limit),
          },
        });

        // savings =
        //   result.data && result.data.studentSaving
        //     ? result.data.studentSaving
        //     : [];
      }
    } else {
      this.props.history.replace({
        pathname: "/student_saving_top_up_history",
        search: `?sessionId=${accountSession._id}&studentId=${
          students[0] ? students[0]._id : ""
        }&limit=${5}`,
      });
    }

    this.setState({
      allStudents: [...students],
    });
  };

  refresh = () => {
    window.location.reload();
  };

  backToStudentSaving = () => {
    urlValues = queryString.parse(this.props.location.search);

    const params = queryString.stringify(urlValues);
    this.props.history.replace({
      pathname: "/student_saving",
      search: `?${params}`,
    });
  };

  render() {
    const { allStudents } = this.state;
    const { allTopUpStudentSaving } = this.props;

    urlValues = queryString.parse(this.props.location.search);

    const getStudent = allStudents
      ? allStudents.filter((s) => s._id === urlValues.studentId)
      : [];

    let counter = 0;
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fa fa-archive"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                {translate("Tabungan Siswa")}
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 16,
                    paddingVertical: 2,
                  }}
                >
                  <i
                    className="fa fa-sync-alt"
                    style={{
                      fontSize: 20,
                      marginBottom: 5,
                    }}
                  />
                </Text>
              </TouchableOpacity>
            }
          />

          <section className="card" style={{ margin: 0 }}>
            <div
              className="card-block"
              style={{ paddingTop: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-2">
                      <Image
                        style={{ width: 65, height: 65 }}
                        source={require("../assets/owl-laptop-study.png")}
                      />
                    </div>
                    <div className="col-10" style={{ paddingLeft: 30 }}>
                      <div className="float-left text-left">
                        {getStudent.length !== 0 ? (
                          <div
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              width: "200px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getStudent[0].name}
                            <br />
                            <b>
                              {getStudent[0].SchoolInformation
                                ? getStudent[0].SchoolInformation.name
                                : ""}
                            </b>
                            <br />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <View
          style={{
            marginTop: "25vh",
            marginBottom: "50vh",
          }}
        >
          {allTopUpStudentSaving.length === 0 ? (
            <section className="card">
              <div className="card-block">
                <center>
                  <Image
                    style={{ width: 250, height: 250 }}
                    source={require("../assets/money-angel.png")}
                  />
                  <h5>{translate("Riwayat Top Up Tidak Ditemukan")}!!</h5>
                </center>
              </div>
            </section>
          ) : (
            allTopUpStudentSaving.map((topUp) => (
              <section
                className="card"
                style={{
                  marginBottom: 8,
                  borderWidth: "5px",
                  borderRadius: 10,
                }}
              >
                <div className="card-body">
                  <View
                    style={{
                      flex: 1,
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      Tanggal:{" "}
                      {dayjs(topUp._createdAt)
                        .locale("id")
                        .format("DD-MM-YYYY HH:mm")}
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      Nominal:
                    </Text>
                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      <NumberFormat
                        className="form-control"
                        value={topUp.nominal}
                        thousandSeparator={"."}
                        decimalSeparator={","}
                        prefix={`${"Rp"}. `}
                        disabled={true}
                      />
                    </Text>

                    {topUp.status === "CANCELED" ? (
                      <Text
                        style={{
                          marginBottom: 5,
                          fontSize: 18,
                        }}
                      >
                        Alasan dibatalkan: {topUp.reasonForCancellation}
                      </Text>
                    ) : null}

                    <Text
                      style={{
                        marginBottom: 5,
                        fontSize: 18,
                      }}
                    >
                      <a href={topUp.attachmentFileUrl}>
                        <i className="fa fa-download" /> Bukti Pembayaran
                      </a>
                    </Text>
                  </View>
                </div>
                <div
                  className="card-footer"
                  style={{
                    backgroundColor:
                      topUp.status === "WAITING"
                        ? "#ffa726"
                        : topUp.status === "CANCELED"
                        ? "#ef5350"
                        : "#ef5350",
                    color: "#fff",
                    fontSize: 16,
                    borderBottomLeftRadius: 10,
                    borderBottomRightRadius: 10,
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                >
                  <center>{topUp.status}</center>
                </div>
              </section>
            ))
          )}
        </View>
        <View>
          <div>ST</div>
        </View>
        <div className="fixed-bottom">
          <View
            style={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            <button
              type="button"
              onClick={this.backToStudentSaving}
              style={{
                zIndex: 9999,
                backgroundColor: "#00a8ff",
                height: 50,
                borderWidth: 0,
                borderRadius: 0,
                // boxShadow: "0px -2px 5px 2px #ddd",
              }}
              className="btn btn-lg btn-primary btn-block"
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 16,
                }}
              >
                <i className="fa fa-arrow-left" /> {translate("Kembali")}
              </Text>
            </button>
          </View>
        </div>
      </View>
    );
  }
}
const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

const SAVING_QUERIES = gql`
  query listQuery($_id: String, $studentId: String) {
    allStudentBySessionId(sessionId: $_id) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }

    allTopUpStudentSaving(studentId: $studentId) {
      _id
      nominal
      attachmentFileUrl

      status
      confirmedAt

      reasonForCancellation
      _createdAt
    }
  }
`;

export default withRouter(
  withI18n("saving")((props) => {
    urlValues = queryString.parse(props.location.search);

    let studentId = urlValues.studentId ? urlValues.studentId : "";

    return (
      <Query
        query={SAVING_QUERIES}
        client={mwvClient}
        variables={{
          _id: urlValues.sessionId,
          studentId,
          // x: studentId,
        }}
      >
        {({ error, loading, data, refetch }) => (
          <div>
            <StudentSavingTopUpHistory
              {...props}
              error={error}
              loading={loading}
              studentSaving={
                data && data.studentSaving ? data.studentSaving : []
              }
              allTopUpStudentSaving={
                data && data.allTopUpStudentSaving
                  ? data.allTopUpStudentSaving
                  : []
              }
              refetch={refetch}
            />
          </div>
        )}
      </Query>
    );
  })
);
