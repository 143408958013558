import React, { Component } from "react";
import {
  Text,
  View,
  ScrollView,
  StatusBar,
  Image,
  Linking,
} from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import ProfileImage from "../components/ProfileImage";
import ListSection from "../components/ListSection";
import TouchableSectionItem from "../components/TouchableSectionItem";
import {
  faUser,
  faLock,
  faLifeRing,
  faInfoCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { ApolloConsumer, Query, Mutation } from "react-apollo";
import gql from "graphql-tag";
import firebaseConfig from "../firebaseConfig.json";
import "firebase/messaging";
import firebase from "firebase/app";
import { withI18n, translate } from "../libs/withI18n";

class Account extends Component {
  state = {
    loading: false,
    session: {},
    deviceInfo: {},
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession);

    if (accountSession !== null) {
      // this.props.history.push({
      //   pathname: "/account",
      //   search: `?sessionId=${accountSession._id}`
      // });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  handleChangeProfileImage = (e) => {};

  handleLogout = () => {
    if (
      window.confirm(
        `${translate("Apakah Anda yakin untuk keluar dari aplikasi")} ?`
      )
    ) {
      localforage.clear();
      this.props.history.push({
        pathname: "/login",
      });
      setTimeout(async () => {
        if (!firebase.apps.length) {
          // console.log("Initialize firebase...");
          firebase.initializeApp(firebaseConfig);
        }
        firebase
          .auth()
          .signOut()
          .then(
            function () {
              console.log("Signed Out");
            },
            function (error) {
              console.error("Sign Out Error", error);
            }
          );
        let fcmToken = await firebase.messaging().getToken();
        // console.log({ fcmToken });
        await this.props.logOutAndUnsubscribe({
          variables: {
            fcmToken,
          },
        });
        // addNotification({
        //   message: "Logout success",
        //   level: "success"
        // });
        console.log("Logout...");
      }, 100);
    }
  };

  render() {
    const { currentUser } = this.props;
    return (
      <View style={{ flex: 1 }}>
        <StatusBar
          backgroundColor="#1696ff"
          barStyle="light-content"
          animated={true}
        />
        <div className="fixed-top">
          <HeaderBar
            title={currentUser ? currentUser.name : <Text>Akun Saya</Text>}
          />
        </div>
        <BackgroundImage />
        <ScrollView style={{ marginTop: 55, marginBottom: 55 }}>
          <ProfileImage
            points={"0"}
            balance={"0"}
            profileImageUrl={null}
            onChangeProfileImage={this.handleChangeProfileImage}
          />
          <ListSection>
            <TouchableSectionItem
              onPress={(e) =>
                this.props.history.push({
                  pathname: "/my_profile",
                })
              }
              icon={faUser}
              label="My Profile"
            />
            <TouchableSectionItem
              // onPress={e => {
              //   this.props.navigation.navigate("ChangePassword", {
              //     currentUser
              //   });
              // }}
              onPress={(e) =>
                this.props.history.push({
                  pathname: "/change_password",
                })
              }
              icon={faLock}
              label="Change Password"
            />
          </ListSection>

          <ListSection>
            <TouchableSectionItem
              onPress={(e) => {
                this.props.history.push({
                  pathname: `/help`,
                  // search: `?sessionId=${this.state.session._id}`
                });
              }}
              icon={faLifeRing}
              label="Help Center"
            />
            <TouchableSectionItem
              // onPress={e => {
              //   this.props.navigation.navigate("TermsOfService");
              // }}
              icon={faInfoCircle}
              label="Terms of Service"
            />
            <TouchableSectionItem
              onPress={(e) => {
                const link =
                  "http://play.google.com/store/apps/details?id=com.inimedia.schooltalk";
                Linking.canOpenURL(link).then(
                  (supported) => {
                    supported && Linking.openURL(link);
                  },
                  (err) => console(err)
                );
              }}
              icon={
                <Image
                  style={{
                    width: 32,
                    height: 32,
                    marginLeft: -3,
                    resizeMode: "contain",
                  }}
                  source={require("../assets/logo.schooltalk.png")}
                />
              }
              label={
                <Text style={{ fontSize: 16, marginLeft: -2 }}>
                  Rate Us Five Stars{"\n"}
                  {this.state.deviceInfo ? (
                    <Text style={{ color: "#1696ff", fontSize: 12 }}>
                      School Talk version {this.state.deviceInfo.version}
                    </Text>
                  ) : null}
                </Text>
              }
            />
          </ListSection>
          <ListSection>
            <TouchableSectionItem
              onPress={this.handleLogout}
              icon={faSignOutAlt}
              label="Log Out"
            />
          </ListSection>
        </ScrollView>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const QUERY = gql`
  query currentUser {
    currentUser {
      _id
      name
      email
      phone
      status
      roles
      subscribedTopics
      profileImageUrl
    }
  }
`;

const LOGOUT = gql`
  mutation logOutAndUnsubscribe($fcmToken: String!) {
    logOutAndUnsubscribe(fcmToken: $fcmToken)
  }
`;

export default withI18n("account")((props) => (
  <ApolloConsumer>
    {(client) => (
      <Mutation mutation={LOGOUT}>
        {(logOutAndUnsubscribe) => (
          <Query query={QUERY}>
            {({ error, loading, data, refetch }) => (
              <div>
                <Account
                  {...props}
                  client={client}
                  error={error}
                  loading={loading}
                  currentUser={
                    data && data.currentUser
                      ? data.currentUser
                      : { name: "Loading..." }
                  }
                  refetch={refetch}
                  logOutAndUnsubscribe={logOutAndUnsubscribe}
                />
              </div>
            )}
          </Query>
        )}
      </Mutation>
    )}
  </ApolloConsumer>
));
