import React, { Component } from "react";
import { View, StatusBar, Text, TouchableOpacity } from "react-native-web";
import BackgroundImage from "../components/BackgroundImage";
import HeaderBar from "../components/HeaderBar";
import localforage from "localforage";
import NavigationWithFlex from "../components/NavigationWithFlex";
import { Query } from "react-apollo";
import gql from "graphql-tag";
import { withRouter } from "react-router";
import ApolloClient from "apollo-boost";
import queryString from "query-string";
import { QrReader } from "react-qr-reader";
import lodash from "lodash";

let urlValues;
class QrCode extends Component {
  state = {
    // Adhe
    // username: __DEV__ ? "083854430431" : "",
    // password: __DEV__ ? "123123" : "",

    // Rama
    // username: __DEV__ ? "089624809625" : "",
    // password: __DEV__ ? "123123" : "",

    username: "083831114477",
    password: "3335557",

    loading: false,
    keyword: "",
    deviceInfo: {},
    session: {},
  };

  componentDidMount = async () => {
    let accountSession = await localforage.getItem(
      "accountSession",
      (err, val) => {
        if (err !== null) {
          return null;
        }
        return val;
      }
    );

    accountSession = JSON.parse(accountSession);

    // console.log(accountSession);

    urlValues = queryString.parse(this.props.location.search);
    let result = await mwvClient.query({
      query: ALL_QUERIES,
      variables: {
        sessionId: accountSession._id,
      },
    });
    let employees =
      result.data && result.data.allEmployeesBySessionId
        ? result.data.allEmployeesBySessionId
        : [];

    if (accountSession !== null) {
      this.setState({
        session: accountSession,
        username: accountSession.Account.name,
        selectedEmployeeId:
          urlValues.employeeId || lodash.get(employees, ["0", "_id"]),
      });
    } else {
      this.props.history.push({
        pathname: "/login",
      });
    }
  };

  openPage = (item) => (e) => {
    this.props.history.push({
      pathname: "/help_item_descriptions",
      search: `?sessionId=${this.state.session._id}&categoryId=${urlValues.categoryId}&categoryName=${urlValues.categoryName}&itemId=${item._id}`,
    });
  };

  refresh = () => {
    window.location.reload();
  };

  onResult = (result, error) => {
    if (!!result) {
      let stringResult = lodash.get(result, "text", "");
      if (stringResult) {
        stringResult = stringResult.split(":");
        const resultKey = stringResult[0];
        const resultValue = stringResult[1];

        if (resultKey === "ClassroomId") {
          return this.props.history.push({
            pathname: "/daftar_mapel_presensi",
            search: `?sessionId=${this.state.session._id}&classroomId=${resultValue}&employeeId=${this.state.selectedEmployeeId}`,
          });
        } else if (resultKey === "ClassroomId__presensiSiswa") {
          return this.props.history.push({
            pathname: "/student_data_presensi",
            search: `?sessionId=${this.state.session._id}&classroomId=${resultValue}`,
          });
        } else {
          console.info(result);
        }
      }
    }
  };

  render() {
    return (
      <View style={{ flex: 1 }}>
        <div className="fixed-top">
          <StatusBar
            backgroundColor="#1696ff"
            barStyle="light-content"
            animated={true}
          />
        </div>

        <BackgroundImage />

        <div className="fixed-top">
          <HeaderBar
            title={
              <Text>
                <i
                  className="fas fa-qrcode"
                  style={{
                    fontSize: 20,
                  }}
                />{" "}
                Scan QR
              </Text>
            }
            right={
              <TouchableOpacity onPress={this.refresh}>
                <Text
                  style={{
                    paddingRight: 12,
                    paddingLeft: 20,
                    color: "white",
                    fontSize: 18,
                    paddingVertical: 2,
                  }}
                >
                  Closed
                </Text>
              </TouchableOpacity>
            }
          />
        </div>

        <View
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            marginBottom: 80,
            marginTop: 70,
          }}
        >
          <QrReader
            onResult={this.onResult}
            constraints={{
              facingMode: "environment",
            }}
            style={{ width: "100%" }}
          />
        </View>

        <NavigationWithFlex
          history={this.props.history}
          session={this.state.session}
        />
      </View>
    );
  }
}

const ALL_QUERIES = gql`
  query allQueries($sessionId: String) {
    allEmployeesBySessionId(sessionId: $sessionId) {
      _id
      name
      SchoolInformation {
        _id
        name
      }
      photoUrl
    }
  }
`;

const mwvClient = new ApolloClient({
  uri: "https://api.mwv.softwaresekolah.co.id/graphql",
  // uri: "http://localhost:9001/graphql",
});

export default withRouter((props) => (
  <Query
    query={ALL_QUERIES}
    client={mwvClient}
    variables={{
      sessionId: urlValues && urlValues.sessionId ? urlValues.sessionId : "",
    }}
  >
    {({ error, loading, data, refetch }) => (
      <div>
        <QrCode
          {...props}
          error={error}
          loading={loading}
          refetch={refetch}
          allEmployees={
            data && data.allEmployeesBySessionId
              ? data.allEmployeesBySessionId
              : []
          }
        />
      </div>
    )}
  </Query>
));
